import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { hasProp } from 'utils/object';
import { Text } from 'components/atoms';
import { InputError } from 'components/molecules';
import { Button } from 'reactstrap';

class FileInput extends Component {
	constructor(props) {
		super(props);

		this.state = {};
		this.input = React.createRef();
	}

	handleInputClick = e => {
		e.preventDefault();
		this.input.click();
	};

	onChange = e => {
		this.props.onChange(e);
	};

	render() {
		const {
				className,
				dataCy,
				error,
				fileType,
				hasError,
				id,
				label,
				name,
				placeholder,
				required,
				value
			} = this.props,
			fileName = hasProp(value, 'name') ? value.name : placeholder,
			fileNameClassProps = classnames('label mb-0', required && 'required');

		return (
			<div
				className={classnames('file-input-wrapper', className && className)}
				id={id}
				data-cy={`file-input-wrapper-${dataCy}`}
			>
				<div className="file-input-container">
					<div className="flex align-center">
						<Button className="label" size="lg" color="primary" onClick={this.handleInputClick}>
							<label className="pointer mb-0" htmlFor={name} data-cy={`label-${dataCy}`}>
								{label}
							</label>
						</Button>
						<Text dataCy={`value-${dataCy}`} className={fileNameClassProps}>
							{fileName}
						</Text>
					</div>
					<input
						data-cy={`input-${dataCy}`}
						id={name}
						name={name}
						type="file"
						onChange={this.onChange}
						hidden="true"
						ref={c => (this.input = c)}
						accept={fileType ? fileType : undefined}
					/>
				</div>
				{hasError && <InputError text={error} dataCy={`file-${name}-error`} />}
			</div>
		);
	}
}

FileInput.defaultProps = {
	label: 'Lorem.. Label',
	placeholder: 'Lorem.. Placeholder'
};

FileInput.propTypes = {
	className: PropTypes.string,
	dataCy: PropTypes.string,
	error: PropTypes.string,
	fileType: PropTypes.string,
	hasError: PropTypes.bool,
	id: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	// translate: PropTypes.func.isRequired,
	value: PropTypes.object
};

export default FileInput;
