import * as t from 'actions/requests/types';
import { hasProp } from 'utils/object';

/**
 * Redux_LoaderState
 * @interface Redux_LoaderState
 */

const requestsDefaultState = {};

export default (state = requestsDefaultState, action) => {
	switch (action.type) {
		case t.FETCH_SUCCESS: {
			if (hasProp(action.payload, 'url')) {
				return {
					...state,
					[action.payload.url]: { lastRequest: new Date(), data: action.payload.data }
				};
			} else {
				return { ...state, [action.payload]: new Date() };
			}
		}
		case t.FETCH_ERROR:
		case t.CLEAR_FETCH: {
			let newState = Object.assign({}, state);
			if (action.payload === 'ALL') {
				newState = {};
			} else {
				delete newState[action.payload];
			}
			return newState;
		}

		default:
			return state;
	}
};
