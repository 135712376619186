import WelcomePhrase from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { hasProp } from 'utils/object';

const mapStateToProps = state => {
	return {
		translate: getTranslate(state.locale),
		isAuthed: state.users && state.users.authenticated,
		firstName: hasProp(state.users, 'user.name.first') ? state.users.user.name.first : '',
		points: hasProp(state.users, 'user.points') ? state.users.user.points : '0'
	};
};

export default connect(mapStateToProps)(WelcomePhrase);
