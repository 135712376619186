import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Text } from 'components/atoms';

const DashboardGetConnectedButtons = ({ translate, canRedeem }) => {
	return (
		<div className="center dashboard-buttons">
			{canRedeem && (
				<Text>
					<Link to="/rewards" data-cy="use-points-link">
						{translate('Rewards.useMyPoints')}
					</Link>
				</Text>
			)}
			<Text>
				<Link to="/history" data-cy="history-link">
					{translate('Rewards.viewMyHistory')}
				</Link>
			</Text>
		</div>
	);
};

DashboardGetConnectedButtons.defaultProps = {};

DashboardGetConnectedButtons.propTypes = {
	translate: PropTypes.func.isRequired,
	canRedeem: PropTypes.bool
};

export default DashboardGetConnectedButtons;
