import Social from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => {
	const socials = state.details.restaurant && state.details.restaurant.social ? state.details.restaurant.social : {};

	return { socials, translate: getTranslate(state.locale) };
};

export default connect(mapStateToProps)(Social);
