import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SearchTopBar } from 'components/molecules';

/**
 * [SearchPageTopBar description]
 * @extends Component
 */
class SearchPageTopBar extends Component {
	render() {
		const {
			children,
			filters,
			onSearch,
			results,
			searchErrType,
			selectedFiltersCount,
			showList,
			setupFlow
		} = this.props;
		return (
			<SearchTopBar
				locationCount={results.length}
				isListDisplayed={showList}
				cuisineItems={filters.cuisine}
				dietaryItems={filters.dietaryPreferences}
				amenityItems={filters.amenities}
				activeFiltersLength={selectedFiltersCount}
				onSearch={onSearch}
				searchErrType={searchErrType}
				setupFlow={setupFlow}
			>
				{children}
			</SearchTopBar>
		);
	}
}

SearchPageTopBar.defaultProps = {};

/**
 * [propTypes description]
 * @interface SearchPageTopBarProps
 *
 * @example {}
 */
SearchPageTopBar.propTypes = {
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	filters: PropTypes.object.isRequired,
	onSearch: PropTypes.func.isRequired,
	results: PropTypes.array.isRequired,
	searchErrType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired, // if bool, must be false
	selectedFiltersCount: PropTypes.number.isRequired,
	showList: PropTypes.bool.isRequired,
	setupFlow: PropTypes.bool
};

export default SearchPageTopBar;
