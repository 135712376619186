/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Promise from 'bluebird';
import { Field } from 'redux-form';
import { Button, Row, Col } from 'reactstrap';
import { RegisteredConnections } from 'components/molecules';
import { ReduxFormCCTextBox, ReduxFormCCTypeDropdown, ReduxFormInput } from 'components/organisms';
import { hasProp } from 'utils/object';
import { isEqual } from 'lodash';
import { getInternalConnectionType } from 'utils/card';
import validateConenctToPointsForm from './validate';
import { SubmissionError } from 'redux-form';
import { connectionCreatedWindowEvent, DELETE_ALL_CONNECTIONS } from 'utils/windowEvents';
import { ModalPage } from 'components/molecules';
import requiredFieldsForCardTypePresentValidator from './requiredFieldsForCardTypePresentValidator';
import { preferredCCTypeDisplayText } from 'utils/card';
import isTrickCleanState from './isTrickCleanState';

class ConnectToPointsForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cardTypeField: null,
			cardNumberField: null,
			empIdField: null,
			nameOnCardField: null,
			firstNameField: null,
			middleNameField: null,
			lastNameField: null,
			emailField: null,
			connectionCount: props.connectionCount || 0
		};
	}

	componentDidMount = () => {
		window.addEventListener(DELETE_ALL_CONNECTIONS, this.resetConnectionCount);
	};

	componentDidUpdate = prevProps => {
		this.initCardTypeField(prevProps);
		this.initCardNumberField(prevProps);
		this.initEmpIdField(prevProps);
		if (this.props.isDoD) {
			this.initFirstNameField(prevProps);
			this.initMiddleNameField(prevProps);
			this.initLastNameField(prevProps);
		} else {
			this.initNameOnCardField(prevProps);
		}
		this.initEmailField(prevProps);
		this.initUnsavedChangesModal();
	};

	componentWillUnmount = () => {
		window.removeEventListener(DELETE_ALL_CONNECTIONS, this.resetConnectionCount);
	};

	initUnsavedChangesModal = () => {
		const { translate } = this.props;

		this.props.setModal({
			id: 'unsaved-changes-modal',
			header: translate('ConnectToPoints.modals.unsavedChanges.header'),
			body: translate('ConnectToPoints.modals.unsavedChanges.body'),
			primaryButtonText: translate('ConnectToPoints.modals.unsavedChanges.primaryButtonText'),
			secondaryButtonText: translate('ConnectToPoints.modals.unsavedChanges.secondaryButtonText')
		});
	};

	isRegistration = () => window.location.pathname.indexOf('register') > -1;

	hasMaxConnections = () => this.state.connectionCount === 10;

	handleResetCardTypeField = prevProps => {
		let shouldClearField =
			hasProp(prevProps, 'company.code') && !isEqual(this.props.company.code, prevProps.company.code);

		if (shouldClearField) {
			this.doClearCardTypeField();
		}
	};

	doClearCardTypeField = () => {
		let isSingleConenctionType = hasProp(this.props, 'company.supportedConnections')
			? this.props.company.supportedConnections.length === 1
			: false;

		if (isSingleConenctionType) {
			this.props.setFormValue('selectedCardType', this.props.company.supportedConnections[0]);
		} else {
			this.props.setFormValue('selectedCardType', undefined);
		}
	};

	resetConnectionCount = () => {
		this.setState({ connectionCount: 0 });
	};

	isTrickCleanState = () => {
		if (this.hasMaxConnections()) {
			// this should never happen as the form should be hidden if the user has max connections.
			return true;
		}

		return isTrickCleanState(this.props.formVals, this.props.isDoD);
	};

	fireSubmitForm = () => {
		let form = document.getElementById('connect-to-points-form');
		const event = new Event('submit');
		form.dispatchEvent(event);
	};

	submit = async vals => {
		return await this.doSubmit(vals);
	};

	proceed = () => {
		if (this.isRegistration()) {
			this.goToNextStep();
		} else {
			this.goToProfile();
		}
	};

	doSubmit = async vals => {
		const { formVals, isDoD, onSubmitSuccess } = this.props;
		const card = hasProp(formVals, 'selectedCardType') ? formVals.selectedCardType : false;
		const idType = getInternalConnectionType(card.type);

		const fullNumberRequired = card.fullNumberRequired === undefined ? true : card.fullNumberRequired;
		let errors = validateConenctToPointsForm(vals, idType, fullNumberRequired, isDoD);

		if (Object.keys(errors).length) {
			throw new SubmissionError(errors);
		} else {
			const resErrors = await this.createConnection(vals, idType);
			console.log('%c errors', 'color: limegreen', errors);
			if (resErrors && Object.keys(resErrors).length) {
				console.log('%c resErrors', 'color: limegreen', resErrors);
				throw new SubmissionError(resErrors);
			}
			onSubmitSuccess && onSubmitSuccess();
			return true;
		}
	};

	createConnection = (vals, idType) => {
		let cardDetails = {
			id: vals.selectedCardType.id,
			number: vals.cardNumber,
			company: this.props.company.code
		};
		if (!this.props.isDoD) {
			cardDetails.nameOnCard = vals.nameOnCard;
		} else {
			cardDetails.firstName = vals.firstName;
			cardDetails.middleName = vals.middleName;
			cardDetails.lastName = vals.lastName;
		}

		if (idType === 'empID') {
			cardDetails.employeeId = vals.employeeId;
		}

		return new Promise(resolve => {
			this.props.submitConnection(cardDetails, async res => {
				if (res.code === 200) {
					this.handleCreateSuccess(vals);
					if (this.props.isDoD && !this.isRegistration()) {
						this.props.updateUser(
							{
								name: {
									first: vals.firstName,
									middle: vals.middleName,
									last: vals.lastName
								}
							},
							() => {}
						);
					} else {
						await this.props.refreshUser();
					}
				} else {
					this.handleCreateError(res, resolve);
				}
				resolve();
			});
		});
	};

	handleCreateSuccess = vals => {
		connectionCreatedWindowEvent({
			cardType: vals.selectedCardType.cardType,
			last4: vals.cardNumber.substring(vals.cardNumber.length - 4),
			nameOnCard: vals.nameOnCard,
			employeeId: vals.employeeId
		});
		this.setState({ connectionCount: this.state.connectionCount + 1 });
		this.resetForm();
	};

	handleCreateError = (code, resolve) => {
		if (code === 409) {
			resolve({ cardNumber: 'connectionExists' });
		}
		resolve({ createError: code });
	};

	handleNextClick = async () => {
		const { isDoD, formVals } = this.props;

		if (this.isTrickCleanState()) {
			this.proceed();
		}

		if (requiredFieldsForCardTypePresentValidator(formVals, isDoD)) {
			let didSubmit = await this.props.handleSubmit(this.submit)();
			if (didSubmit === true) {
				this.proceed();
			}
		} else {
			this.handleLeavePage();
		}
	};

	handleLeavePage = () => {
		if (this.isTrickCleanState(this.props.formVals)) {
			this.proceed();
		} else {
			this.props.toggleModal(true);
		}
	};

	resetForm = () => {
		this.props.reset();
		this.props.setFormValue('nameOnCard', '');
		this.props.setFormValue('email', '');
		this.props.setFormValue('employeeId', '');
	};

	goToProfile = () => {
		this.props.history.push('/profile');
	};

	goToNextStep = () => {
		this.props.nextStep();
	};

	onSkip = () => {
		this.props.showShowSkipModal();
	};

	render() {
		const { cards, isDoD, translate } = this.props;
		if (!cards || cards.length === 0) {
			return isDoD ? this.dodForm() : this.form();
		}

		let cardDislpayText = cards && cards.length ? preferredCCTypeDisplayText(cards[0].cardType) : false;
		let issuer = cards[0].issuer.name;

		let companyName = `${issuer} (${cardDislpayText})`;
		if (cardDislpayText.toLowerCase() === issuer.toLowerCase() || cardDislpayText.length === 0) {
			companyName = `${issuer}`;
		}

		return (
			<React.Fragment>
				{cards && cards.length === 1 && (
					<Col className="card-type mb-0" xs="12" md="6">
						<div>
							<span className="label">
								{translate('ConnectToPoints.inputs.creditcardType.singleCardLabel')}:{' '}
							</span>
							<strong className="company-name">{companyName}</strong>
						</div>
					</Col>
				)}
				{isDoD ? this.dodForm() : this.form()}
			</React.Fragment>
		);
	}

	form = () => {
		const { cards, modal, showRegistered } = this.props,
			card = hasProp(this.props.formVals, 'selectedCardType') ? this.props.formVals.selectedCardType : false,
			idType = getInternalConnectionType(card.type);

		return (
			<form name="connect-to-points" onSubmit={this.props.handleSubmit(this.submit)} id="connect-to-points-form">
				<ModalPage isOpen={modal.isOpen} secondaryClick={this.proceed} primaryClick={this.fireSubmitForm} />
				<Row>
					<Col xs="12" lg={showRegistered ? '6' : '12'}>
						<Col xs="12" className={classnames(!this.props.isLV && cards && cards.length > 1 && 'mt-2')}>
							{this.state.cardTypeField}
						</Col>
						<Col xs="12">{this.state.nameOnCardField}</Col>

						{card && (
							<React.Fragment>
								<Col>{this.state.cardNumberField}</Col>
								{idType === 'empID' && <Col>{this.state.empIdField}</Col>}
								{idType === 'email' && <Col>{this.state.emailField}</Col>}
							</React.Fragment>
						)}
						<Row
							className={
								this.isRegistration() ? classnames('') : classnames('form-buttons with-help mb-6')
							}
						>
							{this.buttons()}
						</Row>
					</Col>

					{showRegistered && (
						<Col xs="12" lg="6" className={classnames(cards && cards.length === 1 && 'mt-6')}>
							<RegisteredConnections />
						</Col>
					)}
				</Row>
			</form>
		);
	};

	dodForm = () => {
		const { cards, isLV, modal } = this.props,
			card = hasProp(this.props.formVals, 'selectedCardType') ? this.props.formVals.selectedCardType : false;

		return (
			<form name="connect-to-points" onSubmit={this.props.handleSubmit(this.submit)} id="connect-to-points-form">
				<ModalPage isOpen={modal.isOpen} secondaryClick={this.proceed} primaryClick={this.fireSubmitForm} />
				<Row className={classnames(isLV ? 'row-reverse' : 'column-reverse')}>
					<Col xs="12" lg="6">
						<Col xs="12" className={classnames(!this.props.isLV && cards && cards.length > 1 && 'mt-2')}>
							{this.state.cardTypeField}
						</Col>

						{card && (
							<React.Fragment>
								<Col>{this.state.cardNumberField}</Col>
							</React.Fragment>
						)}
						{<Row className="form-buttons with-help mb-6 row-reverse">{isLV && this.buttons()}</Row>}
					</Col>

					<Col xs="12" lg="6">
						<Col xs="12">{this.state.firstNameField}</Col>
						<Col xs="12">{this.state.middleNameField}</Col>
						<Col xs="12">{this.state.lastNameField}</Col>
					</Col>
				</Row>
				{!isLV && this.buttons()}
			</form>
		);
	};

	buttons = () => {
		const { buttons, isDoD, translate } = this.props;
		const { connectionCount } = this.state,
			disabled = connectionCount === 10;

		if (buttons) {
			return buttons({ submitDisabled: disabled });
		}

		if (isDoD) {
			return this.dodButtons();
		} else {
			const nextButton = this.nextButton(),
				addCardOffset = this.isRegistration() ? 0 : nextButton ? '0' : '6',
				columnWidth = this.isRegistration() && !nextButton ? '12' : '6';

			return (
				<React.Fragment>
					<Col xs={{ size: columnWidth, offset: addCardOffset }}>
						<Button
							disabled={disabled}
							type="submit"
							color="secondary"
							className="mt-0 ml-0 mr-0 full-width"
							id="add-card-connect-to-points"
						>
							{translate('Profile.payment.notConnected.connectBtn')}
						</Button>
					</Col>

					{nextButton && <Col xs="6">{nextButton}</Col>}
				</React.Fragment>
			);
		}
	};

	dodButtons = () => {
		const { isSV, isMV, isLV } = this.props;
		return (
			<Row
				className={classnames(
					'full-width form-buttons mb-6',
					isSV && 'full-width',
					isMV && 'justify-center',
					isLV && 'row-reverse'
				)}
			>
				<Col xs="12" md="5" lg="6">
					{this.nextButton()}
				</Col>
			</Row>
		);
	};

	nextButton = () => {
		const { connectionCount } = this.state,
			{ isDoD } = this.props,
			showNextButton = (this.isRegistration() && connectionCount > 0) || !this.isRegistration() || isDoD,
			color = 'primary';

		return showNextButton ? (
			<Button
				onClick={this.handleNextClick}
				className="mt-0 ml-0 mr-0 full-width"
				id="next-connect-to-points"
				color={color}
			>
				{this.props.translate(`ConnectToPoints.primary_button`)}
			</Button>
		) : null;
	};

	initCardTypeField = prevProps => {
		const hasCompany =
			(this.props.initialValues.selectedCardType && prevProps.initialValues.selectedCardType === undefined) ||
			!isEqual(this.props.company, prevProps.company) ||
			(!this.state.cardTypeField && this.props.company);

		const me = this;
		if (hasCompany) {
			this.handleResetCardTypeField(prevProps);
			const field = (
				<Field
					name="selectedCardType"
					component={field => {
						if (!field.input.value && this.props.initialValues.selectedCardType) {
							field.input.onChange(this.props.initialValues.selectedCardType);
						}
						const doBeforeChange = option => {
							if (field.input.value && option.id !== field.input.value.id) {
								me.props.setFormValue('cardNumber', '');
							}
						};

						return (
							<ReduxFormCCTypeDropdown
								field={field}
								doBeforeChange={doBeforeChange}
								disabled={this.state.connectionCount === 10}
								required={true}
							/>
						);
					}}
				/>
			);

			this.setState({ cardTypeField: field });
		}
	};

	initCardNumberField = prevProps => {
		if (
			(this.props.formVals.selectedCardType && !this.state.cardNumberField) ||
			!isEqual(this.props.formVals.selectedCardType, prevProps.formVals.selectedCardType)
		) {
			const card = this.props.formVals.selectedCardType,
				field = (
					<Field
						name="cardNumber"
						component={field => {
							return (
								<ReduxFormCCTextBox
									field={field}
									card={card}
									disabled={this.state.connectionCount === 10}
									required={true}
									isDoD={this.props.isDoD}
								/>
							);
						}}
					/>
				);
			this.setState({ cardNumberField: field });
		}
	};

	initEmpIdField = prevProps => {
		const card = hasProp(this.props.formVals, 'selectedCardType') ? this.props.formVals.selectedCardType : false;

		if (!card) return null;

		if (
			!this.state.empIdField ||
			!isEqual(this.props.formVals.selectedCardType, prevProps.formVals.selectedCardType)
		) {
			let field = (
				<Field
					name="employeeId"
					component={field => {
						return (
							<ReduxFormInput
								field={field}
								inputProps={{
									dataCy: 'employeeId-field',
									name: 'input_employeeId',
									id: 'input-employeeId',
									error: this.props.translate('ConnectToPoints.inputs.employeeId.errorText'),
									hasError: !field.meta.valid,
									label: this.props.translate('ConnectToPoints.inputs.employeeId.label'),
									help: this.props.translate('ConnectToPoints.inputs.employeeId.help'),
									example:
										card.instructions.connection === 'null' ? '' : card.instructions.connection,
									disabled: this.state.connectionCount === 10,
									required: true
									// value: prevProps.formVals.employeeId
								}}
							/>
						);
					}}
				/>
			);

			this.setState({ empIdField: field });
		}
	};

	initNameOnCardField = prevProps => {
		if (!this.state.nameOnCardField || prevProps.isLV !== this.props.isLV) {
			let field = (
				<Field
					name="nameOnCard"
					component={field => {
						return (
							<ReduxFormInput
								field={field}
								inputProps={{
									// className: classnames(this.props.isLV && 'mt-0', !this.props.isLV && 'mt-8'),
									dataCy: 'nameOnCard-field',
									name: 'input_nameOnCard',
									id: 'input-nameOnCard',
									error: this.props.translate('ConnectToPoints.inputs.cardholderName.errorText'),
									hasError: !field.meta.valid,
									label: this.props.translate('ConnectToPoints.inputs.cardholderName.label'),
									help: this.props.translate('ConnectToPoints.inputs.cardholderName.help'),
									example: this.props.translate('ConnectToPoints.inputs.cardholderName.example'),
									disabled: this.state.connectionCount === 10,
									required: true
								}}
							/>
						);
					}}
				/>
			);
			this.setState({ nameOnCardField: field });
		}
	};

	initFirstNameField = prevProps => {
		if (!this.state.firstNameField || prevProps.isLV !== this.props.isLV) {
			let field = (
				<Field
					name="firstName"
					component={field => {
						return (
							<ReduxFormInput
								field={field}
								inputProps={{
									// className: classnames(this.props.isLV && 'mt-0', !this.props.isLV && 'mt-8'),
									dataCy: 'firstName-field',
									name: 'input_firstName',
									id: 'input-firstName',
									error: this.props.translate('ConnectToPoints.inputs.dod-firstName.errorText'),
									hasError: !field.meta.valid,
									label: this.props.translate('ConnectToPoints.inputs.dod-firstName.label'),
									disabled: this.state.connectionCount === 1,
									required: true
								}}
							/>
						);
					}}
				/>
			);
			this.setState({ firstNameField: field });
		}
	};

	initMiddleNameField = prevProps => {
		if (!this.state.firstNameField || prevProps.isLV !== this.props.isLV) {
			let field = (
				<Field
					name="middleName"
					component={field => {
						return (
							<ReduxFormInput
								field={field}
								inputProps={{
									// className: classnames(this.props.isLV && 'mt-0', !this.props.isLV && 'mt-8'),
									dataCy: 'middleName-field',
									name: 'input_middleName',
									id: 'input-middleName',
									error: this.props.translate('ConnectToPoints.inputs.dod-middleName.errorText'),
									hasError: !field.meta.valid,
									label: this.props.translate('ConnectToPoints.inputs.dod-middleName.label'),
									disabled: this.state.connectionCount === 1,
									required: false
								}}
							/>
						);
					}}
				/>
			);
			this.setState({ middleNameField: field });
		}
	};

	initLastNameField = prevProps => {
		if (!this.state.lastNameField || prevProps.isLV !== this.props.isLV) {
			let field = (
				<Field
					name="lastName"
					component={field => {
						return (
							<ReduxFormInput
								field={field}
								inputProps={{
									// className: classnames(this.props.isLV && 'mt-0', !this.props.isLV && 'mt-8'),
									dataCy: 'lastName-field',
									name: 'input_lastName',
									id: 'input-lastName',
									error: this.props.translate('ConnectToPoints.inputs.dod-lastName.errorText'),
									hasError: !field.meta.valid,
									label: this.props.translate('ConnectToPoints.inputs.dod-lastName.label'),
									disabled: this.state.connectionCount === 1,
									required: true
								}}
							/>
						);
					}}
				/>
			);
			this.setState({ lastNameField: field });
		}
	};

	initEmailField = () => {
		if (!this.state.emailField) {
			let field = (
				<Field
					name="email"
					component={field => {
						return (
							<ReduxFormInput
								field={field}
								inputProps={{
									dataCy: 'email-field',
									name: 'input_email',
									id: 'input-email',
									error: this.props.translate('ConnectToPoints.inputs.employeeEmail.errorText'),
									hasError: !field.meta.valid,
									label: this.props.translate('ConnectToPoints.inputs.employeeEmail.label'),
									help: this.props.translate('ConnectToPoints.inputs.employeeEmail.help'),
									example: this.props.translate('ConnectToPoints.inputs.employeeEmail.example'),
									disabled: this.state.connectionCount === 10,
									required: true
								}}
							/>
						);
					}}
				/>
			);
			this.setState({ emailField: field });
		}
	};
}

ConnectToPointsForm.defaultProps = {
	showRegistered: true
};

ConnectToPointsForm.propTypes = {
	buttons: PropTypes.any,
	cards: PropTypes.array,
	company: PropTypes.object,
	connectionCount: PropTypes.number.isRequired,
	formVals: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	isDoD: PropTypes.bool.isRequired,
	isSV: PropTypes.bool.isRequired,
	isMV: PropTypes.bool.isRequired,
	isLV: PropTypes.bool.isRequired,
	modal: PropTypes.object.isRequired,
	nextStep: PropTypes.func.isRequired,
	onSubmitSuccess: PropTypes.func,
	refreshUser: PropTypes.func.isRequired,
	reset: PropTypes.func.isRequired,
	setFormValue: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	showRegistered: PropTypes.bool,
	showShowSkipModal: PropTypes.func.isRequired,
	submitConnection: PropTypes.func.isRequired,
	toggleModal: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired
};

export default ConnectToPointsForm;
