import PaymentNotConnected from './ui-component';
import { withRouter } from 'react-router-dom';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { startChangeStep } from 'actions/registration';

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	startChangeStep: step => dispatch(startChangeStep(step))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentNotConnected));
