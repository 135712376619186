import { containsNoSpaceValidator, lengthValidator } from 'utils/form/validators';

/**
 * Validates user name.  If fails, returns lang file keys for error messages
 * @param  {string} username   The username string provided by the user.
 * @param  {Number} [length=3] The min length the username must be.
 * @return {[string]}          An array of lang file keys that correspond to error messages.
 */
const usernameValidator = (username, length = 3) => {
	let errors = [],
		passesLength = lengthValidator(username, length),
		passesSpaceCheck = containsNoSpaceValidator(username);

	if (!passesLength) {
		errors.push('Registration.requirement-boxes.username.1');
	}

	if (!passesSpaceCheck) {
		errors.push('Registration.requirement-boxes.username.2');
	}

	return passesLength && passesSpaceCheck ? undefined : errors;
};

export default usernameValidator;
