import React from 'react';
import PropTypes from 'prop-types';
import { PageHeaderTitle, Text } from 'components/atoms';
import { PageHeader } from 'components/molecules';
import { Button } from 'reactstrap';
import { LoadingBase } from 'components/atoms';
import { getLowerCasedParamsPreservingSpecialCharacters } from 'utils/url';

const AutoEnrollUserExpiredToken = ({ error, generateNewAutoEnrollToken, isLoading, linkSent, translate }) => {
	const params = getLowerCasedParamsPreservingSpecialCharacters(),
		token = params.token;
	return (
		<div className="flex row justify-center" data-cy="auto-enroll-expired-token">
			<PageHeader hideImage={true}>
				<PageHeaderTitle align="left" dataCy="update-personal-title">
					{translate('AutoEnrollUserForm.expiredToken.header')}
				</PageHeaderTitle>
			</PageHeader>
			<div className="flex-column center">
				{!isLoading && (
					<React.Fragment>
						{error === 'Token already used' && (
							<p>{translate('AutoEnrollUserForm.expiredToken.alreadyRegenerated')}</p>
						)}
						<Text>
							{error
								? error
								: linkSent
								? translate('AutoEnrollUserForm.expiredToken.sentDescription')
								: translate('AutoEnrollUserForm.expiredToken.description')}
						</Text>
					</React.Fragment>
				)}
				{!linkSent && !isLoading && !error && (
					<Button className="mt-10" color="primary" onClick={generateNewAutoEnrollToken.bind(this, token)}>
						{translate('AutoEnrollUserForm.expiredToken.cta')}
					</Button>
				)}
			</div>
			{isLoading && <LoadingBase message={translate('AutoEnrollUserForm.expiredToken.loading')} />}
		</div>
	);
};

AutoEnrollUserExpiredToken.defaultProps = {};

AutoEnrollUserExpiredToken.propTypes = {
	error: PropTypes.oneOf([false, PropTypes.oneOfType([PropTypes.string])]).isRequired,
	generateNewAutoEnrollToken: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	linkSent: PropTypes.bool.isRequired,
	translate: PropTypes.func.isRequired
};

export default AutoEnrollUserExpiredToken;
