import DashboardConnectMessage from './ui-component';
import { connect } from 'react-redux';

import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(DashboardConnectMessage);
