import BusinessSearchMap from './ui-component';
import { connect } from 'react-redux';
import { setActivePin, setMapCardDisplayed } from 'actions/search_page';
import {
	reCenterToGeoLocation,
	setAreaSearchDisplayed,
	setCenter,
	setCenterPan,
	setZoom
} from 'actions/business_search_map';
import withSizes from 'react-sizes';
import { isSV } from 'utils/sizes';

const mapStateToProps = state => ({
	activePin: state.searchPage.activePin,
	areaSearchDisplayed: state.businessSearchMap.areaSearchDisplayed,
	center: state.businessSearchMap.center,
	centerPan: state.businessSearchMap.centerPan,
	centerToGeoLocation: state.businessSearchMap.centerToGeoLocation,
	geoLocation: {
		lat: state.geoLocation.lat,
		lng: state.geoLocation.lng
	},
	geoLocationDenied: state.geoLocation.wasDenied,
	hasGeoLocation: state.geoLocation.hasGeoLocation,
	mapCardDisplayed: state.searchPage.mapCardDisplayed,
	rehydrateFromSaveState: state.searchPage.rehydrateFromSaveState,
	results: state.businessSearchFilter.filteredResults,
	showList: state.searchPage.showList,
	zoom: state.businessSearchMap.zoom
});

const mapDispatchToProps = dispatch => ({
	reCenterToGeoLocation: shouldCenter => dispatch(reCenterToGeoLocation(shouldCenter)),
	setActivePin: pin => dispatch(setActivePin(pin)),
	setAreaSearchDisplayed: isDisplayed => dispatch(setAreaSearchDisplayed(isDisplayed)),
	setCenter: async center => await setCenter(dispatch, center),
	setCenterPan: center => dispatch(setCenterPan(center)),
	setMapCardDisplayed: isDisplayed => dispatch(setMapCardDisplayed(isDisplayed)),
	setZoom: zoom => dispatch(setZoom(zoom))
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withSizes(mapSizesToProps)(connect(mapStateToProps, mapDispatchToProps)(BusinessSearchMap));
