import React from 'react';
import PropTypes from 'prop-types';

const HamburgerIcon = ({ className, config, handleClick, translate }) => (
	<div className={`${className || ''} Di_HeaderHambMenu`} onClick={e => handleClick(e, 'Mobile_Main')}>
		<img
			className="hamburger-icon"
			src={`${config.cdn_basePath}/icons/icon-hamburger.svg`}
			alt={translate('header.dinova-alt')}
		/>
	</div>
);

HamburgerIcon.propTypes = {
	className: PropTypes.string,
	// Required
	config: PropTypes.object.isRequired,
	handleClick: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

export default HamburgerIcon;
