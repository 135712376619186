import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { each } from 'lodash';

class PageHeader extends React.Component {
	// links
	leftComponent = null;
	rightComponent = null;

	// title
	titleComponent = null;
	setComponent = component => {
		switch (component.type.name) {
			case 'PageHeaderTitle':
				this.titleComponent = component;
				break;
			default:
				break;
		}
	};

	render() {
		// prepare all classes
		const { className, children, config, width, height, cobranded, isLV, hide } = this.props;
		const componentClasses = classNames('container', 'page-header', className, cobranded ? 'cobranded' : '');

		let bannerImg = this.props.bannerImg;
		let bannerUrl = this.props.bannerUrl;
		let useCrop = this.props.useCrop;
		let useTop = this.props.useTop;
		let hideImage = this.props.hideImage;
		if (children && children.type) {
			this.setComponent(children);
		} else if (children) {
			each(children, component => {
				this.setComponent(component);
			});
		}

		let widthParam = width ? width : 1400;
		let heightParam = height ? height : 200;

		if (bannerImg && cobranded) {
			widthParam = 2800;
			heightParam = 400;
		}

		let imgSrc = useCrop
			? `${bannerImg}?w=${widthParam}&crop=edges&h=${heightParam}&fit=crop`
			: `${bannerImg}?w=${widthParam}&h=${heightParam}`;

		imgSrc = useTop ? `${imgSrc}&crop=top` : imgSrc;

		if (bannerImg && cobranded) {
			imgSrc = imgSrc.replace('&fit=crop', '');
			if (!isLV) {
				widthParam = window.innerWidth;
				heightParam = 138;
				imgSrc = `${imgSrc.replace('LV', 'SV')}?w=${widthParam}&h=${heightParam}`;
			}
		}

		let img = bannerImg ? (
			<img
				className={cobranded ? 'page-banner cobranded' : 'page-banner'}
				src={bannerImg.indexOf('static') > -1 ? bannerImg : `${config.cdn_basePath}/graphics/${imgSrc}`}
				aria-hidden={true}
				alt={cobranded ? 'Page Banner' : 'Promotional Banner'}
			/>
		) : null;

		if (bannerUrl !== undefined) {
			img = (
				<a
					href={bannerUrl}
					target="top"
					alt="2x for personal, 10x for business document"
					className="page-banner"
				>
					{img}
				</a>
			);
		}

		return (
			!hide && (
				<div className={componentClasses}>
					{bannerImg && !hideImage && (
						<div className="row">
							<div className="col">{img}</div>
						</div>
					)}
					{this.showHeaderBar && this.titleComponent && (
						<div className="row">
							<div className="col page-header-col">
								<header className="header-bar">{this.titleComponent}</header>
							</div>
						</div>
					)}
					{children}
				</div>
			)
		);
	}
}

PageHeader.defaultProps = {
	className: '',
	bannerImg: null,
	useCrop: true,
	useTop: false,
	width: null,
	height: null,
	cobranded: false,
	isLV: true,
	hideImage: false
};

PageHeader.propTypes = {
	className: PropTypes.string,
	bannerImg: PropTypes.string,
	bannerUrl: PropTypes.string,
	children: PropTypes.node,
	config: PropTypes.object,
	useCrop: PropTypes.bool,
	useTop: PropTypes.bool,
	width: PropTypes.number,
	height: PropTypes.number,
	cobranded: PropTypes.bool,
	isLV: PropTypes.bool,
	hideImage: PropTypes.bool,
	hide: PropTypes.bool
};

export default PageHeader;
