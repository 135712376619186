import RestaurantResultsList from './ui-component';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
	isLoading: state.searchPage.isLoading
	// didFetch: state.businessSearchFilter.didFetch
	// results: state.businessSearchFilter.filtere / dResults
});

export default connect(mapStateToProps)(RestaurantResultsList);
// export default RestaurantResultsList;
