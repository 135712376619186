import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { deleteAllConnectionsWindowEvent } from 'utils/windowEvents';

const DeleteConnectionsWarningModal = ({
	close,
	confirmHandler,
	connections,
	deleteConnections,
	setIsLoading,
	refreshUser,
	setCompanySearch,
	show,
	translate,
	usersCompanyName
}) => {
	const confirm = async () => {
		const promises = [];
		if (connections.length > 0) {
			setIsLoading(true, true);
		}
		connections.forEach(connection => {
			const hideLoading = false;
			promises.push(
				new Promise(resolve => {
					deleteConnections(
						connection.id,
						() => {
							resolve();
						},
						hideLoading
					);
				})
			);
		});
		await Promise.all(promises);
		if (confirmHandler) {
			confirmHandler();
		}
		await refreshUser();
		deleteAllConnectionsWindowEvent();
		setIsLoading(false, false);
		close();
	};

	const cancel = () => {
		setCompanySearch(usersCompanyName);
		close();
	};

	return (
		<Modal className="delete_connections_warning_modal" isOpen={show} centered>
			<ModalHeader>{translate('EditPersonal.confirmCompanyChangeModal.title')}</ModalHeader>

			<ModalBody>{translate('EditPersonal.confirmCompanyChangeModal.body')}</ModalBody>

			<ModalFooter>
				<Container>
					<Row>
						<Col sm="12">
							<Button block color="primary" onClick={confirm} id="confirm-company-change">
								{translate('common.continueBtn')}
							</Button>
						</Col>
						<Col sm="12">
							<Button block color="secondary" onClick={cancel} id="cancel-company-change">
								{translate('common.cancelBtn')}
							</Button>
						</Col>
					</Row>
				</Container>
			</ModalFooter>
		</Modal>
	);
};

DeleteConnectionsWarningModal.defaultProps = {};

DeleteConnectionsWarningModal.propTypes = {
	close: PropTypes.func.isRequired,
	connections: PropTypes.array.isRequired,
	confirmHandler: PropTypes.func,
	deleteConnections: PropTypes.func.isRequired,
	setIsLoading: PropTypes.func.isRequired,
	refreshUser: PropTypes.func.isRequired,
	setCompanySearch: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
	translate: PropTypes.func.isRequired,
	usersCompanyName: PropTypes.string.isRequired
};

export default DeleteConnectionsWarningModal;
