import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import classnames from 'classnames';

// import ./style.scss

/**
 * RestaurantCuisines
 * @param {RestaurantCuisinesProps} props
 */
const RestaurantCuisines = ({ cuisineAndMS, isLV }) => {
	return (
		<Text className={classnames('details-cuisines', !isLV && 'mb-0')} align={'left'} dataCy="details-page-cuisines">
			{cuisineAndMS}
		</Text>
	);
};

RestaurantCuisines.defaultProps = {};

/**
 * RestaurantCuisinesProps
 * @typedef {Object} RestaurantCuisinesProps
 */
RestaurantCuisines.propTypes = {
	cuisineAndMS: PropTypes.string,
	isLV: PropTypes.bool.isRequired
};

export default RestaurantCuisines;
