import CardFold from './ui-component';
import withSizes from 'react-sizes';
import { connect } from 'react-redux';
import { setActiveCard, setShowAbout } from 'actions/landing_page_cards';
import { isLV } from 'utils/sizes';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = (state, ownProps) => ({
	showAbout: state.landingCards.showAbout,
	isActive: state.landingCards.activeCard === ownProps.cardId,
	isAuthed: state.users.authenticated.length ? true : false,
	translate: getTranslate(state.locale),
	IMAGE_BASE_URL: state.config.cdn_basePath
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	close: () => dispatch(setActiveCard()),
	setShowAbout: show => dispatch(setShowAbout(show, ownProps.cardId))
});

const mapSizesToProps = sizes => ({
	isLV: isLV(sizes)
});

export default withSizes(mapSizesToProps)(connect(mapStateToProps, mapDispatchToProps)(CardFold));
