import React from 'react';
import PropTypes from 'prop-types';
import { ClientItem } from 'components/atoms';

/**
 * Used in {@link DetailsPage} to display contact information.
 * @param {ClientInfoProps} props
 */

class ClientInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			clients: []
		};
	}

	componentDidMount() {
		this.setState({
			clients: this.props.clients
		});
	}

	filteredList = letter => {
		console.log('letter:', letter);
		this.setState({
			clients:
				letter != ''
					? this.props.clients.filter(client => client.name.toUpperCase().startsWith(letter))
					: this.props.clients
		});
	};

	distinctLetters = clients => [...new Set(clients.map(client => client.name.charAt(0)).sort())];

	render() {
		return (
			<div className="details-contact">
				<div className="contact-letters mb-5">
					<span>
						<button onClick={() => this.filteredList('')}>All&nbsp;</button>
					</span>
					{this.distinctLetters(this.props.clients).map((letter, i) => {
						return (
							<span key={`${i}`}>
								<button onClick={() => this.filteredList(letter)}>
									{letter === '' ? 'All' : letter}&nbsp;
								</button>
							</span>
						);
					})}
				</div>
				<div className="contact-info">
					<ul>
						{this.state.clients.map((client, i) => {
							return (
								<li key={`contact-listitem-${i}`}>
									<ClientItem key={`contact-info-${i}`} client={client} />
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		);
	}
}

ClientInfo.defaultProps = {
	clients: [
		{
			name: '',
			website: '',
			isPrivate: false
		}
	]
};

/**
 * {@link ClientInfo} Props
 * @interface Props_ClientInfo
 * @property {IClient[]}
 */
ClientInfo.propTypes = {
	// Optional
	clients: PropTypes.array
};

export default ClientInfo;
