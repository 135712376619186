import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List, NoResultsNotice, RestaurantListItemNew } from 'components/molecules';
import { chain } from 'lodash';
import classnames from 'classnames';

// import "./style.scss";

/**
 * [RestaurantResultsList description]
 * @extends Component
 */
class RestaurantResultsList extends Component {
	/**
	 * Groups results by restaurant chain. Ex: Pizza Hut.
	 * @param  {[array]} restaurants An array of Restaurants returned from dinova api.
	 * @return {[array]}             The array of restaurants with sub arrays of grouped restaurants.  Ex: [Bob Evans, [Pizza Hut 1, Pizza Hut 2], McDonalds]
	 */
	groupedByRestaurants = (restaurants, shouldGroup) => {
		return shouldGroup
			? chain(restaurants)
					.groupBy(restaurant => restaurant.brand.id)
					.map(locations => locations.sort((a, b) => a.distance?.span || 0 - b.distance?.span || 0))
					.value()
			: chain(restaurants)
					.groupBy(resaurant => resaurant.id)
					.map(locations => locations.sort((a, b) => a.distance?.span || 0 - b.distance?.span || 0))
					.value();
	};

	render() {
		const {
			activePin,
			didFetch,
			hideMapPin,
			isLoading,
			results,
			showFavoriteIcon,
			showFilter,
			shouldGroup = true
		} = this.props;

		const groupedResults = this.groupedByRestaurants(results, shouldGroup),
			hasResults = !isLoading || groupedResults.length > 0,
			isLoadingList = (!didFetch && groupedResults.length === 0) || isLoading,
			noResults = didFetch && groupedResults.length === 0;

		return (
			<>
				{noResults && <NoResultsNotice />}
				{(hasResults || isLoadingList) && (
					<List
						className={classnames('business-search-results', this.props.listClassName)}
						itemComponent={RestaurantListItemNew}
						itemComponentProps={{ activePin, hideMapPin, showFilter, showFavoriteIcon }}
						items={isLoadingList ? [] : groupedResults}
						listId="search-results-list"
					/>
				)}
			</>
		);
	}
}

RestaurantResultsList.defaultProps = {};

/**
 * [propTypes description]
 * @interface RestaurantResultsListProps
 *
 * @example {}
 */
RestaurantResultsList.propTypes = {
	activePin: PropTypes.string,
	didFetch: PropTypes.bool.isRequired,
	hideMapPin: PropTypes.bool,
	isLoading: PropTypes.bool.isRequired,
	listClassName: PropTypes.string,
	results: PropTypes.array.isRequired,
	showFilter: PropTypes.bool,
	showFavoriteIcon: PropTypes.bool,
	shouldGroup: PropTypes.bool
};

export default RestaurantResultsList;
