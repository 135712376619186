import React from 'react';
import PropTypes from 'prop-types';
import OptionsList from 'components/organisms/OptionsList';

/**
 * Used in {@link DetailsPage} to show avaialable amenities.
 * @param {AmenitiesProps} props
 *
 * @example <Amenities amenities={UIAmenity[]} entertainments=["Takeout"] />
 */
const Amenities = ({ detailedAmenities, briefAmenities }) => {
	let detailed = [];
	detailedAmenities.forEach((entertainment, i) => {
		if (entertainment.text) {
			detailed.push(
				<p key={`${entertainment.title}-${i}`}>
					<span>{entertainment.title}: </span>
					{entertainment.text}
				</p>
			);
		}
	});

	return (
		<div className="amenities" data-cy="amenities-section">
			<OptionsList options={briefAmenities} dataCy="amenities-list" />
			{detailed.length > 0 && (
				<div className="detailed" data-cy="amenities-detailed">
					{detailed}
				</div>
			)}
		</div>
	);
};

Amenities.defaultProps = {
	amenities: [],
	entertainments: []
};

/**
 * IUIAmenity
 * @interface IUIAmenity
 * @property {string} text  The description text for this amenity.
 * @property {string} title The title text for this amenity.
 */

/**
 * {@link Amenities} Props
 * @interface Props_Amenities
 * @property {string[]} [briefAmenities=[]]
 * @property {IUIAmenity[]} [detailedAmenities=[]] {@link UIAmenity}
 */
Amenities.propTypes = {
	// Optional
	briefAmenities: PropTypes.array,
	detailedAmenities: PropTypes.array
};

export default Amenities;
