import DiningOptionsContactInfo from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { hasProp } from 'utils/object';

const mapStateToProps = state => {
	let url = false;
	if (hasProp(state.details, 'restaurant.amenities.onlineOrdering.url')) {
		url = state.details.restaurant.amenities.onlineOrdering.url;
	}

	return {
		url: url,
		phone: hasProp(state.details, 'restaurant.contacts.general.numbers.main')
			? state.details.restaurant.contacts.general.numbers.main
			: false,
		translate: getTranslate(state.locale)
	};
};

export default connect(mapStateToProps)(DiningOptionsContactInfo);
