import React, { useMemo } from 'react';
import { SubHeader } from 'components/molecules';
import PropTypes from 'prop-types';

const LINKS = [
	{
		to: '/mydinova',
		titleKey: 'common.home'
	},
	{
		to: '/history',
		titleKey: 'common.history'
	},
	{
		to: '/rewards',
		titleKey: 'common.redemption'
	},
	{
		to: '/recommendations',
		titleKey: 'Profile.recommendations.title'
	},
	{
		to: '/refer/details',
		titleKey: 'common.rac'
	}
];

const DashboardHeader = ({ hasConnections, isLoadingUser }) => {
	const links = useMemo(() => {
		if (!isLoadingUser && hasConnections) {
			return LINKS;
		} else {
			return LINKS.slice(0, LINKS.length - 1);
		}
	}, [isLoadingUser, hasConnections]);

	return <SubHeader size="h1" titleKey={'Rewards.navHeaderTitle'} links={links} dataCy="dashboard-subheader" />;
};

DashboardHeader.defaultProps = {};

DashboardHeader.propTypes = {
	hasConnections: PropTypes.bool,
	isLoadingUser: PropTypes.bool
};

export default DashboardHeader;
