import React from 'react';
import styles from './style.module.scss';
import PropTypes from 'prop-types';
import { InvisibleButton, Text } from 'components/atoms';

const CompleteSetupBanner = ({ dismissedForever, mb, translate, toggleModalOpen, viewCompletion, showComplete }) => {
	const points = translate('ProfileSetup.points');

	let message = (
		<Text className={styles.link}>
			{translate(
				!viewCompletion && showComplete
					? 'ProfileSetup.completeBanner.finished'
					: 'ProfileSetup.completeBanner.noStepsCompleteMessage',
				{ points }
			)}
		</Text>
	);

	if (dismissedForever) {
		return null;
	}

	return (
		<div className={styles.wrapper} style={mb ? { marginBottom: mb } : {}}>
			<InvisibleButton onClick={toggleModalOpen}>
				<div className={styles.btnContent}>{message}</div>
			</InvisibleButton>
		</div>
	);
};

CompleteSetupBanner.propTypes = {
	dismissedForever: PropTypes.bool,
	mb: PropTypes.number,
	toggleModalOpen: PropTypes.func,
	translate: PropTypes.func.isRequired,
	viewCompletion: PropTypes.bool,
	showComplete: PropTypes.bool
};

export default CompleteSetupBanner;
