import React from 'react';
import PropTypes from 'prop-types';
import { CTAMenu } from 'components/molecules';

const DetailsAboutCTAMenu = ({ actions }) => {
	return actions.length > 0 ? <CTAMenu actions={actions} /> : null;
};

DetailsAboutCTAMenu.defaultProps = {};

DetailsAboutCTAMenu.propTypes = {
	actions: PropTypes.array
};

export default DetailsAboutCTAMenu;
