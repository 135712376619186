import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// import "./style.scss";

/**
 * [CollapsiblePanel description]
 * @extends Component
 */
class CollapsiblePanel extends Component {
	render() {
		const { isOpen, children, className, toggle, onToggleClick } = this.props;
		const classProps = classnames('collapsible-panel', !isOpen && 'closed', className && className);

		return (
			<div className={classProps}>
				<div>
					<div>{children}</div>
				</div>
				{toggle && (
					<div className="toggle" onClick={() => onToggleClick(!isOpen)}>
						<i className="fa fa-chevron-left" />
					</div>
				)}
			</div>
		);
	}
}

CollapsiblePanel.defaultProps = {};

/**
 * [propTypes description]
 * @interface CollapsiblePanelProps
 *
 * @example {}
 */
CollapsiblePanel.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	className: PropTypes.string,
	onToggleClick: PropTypes.func,
	toggle: PropTypes.bool
};

export default CollapsiblePanel;
