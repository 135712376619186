import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

class FormFieldRequirements extends React.Component {
	title = () => {
		if (this.props.formFieldText) {
			return this.props.formFieldText.titleKey
				? this.props.translate(this.props.formFieldText.titleKey)
				: this.props.formFieldText.title;
		}
	};

	requirementList = () => {
		const {
			translate,
			formFieldText: { requirements }
		} = this.props;

		if (this.props.formFieldText) {
			return requirements.map((requirement, index) => (
				<li className={requirement.status} key={index}>
					<i className="fas fa-check" />
					{requirement.textKey ? translate(requirement.textKey) : requirement.text}
				</li>
			));
		}
	};

	render() {
		console.log('this.props', this.props);
		return (
			<div className="form-field-requirements di_TintGrey mt-2">
				<p>{this.title()}</p>
				<ul className="requirement-list">{this.requirementList()}</ul>
			</div>
		);
	}
}

FormFieldRequirements.propTypes = {
	formFieldText: PropTypes.object.isRequired,
	translate: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(FormFieldRequirements);
