/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import PropTypes from 'prop-types';
import { CCTextBox } from 'components/molecules';
import { getCCInputLabelBasic, getCCHelp } from 'utils/card';
import { onlyNumbers } from 'utils/regex';
import classnames from 'classnames';

const ReduxFormCCTextBox = props => {
	const { card, disabled, field, isDoD, required, translate /* hasError, cardErrorType */ } = props;
	const { input, meta } = field;

	return card ? (
		<CCTextBox
			cardTypes={[card.cardType]}
			className={classnames('cc-input')}
			color="primary"
			disabled={disabled}
			errorText={translate(`ConnectToPoints.inputs.creditcard.${meta.error}`)} // invalidCardNumber || invalidCardType
			example={
				card && card.instructions ? (card.instructions.card === 'null' ? '' : card.instructions.card) : null
			}
			fullNumberRequired={card ? card.fullNumberRequired : true}
			help={getCCHelp(translate, card)}
			id={`card`}
			isValid={meta.valid}
			label={
				isDoD
					? translate('ConnectToPoints.inputs.creditcard.dod-card-label')
					: getCCInputLabelBasic(
							translate,
							card ? card.cardType : undefined,
							card ? card.fullNumberRequired : true
					  )
			}
			languageNode="ConnectToPoints"
			name={`card`}
			onChange={e => {
				input.onChange(onlyNumbers(e.target.value));
			}}
			styleName="ReqIcons"
			value={input.value}
			required={required}
			toggle={true}
		/>
	) : null;
};

ReduxFormCCTextBox.defaultProps = {};
ReduxFormCCTextBox.propTypes = {
	field: PropTypes.object.isRequired,
	card: PropTypes.object.isRequired,
	disabled: PropTypes.bool,
	isDoD: PropTypes.bool,
	required: PropTypes.bool,
	translate: PropTypes.func.isRequired
};

export default ReduxFormCCTextBox;
