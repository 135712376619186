import React from 'react';
import PropTypes from 'prop-types';
import { Text, Title } from 'components/atoms';
import { Button } from 'reactstrap';

const CardFace = ({ cardId, isActive, isLV, open, setShowAbout, translate }) => {
	const translateKey = `landingPage.cards.cards.${cardId}`;
	const cardKey = translate(`${translateKey}.cardKey`);

	return (
		<div className="face" aria-hidden={isActive}>
			<div className="mask">
				<div>
					<Title size="h2" transform="none" align="left">
						{translate(`${translateKey}.face.header`)}
					</Title>
					<Text size="sm">{translate(`${translateKey}.face.catchPhrase`)}</Text>
					{!isLV && cardKey !== 'dine-assist' && (
						<Button
							onClick={setShowAbout.bind(this, true)}
							className="bright link-button"
							disabled={isActive}
						>
							{translate(`${translateKey}.aboutLink`)}
						</Button>
					)}
				</div>

				{cardKey === 'dine-assist' ? (
					<a
						href="https://dinova.com/online-events/"
						className="card-button"
						target="_blank"
						rel="noreferrer"
					>
						{translate(`${translateKey}.searchLinkButton`)}
					</a>
				) : (
					<Button
						color="primary"
						className="card-button"
						onClick={open.bind(this, cardKey)}
						disabled={isActive}
					>
						{translate(`${translateKey}.searchLinkButton`)}
					</Button>
				)}
			</div>
		</div>
	);
};

CardFace.defaultProps = {};

CardFace.propTypes = {
	// component props
	cardId: PropTypes.string.isRequired,

	// redux props
	isActive: PropTypes.bool.isRequired,
	open: PropTypes.func.isRequired,
	setShowAbout: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired,

	// with sizes props
	isLV: PropTypes.bool.isRequired
};

export default CardFace;
