/* eslint-disable no-mixed-spaces-and-tabs */
import { emailValidator } from 'utils/form/validators';
import { preferredCCTypeNormalized, validateCard, getCardTypeValitationReqs, EMP_ID, EMAIL } from 'utils/card';
import Papa from 'papaparse'; // https://www.papaparse.com/docs#config
// import { startCase } from 'lodash';
import sha256 from 'js-sha256';
import { transformKeyNames } from 'utils/object';

// const onlyLastFourCardError = 'Lorem.. Only the last 4 card numbers are required';

const EMAIL_HEADER_ONE = 'cardholder email',
	EMAIL_HEADER_TWO = 'email',
	EMAIL_HEADER_THREE = 'employee email',
	FIRST_NAME_HEADER = 'first name',
	LAST_NAME_HEADER = 'last name',
	LAST_FOUR_HEADER = 'last 4 digits on card',
	FULL_NUMBER_HEADER = 'full credit card',
	EMPID_HEADER_ONE = 'employee id',
	EMPID_HEADER_TWO = 'emp id',
	EMPID_HEADER_THREE = 'emp id (email)';

const normalizeDecimalCCNumber = cardNumber => {
	cardNumber = `${cardNumber}`;
	let decimal = cardNumber.indexOf('.');
	if (decimal > -1) {
		cardNumber = cardNumber.slice(0, (cardNumber.length - decimal) * -1);
	}

	return cardNumber;
};

const normalizeScientificCCNumber = cardNumber => {
	return cardNumber.toLocaleString('fullwide', { useGrouping: false });
};

const normalizeCardNumber = cardNumber => {
	cardNumber = normalizeScientificCCNumber(cardNumber);
	cardNumber = normalizeDecimalCCNumber(cardNumber);

	return cardNumber;
};

const getCardValidState = (result, cardType, cardRequired, fullNumberRequired, translate) => {
	let cardError = false;
	let validCardNumber = false;
	result[FIRST_NAME_HEADER] = result[FIRST_NAME_HEADER].trim();
	result[LAST_NAME_HEADER] = result[LAST_NAME_HEADER].trim();

	if (cardRequired) {
		if (fullNumberRequired) {
			if (result[LAST_FOUR_HEADER]) {
				cardError = translate('AdminDashboard.AutoEnrollAdminPage.fileErrors.invalidCardNumber');
			} else if (!result[FULL_NUMBER_HEADER]) {
				cardError = translate('AdminDashboard.AutoEnrollAdminPage.fileErrors.missingCardNumber');
			} else {
				// remove decimal point if cell formatting was broken
				result[FULL_NUMBER_HEADER] = normalizeCardNumber(result[FULL_NUMBER_HEADER]);
				const { isValidCardType, isValidCardNumber } = validateCard({ cardType }, result[FULL_NUMBER_HEADER]);

				if (!isValidCardType) {
					cardError = translate('AdminDashboard.AutoEnrollAdminPage.fileErrors.invalidCardType').replace(
						'${cardType}',
						preferredCCTypeNormalized(cardType)
					);
				} else if (!isValidCardNumber) {
					cardError = translate('AdminDashboard.AutoEnrollAdminPage.fileErrors.invalidCardNumber');
				} else {
					let lastFour = result[FULL_NUMBER_HEADER].slice(result[FULL_NUMBER_HEADER].length - 4);
					result[FULL_NUMBER_HEADER] = sha256(result[FULL_NUMBER_HEADER]);
					result.last4 = lastFour;

					validCardNumber = true;
				}
			}
		} else {
			if (result[FULL_NUMBER_HEADER]) {
				cardError = translate('AdminDashboard.AutoEnrollAdminPage.fileErrors.invalidCardNumber');
				validCardNumber = false;
			} else if (!result[LAST_FOUR_HEADER]) {
				cardError = translate('AdminDashboard.AutoEnrollAdminPage.fileErrors.missingCardNumber');
				validCardNumber = false;
			} else {
				// remove decimal point if cell formatting was broken
				result[LAST_FOUR_HEADER] = normalizeCardNumber(result[LAST_FOUR_HEADER]);
				if (result[LAST_FOUR_HEADER].length === 4) {
					cardError = '';
					validCardNumber = true;
				} else if (result[LAST_FOUR_HEADER].length > 4) {
					cardError = translate('AdminDashboard.AutoEnrollAdminPage.fileErrors.invalidCardNumber');
					validCardNumber = false;
				} else {
					cardError = translate('AdminDashboard.AutoEnrollAdminPage.fileErrors.missingLastFour');
					validCardNumber = false;
				}
			}
		}
	} else {
		validCardNumber = true;
	}

	return { cardError, validCardNumber };
};
/**
 * Process CSV rows following validation requirements for hash connection type.
 * @param  {array}   results            An array of results returned from Papa.parse.complete.results.data
 * @param  {number}  cardType           Card type that dictates how we will process the CSV file.  Options are 1 ('amex'), 2 (visa), 3 (mastercard).
 * @param  {boolean} cardRequired       If the cc is required
 * @param  {boolean} fullNumberRequired If the full card number is required
 * @return {object}                     A {ProcessedCSV} object.
 */
const csvProcessor = (results, cardType, cardRequired, fullNumberRequired, connectionType, translate) => {
	const validationType = getCardTypeValitationReqs(connectionType),
		processed = results.reduce(
			(accum, result, index) => {
				result = transformKeyNames(result, key => {
					key = key.trim();
					return key.toLowerCase();
				});

				const emailExists =
					(result[EMAIL_HEADER_ONE] && result[EMAIL_HEADER_ONE].length) ||
					(result[EMAIL_HEADER_TWO] && result[EMAIL_HEADER_TWO].length) ||
					(result[EMAIL_HEADER_THREE] && result[EMAIL_HEADER_THREE].length);

				const validEmail =
						(result[EMAIL_HEADER_ONE] && emailValidator(result[EMAIL_HEADER_ONE])) ||
						(result[EMAIL_HEADER_TWO] && emailValidator(result[EMAIL_HEADER_TWO])) ||
						(result[EMAIL_HEADER_THREE] && emailValidator(result[EMAIL_HEADER_THREE])),
					validFirstName = result[FIRST_NAME_HEADER] && result[FIRST_NAME_HEADER].length > 0,
					validLastName = result[LAST_NAME_HEADER] && result[LAST_NAME_HEADER].length > 0;

				const { cardError, validCardNumber } = getCardValidState(
					result,
					cardType,
					cardRequired,
					fullNumberRequired,
					translate
				);

				const empIDRequired = validationType === EMP_ID || validationType === EMAIL;
				const validEmpId =
					(result[EMPID_HEADER_ONE] && result[EMPID_HEADER_ONE].length > 0) ||
					(result[EMPID_HEADER_TWO] && result[EMPID_HEADER_TWO].length > 0) ||
					(result[EMPID_HEADER_THREE] && result[EMPID_HEADER_THREE].length > 0);
				let canContinue = true;
				if (empIDRequired && !validEmpId) {
					canContinue = false;
				}

				if (validEmail && validFirstName && validLastName && validCardNumber && canContinue) {
					// valid return
					return {
						error: accum.error,
						errors: [...accum.errors],
						valids: [...accum.valids, result]
					};
				} else {
					const errors = [];
					// let formError = '';

					if (!emailExists) {
						errors.push(translate('AdminDashboard.AutoEnrollAdminPage.fileErrors.missingEmail'));
					} else if (!validEmail) {
						errors.push(translate('AdminDashboard.AutoEnrollAdminPage.fileErrors.invalidEmail'));
					}
					if (!validFirstName) {
						errors.push(translate('AdminDashboard.AutoEnrollAdminPage.fileErrors.missingFirst'));
					}
					if (!validLastName) {
						errors.push(translate('AdminDashboard.AutoEnrollAdminPage.fileErrors.missingLast'));
					}
					if (!validCardNumber) {
						errors.push(cardError);
						// formError = formCardTypeError(startCase(preferredCCTypeNormalized(cardType)));
					}
					if (!validEmpId && empIDRequired) {
						errors.push(translate('AdminDashboard.AutoEnrollAdminPage.fileErrors.missingEmpId'));
					}

					return {
						error: accum.error,
						errors: [...accum.errors, { ...result, row: index + 2, errors }], // + 2 to make up for index starts at 0 and papa parse removing header row from results
						valids: [...accum.valids]
					};
				}
			},
			{
				error: '',
				errors: [],
				valids: []
			}
		);

	return { type: 'hash', results: { ...processed } };
};

/**
 * ProcessedCSV type.  The expected return shape from out custom AutoEnroll CSV processing.
 * @type {ProcessedCSV}
 * @param {string} type      Can be one of "hash", "empId", "email".
 * @param {object} processed An object containing key "errors" as an array of rows that had errors and a key "valids" as an array of rows that are valid.
 */

/**
 * processCSVFromCardType
 * @param  {number}   connectionType      Connection type that dictates how we will process the CSV file.  Options are 1 ('hash'), 2 ('empId'), 3 ('email').
 * @param  {number}   cardType            Card type that dictates how we will process the CSV file.  Options are 1 ('amex'), 2 (visa), 3 (mastercard).
 * @param  {boolean}  fullNumberRequired  An array of csv results returned from Papa.parse.complete
 * @param  {array}    csvRows             An array of csv results returned from Papa.parse.complete
 * @return {object}                       Returns a {ProcessedCSV} object
 */
export const processCSVFromCardType = (
	connectionType,
	cardType,
	cardRequired,
	fullNumberRequired,
	translate,
	csvRows
) => {
	return csvProcessor(csvRows.data, cardType, cardRequired, fullNumberRequired, connectionType, translate);
};

/**
 * Default options for doPapaParse.  Conforms to Papa.parse config object shape. https://www.papaparse.com/docs#config
 * @type {DefaultPapaParseOpts}
 */
const defaultPapaParseOpts = {
	header: true, // key data by field name instead of index/position
	worker: true,
	skipEmptyLines: true,
	comments: false,
	fastMode: true,
	complete: results => {
		console.log('DONE!', results);
	}
};

/**
 * doPapaParse triggers Papa.parse and wrapps it in a promise to allow the UI to wait for csv file processing to complete.
 * @param  {file}          csvFile                      A CSV file.
 * @param  {PapaParseOpts} [opts=defaultPapaParseOpts]  Optional Papa.parse config object.  Defaults to {DefaultPapaParseOpts}
 * @return {Promise}       Returns a promise whose resolve will contain {DefaultPapaParseOpts}.complete return object and whose reject will return Papa.parse results object that includes an error array of all the rows that errored.
 */
export const doPapaParse = (csvFile, opts) => {
	return new Promise((resolve, reject) => {
		// merge default options with provided options.
		const mergedOpts = Object.assign({}, defaultPapaParseOpts, opts);
		// override complete function to use our promises resolve or reject
		mergedOpts.complete = results => {
			if (results.errors.length) {
				reject(results);
			} else {
				resolve(opts.complete(results));
			}
		};
		Papa.parse(csvFile, mergedOpts);
	});
};
