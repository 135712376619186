import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import { Button } from 'reactstrap';
import { ModalPage } from 'components/molecules';

// import "./style.scss";

/**
 * [COVIDRestaurantUniquePrecautions description]
 * @extends Component
 */
class COVIDRestaurantUniquePrecautions extends Component {
	componentDidMount = () => {
		this.initModal();
	};

	initModal = () => {
		this.props.setModal({
			id: 'covid-learn-more',
			header: this.props.translate('Details.covid.learnMoreModal.header'),
			body: (
				<Text weight={'thin'} className="preserve-text">
					{this.props.otherSteps}
				</Text>
			),
			primaryButtonText: 'close',
			secondaryButtonText: false
		});
	};

	showMore = () => {
		this.props.toggleModal(true);
	};

	render() {
		const { otherSteps, translate } = this.props;
		return otherSteps ? (
			<React.Fragment>
				<ModalPage isOpen={this.props.modal.isOpen} closeOnOutsideClick={true} />
				<div className="COVIDRestaurantUniquePrecautions flex">
					<Text weight="bold" className="mb-0">
						{translate('Details.covid.info')}
						<Button className="more-button bold ml-2 static" onClick={this.showMore}>
							{translate('Details.covid.learn-more')}
						</Button>
					</Text>
				</div>
			</React.Fragment>
		) : null;
	}
}

COVIDRestaurantUniquePrecautions.defaultProps = {};

/**
 * [propTypes description]
 * @interface COVIDRestaurantUniquePrecautionsProps
 *
 * @example {}
 */
COVIDRestaurantUniquePrecautions.propTypes = {
	modal: PropTypes.object.isRequired,
	otherSteps: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	setModal: PropTypes.func.isRequired,
	toggleModal: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

export default COVIDRestaurantUniquePrecautions;
