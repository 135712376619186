import ProfileSetupStepList from './ui-component';
import { connect } from 'react-redux';
import { completeStep, updateForm } from 'actions/profile_setup';
import { selectCompletedSteps } from 'actions/profile_setup/selectors';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import { getTranslate } from 'react-localize-redux';
import { withRouter } from 'react-router';
import { closeModal } from 'actions/profile_setup';

const mapStateToProps = state => {
	return {
		...selectCompletedSteps(state),
		translate: getTranslate(state.locale)
	};
};

const mapDispatchToProps = dispatch => ({
	completeStep: () => dispatch(completeStep(ProfileSetupSteps.EMAILS)),
	closeProfileSetupModal: () => dispatch(closeModal()),
	updateForm: ({ field, value }) => dispatch(updateForm({ field, value }))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileSetupStepList));
