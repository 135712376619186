import axios from 'axios';
import * as t from './types';
import { setIsLoading } from '../loader';
import { getTranslate } from 'react-localize-redux';
import { has } from 'lodash';
import { history } from 'utils/router';
import { AUTH_REQUEST_HEADER } from 'actions/utils';

export const clearRestaurant = () => dispatch => {
	return dispatch({ type: t.CLEAR_RESTAURANT });
};

/**
 * Fetch and load a resturant detail object.
 * @param  {Object} [data={}] An object with the id of the resturant who's details are being requested.
 */
export const loadRestaurant = (data = {}) => async (dispatch, getState) => {
	try {
		// loading screen
		dispatch(setIsLoading(true, 'Loading restaurant'));

		// <!-- make search query
		const RESTAURANT_BASE_URL = getState().config.api_basePath_restaurant + 'restaurants/';
		let searchQuery = `${RESTAURANT_BASE_URL}${data.id}`;
		// -->

		// search
		const queryRes = await axios.get(searchQuery, AUTH_REQUEST_HEADER()).catch(error => {
			throw error;
		});

		// get translations
		const translate = getTranslate(getState().locale);

		let response = {
			imageBaseUrl: getState().config.cdn_basePath,
			restaurant: queryRes.data.restaurants[0],
			translate
		};
		return dispatch({ type: t.LOAD_RESTAURANT, status: 'success', response });
	} catch (err) {
		if (err.response && err.response.status === 404) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx

			history.push('/not-found');
		}

		let errorMsg = has(err, 'response.data.response.msg') ? err.response.data.response.msg : err.message;
		return dispatch({ type: t.LOAD_RESTAURANT, status: 'error', error: errorMsg });
	} finally {
		// !END loading screen
		dispatch(setIsLoading(false));
	}
};
