export const ADD_FAVORITES_FILTER = 'ADD_FAVORITES_FILTER';
export const APPLY_URL_FAVORITES_SEARCH_FILTERS = 'APPLY_URL_FAVORITES_SEARCH_FILTERS';
export const CLEAR_FAVORITES_FILTERS = 'CLEAR_FAVORITES_FILTERS';
export const FAVORITES_FILTER_WORKING = 'FAVORITES_FILTER_WORKING';
export const HYDRATE_FROM_FAVORITES_SEARCH_PAGE_SAVE_STATE = 'HYDRATE_FROM_FAVORITES_SEARCH_PAGE_SAVE_STATE';
export const REMOVE_FAVORITES_FILTER = 'REMOVE_FAVORITES_FILTER';
export const RESET_FAVORITES_SEARCH = 'RESET_FAVORITES_SEARCH';
export const SET_FAVORITES_SEARCH_FIND = 'SET_FAVORITES_SEARCH_FIND';
export const SET_FAVORITES_SEARCH_NEAR = 'SET_FAVORITES_SEARCH_NEAR';
export const SAVE_FAVORITES_SEARCH_PAGE_STATE = 'SAVE_FAVORITES_SEARCH_PAGE_STATE';
