import Step from './ui-component';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	isDoD: state.general.dodMode,
	isDoorDash: state.general.doordashMode,
	users: state.users
});

export default connect(mapStateToProps)(Step);
