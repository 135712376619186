import * as t from './types';
import { createAction } from 'redux-act';

/**
 * Load the config vars depending on the current env.
 */
export const loadConfig = createAction(t.LOAD_CONFIG, () => {
	let ENV;
	if (window.Cypress) {
		/* eslint-disable no-undef */
		ENV = Cypress.env().NODE_ENV || 'development';
		/* eslint-enable no-undef */
	} else {
		ENV = process.env.NODE_ENV || 'development';
	}

	let config = null;
	switch (ENV) {
		// case 'uat':
		// case 'development':
		case 'prod':
		case 'production':
			config = require('../../config/config.prod')({ env: 'production' });
			break;
		case 'test':
		case 'testing':
		case 'uat':
			config = require('../../config/config.uat')({ env: 'uat' });
			break;
		case 'zuppler':
			config = require('../../config/config.zuppler')({ env: 'zuppler' });
			break;
		case 'zuppler_uat':
			config = require('../../config/config.zuppler_uat')({ env: 'zuppler_uat' });
			break;
		default:
			config = require('../../config/config.dev')({ env: 'development' });
			break;
	}

	return config;
});
