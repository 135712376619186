import { connect } from 'react-redux';
import AddCreditCardStep from './ui-component';
import { getTranslate } from 'react-localize-redux';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import { goToStep } from 'actions/profile_setup';
import { selectCreditCardComplete } from 'actions/profile_setup/selectors';
import { setCardToEdit } from 'actions/edit_payment';
import { sortUserConnections } from 'utils/user';
import { hasProp } from 'utils/object';
import { closeModal } from 'actions/profile_setup';

const mapStateToProps = state => {
	return {
		connection: hasProp(state, 'users.user.connections') ? sortUserConnections(state.users.user)[0] : [],
		complete: selectCreditCardComplete(state),
		translate: getTranslate(state.locale)
	};
};

const mapDispatchToProps = dispatch => ({
	closeProfileSetupModal: () => dispatch(closeModal()),
	goToGreeting: () => dispatch(goToStep(ProfileSetupSteps.GREETING)),
	setCardToEdit: card => dispatch(setCardToEdit(card))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCreditCardStep);
