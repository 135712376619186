/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withAccordion } from 'components/molecules';
import { preferredCCTypeDisplayText } from 'utils/card';
import { Text } from 'components/atoms';
import { CONNECTION_CREATED, DELETE_ALL_CONNECTIONS } from 'utils/windowEvents';
import { WithHelp } from 'components/molecules';

class RegisteredConnections extends Component {
	constructor(props) {
		super(props);

		this.state = {
			userConnections: props.userConnections || []
		};
	}

	componentDidMount = () => {
		window.addEventListener(DELETE_ALL_CONNECTIONS, this.deleteAllConnections);
		window.addEventListener(CONNECTION_CREATED, this.connectionCreated);
	};

	componentDidUpdate = () => {
		if (this.state.userConnections.length === 0 && this.props.userConnections.length !== 0) {
			this.setState({ userConnections: this.props.userConnections });
		}
	};

	componentWillUnmount = () => {
		window.removeEventListener(DELETE_ALL_CONNECTIONS, this.deleteAllConnections);
		window.removeEventListener(CONNECTION_CREATED, this.connectionCreated);
	};

	deleteAllConnections = () => {
		this.setState({ userConnections: [] });
	};

	connectionCreated = event => {
		const connection = event.detail;
		let userConnections = this.state.userConnections.slice();
		userConnections.push(connection);
		this.setState({ userConnections });
	};

	getCCIconImage = cardName => {
		const { imageBaseUrl } = this.props;
		cardName = cardName.toLowerCase();

		let iconType;
		switch (cardName) {
			case 'mastercard': {
				iconType = 'mc';
				break;
			}
			case 'american express': {
				iconType = 'amex';
				break;
			}
			default: {
				iconType = cardName;
				break;
			}
		}
		return `${imageBaseUrl}/icons/icon-${iconType}.svg`;
	};

	render() {
		const { supportedConnectionCardTypes, translate } = this.props;
		const { userConnections } = this.state;
		const connectionCount = userConnections.length;

		return withAccordion(
			connectionCount === 0 ? (
				<div className="connected-cards-content empty">{translate('RegisteredConnections.noConnections')}</div>
			) : (
				<div className="connected-cards-content">
					{userConnections.map((connection, index) => {
						let cardName = `${preferredCCTypeDisplayText(connection.cardType)}`;
						const ineligible =
								supportedConnectionCardTypes.length > 0 &&
								supportedConnectionCardTypes.indexOf(connection.cardType) === -1,
							classProps = classnames('connected-card', ineligible && 'ineligible has-help');

						let cardNumber;
						if (cardName === 'American Express') {
							cardNumber = `XXXX-XXXXXX-X${connection.last4}`;
						} else {
							cardNumber = `XXXX-XXXX-XXXX-${connection.last4}`;
						}

						return (
							<div
								key={`userConnection-${index}`}
								className={classProps}
								data-cy={`registered-connection-${index}`}
							>
								<div>
									<Text size="sm" weight="thin" dataCy={`name-on-card-${index}`}>
										<span className="normal">
											{translate('ConnectToPoints.inputs.cardholderName.label')}:
										</span>{' '}
										{connection.nameOnCard}
									</Text>
									<Text size="sm" weight="thin" dataCy={`card-number-${index}`}>
										<span className="normal">{cardName}:</span> {cardNumber}
									</Text>
									{connection.employeeId && (
										<Text size="sm" weight="thin" dataCy={`empId-${index}`}>
											<span className="normal">
												{translate('ConnectToPoints.inputs.employeeId.label')}:
											</span>{' '}
											{connection.employeeId}
										</Text>
									)}
								</div>
								<div className="flex-col align-end">
									<img src={this.getCCIconImage(cardName)} className="cc-icon" alt="" />
									{ineligible && (
										<WithHelp
											id={`connection-help-${index}`}
											help={translate('RegisteredConnections.ineligibleConnectionHelp')}
										/>
									)}
								</div>
							</div>
						);
					})}
				</div>
			),
			{
				title:
					connectionCount === 0 ? (
						translate('RegisteredConnections.title')
					) : (
						<span>
							{translate('RegisteredConnections.title')}{' '}
							<span className="font-green">({connectionCount}/10)</span>
						</span>
					),
				canCollapse: false,
				expanded: true,
				className: 'bordered',
				titleAlign: 'center',
				dataCy: 'registered-connections'
			}
		);
	}
}

RegisteredConnections.defaultProps = {};

RegisteredConnections.propTypes = {
	// deleteConnection: PropTypes.func.isRequired,
	imageBaseUrl: PropTypes.string.isRequired,
	translate: PropTypes.func.isRequired,
	userConnections: PropTypes.array.isRequired,
	supportedConnectionCardTypes: PropTypes.arrayOf(PropTypes.number)
};

export default RegisteredConnections;
