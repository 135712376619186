import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { LoadingBase, Text, Title } from 'components/atoms';
import { BasicModal, PageError } from 'components/molecules';
import { InputField } from 'components/organisms';
import { Row, Col, Button } from 'reactstrap';
import { hasProp } from 'utils/object';

class RecommendationsModal extends React.Component {
	componentDidMount = async () => {
		await this.props.fetchRecommendationsOptions();
	};

	errorCmp = () => {
		const { error } = this.props;
		if (error && error !== 'NONE_SELECTED') {
			let title =
				this.props.errorCode === 500
					? this.props.translate('NotFound.header3')
					: this.props.translate(`errors.codes.${this.props.errorCode}.message`);
			let content =
				this.props.errorCode === 500
					? this.props.translate(`errors.codes.${this.props.errorCode}.message`)
					: this.props.error;
			return (
				<React.Fragment>
					<Title size="h2" align="center" dataCy="recommendations-modal-title">
						{title}
					</Title>
					<Text size="sm" align="center">
						{content}
					</Text>
					<div className="scroll-modal-buttons">
						<Button className={'col col-sm-12'} color="primary" onClick={this.props.closeModal}>
							{this.props.translate('common.continueBtn')}
						</Button>
					</div>
				</React.Fragment>
			);
		} else {
			return null;
		}
	};

	submitRecommendation = ({ form, restaurantId, type }) => {
		let hasSelection =
			form
				.map(group => {
					let thing =
						group.categories
							.map(cat => {
								return hasProp(cat, 'value') ? cat.value.length > -1 : cat.selected;
							})
							.indexOf(true) > -1;
					return thing;
				})
				.indexOf(true) > -1;

		if (!hasSelection) {
			this.props.setNoSelectionError();
		} else {
			this.props.submitRecommendation({
				form,
				restaurantId,
				type
			});
		}
	};

	getItems = () => {
		const { type, prefill } = this.props;
		let items = prefill ? this.props[`prefill${type}`] : this.props[type]; // type === 'positive' || 'negative'

		if (prefill && (!items || !items.length)) {
			items = this.props[type];
		}

		return items;
	};

	renderOptionGroups = () => {
		const { applyRecommendation, translate, type } = this.props;
		const items = this.getItems();
		if (!items.length) {
			return null;
		}

		return items.map(item => {
			const groupName = item.name;
			if (groupName.toLowerCase() === 'general' && type === 'positive') {
				return null;
			}
			return (
				<Col xs="12" key={groupName}>
					{type !== 'negative' && (
						<Title align="left" size="h3" transform="none">
							{groupName}
						</Title>
					)}
					<div>
						{item.categories.map(item => {
							if (item.type === 'textarea') {
								return (
									<InputField
										className="col col-sm-12 col-md-12"
										key={item.id}
										type="textarea"
										label={translate('Recommendations.negativePlaceholder')}
										id={item.id}
										dataCy={item.id}
										value={item.value || item.feedback}
										onChange={e => {
											applyRecommendation({
												dataKey: item.id,
												value: e.target.value,
												groupKey: groupName,
												type
											});
										}}
									/>
								);
							} else {
								return (
									<InputField
										className="col col-sm-12 col-md-12"
										key={item.id}
										type="checkbox"
										id={item.id}
										dataCy={item.id}
										options={[
											{
												label: item.name,
												checked: item.selected
											}
										]}
										onChange={applyRecommendation.bind(this, {
											dataKey: item.id,
											value: !item.selected,
											groupKey: groupName,
											type
										})}
									/>
								);
							}
						})}
					</div>
				</Col>
			);
		});
	};

	titleCmp = () => {
		const { isSubmitting, prefill, translate, type } = this.props,
			title = prefill
				? translate(`Recommendations.editRecTypeTitle`)
				: translate(`Recommendations.titles.${type}`);

		if (!isSubmitting) {
			return (
				<Title size="h2" align="center" dataCy="recommendations-modal-title">
					{title}
				</Title>
			);
		}

		return null;
	};

	changeRecTypeCmp = () => {
		const { canToggleRecType, isSubmitting, restaurantId, translate, type } = this.props,
			yesBtnClassProps = classnames('black', type === 'positive' && 'active'),
			noBtnClassProps = classnames('black', type === 'negative' && 'active');

		if (!isSubmitting) {
			return (
				<Row className="rec-type-toggles">
					<Col xs="12">
						<Text size="sm" align="center">
							{translate(`Recommendations.editRecTypePrompt`)}
						</Text>
					</Col>
					<Row className="center">
						<Col xs="4">
							<Button
								className={yesBtnClassProps}
								onClick={this.props.switchType.bind(this, 'positive', restaurantId, canToggleRecType)}
							>
								{translate('common.yes')}
							</Button>
						</Col>
						<Col xs="4">
							<Button
								className={noBtnClassProps}
								onClick={this.props.switchType.bind(this, 'negative', restaurantId, canToggleRecType)}
							>
								{translate('common.no')}
							</Button>
						</Col>
					</Row>
				</Row>
			);
		}

		return null;
	};

	formErrorCmp = () => {
		const { error, translate } = this.props;

		if (error === 'NONE_SELECTED') {
			return <PageError message={translate('Recommendations.none-selected')} />;
		}

		return null;
	};

	loadingCmp = () => {
		const { isSubmitting, isLoading, translate } = this.props;
		const items = this.getItems();

		if (!isSubmitting && (isLoading || !Object.keys(items).length)) {
			return <LoadingBase message={translate('Recommendations.loadingForm')} />;
		}

		return null;
	};

	submittingCmp = () => {
		const { isSubmitting, translate } = this.props;

		if (isSubmitting) {
			return <LoadingBase message={translate('Recommendations.submittingForm')} />;
		}

		return null;
	};

	formCmp = () => {
		const { closeModal, isLoading, detailsRestaurantId, isSubmitting, restaurantId, type, translate } = this.props;

		const items = this.getItems();

		if (!isLoading && !isSubmitting && Object.keys(items).length > 0) {
			return (
				<React.Fragment>
					<Text size="sm" align="center" className="pos-sub-title">
						{translate('common.checkAll')}
					</Text>
					<form>
						<div className="row custom-scroll">{this.renderOptionGroups()}</div>
						{type === 'negative' && (
							<Text className="disclaimer" size="xs" align="left">
								{translate('Recommendations.negativeDisclaimer')}
							</Text>
						)}
						<div className="scroll-modal-buttons">
							<Button
								className={'submit'}
								color="primary"
								onClick={this.submitRecommendation.bind(this, {
									form: items,
									restaurantId: restaurantId || detailsRestaurantId,
									type
								})}
							>
								{translate('common.continueBtn')}
							</Button>

							<Button className={'col col-sm-12 mt-0'} color="secondary" onClick={closeModal}>
								{translate('common.cancelBtn')}
							</Button>
						</div>
					</form>
				</React.Fragment>
			);
		}

		return null;
	};

	render() {
		const { type } = this.props;
		if (!type) {
			return null;
		}

		const { className, error, show } = this.props,
			classProps = classnames(
				'recommendations_modal',
				type,
				error && error !== 'NONE_SELECTED' && 'center',
				error && error === 'NONE_SELECTED' && 'none-selected',
				'prefill',
				className && className
			);

		const recPages = ['restaurants', 'history', 'recommendations'];
		let isRecPage = false;
		recPages.forEach(page => {
			if (window.location.pathname.indexOf(page) > -1) {
				isRecPage = true;
			}
		});

		return this.props.authenticated ? (
			<BasicModal className={classProps} show={isRecPage && show} showClose={false}>
				{this.errorCmp()}
				{(!error || error === 'NONE_SELECTED') && (
					<React.Fragment>
						{this.titleCmp()}
						{this.changeRecTypeCmp()}
						{this.formErrorCmp()}
						{this.loadingCmp()}
						{this.submittingCmp()}
						{this.formCmp()}
					</React.Fragment>
				)}
			</BasicModal>
		) : null;
	}
}

RecommendationsModal.defaultProps = {};

RecommendationsModal.propTypes = {
	applyRecommendation: PropTypes.func.isRequired,
	authenticated: PropTypes.bool.isRequired,
	canToggleRecType: PropTypes.bool.isRequired,
	className: PropTypes.string,
	closeModal: PropTypes.func.isRequired,
	detailsRestaurantId: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.string]), // redux provided prop
	error: PropTypes.oneOf([false, PropTypes.oneOfType([PropTypes.string])]),
	errorCode: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.string]),
	fetchRecommendationsOptions: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	isSubmitting: PropTypes.bool.isRequired,
	prefill: PropTypes.bool,
	restaurantId: PropTypes.string, // parent component provided prop
	setNoSelectionError: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
	submitRecommendation: PropTypes.func.isRequired,
	switchType: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired,
	type: PropTypes.oneOf(['positive', 'negative', ''])
};

export default RecommendationsModal;
