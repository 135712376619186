import React from 'react';
import PropTypes from 'prop-types';
import ICONS from './social-icons';
import { Title } from 'components/atoms';

/**
 * Displays a restaurant's avialble social links. Ex: Facebook, Twitter.
 * @param {SocialProps} props
 */
const Social = ({ socials, translate }) => {
	return (
		<div className="social-wrapper flex align-center pt-1">
			<Title align="left" transform="none" className="mr-4">
				{translate('Details.sections.social')}
			</Title>
			{Object.keys(socials).map((key, i) => {
				return ICONS[key] ? (
					<a
						key={`social-${i}`}
						href={socials[key]}
						target="_blank"
						rel="noopener noreferrer"
						data-cy={`social-item-${key}`}
					>
						<img
							className="social-icon"
							src={ICONS[key]}
							alt={key}
							key={`social-icon-${i}-${key}`}
							data-cy={`social-item-icon-${key}`}
						/>
					</a>
				) : null;
			})}
		</div>
	);
};

Social.defaultProps = {
	socials: {}
};

/**
 * {@link Social} Props
 * @interface Props_Social
 * @property {object} socials Ex: { twitter: 'twitterFolowLink.example' }
 */
Social.propTypes = {
	// Optional
	socials: PropTypes.object,
	// Required
	translate: PropTypes.func.isRequired
};

export default Social;
