import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import WithDropdown from './withDropdown';
import { DropdownOption } from 'components/atoms';
import { DropdownLabel, InputError, InputFieldFocusBorder } from 'components/molecules';

/**
 * A dropdown component that allows the user to select from a list of options.
 * @extends Component
 * @param {DropdownProps} props
 * @param {string} [props.cdn_basePath] The app's asset base url.
 * @param {string} [props.className] Additional classNames to add to the dropdown wrapper.
 * @param {function} [props.optionComponent] A stateless react component func.
 * @param {string} [props.title] The text to show in the button that triggers to show the dropdown.
 * @param {array} [props.options] The options to loop over while creating dropdown options.
 * @param {function} [props.onChange] The handler func for when an option is clicked on.
 * @param {string|number} [props.value] The value to apply to the dropdown.
 *
 * @example <Dropdown title="DropdownTitle" options={[{value: 'value', displayValue: 'Display Value'}]}/>
 */
class Dropdown extends WithDropdown {
	constructor(props) {
		super(props);
	}

	onClick = (item, index) => {
		this.toggleDropdown();
		if (this.props.onChange) {
			this.props.onChange(item, index);
		}
	};

	render() {
		const { children, dataCy, hasError, error, label, options, required, title, value } = this.props;
		const { isOpen } = this.state;
		const dropdownClass = this.getWrapperClassProps();
		const listWrapperClass = this.getListWrapperClassProps();

		const labelClass = this.getLabelClassProps();
		const optionComponent = this.props.optionComponent || DropdownOption;

		return (
			<div className={dropdownClass} onClick={e => e.stopPropagation()} data-cy={`${dataCy}-dropdown-wrapper`}>
				<InputFieldFocusBorder labelText={label || title} required={required} />
				<DropdownLabel
					dataCy={dataCy}
					isOpen={isOpen}
					labelClass={labelClass}
					onClick={this.toggleDropdown}
					label={label || title}
					value={value ? value.displayValue : undefined}
					refSetter={c => (this.dropdown_trigger = c)}
					required={required}
				/>
				<div
					className={listWrapperClass}
					style={{ height: this.state.height, transition: this.state.openSpeed }}
				>
					<div
						className={'dropdown-list animated'}
						ref={c => (this.dropdown = c)}
						data-cy={`${dataCy}-options-wrapper`}
					>
						{options.length > 0 &&
							options.map((item, i) => {
								const selected =
									item.value ===
									(this.props.value && this.props.value.value
										? this.props.value.value
										: this.props.value);
								const className = classNames('option', selected && 'selected');
								return optionComponent({
									option: item,
									config: { onClick: this.onClick.bind(this, item, i), className },
									key: `dropdown-item-${JSON.stringify(this.props.value)}-${i}`,
									ariaHidden: !isOpen
								});
							})}
					</div>
				</div>
				{hasError && <InputError text={error} dataCy={`dropdown-error`} />}

				{children}
			</div>
		);
	}
}

Dropdown.defaultProps = {
	cdn_basePath: '',
	className: '',
	options: [],
	hasError: false,
	isRequired: false,
	onChange: () => {},
	title: 'Dropdown'
};

/**
 * {@link Dropdown} Props
 * @interface Props_Dropdown
 * @property {string} cdn_basePath The app's asset base url.
 * @property {string} className Additional classNames to add to the dropdown wrapper.
 * @property {string} dataCy    A unique ID for cypress tests.
 * @property {boolean} hasError If this dropdown is in an error state.
 * @property {function} optionComponent A stateless react component func.
 * @property {string} title The text to show in the button that triggers to show the dropdown.
 * @property {array} options The options to loop over while creating dropdown options.
 * @property {function} onChange The handler func for when an option is clicked on.
 * @property {boolean} isRequired If this field is required.
 * @property {string|number} value The value to apply to the dropdown.
 */
Dropdown.propTypes = {
	// Optional
	cdn_basePath: PropTypes.string,
	className: PropTypes.string,
	dataCy: PropTypes.string,
	disabled: PropTypes.bool,
	error: PropTypes.string,
	hasError: PropTypes.bool,
	isRequired: PropTypes.bool,
	onChange: PropTypes.func,
	optionComponent: PropTypes.func,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			displayValue: PropTypes.string.isRequired
		})
	),
	required: PropTypes.bool,
	title: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
};

export default Dropdown;
