import { hasProp } from 'utils/object';

export let AutoCompleteService = null;

export const initAutoCompleteService = () => {
	if (!AutoCompleteService) {
		if (!hasProp(window, 'google.maps.places.Autocomplete')) {
			throw new Error('Google Autocomplete Service is not loaded');
		} else {
			AutoCompleteService = new window.google.maps.places.AutocompleteService();
		}
	}
};

export const getSuggestionsFromGoogle = search => {
	initAutoCompleteService();
	return new Promise(resolve => {
		AutoCompleteService.getPlacePredictions({ input: search }, (results, status) => {
			if (status === 'OK') {
				resolve(results);
			} else {
				resolve(false);
			}
		});
	});
};
