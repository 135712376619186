import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import { EditProfileActions, FormFieldRequirements, ProfileSectionItem, RequiredFieldNote } from 'components/molecules';
import { InputField } from 'components/organisms';
import { history } from 'utils/router';

class EditSecurityForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mfa: this.props.mfaEnabled
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.mfaEnabled != this.props.mfaEnabled) {
			this.setState({
				mfa: this.props.mfaEnabled
			});
		}
	}

	onUpdate = e => {
		if (this.props.currPassword.value === '' && this.props.newPassword.value === '') {
			history.replace('/profile');
		} else {
			this.props.onUpdate(e);
		}
	};

	onCancel = e => {
		if (this.props.currPassword.value === '' && this.props.newPassword.value === '') {
			history.replace('/profile');
		} else {
			this.props.onCancel(e);
		}
	};

	changePref(key) {
		let newState = !this.state[key];
		this.setState({ [key]: newState });

		if (newState && !this.props.phoneNumber) {
			let mfa = {
				phone: {
					mobile: '0000000000'
				},
				enabled: true
			};
			this.props.onEnableMFA(mfa);
		} else {
			let mfa = {
				enabled: false
			};
			this.props.onEnableMFA(mfa);
		}
	}

	render() {
		const { className, onChange, translate, newPassword, username, currPassword, confirmPassword } = this.props,
			classProps = classNames('edit-security-form', className),
			usernameItem = { key: 'username', value: username };

		return (
			<div className={classProps}>
				<RequiredFieldNote />
				<ProfileSectionItem item={usernameItem} section="security" className="mb-4" />
				<Row>
					<InputField
						label={translate(`Profile.security.labels.currPassword`)}
						name="currPasswordField"
						id="curr-password-field"
						dataCy="curr-password-field"
						hasError={!currPassword.isValid}
						error={currPassword.errorText}
						value={currPassword.value}
						onChange={e => onChange(e, 'currPassword')}
						type="password"
					/>

					<InputField
						label={translate(`Profile.security.labels.newPassword`)}
						name="newPasswordField"
						id="new-password-field"
						dataCy="new-password-field"
						hasError={!newPassword.isValid}
						error={newPassword.errorText}
						value={newPassword.value}
						onChange={e => onChange(e, 'newPassword')}
						type="password"
					/>

					<InputField
						label="Confirm Password"
						name="confirmPasswordField"
						id="confirm-password-field"
						dataCy="confirm-password-field"
						hasError={!confirmPassword.isValid}
						error={confirmPassword.errorText}
						value={confirmPassword.value}
						onChange={e => onChange(e, 'confirmPassword')}
						type="password"
					/>

					<FormFieldRequirements formFieldText={newPassword} />

					{!this.props.mfaRequired && (
						<InputField
							type="checkbox"
							id="monthly-sum"
							dataCy="monthly-sum"
							options={[
								{
									label: 'Enable Multi-Factor Authentication',
									checked: this.state.mfa
								}
							]}
							onChange={() => this.changePref('mfa')}
						/>
					)}
				</Row>

				<EditProfileActions onUpdate={this.onUpdate.bind(this)} onCancel={this.onCancel.bind(this)} />
			</div>
		);
	}
}

EditSecurityForm.defaultProps = {
	className: ''
};

EditSecurityForm.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
	onEnableMFA: PropTypes.func,
	translate: PropTypes.func,
	onUpdate: PropTypes.func,
	onCancel: PropTypes.func,
	currPassword: PropTypes.object,
	newPassword: PropTypes.object,
	confirmPassword: PropTypes.object,
	username: PropTypes.string,
	mfaEnabled: PropTypes.bool,
	phoneNumber: PropTypes.string,
	mfaRequired: PropTypes.bool
};

export default EditSecurityForm;
