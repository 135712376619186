import RecommendationsModal from './ui-component';
import { connect } from 'react-redux';
import { hasProp } from 'utils/object';
import {
	applyRecommendation,
	fetchRecommendationsOptions,
	toggleShowModal,
	setNoSelectionError,
	submitRecommendation
} from 'actions/recommendations_modal';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
	canToggleRecType: state.recommendationsModal.canToggleRecType,
	detailsRestaurantId: hasProp(state, 'details.restaurant.id') ? state.details.restaurant.id : false,
	isAuthed: state.users.authenticated.length > 0,
	isLoading: state.recommendationsModal.isLoadingOptions,
	isSubmitting: state.recommendationsModal.isSubmitting,
	show: state.recommendationsModal.show,
	type: state.recommendationsModal.type,
	negative: state.recommendationsModal.negative,
	positive: state.recommendationsModal.positive,
	prefillnegative: state.recommendationsModal.prefillnegative,
	prefillpositive: state.recommendationsModal.prefillpositive,
	prefill: state.recommendationsModal.prefill,
	restaurantId: state.recommendationsModal.restaurantId,
	translate: getTranslate(state.locale),
	error: state.recommendationsModal.error,
	errorCode: state.recommendationsModal.errorCode,
	authenticated: state.users.authenticated.length > 0
});

const mapDispatchToProps = dispatch => ({
	applyRecommendation: config => dispatch(applyRecommendation(config)),
	closeModal: () => dispatch(toggleShowModal(false, '')),
	switchType: (type, restaurantId, canToggleRecType) =>
		dispatch(toggleShowModal(true, type, restaurantId, canToggleRecType)),
	fetchRecommendationsOptions: async () => await fetchRecommendationsOptions(dispatch),
	setNoSelectionError: () => dispatch(setNoSelectionError()),
	submitRecommendation: async submitData => await submitRecommendation(dispatch, submitData)
});

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationsModal);
