import { passwordWarning, usernameWarning } from 'utils/form/warnings';

const registrationFormWarnings = vals => {
	const warnings = {
		password: passwordWarning(vals.password),
		username: usernameWarning(vals.username)
	};

	return warnings;
};

export default registrationFormWarnings;
