import React, { useCallback } from 'react';
import { Button, Row } from 'reactstrap';
import styles from './style.module.scss';
import StepTemplate from '../../StepTemplate';
import PropTypes from 'prop-types';
import { ConnectToPointsForm, CompanySelect } from 'components/organisms';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import { RegisteredConnections } from 'components/molecules';
import { history } from 'utils/router';

const AddCreditCardStep = ({
	closeProfileSetupModal,
	complete,
	connection,
	goToGreeting,
	setCardToEdit,
	translate
}) => {
	const translateBaseKey = 'ProfileSetup.modals.step2';

	const editCreditCard = useCallback(() => {
		setCardToEdit(connection);
		closeProfileSetupModal();
		history.push('/profile/payment/#/');
	}, [closeProfileSetupModal, connection, setCardToEdit]);

	return (
		<StepTemplate title={translate(`${translateBaseKey}.title`)} variant={ProfileSetupSteps.ADD_CREDIT_CARD}>
			{complete && (
				<div className={styles.completedWrapper}>
					<div className={styles.completedContent}>
						<RegisteredConnections />
					</div>
					<Row className={styles.footer}>
						<div className={styles.btnWrapper}>
							<Button color="primary" onClick={editCreditCard}>
								{translate(`${translateBaseKey}.edit`)}
							</Button>
							<Button onClick={goToGreeting}>{translate(`${translateBaseKey}.cancel`)}</Button>
						</div>
					</Row>
				</div>
			)}
			{!complete && (
				<div className={styles.content}>
					<CompanySelect />
					<ConnectToPointsForm
						readOnly
						showRegistered={false}
						onSubmitSuccess={goToGreeting}
						buttons={({ submitDisabled }) => (
							<Row className={styles.footer}>
								<div className={styles.btnWrapper}>
									<Button color="primary" type="submit" disabled={submitDisabled}>
										{translate('common.submit')}
									</Button>
									<Button onClick={goToGreeting}>{translate('common.cancelBtn')}</Button>
								</div>
							</Row>
						)}
					/>
				</div>
			)}
		</StepTemplate>
	);
};

AddCreditCardStep.propTypes = {
	closeProfileSetupModal: PropTypes.func.isRequired,
	complete: PropTypes.bool.isRequired,
	connection: PropTypes.object.isRequired,
	goToGreeting: PropTypes.func.isRequired,
	setCardToEdit: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

export default AddCreditCardStep;
