import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
// import { Link } from 'react-router-dom';
import { Title } from 'components/atoms';

class PaymentNotConnected extends Component {
	constructor(props) {
		super(props);

		this.gotoConnect = this.gotoConnect.bind(this);
	}

	render() {
		const { className, showNotConnectedMessage, translate } = this.props,
			classProps = classNames('payment-not-connected', className);

		return (
			<div className={classProps}>
				{showNotConnectedMessage && (
					<Title size="h4" transform="none">
						{translate('Profile.payment.notConnected.title')}
					</Title>
				)}
				<Button onClick={this.gotoConnect} className="btn-wrapper" id="profile-add-cc" color="primary">
					{translate('Profile.payment.notConnected.connectBtn')}
				</Button>
			</div>
		);
	}

	gotoConnect(e) {
		e.preventDefault();
		this.props.startChangeStep(2);
		this.props.history.push('/users/connect?redirect=/profile');
	}
}

PaymentNotConnected.defaultProps = {
	className: '',
	showNotConnectedMessage: true
};

PaymentNotConnected.propTypes = {
	className: PropTypes.string,
	showNotConnectedMessage: PropTypes.bool,
	startChangeStep: PropTypes.func,
	history: PropTypes.object,
	translate: PropTypes.func.isRequired
};

export default PaymentNotConnected;
