import React from 'react';
import PropTypes from 'prop-types';
import { Field /*, blur*/ } from 'redux-form';
import { InputField } from 'components/organisms';
import { PulseLoader } from 'react-spinners';
import { hasProp } from 'utils/object';
import { COMPANY_SUBMIT_NO_VAL, programaticBlurWindowEvent } from 'utils/windowEvents';
import { ReadOnlyInput } from 'components/molecules';
import sanitizeInput from 'utils/sanitizeInput';

class CompanySelect extends React.Component {
	constructor(props) {
		super(props);

		this.wrapper = React.createRef();
		this.submitNoVal = this.submitNoVal.bind(this);

		this.state = {
			isSelectingFromItemClick: false,
			didFetch: false,
			activeSuggestion: 0,
			isOpen: false,
			showHelp: props.showHelp || false,
			hasFocus: false,
			dirty: false,
			shouldForceRerenderInput: false
		};
	}

	componentDidMount = () => {
		window.addEventListener('keyup', this.handleKeyUp);
		window.addEventListener(COMPANY_SUBMIT_NO_VAL, this.submitNoVal);
		this.initInitialSearchFromReduxState();
	};

	componentDidUpdate = (prevProps, prevState) => {
		if (!prevProps.usersCompany && this.props.usersCompany.name) {
			this.initInitialSearchFromReduxState();
		}
		if (typeof this.props.showHelp === 'boolean') {
			if (this.state.showHelp !== this.props.showHelp) {
				this.setState({ showHelp: this.props.showHelp });
			}
		}
		this.shouldForceRerenderInput(prevProps, prevState);
	};

	componentWillUnmount = () => {
		window.removeEventListener('keyup', this.handleKeyUp);
		window.removeEventListener(COMPANY_SUBMIT_NO_VAL, this.submitNoVal);
	};

	initInitialSearchFromReduxState = async () => {
		if (hasProp(this.props.usersCompany, 'name')) {
			const companies = await this.props.asyncFetchCompanies(this.props.usersCompany.name);
			const doFocus = false;
			this.onItemClick(companies[0], doFocus);
		}
	};

	handleSubmit = e => {
		e.preventDefault();
	};

	submitNoVal = () => {
		this.setState({ dirty: true });
	};

	handleKeyUp = e => {
		if (e.key === 'Enter') {
			e.preventDefault();
			e.stopPropagation();
			if (!this.props.hasError) {
				programaticBlurWindowEvent('company-select');
			}
		} else if (e.key === 'ArrowUp') {
			this.bumpActiveSuggestion(1);
		} else if (e.key === 'ArrowDown') {
			this.bumpActiveSuggestion(-1);
		}
	};

	bumpActiveSuggestion = count => {
		let activeSuggestion = this.state.activeSuggestion + count;

		if (activeSuggestion < 0) {
			activeSuggestion = this.props.suggestions.length - 1;
		} else if (activeSuggestion >= this.props.suggestions.length) {
			activeSuggestion = 0;
		}

		this.setState({ activeSuggestion });
	};

	setRef = cmp => {
		this.wrapper = cmp;
	};

	onItemMouseOver = index => {
		this.setState({ activeSuggestion: index });
	};

	onItemClick = (suggestion, doFocus = true) => {
		if (!suggestion) {
			return;
		}
		if (doFocus) {
			this.wrapper.focus();
		}
		this.setState(
			{ isSelectingFromItemClick: false, activeSuggestion: doFocus ? -1 : this.state.activeSuggestion },
			() => {
				this.props.change('name', suggestion.name);
				this.props.change('code', suggestion.code);
				this.props.selectCompany(suggestion);
				this.props.setCompany(suggestion);
				if (doFocus) {
					this.props.blur('name');
					this.wrapper.blur();
					this.onBlur();
				}
			}
		);
	};

	onFocus = e => {
		this.setState({ hasFocus: true });
		if (this.props.onFocus) {
			this.props.onFocus(e);
		}
	};

	onBlur = e => {
		if (this.state.isSelectingFromItemClick) {
			e.stopPropagation();
		}

		this.onItemClick(this.props.suggestions[this.state.activeSuggestion], true);

		this.setState({ hasFocus: false, dirty: true, didFetch: false });
		if (this.props.onBlur) {
			this.props.onBlur(e);
		}
	};

	onChange = (inputProps, e) => {
		e.target.value = sanitizeInput(e.target.value);
		this.setState({
			dirty: false
		});
		this.props.fetchCompanies(e.target.value);
		inputProps.input.onChange(e);
		if (!this.state.didFetch) {
			this.setState({ didFetch: true, dirty: true });
		} else if (this.state.value === '') {
			this.setState({ didFetch: false, dirty: true });
		}
	};

	onWrapperMouseOver = () => {
		this.setState({ isSelectingFromItemClick: true });
	};

	onWrapperMouseLeave = () => {
		this.setState({ isSelectingFromItemClick: false });
	};

	onHelpClick = () => {
		if (this.props.onHelpClick) {
			this.props.onHelpClick();
		} else {
			this.setState({ showHelp: !this.state.showHelp });
		}
	};

	renderDropdown = props => {
		const { className, isLoading, readOnly, required, suggestions, translate } = this.props,
			{ activeSuggestion } = this.state;
		let companySelectErrorText = translate('select-company.inputs.company.error'),
			companySelectErrorLink = translate('select-company.inputs.company.errorLink');
		companySelectErrorText = companySelectErrorText.replace(
			'{errorLink}',
			`<a href="mailto:${companySelectErrorLink}">${companySelectErrorLink}</a>`
		);

		return readOnly ? (
			<ReadOnlyInput
				className={className}
				labelKey="Profile.personal.labels.company"
				value={props.input.value}
				dataCy="company-select"
			/>
		) : (
			<InputField
				{...props.input}
				id="company-select"
				dataCy="company-select"
				className={className}
				refSetter={this.setRef}
				type="typeahead"
				onChange={this.onChange.bind(this, props)}
				onBlur={this.onBlur}
				onFocus={this.onFocus}
				onMouseOver={this.onWrapperMouseOver}
				onMouseLeave={this.onWrapperMouseLeave}
				hasError={this.state.dirty && this.props.suggestions.length == 0}
				error={companySelectErrorText}
				help={translate('select-company.inputs.company.help')}
				label={translate('select-company.inputs.company.label')}
				showHelp={this.state.showHelp}
				onHelpClick={this.onHelpClick}
				required={required}
				autoComplete="off"
				autoCorrect="off"
				spellCheck="off"
			>
				{props.input.value.length > 0 &&
					suggestions.length === 0 &&
					!this.props.hasSelection &&
					this.state.hasFocus && (
						<div className="no-suggestions">
							{isLoading && (
								<PulseLoader sizeUnit={'px'} size={10} color={'#1c871c'} loading={isLoading} />
							)}
							{!isLoading && <em>No suggestions</em>}
						</div>
					)}
				{suggestions.length > 0 && (
					<ul className="suggestions" ref={c => (this.list = c)}>
						{suggestions.slice(0, 6).map((s, index) => {
							let className,
								suggestion = hasProp(s, 'match.text') ? s.match.text : s.name;

							// Flag the active suggestion with a class
							if (index === activeSuggestion) {
								className = 'suggestion-active';
							}

							return (
								<li
									className={className}
									key={`${suggestion}-${index}`}
									onClick={this.onItemClick.bind(this, s)}
									onMouseOver={this.onItemMouseOver.bind(this, index)}
								>
									{suggestion}
								</li>
							);
						})}
					</ul>
				)}
			</InputField>
		);
	};

	/**
	 * Because we are using a redux form input that then reders our custom input component, the redux form field render func does not get fired on all state changes.
	 * Ex: suggestions.length is scoped to this CompanySelect component, and does not reach our redux field state.
	 * If this function finds there were changes that should cause a rerender of the redux field, this.state.shouldForceRerenderInput
	 * will be updated with a new random number.  this.state.shouldForceRerenderInput is then used as the redux fields value prop.
	 * @param    {[type]} prevProps CompanySelect previous props.
	 * @param    {[type]} prevState CompanySelect previous state.
	 * @return   {[type]}           returns null
	 * @modifies {[type]}           updates this.state.shouldForceRerenderInput with a new random number.
	 */
	shouldForceRerenderInput = (prevProps, prevState) => {
		let didChange;

		if (prevProps.suggestions.length !== this.props.suggestions.length) {
			didChange = true;
		}

		if (prevProps.isLoading !== this.props.isLoading) {
			didChange = true;
		}

		if (prevState.showHelp !== this.state.showHelp) {
			didChange = true;
		}

		if (prevState.dirty !== this.state.dirty) {
			didChange = true;
		}

		if (prevState.activeSuggestion !== this.state.activeSuggestion) {
			didChange = true;
		}

		if (prevProps.hasSelection !== this.props.hasSelection) {
			didChange = true;
		}

		if (prevState.hasFocus !== this.state.hasFocus) {
			didChange = true;
		}

		if (didChange) {
			this.setState({ shouldForceRerenderInput: Math.floor(Math.random() * 100) });
		}
	};
	render() {
		return (
			<div className="company-select-wrapper">
				<Field
					name="name"
					component={this.renderDropdown}
					type="text"
					value={this.state.shouldForceRerenderInput}
				/>
				{/* values set to this.props.isLoading && this.props.hasSelection && this.state.isOpen to trick the component into rerendering when new suggestions are loaded */}
			</div>
		);
	}
}

CompanySelect.defaultProps = {};

CompanySelect.propTypes = {
	asyncFetchCompanies: PropTypes.func.isRequired,
	blur: PropTypes.func.isRequired, // redux form input blur func
	change: PropTypes.func.isRequired, // redux form input change func
	className: PropTypes.string,
	touch: PropTypes.func.isRequired, // redux form input touch (focus) func
	handleSubmit: PropTypes.func.isRequired,
	hasError: PropTypes.bool.isRequired,
	hasValue: PropTypes.bool.isRequired,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	onHelpClick: PropTypes.func,
	onSubmit: PropTypes.func.isRequired,
	fetchCompanies: PropTypes.func.isRequired,
	hasSelection: PropTypes.bool,
	isLoading: PropTypes.bool.isRequired,
	onChange: PropTypes.func,
	required: PropTypes.bool,
	readOnly: PropTypes.bool,
	setCompany: PropTypes.func.isRequired,
	selectCompany: PropTypes.func.isRequired,
	showHelp: PropTypes.bool,
	suggestions: PropTypes.array,
	translate: PropTypes.func.isRequired,
	usersCompany: PropTypes.oneOfType([
		PropTypes.bool,
		PropTypes.shape({
			name: PropTypes.string
		})
	])
};

export default CompanySelect;
