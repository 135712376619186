import React, { Component } from 'react';
import { DiningOptionsList, ToGoOptionsList, DiningOptionsContactInfo } from 'components/molecules';
// import PropTypes from "prop-types";

// import "./style.scss";

/**
 * [DiningOptions description]
 * @extends Component
 */
class DiningOptions extends Component {
	render() {
		return (
			<div className={'dining-options'}>
				<DiningOptionsList />
				<ToGoOptionsList />
				<DiningOptionsContactInfo />
			</div>
		);
	}
}

DiningOptions.defaultProps = {};

/**
 * [propTypes description]
 * @interface DiningOptionsProps
 *
 * @example {}
 */
DiningOptions.propTypes = {};

export default DiningOptions;
