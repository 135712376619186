import ProfilePaymentInfo from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { hasProp } from 'utils/object';
import { setModal, toggleModal } from 'actions/modal';
import { deletePaymentInfo } from 'actions/users';
import { getCompany } from 'actions/general';
import { sortUserConnections } from 'utils/user';
import { setCardToEdit } from 'actions/edit_payment';

const mapStateToProps = state => ({
	company: hasProp(state, 'users.user.company') ? state.users.user.company : false,
	connections: hasProp(state, 'users.user.connections') ? sortUserConnections(state.users.user) : [],
	name: hasProp(state, 'users.user.name') ? state.users.user.name : '',
	isMerck: hasProp(state, 'users.user.company') ? state.users.user.company.name === 'Merck' : false,
	isDoD: state.general.dodMode,
	modal: state.modal,
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	deletePaymentInfo: (cardId, callback) => dispatch(deletePaymentInfo(cardId, callback)),
	getCompany: (company, showLoading = false) => dispatch(getCompany(company, showLoading)),
	setCardToEdit: card => dispatch(setCardToEdit(card)),
	setModal: modal => dispatch(setModal(modal)),
	toggleModal: toggle => dispatch(toggleModal(toggle))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePaymentInfo);
