import React from 'react';
import PropTypes from 'prop-types';
import { Container, ModalBody, ModalFooter, Row, Col, ModalHeader } from 'reactstrap';
import { Button } from 'reactstrap';

const PhoneNumberPage = ({ phoneField, onStepTwoBackClick, onStepTwoNextClick }) => {
	return (
		<div>
			<ModalHeader>Please enter your phone number</ModalHeader>
			<ModalBody>
				<Row>{phoneField}</Row>
			</ModalBody>
			<ModalFooter>
				<Container>
					<Row>
						<Col sm="12" lg="6">
							<Button
								block
								className="z-depth-0"
								onClick={onStepTwoBackClick}
								id="modal-secondary-button"
							>
								Back
							</Button>
						</Col>
						<Col sm="12" lg="6">
							<Button
								block
								className="z-depth-0"
								onClick={onStepTwoNextClick}
								color="primary"
								id="modal-primary-button"
							>
								Next
							</Button>
						</Col>
					</Row>
				</Container>
			</ModalFooter>
		</div>
	);
};

PhoneNumberPage.defaultProps = {};
PhoneNumberPage.propTypes = {
	phoneField: PropTypes.object,
	onStepTwoBackClick: PropTypes.func,
	onStepTwoNextClick: PropTypes.func
};

export default PhoneNumberPage;
