import RecommendationEndorsement from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import withSizes from 'react-sizes';
import { isSV /*, isMV, isLV*/ } from 'utils/sizes';

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withSizes(mapSizesToProps)(connect(mapStateToProps)(RecommendationEndorsement));
