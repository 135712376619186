import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text } from 'components/atoms';

class BlurbPromo extends React.Component {
	constructor(props) {
		super(props);

		this.wrapper = React.createRef();
		this.content = React.createRef();
	}

	componentDidMount = () => {
		const wrapperHeight = this.wrapper.scrollHeight;
		this.wrapper.style.height = wrapperHeight + 'px';
		this.wrapper.style.width = wrapperHeight + 'px';
		this.wrapper.style.top = (wrapperHeight / 2.3) * -1 + 'px';
		this.wrapper.style.right = (wrapperHeight / 3) * -1 + 'px';

		this.content.style.paddingTop = wrapperHeight / 2 + 'px';
		this.content.style.paddingLeft = wrapperHeight / 7 + 'px';
	};

	render() {
		const { ariaHidden, className, translate, translateKey } = this.props,
			classProps = classnames('promo-banner blurb', className && className);

		return (
			<div
				className={classProps}
				ref={c => (this.wrapper = c)}
				aria-hidden={ariaHidden}
				tabIndex={ariaHidden ? -1 : undefined}
			>
				<div className="promo-banner-content" ref={c => (this.content = c)}>
					<p>{translate(`${translateKey}.promo.blurb`)}</p>
				</div>
			</div>
		);
	}
}

BlurbPromo.defaultProps = {};
BlurbPromo.propTypes = {
	ariaHidden: PropTypes.bool,
	className: PropTypes.string,
	translate: PropTypes.func.isRequired,
	translateKey: PropTypes.string.isRequired
};

class ImagePromo extends React.Component {
	render() {
		const { ariaHidden, className, isFoldPromo, imageBaseUrl, translate, translateKey } = this.props,
			classProps = classnames('promo-banner image', className && className);

		let imageSrc, imageAlt;
		if (isFoldPromo) {
			imageSrc = `${imageBaseUrl}/${translate(`${translateKey}.fold.promoImage`)}`;
			imageAlt = `${imageBaseUrl}/${translate(`${translateKey}.fold.promoImageAlt`)}`;
		} else {
			imageSrc = `${imageBaseUrl}/${translate(`${translateKey}.promo.src`)}`;
			imageAlt =
				'asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf asdf ';
		}

		return (
			<div className={classProps} aria-hidden={ariaHidden} tabIndex={ariaHidden ? -1 : undefined}>
				<div className="promo-banner-content">
					<div className="promo-image-wrapper">
						<img src={imageSrc} alt={imageAlt} />
					</div>
				</div>
			</div>
		);
	}
}
ImagePromo.defaultProps = {};
ImagePromo.propTypes = {
	ariaHidden: PropTypes.bool,
	className: PropTypes.string,
	imageBaseUrl: PropTypes.string.isRequired,
	isFoldPromo: PropTypes.bool,
	translate: PropTypes.func.isRequired,
	translateKey: PropTypes.string.isRequired
};

class SubContentPromo extends React.Component {
	constructor(props) {
		super(props);

		this.wrapper = React.createRef();
		this.content = React.createRef();
	}

	componentDidMount = () => {
		const wrapperHeight = this.wrapper.scrollHeight;
		this.wrapper.style.height = wrapperHeight + 'px';
		this.wrapper.style.width = wrapperHeight + 'px';
		this.wrapper.style.top = (wrapperHeight / 2.3) * -1 + 'px';
		this.wrapper.style.right = (wrapperHeight / 3) * -1 + 'px';

		this.content.style.paddingTop = wrapperHeight / 2 + 'px';
		this.content.style.paddingLeft = wrapperHeight / 7 + 'px';
	};

	render() {
		const { ariaHidden, className, translate, translateKey } = this.props,
			classProps = classnames('promo-banner sub-content', className && className);

		return (
			<div
				className={classProps}
				ref={c => (this.wrapper = c)}
				aria-hidden={ariaHidden}
				tabIndex={ariaHidden ? -1 : undefined}
			>
				<div className="promo-banner-content" ref={c => (this.content = c)}>
					<p className="promo-header">{translate(`${translateKey}.promo.title`)}</p>
					<p>{translate(`${translateKey}.promo.blurb`)}</p>
				</div>
			</div>
		);
	}
}
SubContentPromo.defaultProps = {};
SubContentPromo.propTypes = {
	ariaHidden: PropTypes.bool,
	className: PropTypes.string,
	translate: PropTypes.func.isRequired,
	translateKey: PropTypes.string.isRequired
};

class ListPromo extends React.Component {
	constructor(props) {
		super(props);

		this.wrapper = React.createRef();
		this.content = React.createRef();
	}

	componentDidMount = () => {
		const { hideImages } = this.props;

		if (hideImages) {
			const wrapperHeight = this.wrapper.scrollHeight;
			this.wrapper.style.height = wrapperHeight * 1.25 + 'px';
			this.wrapper.style.width = wrapperHeight * 1.25 + 'px';
			this.wrapper.style.top = (wrapperHeight / 1.7) * -1 + 'px';
			this.wrapper.style.right = (wrapperHeight / 3) * -1 + 'px';

			this.content.style.paddingTop = wrapperHeight / 1.5 + 'px';
			this.content.style.paddingLeft = wrapperHeight / 7 + 'px';
		}
	};

	render() {
		const { ariaHidden, className, hideImages, imageBaseUrl, translate, translateKey } = this.props,
			classProps = classnames('promo-banner list', className && className, !hideImages && 'image');

		const count = translate(`${translateKey}.promo.count`);
		const listItems = [];

		for (let i = 0; i < count; i++) {
			const hasImage = translate(`${translateKey}.promo.items.${i}.image`).indexOf('Missing') === -1;

			listItems.push(
				<li key={`${Math.random()}-${i}`}>
					<Text size="xs">{translate(`${translateKey}.promo.items.${i}.text`)}</Text>
					{!hideImages && hasImage && (
						<div className="promo-image-wrapper">
							<img
								src={`${imageBaseUrl}/${translate(`${translateKey}.promo.items.${i}.image`)}`}
								alt={translate(`${translateKey}.promo.items.${i}.imageAlt`)}
							/>
						</div>
					)}
				</li>
			);
		}

		return (
			<div
				className={classProps}
				ref={c => (this.wrapper = c)}
				aria-hidden={ariaHidden}
				tabIndex={ariaHidden ? -1 : undefined}
			>
				<div className="promo-banner-content" ref={c => (this.content = c)}>
					<p className="promo-header">{translate(`${translateKey}.promo.title`)}</p>
					<ul>{listItems}</ul>
				</div>
			</div>
		);
	}
}
ListPromo.defaultProps = {
	hideImages: false
};
ListPromo.propTypes = {
	ariaHidden: PropTypes.bool,
	className: PropTypes.string,
	hideImages: PropTypes.bool,
	imageBaseUrl: PropTypes.string,
	translate: PropTypes.func.isRequired,
	translateKey: PropTypes.string.isRequired
};

const CardPromoBanner = ({ ariaHidden, className, hideImages, isFoldPromo, imageBaseUrl, translate, translateKey }) => {
	let type;

	if (isFoldPromo) {
		type = 'image';
	} else {
		type = translate(`${translateKey}.promo.type`);
	}

	return type === 'blurb' ? (
		<BlurbPromo ariaHidden={ariaHidden} translate={translate} translateKey={translateKey} className={className} />
	) : type === 'subcontent' ? (
		<SubContentPromo
			ariaHidden={ariaHidden}
			translate={translate}
			translateKey={translateKey}
			className={className}
		/>
	) : type === 'image' ? (
		<ImagePromo
			ariaHidden={ariaHidden}
			translate={translate}
			translateKey={translateKey}
			className={className}
			isFoldPromo={isFoldPromo}
			imageBaseUrl={imageBaseUrl}
		/>
	) : (
		<ListPromo
			ariaHidden={ariaHidden}
			translate={translate}
			translateKey={translateKey}
			hideImages={hideImages}
			className={className}
			imageBaseUrl={imageBaseUrl}
		/>
	);
};

CardPromoBanner.defaultProps = {};

CardPromoBanner.propTypes = {
	ariaHidden: PropTypes.bool,
	className: PropTypes.string,
	hideImages: PropTypes.bool,
	imageBaseUrl: PropTypes.string.isRequired,
	isFoldPromo: PropTypes.bool,
	promo: PropTypes.shape({
		type: PropTypes.oneOf(['list', 'subcontent', 'blurb', 'image']),
		title: PropTypes.string,
		items: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
		blurb: PropTypes.string,
		src: PropTypes.string
	}),
	translate: PropTypes.func.isRequired,
	translateKey: PropTypes.string.isRequired
};

export default CardPromoBanner;
