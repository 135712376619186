import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import { startCase } from 'lodash';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import { SHOW_LESS_ALL_FILTERS } from 'utils/windowEvents';

// import "./style.scss";

/**
 * [FilterGroup description]
 * @extends Component
 */
class FilterGroup extends Component {
	state = {
		showMore: false
	};

	componentDidMount = () => {
		window.addEventListener(SHOW_LESS_ALL_FILTERS, this.showLess, false);
		console.log('added event listener');
	};

	componentWillUnmount = () => {
		window.removeEventListener(SHOW_LESS_ALL_FILTERS, this.showLess);
		console.log('removed event listener');
	};

	toggleShowMore = () => {
		this.setState({ showMore: !this.state.showMore });
	};

	showLess = () => {
		console.log('firing this.showLess');
		this.setState({ showMore: false });
		console.log('state:', this.state);
	};

	getOptions = () => {
		const { concat, options, concatLength } = this.props,
			{ showMore } = this.state;

		if (concat) {
			if (showMore) {
				return options;
			} else {
				return options.slice(0, concatLength);
			}
		} else {
			return options;
		}
	};

	render() {
		const { concat, concatLength, className, expandText, filterName, isSV } = this.props,
			classProps = classnames(className && className, 'filter-group'),
			options = this.getOptions(),
			doConcat = this.props.options.length > concatLength;

		let filterTitle = filterName === 'covid' ? 'COVID-19 Practices' : startCase(filterName);

		return Object.values(options).length > 0 ? (
			<div key={filterName} className={classProps} data-group={filterName}>
				<Text className={classnames('bold', isSV ? 'mb-3' : 'mb-1')}>{filterTitle}</Text>
				<ul>
					{options}
					{concat && doConcat && (
						<Button onClick={this.toggleShowMore} className="more-button">
							{this.state.showMore ? expandText.open : expandText.closed}
						</Button>
					)}
				</ul>
			</div>
		) : null;
	}
}

FilterGroup.defaultProps = {
	concatLength: 5
};

/**
 * [propTypes description]
 * @interface FilterGroupProps
 *
 * @example {}
 */
FilterGroup.propTypes = {
	className: PropTypes.string,
	expandText: PropTypes.shape({
		open: PropTypes.string,
		closed: PropTypes.string
	}),
	filterName: PropTypes.string.isRequired,
	isSV: PropTypes.bool.isRequired,
	options: PropTypes.array.isRequired,
	concat: PropTypes.bool,
	concatLength: PropTypes.number.isRequired
};

export default FilterGroup;
