/* eslint-disable no-case-declarations */
import * as t from '../actions/recommendations_modal/types';
import { hasProp } from 'utils/object';
/**
 * Redux_AutocompleteState
 * @interface Redux_AutocompleteState
 * @property {Array} [completions=[]]
 */

const recommendationsModalInitialState = {
	isLoadingOptions: false,
	isSubmitting: false,
	negative: [],
	positive: [],
	prefillnegative: [],
	prefillpositive: [],
	show: false,
	showThankYou: false,
	type: 'positive',
	prefill: false,
	error: false,
	errorCode: false,
	restaurantId: null,
	canToggleRecType: false
};

const updateRecommendation = (state, { type, dataKey, groupKey, value }) => {
	let recGroupIndex, recCatIndex;
	state[type].forEach((group, groupIndex) => {
		if (group.name === groupKey) {
			recGroupIndex = groupIndex;
			group.categories.forEach((cat, catInde) => {
				if (cat.id === dataKey) {
					recCatIndex = catInde;
				}
			});
		}
	});

	let valueKey = state[type][recGroupIndex].categories[recCatIndex].type === 'textarea' ? 'value' : 'selected';

	state[type][recGroupIndex].categories[recCatIndex][valueKey] = value;

	return [...state[type]];
};

const resetOptions = (show, options) => {
	if (!show) {
		let reset = options.slice();
		reset.forEach(group => {
			group.categories.forEach(cat => {
				if (hasProp(cat, 'value')) {
					cat.value = '';
				} else {
					cat.selected = false;
				}
			});
		});
		return reset;
	} else {
		return options;
	}
};

export default (state = recommendationsModalInitialState, action) => {
	switch (action.type) {
		case t.TOGGLE_SHOW_RECOMMENDATIONS_MODAL:
			let negative = resetOptions(action.payload.show, state.negative),
				positive = resetOptions(action.payload.show, state.positive),
				restaurantId = action.payload.restaurantId,
				canToggleRecType = action.payload.canToggleRecType;

			if (action.payload.show === false) {
				return {
					isLoadingOptions: false,
					isSubmitting: false,
					negative,
					positive,
					show: false,
					showThankYou: false,
					type: 'positive',
					prefill: false,
					error: false,
					errorCode: false,
					restaurantId: null,
					canToggleRecType
				};
			}

			return {
				...state,
				show: action.payload.show,
				type: action.payload.type,
				prefill: state.prefill,
				negative,
				positive,
				restaurantId,
				canToggleRecType
			};
		case t.TOGGLE_SHOW_RECOMMENDATIONS_TY_MODAL: {
			return {
				...state,
				showThankYou: action.payload,
				error: false
			};
		}
		case t.APPLY_RECOMMENDATION: {
			return {
				...state,
				[action.payload.type]: updateRecommendation(Object.assign({}, state), action.payload),
				error: false
			};
		}
		case t.FETCH_RECOMMENDATIONS_OPTIONS_START: {
			return {
				...state,
				isLoadingOptions: true,
				error: false
			};
		}
		case t.FETCH_RECOMMENDATIONS_OPTIONS_SUCCESS: {
			let options = {
				negative: [],
				positive: []
			};

			const addOptions = (type, optSet) => {
				optSet = optSet.map(opt => {
					let categories = [];
					opt.categories.forEach(cat => {
						categories.push({ ...cat, selected: false });
					});
					if (type === 'negative' && opt.name === 'General') {
						// add feedback field
						categories.push({
							id: 'desc',
							type: 'textarea',
							value: ''
						});
					}
					return {
						...opt,
						categories
					};
				});
				options[type] = optSet;
			};

			addOptions('positive', action.payload.positive);
			addOptions('negative', action.payload.negative);

			return {
				...state,
				isLoadingOptions: false,
				error: false,
				...options // negative, positive
			};
		}
		case t.FETCH_RECOMMENDATIONS_OPTIONS_FAILURE: {
			return {
				...state,
				isLoadingOptions: false,
				error: action.payload
			};
		}
		case t.SUBMIT_RECOMMENDATION_START: {
			return {
				...state,
				isSubmitting: true,
				prefill: false,
				error: false
			};
		}
		case t.SUBMIT_RECOMMENDATION_SUCCESS: {
			const show = false;
			let negative = resetOptions(show, state.negative),
				positive = resetOptions(show, state.positive);

			return {
				...state,
				isSubmitting: false,
				show, // hide original modal
				showThankYou: true,
				positive,
				negative,
				error: false
			};
		}
		case t.SUBMIT_RECOMMENDATION_FAILURE: {
			return {
				...state,
				error: action.payload.error,
				showThankYou: false,
				isSubmitting: false,
				isLoading: false,
				errorCode: action.payload.code
			};
		}
		case t.SHOW_RECOMMENDATIONS_MODAL_PREFILLED: {
			let transformed = state[action.payload.type].slice();

			transformed.forEach(group => {
				group.categories.forEach(cat => {
					let userRecs = action.payload.recommendation.for;
					if (cat.id === 'desc') {
						cat.value = action.payload.recommendation.feedback;
					} else {
						cat.selected = userRecs.indexOf(cat.id) > -1;
					}
				});
			});

			return {
				...state,
				show: true,
				showThankYou: false,
				isSubmitting: false,
				isLoadingOptions: false,
				prefill: true,
				prefillDate: action.payload.recommendation.date,
				[`prefill${action.payload.type}`]: transformed, // negative, positive
				type: action.payload.type,
				error: false,
				restaurantId: action.payload.recommendation.restaurant.id
			};
		}

		case t.SUBMIT_NONE_SELECTED: {
			return {
				...state,
				showThankYou: false,
				isSubmitting: false,
				isLoading: false,
				error: 'NONE_SELECTED',
				errorCode: 'NONE_SELECTED'
			};
		}
		default:
			return state;
	}
};
