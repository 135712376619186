const _ = require('lodash');
const commonConfig = require('./config.common');

// eslint-disable-next-line no-unused-vars
module.exports = function(options) {
	return _.merge({}, commonConfig(options), {
		// prod config here
		api_basePath_myDinova: 'https://dev.dinova.com/api-mydinova/v1',
		api_basePath_restaurant: 'https://dev.dinova.com/api-restaurant/v1/',
		api_basePath_zuppler: '//order.biznettechnologies.com',
		zuppler_dataChannelPath: 'http://api.biznettechnologies.com/v3/channels/dinovauat.json',
		cdn_basePath: 'https://dinova-images-uat.imgix.net',
		imageBaseUrl: 'https://dinova-images-uat.imgix.net',
		content_paths: [
			'https://public-assets.uat.dinova.com/mydinova/v2/apps.json'
			// 'https://s3.amazonaws.com/dinova-public-assets-uat/mydinova/mydinova_copy.json',
			// 'https://s3.amazonaws.com/dinova-public-assets-uat/mydinova/search_dinova_copy.json'
		],
		web_basePath: 'https://zuppler.uat.dinova.com/',
		currentEnv: 'zuppler_uat',
		color: 'green',
		ga_key: 'UA-47151707-7',
		gtm_key: 'GTM-576P6RK',
		header_logo_link: 'https://www.dinova.com/',
		federated_identity_pool: 'us-east-1:db7b8603-a9b2-436e-b5a6-094dccc5119c',
		aws_connect_bucket: 'dinova-client-portal-storage-dev'
	});
};
