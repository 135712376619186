import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import classnames from 'classnames';
import { CalendarDropdown } from 'components/molecules';

class DateInput extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	onChange = date => {
		this.props.onChange(date);
	};

	render() {
		return <CalendarDropdown {...this.props} />;
	}
}

DateInput.defaultProps = {};

DateInput.propTypes = {
	className: PropTypes.string,
	dataCy: PropTypes.string,
	disabled: PropTypes.bool,
	id: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
};

export default DateInput;
