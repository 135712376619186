import React, { useEffect } from 'react';
import { Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
	LazyAddCreditCard,
	LazyCompleteEmail,
	LazyCompleteProfile,
	LazyGreeting,
	LazyProfileSetupFinishStep
} from './components/Steps';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import styles from './style.module.scss';
import { useDisableScrolling } from 'hooks';
import CompleteSetupBanner from './components/CompleteSetupBanner';
import {
	SETUP_COMPLETE_URL_PARAM_NAME,
	SETUP_COMPLETE_URL_PARAM_VALUE
} from 'components/organisms/ProfileSetupModal/constants';
import queryString from 'query-string';

const ProfileSetupModal = ({
	currentStep,
	isAuthenticated,
	isSetupComplete,
	location,
	modalOpen,
	toggleModalOpen,
	closeModal,
	registrationDate,
	translate,
	completedSteps,
	totalSteps,
	isFinishedViewed,
	isLoading,
	showComplete
}) => {
	const isSearchPage = location.pathname.includes('search');
	const isSetupFlowOnSearchPage = false;
	const isRegistrationFlow = location.pathname.indexOf('/users/register') > -1;
	const isSetupCompleteOnSearchPage =
		(queryString.parse(location.search)[SETUP_COMPLETE_URL_PARAM_NAME] ?? '') === SETUP_COMPLETE_URL_PARAM_VALUE;

	const checkEligibility = () => {
		const translateBaseKey = 'ProfileSetup';
		const eligibleDate = new Date(translate(`${translateBaseKey}.startDate`));
		if (registrationDate === null) {
			return true;
		}
		return registrationDate >= eligibleDate;
	};

	useDisableScrolling(modalOpen && !isRegistrationFlow && checkEligibility());
	useEffect(() => {
		if (isSetupCompleteOnSearchPage) {
			console.log('checking isSetupCompleteOnSearchPage');
			closeModal();
		} else if (isSetupFlowOnSearchPage && !modalOpen) {
			console.log('checking isSetupFlowOnSearchPage && !modalOpen');
			toggleModalOpen();
		}
	}, [isSetupCompleteOnSearchPage, isSetupFlowOnSearchPage, modalOpen, toggleModalOpen, closeModal]);

	useEffect(() => {
		// Check if all steps are completed
		if (completedSteps === totalSteps && !isFinishedViewed) {
			if (isSearchPage && !modalOpen) {
				toggleModalOpen();
			}
		} else if (completedSteps == totalSteps) {
			closeModal();
		}
	}, [completedSteps, totalSteps, toggleModalOpen, modalOpen]);

	if (!checkEligibility()) {
		console.log('user is not eligibile for profile setup');
		return null;
	} else if (
		(isAuthenticated && isFinishedViewed) ||
		!isAuthenticated ||
		isSetupFlowOnSearchPage ||
		(isSetupComplete && isFinishedViewed) ||
		isRegistrationFlow
	) {
		console.log('checking failed in second spot');
		return null;
	}

	return isLoading ? null : (
		<div>
			<Modal open={modalOpen} className={styles.modal} data-cy="profile-setup-modal">
				<div
					className={styles.modalContent}
					data-cy="content"
					style={
						{
							// maxHeight: currentStep === ProfileSetupSteps.ADD_CREDIT_CARD ? 'unset' : '390px'
						}
					}
				>
					{showComplete ? (
						<LazyProfileSetupFinishStep />
					) : (
						<>
							<button className={styles.closeBtn} onClick={toggleModalOpen} data-cy="close-btn" />
							{currentStep === ProfileSetupSteps.GREETING && <LazyGreeting />}
							{currentStep === ProfileSetupSteps.PROFILE && <LazyCompleteProfile />}
							{currentStep === ProfileSetupSteps.ADD_CREDIT_CARD && <LazyAddCreditCard />}
							{currentStep === ProfileSetupSteps.EMAILS && <LazyCompleteEmail />}
						</>
					)}
				</div>
			</Modal>
			{!isSetupComplete && <CompleteSetupBanner {...(isSearchPage ? { mb: 16 } : {})} />}
		</div>
	);
};

ProfileSetupModal.propTypes = {
	currentStep: PropTypes.string,
	isAuthenticated: PropTypes.bool,
	isSetupComplete: PropTypes.bool,
	location: PropTypes.shape({
		pathname: PropTypes.string,
		search: PropTypes.string
	}),
	modalOpen: PropTypes.bool,
	toggleModalOpen: PropTypes.func,
	closeModal: PropTypes.func,
	finishOnboarding: PropTypes.func,
	isLoading: PropTypes.bool.isRequired,
	registrationDate: PropTypes.any,
	translate: PropTypes.func,
	completedSteps: PropTypes.number,
	totalSteps: PropTypes.number,
	isFinishedViewed: PropTypes.bool,
	showComplete: PropTypes.bool
};

export default ProfileSetupModal;
