import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DropdownToggle, DropdownMenu, InputGroupButtonDropdown } from 'reactstrap';
import Calendar from 'react-calendar';
import moment from 'moment';

class CalendarDropdown extends Component {
	static defaultProps = {
		className: ''
	};

	constructor(props) {
		super(props);
		this.state = {
			show: false,
			date: moment(props.cateringDate).format('MMM D')
		};
	}

	render() {
		const { className, cateringDate, isSV } = this.props;
		const { show } = this.state;
		const classProps = classNames('calendar-dropdown', className);

		let minDate = new Date(),
			maxDate = moment(minDate)
				.add(90, 'd')
				.toDate();
		const Label = <i className="arrow" />;

		return (
			<InputGroupButtonDropdown addonType="prepend" isOpen={show} className={classProps} toggle={this.toggle}>
				<DropdownToggle caret>
					<span className="title">{this.state.date}</span>
					<div className="caret-wrapper">
						<i className="caret" />
					</div>
				</DropdownToggle>
				<DropdownMenu right={!isSV}>
					<Calendar
						value={cateringDate}
						onChange={this.handleChange}
						minDate={minDate}
						maxDate={maxDate}
						calendarType="US"
						next2Label={null}
						prev2Label={null}
						minDetail="month"
						showNeighboringMonth={true}
						tileDisabled={this.handleTileDisabled}
						prevLabel={Label}
						nextLabel={Label}
					/>
				</DropdownMenu>
			</InputGroupButtonDropdown>
		);
	}

	toggle = () => {
		this.setState({ show: !this.state.show });
	};

	handleChange = date => {
		date = new Date(date).setHours(12, 0, 0, 0);
		this.setState({
			date: moment(date).format('MMM D')
		});
		this.props.setDate(new Date(date));
		this.toggle();
		if (this.props.onChange) {
			this.props.onChange(date);
		}
	};

	/**
	 * disable days of neighboring months
	 * @param activeStartDate {Date} - selected month on calendar view
	 * @param date {Date} - tile's date
	 * @returns {boolean} - is this tile disabled?
	 */
	handleTileDisabled = ({ activeStartDate, date }) => {
		return activeStartDate.getMonth() !== date.getMonth();
	};
}

CalendarDropdown.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
	setDate: PropTypes.func.isRequired,
	cateringDate: PropTypes.instanceOf(Date),
	isSV: PropTypes.bool
};

export default CalendarDropdown;
