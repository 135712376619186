import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { LoadingBase, Text } from 'components/atoms';
import { ActiveFiltersBar, CollapsiblePanel } from 'components/molecules';
import { BusinessSearchFilter } from 'components/organisms';
import { Button } from 'reactstrap';

/**
 * [SearchPageFilterPanel description]
 * @extends Component
 */
class SearchPageFilterPanel extends Component {
	render() {
		const {
				activeFilters,
				addFilter,
				clearFilters,
				isLoading,
				isSV,
				showFilter,
				hasActiveFilters,
				removeFilter,
				translate
			} = this.props,
			filterClassProps = classnames(isSV && showFilter ? '' : !isSV ? '' : 'slide-up');

		return (
			<CollapsiblePanel isOpen={showFilter}>
				{isLoading && (
					<div style={{ marginTop: '50px' }}>
						<LoadingBase />
					</div>
				)}
				{!isLoading && (
					<>
						<div className="flex align-baseline filter-title">
							<Text className="mb-0 bold mr-2">{translate('FilterModal.title')}</Text>
							{hasActiveFilters && (
								<Button className="text" onClick={clearFilters}>
									{translate('FilterModal.clear')}
								</Button>
							)}
						</div>
						<ActiveFiltersBar activeFilters={activeFilters} removeFilter={removeFilter} />
						<BusinessSearchFilter
							addFilter={addFilter}
							removeFilter={removeFilter}
							className={filterClassProps}
							clearFilters={clearFilters}
						/>
					</>
				)}
			</CollapsiblePanel>
		);
	}
}

SearchPageFilterPanel.defaultProps = {};

/**
 * SearchPageFilterPanelProps
 * @typedef {Object} SearchPageFilterPanelProps
 */
SearchPageFilterPanel.propTypes = {
	activeFilters: PropTypes.array.isRequired,
	addFilter: PropTypes.func.isRequired,
	clearFilters: PropTypes.func.isRequired,
	hasActiveFilters: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,
	isSV: PropTypes.bool.isRequired,
	removeFilter: PropTypes.func.isRequired,
	showFilter: PropTypes.bool.isRequired,
	translate: PropTypes.func.isRequired
};

export default SearchPageFilterPanel;
