import * as t from './types';
import { addTranslationForLanguage } from 'react-localize-redux';
import axios from 'axios';
import { merge } from 'lodash';
import { STORE } from 'components/environment/App/redux_store.js';
import { setIsLoading } from 'actions/loader';

/**
 * When localization strings are loaded, set localization loading states to complete.
 * @param  {object} strings An object of localized strings.
 */
// eslint-disable-next-line no-unused-vars
export const stringsFetchDataSuccess = strings => {
	return {
		type: t.STRINGS_FETCHED_SUCCESS,
		isBusy: false,
		error: undefined
	};
};

/**
 * When localization string fetch fails, set localization loading state to failed.
 * @param  {object} error The error from the failed API request.
 */
export const stringFetchedDataError = error => {
	return {
		type: t.STRINGS_FETCHED_ERROR,
		isBusy: false,
		error
	};
};

/**
 * Request for fetching localization strings.
 * @param  {string} url Where to fetch the localization strings from.
 */
export const requestStrings = url => {
	return {
		type: t.REQUEST_STRINGS,
		url
	};
};

// thunk action creators
/**
 * Kick off request to fetch localization strings.
 */
export const startFetchStrings = () => async dispatch => {
	dispatch(setIsLoading(true));
	const response = await getLanguageStrings();

	dispatch(addTranslationForLanguage(response.data.en, 'en'));
	dispatch(stringsFetchDataSuccess(response.data));
	dispatch(setIsLoading(false));
};

/**
 * Create GET requests for each langauge string content path.  Wait for all promises to resolve.  Merge together response data.  Return merged object.
 * @return {object} API data response object. response.data will contain language strings.
 */
const getLanguageStrings = async () => {
	const urls = contentPaths();
	let fetchers = [];

	urls.forEach(async url => {
		fetchers.push(axios.get(url));
	});

	const res = await Promise.all(fetchers);
	const data = merge({}, ...res);
	return data;
};

/**
 * Retrives the URLs to fetch the localization strings from.
 * @return {string[]} An array of URLs where localization files can be fetched.
 */
const contentPaths = () => {
	let config = STORE.getState().config;
	let contentPath = config[`content_paths`];
	return contentPath;
};
