import COVIDRestaurantUniquePrecautions from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { setModal, toggleModal } from 'actions/modal';
import { hasProp } from 'utils/object';

/* eslint-disable no-mixed-spaces-and-tabs */
const mapStateToProps = state => {
	return {
		modal: state.modal,
		otherSteps: hasProp(state, 'details.restaurant.otherSteps')
			? state.details.restaurant.otherSteps.charAt(0).toUpperCase() + state.details.restaurant.otherSteps.slice(1)
			: false,
		translate: getTranslate(state.locale)
	};
};

const mapDispatchToProps = dispatch => ({
	setModal: modal => dispatch(setModal(modal)),
	toggleModal: toggle => dispatch(toggleModal(toggle))
});

export default connect(mapStateToProps, mapDispatchToProps)(COVIDRestaurantUniquePrecautions);
