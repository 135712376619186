import * as t from './types';

export const initCOVID19 = (state, translate) => {
	const config = {
		active: translate(`notification.states.${state}.active`) === 'true',
		message: translate(`notification.states.${state}.message`),
		color: translate(`notification.states.${state}.color`),
		activeFilter: translate(`notification.states.${state}.activeFilter`) === 'true'
	};

	return {
		type: t.COVID_SET_STATE,
		payload: { state, config }
	};
};

export const toggleCovidShow = show => {
	return {
		type: t.TOGGLE_COVID_SHOW,
		payload: show
	};
};
