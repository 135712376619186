import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { LoadingBase } from 'components/atoms';

const List = ({ align, className, items, itemComponent, itemComponentProps, listId, onItemClick }) => {
	const ItemComponent = itemComponent,
		classProps = classnames('list', className, align);

	return (
		<div className={classProps}>
			{items.length === 0 ? (
				<LoadingBase />
			) : (
				items.map((item, index) => (
					<ItemComponent
						item={item}
						key={`${listId}-${index}`}
						onItemClick={onItemClick}
						{...(itemComponentProps || {})}
					/>
				))
			)}
		</div>
	);
};

List.defaultProps = {
	align: 'vertical',
	items: []
};

List.propTypes = {
	className: PropTypes.string,
	align: PropTypes.oneOf(['vertical', 'horizontal']),
	items: PropTypes.array.isRequired,
	itemComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
	itemComponentProps: PropTypes.any,
	listId: PropTypes.string.isRequired,
	onItemClick: PropTypes.func
};

export default List;
