import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class HeaderSection extends Component {
	render() {
		const { className, bannerImg, logoImg, title, translate, withoutLogo } = this.props;
		const classProps = classNames('header-section', className);
		const bannerImgUrl = `url("${bannerImg}")`;

		return (
			<div className={classProps}>
				<div className="banner-img" style={{ backgroundImage: bannerImgUrl }} />
				<a className="back-link" onClick={this.back}>
					<i className="fa fa-chevron-left" />
					{translate('Details.back')}
				</a>
				<span className="title">{title}</span>
				{withoutLogo !== true && <img className="logo-img" src={logoImg} />}
			</div>
		);
	}

	back = event => {
		event.preventDefault();
		if (this.props.history.length > 1) {
			this.props.history.goBack();
		} else {
			this.props.history.push('/search');
		}
	};
}

HeaderSection.defaultProps = {
	className: ''
};

HeaderSection.propTypes = {
	className: PropTypes.string,
	bannerImg: PropTypes.string,
	title: PropTypes.string,
	logoImg: PropTypes.string,
	translate: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	withoutLogo: PropTypes.bool
};

export default HeaderSection;
