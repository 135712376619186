import React from 'react';
import PropTypes from 'prop-types';
import { InvisibleButton } from 'components/atoms';
import { Text } from 'components/atoms';
import styles from './style.module.scss';
import classnames from 'classnames';

const StepListItem = ({ complete, goToStep, onClick, step, title }) => {
	return (
		<InvisibleButton onClick={() => (onClick ? onClick() : goToStep(step))} fullWidth>
			<div className={classnames(styles.wrapper, styles.row)}>
				<div className={styles.row}>
					{complete ? (
						<i className={classnames(styles.completeIcon, 'fas fa-check-circle')} />
					) : (
						<div className={styles.incompleteCircle} />
					)}
					<Text weight="bold" className={styles.text}>
						{title}
					</Text>
				</div>
				<i className={classnames(styles.caret, 'fas fa-angle-right')} />
			</div>
		</InvisibleButton>
	);
};

StepListItem.propTypes = {
	complete: PropTypes.bool.isRequired,
	goToStep: PropTypes.func.isRequired,
	onClick: PropTypes.func,
	step: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired
};

export default StepListItem;
