import * as t from '../actions/location_autocomplete/types';

/**
 * Redux_LocationAutocompleteState
 * @interface Redux_LocationAutocompleteState
 * @property {string}  [address='']
 * @property {boolean} [loading=false]
 * @property {array}   [suggestions=[]]
 * @property {boolean} [ready=false]
 */
const locationAutocompleteInstanceDefaultState = {
	currentLocationItem: false,
	activeItem: false,
	noSuggestionsFoundItem: false,
	search: '',
	showLoader: false,
	suggestions: []
};

const locationAutoCompleteReducerDefaultState = {};

export default (state = locationAutoCompleteReducerDefaultState, action) => {
	switch (action.type) {
		case t.INIT_LOCATION_AUTOCOMPLETE_INSTANCE: {
			const { id, currentLocationItem, noSuggestionsFoundItem } = action.payload;
			if (!state[id]) {
				return {
					...state,
					[id]: {
						...locationAutocompleteInstanceDefaultState,
						currentLocationItem,
						noSuggestionsFoundItem,
						suggestions: [currentLocationItem]
					}
				};
			} else {
				return state;
			}
		}

		case t.FETCH_LOCATION_COMPLETIONS_START: {
			const { id, search, showLoader } = action.payload;

			return {
				...state,
				[id]: {
					...state[id],
					activeItem: false,
					search,
					showLoader
				}
			};
		}
		case t.FETCH_LOCATION_COMPLETIONS_SUCCESS: {
			const { id, suggestions } = action.payload;
			return {
				...state,
				[id]: {
					...state[id],
					suggestions: [state[id].currentLocationItem, ...suggestions],
					showLoader: false
				}
			};
		}
		case t.FETCH_LOCATION_COMPLETIONS_NO_RESULTS: {
			const id = action.payload;
			return {
				...state,
				[id]: {
					...state[id],
					suggestions: [state[id].noSuggestionsFoundItem, state[id].currentLocationItem],
					showLoader: false
				}
			};
		}

		case t.SET_LOCATION_AUTOCOMPLETE_ACTIVE_ITEM: {
			const { id, activeItem } = action.payload;

			return {
				...state,
				[id]: {
					...state[id],
					activeItem
				}
			};
		}

		case t.LOCATION_AUTOCOMPLETE_ITEM_CLICK: {
			const { id, suggestion } = action.payload;

			return {
				...state,
				[id]: {
					...state[id],
					activeItem: 1,
					suggestions: [state[id].currentLocationItem, suggestion]
				}
			};
		}
		default:
			return state;
	}
};
