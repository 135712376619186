import AutoEnrollUserInvalidToken from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
	isLoading: state.autoEnrollUserFormPage.isLoading,
	linkSent: state.autoEnrollUserFormPage.linkSent,
	error: state.autoEnrollUserFormPage.error,
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(AutoEnrollUserInvalidToken);
