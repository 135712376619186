import React, { Component } from 'react';
import { Text } from 'components/atoms';
import { getLangFileCardLabelKey } from 'utils/card';
import PropTypes from 'prop-types';

class ProfilePaymentIneligibilityMessage extends Component {
	render() {
		return this.props.ineligibleConnectionCardTypes.size > 0 ? (
			<div className="center ineligible-message" data-cy="profile-payment-ineligibility-message-wrapper">
				<Text
					size="sm"
					weight="thin"
					align="center"
					className="mb-0"
					dataCy="profile-payment-ineligibility-notice"
				>
					{this.buildIneligibleNotice()}
				</Text>
				{!this.props.hasEligible && (
					<Text
						size="sm"
						weight="thin"
						align="center"
						className="mb-0"
						dataCy="profile-payment-ineligibility-prompt"
					>
						{this.buildIneligiblePrompt()}
					</Text>
				)}
			</div>
		) : null;
	}

	buildIneligibleNotice = () => {
		if (this.props.ineligibleConnectionCardTypes[0] != null) {
			let cardNames = Array.from(this.props.ineligibleConnectionCardTypes).map(t =>
				this.props.translate(`Profile.payment.ineligible.cardNames.${getLangFileCardLabelKey(t)}`)
			);

			if (cardNames.length === 1) {
				return this.buildSingleCardEligibiltiyMessage('notice', cardNames);
			} else {
				if (cardNames.length === 2) {
					return this.buildTwoCardEligibilityMessage('notice', cardNames);
				} else {
					return this.buildThreePlusCardEligibilityMessage('notice', cardNames);
				}
			}
		}
	};

	buildIneligiblePrompt = () => {
		let cardNames = this.props.supportedConnectionCardTypes.map(t =>
			this.props.translate(`Profile.payment.ineligible.cardNames.${getLangFileCardLabelKey(t)}`)
		);

		if (cardNames.length === 1) {
			return this.buildSingleCardEligibiltiyMessage('singlePrompt', cardNames);
		} else {
			if (cardNames.length === 2) {
				return this.buildTwoCardEligibilityMessage('multiPrompt', cardNames);
			} else {
				return this.buildThreePlusCardEligibilityMessage('multiPrompt', cardNames);
			}
		}
	};

	buildSingleCardEligibiltiyMessage = (translateKey, cardNames) => {
		return (
			this.props
				// get prompt string from lang file
				.translate(`Profile.payment.ineligible.${translateKey}`)
				// replace cardType place holder with actual card type
				.replace('${cardTypes}', cardNames.join(''))
		);
	};

	buildTwoCardEligibilityMessage = (translateKey, cardNames) => {
		const joiner = this.props.translate('Profile.payment.ineligible.joiners.or');
		return (
			this.props
				// get prompt string from lang file
				.translate(`Profile.payment.ineligible.${translateKey}`)
				// replace cardTypes place holder with actual card types and joiner
				.replace('${cardTypes}', cardNames.join(` ${joiner} `))
				// use an or a as needed
				.replace('a(n)', cardNames[0][0].toLowerCase() === 'a' ? 'an' : 'a')
		);
	};

	buildThreePlusCardEligibilityMessage = (translateKey, cardNames) => {
		const joiner = this.props.translate('Profile.payment.ineligible.joiners.or');
		// put joiner in correct place
		cardNames.splice(-1, 0, joiner);
		return (
			this.props
				// get prompt string from lang file
				.translate('Profile.payment.ineligible.multiPrompt')
				// join card names with an ','
				.replace('${cardTypes}', cardNames.join(`, `))
				// remove trailing ',' from joiner caused by join(', ')
				.replace(`${joiner},`, joiner)
				// user an or a as needed
				.replace('a(n)', cardNames[0][0].toLowerCase() === 'a' ? 'an' : 'a')
		);
	};
}

ProfilePaymentIneligibilityMessage.defaultProps = {};

ProfilePaymentIneligibilityMessage.propTypes = {
	hasEligible: PropTypes.bool.isRequired,
	translate: PropTypes.func.isRequired,
	ineligibleConnectionCardTypes: PropTypes.oneOfType([PropTypes.instanceOf(Set), PropTypes.array]),
	supportedConnectionCardTypes: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.string),
		PropTypes.arrayOf(PropTypes.number)
	])
};

export default ProfilePaymentIneligibilityMessage;
