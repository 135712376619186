import DatePaginator from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
	IMAGE_BASE_URL: state.config.cdn_basePath,
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(DatePaginator);
