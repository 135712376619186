4; /* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Row, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
import metaTagConfig from './meta-tags';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { LastLoginMessage, LoadingBase } from 'components/atoms';
import {
	DashboardConnectMessage,
	DashboardHeader,
	MetaTags,
	PageErrorWithCopy,
	MFAModalPage
} from 'components/molecules';
import { DashboardBanner, DashboardPoints, DashboardPromotions } from 'components/organisms';
import { stringToBoolean } from 'utils/validations';
import { history } from 'utils/router';
import { hasProp } from 'utils/object';
import classnames from 'classnames';

export const RING_TYPES = {
	NO_POINTS_NO_CONNECTIONS: 'NO_POINTS_NO_CONNECTIONS',
	NO_POINTS: 'NO_POINTS',
	INELIGIBLE: 'NO_POINTS',
	HAS_POINTS: 'HAS_POINTS',
	NO_CONNECTIONS: 'NO_CONNECTIONS'
};

class DashboardPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showBanner: false,
			user: undefined,
			isLoading: true,
			points: 0,
			connections: 0,
			showPromo: false,
			hasMFAError: false
		};

		this.cookies = new Cookies();
	}

	componentDidMount = async () => {
		this.props.updateHeader(true);
		window.scrollTo(0, 0);
		await this.getProfile();
		await this.getRewards();
		await this.showBanner();
	};

	getRewards = async () => {
		this.setState({ isLoading: true });
		await this.props.getRewards(); // populate redux reward store
		this.setState({ isLoading: false });
	};

	getProfile = async () => {
		this.props.clearError();
		this.setState({ isLoading: true });
		const showLoading = false;
		const user = await this.props.getProfile(showLoading, true);

		if (user === 500 || user === undefined) {
			this.props.clearError();
			this.setRedirect();
			return;
		}

		const cookie = this.cookies.get(`show-referral-${user.userName}`) || 'true';

		this.setState(state => ({
			...state,
			user: user,
			points: user.points,
			connections: user.connections.length,
			showBanner: stringToBoolean(cookie) && user.connections.length > 0,
			isLoading: false
		}));

		this.showBanner();
		this.showPromo();
		this.cookies.get('dontAsk') == undefined && this.showMFA();
	};

	ringType = () => {
		if (this.isIneligible()) {
			return RING_TYPES.INELIGIBLE;
		} else if (this.state.points > 0 && this.state.connections === 0) {
			return RING_TYPES.NO_POINTS_NO_CONNECTIONS; // You have earned XX points [> Use my points] [> View my points history] |FOOTER| Want to earn even more myDinova reward points?
		} else if (this.state.connections === 0) {
			return RING_TYPES.NO_CONNECTIONS; // You have one more step to complete to start earning myDinova Reward points [GET POINTS]
		} else if (this.state.points === 0) {
			return RING_TYPES.NO_POINTS; // Find a Dinova Restaurant to earn your first myDinova points [FIND]
		} else {
			return RING_TYPES.HAS_POINTS; // You have earned XX points [REDEEM] [HISTORY]
		}
	};

	onClick = () => {
		this.props.startChangeStep(2);
		history.push('/users/connect?redirect=/mydinova');
	};

	onBannerClick = () => {
		history.push('/refer/details');
	};

	onBannerClose = e => {
		e.stopPropagation();
		this.cookies.set(`show-referral-${this.state.user.userName}`, false, {
			path: '/',
			expires: moment()
				.add(30, 'days')
				.toDate()
		});
		this.setState({
			...this.state,
			showBanner: false
		});
	};

	setRedirect = () => {
		this.setState({
			redirect: true
		});
	};

	onSubmitCode = () => {
		this.onToggle();
		let code = hasProp(this.props.formVals, 'codeField') && this.props.formVals.codeField;
		var payload = {
			code: code
		};

		this.props.authorize_mfa(payload, response => {
			switch (response.code) {
				case 200:
					this.props.clearError();

					break;
				default:
					this.setState({ hasMFAError: response.msg });

					break;
			}
		});
	};

	render() {
		const {
				translate,
				users: { errorCode }
			} = this.props,
			{ isLoading } = this.state;
		const classProps = classnames('dashboard-wrapper', this.showConnectFooter() && 'split');

		return this.state.redirect ? (
			<Redirect to="/logout" />
		) : (
			<div className={classProps} data-cy="DashboardPage">
				<MetaTags tags={metaTagConfig} />
				<MFAModalPage
					isOpen={this.props.modal.isOpen}
					primaryClick={this.onPrimaryClick}
					secondaryClick={this.onSecondaryClick}
					tertiaryClick={this.onSubmitCode}
					section="start"
					hasError={this.state.hasMFAError}
					isRequired={
						hasProp(this.state.user, 'company.security') && this.state.user.company.security.mfaRequired
					}
				/>
				<DashboardHeader />
				<div className="Di_SubHead Di_HomePic dashboard-page">
					{this.state.showBanner && this.showMobileBanner() && (
						<DashboardBanner onClick={this.onBannerClick} onClose={this.onBannerClose} />
					)}
					<div className="container-fluid Di_HeadSpace">
						<Row>
							<div className="Di_PageLimit mx-auto float-none animated fadeInUp">
								<CardBody>
									{errorCode ? (
										<PageErrorWithCopy
											alert={translate('Dashboard.error.alert')}
											message={translate(`errors.codes.${errorCode}.message`)}
										/>
									) : (
										<React.Fragment>
											{isLoading && <LoadingBase message={translate('Dashboard.loadingMsg')} />}
											{!isLoading && (
												<DashboardPoints
													points={this.state.points}
													primaryButtonText={this.primaryButtonText()}
													secondaryButtonText={translate('Dashboard.history_button')}
													singleButtonText={this.singleButtonText()}
													history={history}
													ringType={this.ringType}
													onClick={this.onClick}
												/>
											)}

											{this.showConnectFooter() && <DashboardConnectMessage />}
										</React.Fragment>
									)}
								</CardBody>
								{this.state.showPromo && <DashboardPromotions />}
							</div>
						</Row>
					</div>
				</div>
				<LastLoginMessage className="mb-0 pt-4" />
			</div>
		);
	}

	showMobileBanner = () => this.props.windowWidth < 768 && this.state.showBanner;

	showDesktopBanner = () => this.props.windowWidth >= 768 && this.state.showBanner;

	showBanner = () => {
		let response = true;

		if (hasProp(this.state.user, 'company.code')) {
			response = this.props
				.translate(`cobranding.companies.${this.state.user.company.code}.show_dashboard_banner`)
				.includes('Missing')
				? true
				: JSON.parse(
						this.props.translate(
							`cobranding.companies.${this.state.user.company.code}.show_dashboard_banner`
						)
				  );
		}
		this.setState({
			showBanner: response
		});
	};

	showPromo = () => {
		let response = true;
		if (hasProp(this.state.user, 'company.code')) {
			response = this.props
				.translate(`cobranding.companies.${this.state.user.company.code}.show_dashboard_promos`)
				.includes('Missing')
				? true
				: JSON.parse(
						this.props.translate(
							`cobranding.companies.${this.state.user.company.code}.show_dashboard_promos`
						)
				  );
		}
		this.setState({
			showPromo: response
		});
	};

	showMFA = () => {
		if (
			(hasProp(this.state.user, 'company.security') &&
				this.state.user.company.security.mfaRequired &&
				!this.state.user.security.mfaEnabled) ||
			!this.state.user.security.mfaEnabled
		) {
			// console.log('show because', hasProp(this.state.user, 'company.security'););
			this.showModal();
		}
	};

	showModal = () => {
		if (!hasProp(this.state.user, 'company.security') || !this.state.user.company.security.mfaRequired) {
			this.props.setModal({
				id: this.props.translate('Dashboard.modals.mfa_optin.id'),
				header: this.props.translate('Dashboard.modals.mfa_optin.header'),
				body: this.props.translate('Dashboard.modals.mfa_optin.body'),
				primaryButtonText: this.props.translate('Dashboard.modals.mfa_optin.primaryButtonText'),
				secondaryButtonText: this.props.translate('Dashboard.modals.mfa_optin.secondaryButtonText')
			});
		} else {
			this.props.setModal({
				id: this.props.translate('Dashboard.modals.mfa_required.id'),
				header: this.props.translate('Dashboard.modals.mfa_required.header'),
				body: this.props.translate('Dashboard.modals.mfa_required.body'),
				primaryButtonText: this.props.translate('Dashboard.modals.mfa_required.primaryButtonText'),
				secondaryButtonText: null
			});
		}

		this.onToggle();
	};

	onToggle = () => {
		const isOpen = !this.props.modal.isOpen;
		this.props.toggleModal(isOpen);
	};

	showConnectFooter = () => !this.state.isLoading && this.ringType() === RING_TYPES.NO_POINTS_NO_CONNECTIONS;

	primaryButtonText = () =>
		this.props.availableRewards === 0
			? this.props.translate('Dashboard.rewards_button')
			: this.props.translate('Dashboard.redeem_button');

	singleButtonText = () =>
		this.ringType() === RING_TYPES.NO_CONNECTIONS
			? this.props.translate('Dashboard.get_points_button')
			: this.props.translate('Dashboard.find_button');

	isIneligible = () =>
		(hasProp(this.props.users, 'user.company.myDinova') && !this.props.users.user.company.myDinova.eligible) ||
		(hasProp(this.props.users, 'user.company') && !this.props.users.user.company.code);
}

DashboardPage.propTypes = {
	availableRewards: PropTypes.number.isRequired,
	getRewards: PropTypes.func.isRequired,
	updateHeader: PropTypes.func,
	getProfile: PropTypes.func,
	startChangeStep: PropTypes.func,
	users: PropTypes.object,
	translate: PropTypes.func,
	windowWidth: PropTypes.number,
	clearError: PropTypes.func,
	modal: PropTypes.object.isRequired,
	setModal: PropTypes.func.isRequired,
	toggleModal: PropTypes.func.isRequired,
	authorize_mfa: PropTypes.func,
	formVals: PropTypes.object
};

export default DashboardPage;
