import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import { BusinessSearchForm, SearchPageQuickFilters } from 'components/organisms';

class SearchTopBar extends Component {
	state = {
		dropdownWidth: 925,
		dropdownLeft: 25
	};

	render() {
		if (!this.props.isSV) {
			return this.renderLV();
		} else {
			return this.renderSV();
		}
	}

	renderSV = () => {
		const { className, onSearch, translate, searchErrType, setupFlow } = this.props;
		const classProps = classnames('search-top-bar', className);

		let errorMessage;
		if (searchErrType) {
			errorMessage = this.props.translate(searchErrType);
			if (errorMessage.indexOf('Missing') > -1) {
				errorMessage = searchErrType;
			}
		}

		return (
			<div className={classProps}>
				<div className="inner-wrapper" ref={el => (this.innerWrapper = el)}>
					<BusinessSearchForm
						onSubmit={onSearch}
						submitButtonText={translate('common.searchCptl')}
						whiteBackground
						setupFlow={setupFlow}
					/>
					{searchErrType && <div className="search-error-message">{errorMessage}</div>}
					<SearchPageQuickFilters />
					{this.props.children && <div className="bottom-bar">{this.props.children}</div>}
				</div>
			</div>
		);
	};

	renderLV = () => {
		const { className, isSV, onSearch, translate, searchErrType, setupFlow } = this.props;
		const classProps = classnames('search-top-bar', className);

		let errorMessage;
		if (searchErrType) {
			errorMessage = this.props.translate(searchErrType);
			if (errorMessage.indexOf('Missing') > -1) {
				errorMessage = searchErrType;
			}
		}

		return (
			<div className={classProps}>
				<div className="inner-wrapper" ref={el => (this.innerWrapper = el)}>
					<div className={classnames(!isSV && 'flex align-center')}>
						<Text className="mb-0 mr-4 no-wrap bold">{translate('Home.businessSearch.formLabel')}</Text>
						<BusinessSearchForm
							onSubmit={onSearch}
							submitButtonText={translate('common.searchCptl')}
							whiteBackground
							setupFlow={setupFlow}
						/>
					</div>
					{searchErrType && <div className="search-error-message">{errorMessage}</div>}
					{this.props.children && <div className="bottom-bar">{this.props.children}</div>}
				</div>
			</div>
		);
	};
}

SearchTopBar.defaultProps = {
	className: ''
};

SearchTopBar.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,

	translate: PropTypes.func,
	isSV: PropTypes.bool,
	searchErrType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	onSearch: PropTypes.func.isRequired,
	setupFlow: PropTypes.bool
};

export default SearchTopBar;
