import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import isNodeEnv from 'utils/isNodeEnv';
import classNames from 'classnames';

const HeaderLogo = ({ config, isRegisterPage, translate }) => {
	const wrapperCls = classNames('Di_LogoBoxLeft'),
		imgSrc = `${config.cdn_basePath}/icons/icon-dinova-logo.svg`;

	let logoLink = isRegisterPage ? '/' : `${config.header_logo_link}`;

	if (window.location.href.indexOf('localhost') > -1 && isNodeEnv('development') && !window.Cypress) {
		logoLink = '/';
	}

	const onClick = e => {
		e.preventDefault();
		window.location = logoLink;
	};

	const useDinovaLink = logoLink !== '/';

	return (
		<div className={wrapperCls}>
			{useDinovaLink ? (
				<a className="logo-link" href={logoLink} onClick={onClick}>
					<img src={imgSrc} alt={translate('header.dinova-alt')} />
				</a>
			) : (
				<Link className="logo-link" to={logoLink}>
					<img src={imgSrc} alt={translate('header.dinova-alt')} />
				</Link>
			)}
		</div>
	);
};

HeaderLogo.defaultProps = {};

HeaderLogo.propTypes = {
	// Required
	config: PropTypes.object.isRequired,
	translate: PropTypes.func.isRequired,

	// Optional
	isRegisterPage: PropTypes.bool
};

export default HeaderLogo;
