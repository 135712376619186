import SearchPageTopBar from './ui-component';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
	filters: state.businessSearchFilter.userFilters,
	results: state.businessSearchFilter.filteredResults,
	searchErrType: state.searchPage.searchErrType,
	selectedFiltersCount: state.businessSearchFilter.selectedFiltersCount,
	showList: state.searchPage.showList
});

export default connect(mapStateToProps)(SearchPageTopBar);
