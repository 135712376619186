import RestaurantCuisines from './ui-component';
import { connect } from 'react-redux';
import { hasProp } from 'utils/object';
import withSizes from 'react-sizes';
import { isLV } from 'utils/sizes';

const compileCuisines = restaurant => {
	let cuisines = [];

	hasProp(restaurant, 'cuisine.name') && cuisines.push(restaurant.cuisine.name);

	if (hasProp(restaurant, 'menuSpecialties')) {
		restaurant.menuSpecialties.forEach(item => {
			cuisines.push(item.name);
		});
	}

	return cuisines.join(', ');
};

const mapStateToProps = state => ({
	cuisineAndMS: compileCuisines(state.details.restaurant)
});

const mapSizesToProps = sizes => ({
	isLV: isLV(sizes)
});

export default connect(mapStateToProps)(withSizes(mapSizesToProps)(RestaurantCuisines));
