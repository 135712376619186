import RestaurantRecommendations from './ui-component';
import { connect } from 'react-redux';
import { hasProp } from 'utils/object';
import { fetchRecommendationsOptions } from 'actions/recommendations_modal';
import withSizes from 'react-sizes';
import { isLV } from 'utils/sizes';

const mapStateToProps = state => {
	const recommendations = hasProp(state, 'details.recommendations') ? state.details.recommendations : false,
		restaurantId = hasProp(state, 'details.restaurant.id') ? state.details.restaurant.id : false;

	let recommendationOptions = hasProp(state, 'recommendationsModal.positive')
		? state.recommendationsModal.positive
		: {};

	return {
		recommendationOptions,
		recommendations,
		restaurantId
	};
};

const mapDispatchToProps = dispatch => ({
	fetchRecommendationsOptions: async () => await fetchRecommendationsOptions(dispatch)
});

const mapSizesToProps = sizes => ({
	isLV: isLV(sizes)
});

export default connect(mapStateToProps, mapDispatchToProps)(withSizes(mapSizesToProps)(RestaurantRecommendations));
