import { history } from 'utils/router';
import { geocodeByAddress } from 'react-places-autocomplete';
import { STORE } from 'components/environment/App/redux_store.js';

/**
 * IZoneActionLinkConfig
 * @interface IZoneActionLinkConfig
 * @property {string} title   The display name for the link.
 * @property {string} address The string address for this zone link. Ex: 'Atlanta, GA'
 * @property {funcion} action The click handler for this ZoneActionLink.
 */

/**
 * Util that takes a string address, transforms said address into a google place object, sets that google place as the catering search address, redirects to catering page to perform search.
 * @param {IZoneActionLinkConfig} addressLink A zone link config. {@link IZoneActionLinkConfig}
 */
const setCateringParamsThenSearchCatering = async addressLink => {
	// STORE.dispatch(setBusinessSearch(addressLink.address));
	let state = STORE.getState();
	const cateringSearchForm = state.form.cateringSearch.values;
	const address = await geocodeByAddress(addressLink.address);
	if (window.zupplerBridge) {
		await window.zupplerBridge.resetFilters();
		await window.zupplerBridge.setAddressFromGoogle(address[0]);
		await window.zupplerBridge.setServiceType(cateringSearchForm.type.value.toUpperCase(), 'Catering');
		await window.zupplerBridge.setOrderDate(cateringSearchForm.date);
	}

	STORE.dispatch({ type: 'SET_ADDRESS', payload: address[0] });
	STORE.dispatch({ type: 'SET_SHOW_NO_SERVICE', payload: false });
	if (window.zupplerBridge) {
		window.zupplerBridge.setPage('/_');
	}
	history.push('/catering/#/');
};

/**
 * Utility for setting user token on ZupplerBridte - duplicate of Catering Seaarch Tab
 * @param  {string} token 'dinova accessToken'.
 * @return {Promise}
 */
export const loginWithProviderAndToken = token => {
	if (window.zupplerBridge) {
		return window.zupplerBridge.loginWithProviderAndToken('dinova', token);
	} else {
		return;
	}
};

/**
 * Create a CTA button for dinova catering zone location links.
 * @param  {object}                link A cateing zone object returned from Dinova API.
 * @return {IZoneActionLinkConfig} Returns an IZoneActionLinkConfig item object config. {@link IZoneActionLinkConfig}
 */
export const createLinkConfig = link => {
	return {
		title: link.name,
		address: link.location.address,
		action: setCateringParamsThenSearchCatering
	};
};

/**
 * Loop over state.catering.zones and create CTANav links array.
 * @param  {object} state Redux state
 * @return {array}  Returns an array of IZoneActionLinkConfig item object configs. {@link IZoneActionLinkConfig}
 */
export const generateLinksFromState = state => {
	let links = [];

	if (state.catering.zones) {
		links = state.catering.zones
			.filter(link => link.name && link.name.length > 0)
			.map(link => createLinkConfig(link));
	}
	return {
		links
	};
};
