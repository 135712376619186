import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Promise from 'bluebird';
import { Link } from 'react-router-dom';
import metaTagConfig from './meta-tags';
import { MetaTags } from 'components/molecules';
import { includes, isEmpty } from 'lodash';
import ScriptCache from 'utils/scriptCache';
import { LazySectionHeader } from 'components/ecosystems';
import { DashboardConnectMessage } from 'components/molecules';
import { history } from 'utils/router';
import classnames from 'classnames';
import isNodeEnv from 'utils/isNodeEnv';

class Catering extends React.Component {
	scriptCache = undefined;
	serviceType = undefined;
	address = undefined;
	partySize = undefined;
	date = undefined;
	token = undefined;
	constructor(props) {
		super(props);

		this.state = {
			section: undefined,
			isOnCateringPage: false,
			isOnCateringDetailsPage: false,
			showConnection: false,
			showPoints: false,
			showBack: false,
			currentHash: '#/'
		};

		this.scriptCache = new ScriptCache();
	}

	initZupplerBridge = api => {
		api.addIntegrationStateListener(this.onIntegrationChanged);

		window.zupplerBridge = api;

		if (this.token) {
			return api.loginWithProviderAndToken('dinova', this.token); //.catch(this.trackFailedLogins);
		} else {
			return api.logout();
		}
	};

	componentDidMount = async () => {
		window.addEventListener('hashchange', this.setUpdatedHash, true);
		// if no geolocation and not loading and no catering.address
		// redirect to search tabs
		let isCateringPage = `${window.location.pathname}${window.location.hash}` === '/catering/#/';

		if (
			isCateringPage &&
			!this.props.catering.address.formatted_address &&
			!this.props.hasGeoLocation &&
			!this.props.geoLocationLoading
		) {
			window.location.replace(`${window.location.origin}?tab=catering`);
		}

		window.zupplerBridge = null;
		this.serviceType = this.props.catering.serviceType;
		this.address = isEmpty(this.props.catering.address) ? null : this.props.catering.address;
		this.partySize = isEmpty(this.props.catering.partySize) ? null : this.props.catering.partySize;
		this.date = new Date(this.props.catering.date);
		this.token = this.props.users.authenticated;
		window.initZupplerBridge = this.initZupplerBridge;
		this.setIsOnCateringPage();
		this.setIsOnCateringDetailsPage();
		this.initIsOnCateringPageListener();

		setTimeout(() => {
			this.scriptCache.load([
				`${this.props.config['api_basePath_zuppler']}/common.js`,
				`${this.props.config['api_basePath_zuppler']}/portal_dinova.js`
			]);
		}, 500);
	};

	componentDidUpdate(oldProps) {
		const newProps = this.props;

		if (oldProps.users.authenticated !== newProps.users.authenticated) {
			if (window.zupplerBridge) {
				if (newProps.users.authenticated === '') {
					return window.zupplerBridge.logout();
				} else {
					this.token = newProps.users.authenticated;
					return window.zupplerBridge.loginWithProviderAndToken('dinova', newProps.users.authenticated);
				}
			}
		}
		if (oldProps.users.user !== newProps.users.user) {
			if (this.props.users.user && this.props.users.user.connections.length === 0) {
				this.setState({
					showConnection: true
				});
			}
		}
	}

	setUpdatedHash = () => {
		// // console.log('hashchange1', window.location.hash);
		if (!window.location.hash.includes('dashboard')) {
			this.setState({
				currentHash: window.location.hash
			});
		}
	};

	setIsOnCateringPage = () => {
		if (window.location.pathname.indexOf('/catering/') > -1) {
			this.setState({ isOnCateringPage: true });
		} else if (this.state.isOnCateringPage) {
			this.setState({ isOnCateringPage: false });
		}
	};

	setIsOnCateringDetailsPage = () => {
		if (window.location.href.indexOf('/catering/#/restaurants') > -1) {
			this.setState({ isOnCateringDetailsPage: true });
		} else if (this.state.isOnCateringDetailsPage) {
			this.setState({ isOnCateringDetailsPage: false });
		}
	};

	isOnCateringDetailsPage = () => window.location.href.indexOf('/catering/#/restaurants') > -1;

	initIsOnCateringPageListener = () => {
		var me = this;
		history.listen(() => {
			me.setIsOnCateringPage();
		});
	};

	handleFindACaterer = e => {
		e.preventDefault();

		if (this.state.currentHash.includes('receipt')) {
			window.zupplerBridge && window.zupplerBridge.setPage('/_');
			this.setState({ showBack: false });
		} else {
			window.location.hash = this.state.currentHash;
		}
	};

	zupplerAuth = () => {
		console.log('Attempting auth', window, window.zupplerBridge);
		if (window.zupplerBridge) {
			if (this.token) {
				return window.zupplerBridge.loginWithProviderAndToken('dinova', this.token);
			} else {
				return window.zupplerBridge.logout();
			}
		}
	};

	zupplerLogin = token => {
		if (window.zupplerBridge) {
			return window.zupplerBridge.loginWithProviderAndToken('dinova', token);
		}
	};

	zupplerLogout = () => {
		if (window.zupplerBridge) {
			return window.zupplerBridge.logout();
		}
	};

	zupplerSetPage = page => {
		if (window.zupplerBridge) {
			return window.zupplerBridge.setPage(page);
		}
	};

	zupplerAddIntegrationStateListner = () => {
		if (window.zupplerBridge) {
			return window.zupplerBridge.addIntegrationStateListener(this.onIntegrationChanged);
		}
	};

	onIntegrationChanged = (type, params) => {
		console.log('type', type);
		this.setState({ section: type });
		this.props.setCateringIntegrationType(type);

		if (type === 'user:forgotpassword') {
			history.push('/forgotten');
		}
		if (type === 'portal') {
			console.log('DDBG: In portal page', type, params.queries);
		}
		if (type === 'order') {
			console.log('DDBG: In online ordering page', type, params.remoteId, params.restaurant.name);

			this.props.loadRestaurant({ id: params.remoteId });
		}
		if (type === 'user:login') {
			const me = this;
			console.log('DDBG: Login is requested', type, params.returnUrl, params.locale, params.loggedIn);
			let user = {
				userName: params.username,
				password: params.password,
				testing: true,
				campaign: {},
				company: {}
			};
			return new Promise(function(resolve) {
				me.props.login(user, response => {
					if (me.props.users.authenticated) {
						response.token = me.props.users.authenticated;
					}
					console.log('logged in response', response);
					resolve(response);
				});
			});
		}
		if (type === 'user:register') {
			console.log('DDBG: register is requested', type, params.returnUrl, params.locale, params.loggedIn);
			let url = '/users/register?redirect=';
			let redirectURL = params.returnUrl;
			redirectURL = redirectURL.replace(window.location.origin, '');

			history.push(`${url}${redirectURL}`);
		}
		if (type === 'logout') {
			console.log('DDBG: Logout is requested', type, params.returnUrl);
			history.push({
				pathname: '/logout'
			});
		}
		if (type === 'user-profile') {
			console.log('DDBG: profile is requested', type, params);
			let user = {
				phone: { mobile: params.phone },
				name: {
					first: params.name.split(' ')[0],
					last: params.name
						.split(' ')
						.slice(1, params.name.split(' ').count)
						.join(' ')
				}
			};

			this.props.update(user, response => {
				if (response.code === 200) {
					console.log('user details updated.');
				}
			});
		}
		if (type === 'order-receipt') {
			console.log('DDBG: order-receipt is requested', type, params);
			const self = this;
			this.setState({ showBack: true });
			this.props.getProfile(() => {
				if (self.props.users.user && self.props.users.user.connections.length === 0) {
					self.setState({ showConnection: true });
				}
			});
		}
		if (type === 'order-receipt-earnings') {
			console.log('DDBG: order-receipt-params is requested', type, params);
		}
	};

	trackFailedLogins = () => {
		console.error('Failed to login!');
	};

	resetBack = e => {
		e.preventDefault();
		window.zupplerBridge && window.zupplerBridge.setPage('/_');
		this.setState({ showBack: false });
	};

	render() {
		const { config, hidden, promotion } = this.props;

		const classProps =
			document.location.pathname.split('/')[1] !== 'restaurants' ? classNames('details-page', 'container') : null;

		// NOTE: you may not need this.props.catering.display anymore.
		const { isOnCateringPage, isOnCateringDetailsPage } = this.state;
		const className = classnames(
			hidden ? 'hidden' : !isOnCateringPage ? 'hidden' : '',
			// !geoLocationEnabled && 'hidden',
			isOnCateringDetailsPage && 'details-pad'
		);

		const showZupplerLoading = window.location.pathname.indexOf('catering') !== -1,
			doUseZupplerBack =
				this.props.isCateringSearchFlow || window.location.href.indexOf('catering/#/restaurants') !== -1;

		const isProd = isNodeEnv('production') || isNodeEnv('prod');
		const zupplerMenuProps = isProd ? {} : { 'data-totals-mode': 'combined' };

		return (
			<div id="catering" className={className}>
				{window.location.pathname.indexOf('catering') > -1 && <MetaTags tags={metaTagConfig} />}

				<div className={classProps}>
					{(this.state.section === 'portal' ||
						this.state.section === 'dashboard' ||
						window.location.hash.includes('dashboard')) && (
						<LazySectionHeader title="DINOVA CATERING" useZupplerBack={doUseZupplerBack} />
					)}

					<div
						className={
							this.state.section === 'order' || document.location.hash.includes('#/restaurants/')
								? 'secondary-navigation hidden'
								: 'secondary-navigation'
						}
					>
						<a
							href="#"
							onClick={this.handleFindACaterer}
							className={
								this.state.section !== 'dashboard' && !window.location.hash.includes('dashboard')
									? 'active'
									: 'inactive'
							}
						>
							Find a Caterer
						</a>
						<div id="zuppler-navigation-dashboard" className="zuppler-dinova-link" />
					</div>

					{!this.isOnCateringDetailsPage() && promotion.isRunning && promotion.banner && (
						<img src={`${config.imageBaseUrl}/${promotion.banner}`} style={{ width: '100%' }} />
					)}

					<div
						{...zupplerMenuProps}
						id="zuppler-menu"
						style={{ margin: 0 + 'px auto' }}
						data-channel-url={this.props.config['zuppler_dataChannelPath']}
						data-api5-carts-svc={this.props.config['zuppler_dataChannelCartsService']}
						data-api5-restaurants-svc={this.props.config['zuppler_dateChannelRestaurantsService']}
						data-dashboard="standard"
						data-locale="en"
						data-portal-services="PICKUP, DELIVERY"
						data-portal-slices="CustomFilters[12]|Queries(Cuisine,DP,AveragePrice)|MapRestaurants[12](View:Map-List,Grouped,MapUnstyled)"
						data-portal-restore-last-location="no"
						data-branding="multi"
						data-cart="simple"
						data-menu-frame={isProd ? 'cart' : 'cartfull'}
						data-prices="range"
						data-item="squares"
						data-colors-background="#ffffff"
						data-colors-highcontrast="#000000"
						data-colors-midcontrast="#4f4f4f"
						data-colors-lowcontrast="#999999"
						data-colors-brand="#128815"
						data-colors-herobackground="#128815"
						data-colors-herocontrast="#FFFFFF"
						data-fonts-heading="Open Sans Condensed, helvetica, sans-serif"
						data-fonts-body="Open Sans, helvetica, sans-serif"
						data-fonts-deco="Montserrat, helvetica, sans-serif"
					>
						<div id="zloading">{showZupplerLoading ? 'Please wait. Loading menu...' : ''}</div>
						<style
							dangerouslySetInnerHTML={{
								__html: `
                                #zloading {
                                    position: fixed;
                                    top: 0;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    z-index: 999999;
                                    background: rgba(255, 255, 255, 0.8);
                                    color: #000000;
                                    font-size: 21px;
                                    font-weight: 700;
                                    text-align: center;
                                    padding-top: 200px;
                                    padding-top: 30vh;
                                }`
							}}
						/>
					</div>
					<div id="zupplerContainer" className="container" />
				</div>

				<div className="messaging">
					{includes(document.location.hash, 'receipt') && this.state.showConnection && (
						<DashboardConnectMessage translate={this.props.translate} />
					)}
					{this.state.showBack && (
						<div>
							<div className="back">
								{/* <Link to="/catering/#/">Return to search results</Link> */}
								<Link to="/catering/#/" onClick={this.resetBack}>
									Return to search results
								</Link>
							</div>
							<div className="thanks">Thank you for using Dinova Catering</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}

Catering.propTypes = {
	catering: PropTypes.object.isRequired,
	config: PropTypes.object.isRequired,
	hasGeoLocation: PropTypes.bool.isRequired,
	hidden: PropTypes.bool,
	geoLocationLoading: PropTypes.bool.isRequired,
	getProfile: PropTypes.func.isRequired,
	isCateringSearchFlow: PropTypes.bool.isRequired,
	loadRestaurant: PropTypes.func.isRequired,
	promotion: PropTypes.shape({
		isRunning: PropTypes.bool,
		banner: PropTypes.string
	}),
	translate: PropTypes.func.isRequired,
	update: PropTypes.func.isRequired,
	users: PropTypes.object.isRequired,
	///
	setCateringIntegrationType: PropTypes.func.isRequired
};

export default Catering;
