import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Image element with ref set to this.img.  Ref can be used in an HOC to manipulate image element.
 * @extends Component
 * @param {ImageProps} props
 */
class Image extends Component {
	render() {
		const { alt, className, dataCy, onLoad, src } = this.props;
		return (
			<img
				alt={alt}
				src={src}
				className={className}
				onLoad={onLoad}
				ref={c => (this.img = c)}
				data-cy={`image-${dataCy}`}
			/>
		);
	}
}

Image.defaultProps = {
	className: '',
	onLoad: () => {},
	src: ''
};

/**
 * Image component props shape.
 * @interface Props_Image
 * @property {string} [className=null] Additional className to add to the image element.
 * @property {string} [dataCy=null]    A unique id for cypress tests.
 * @property {string} [src=""]       The image src.
 * @property {func}   [onLoad=() => {}]    On image load handler.
 */
Image.propTypes = {
	alt: PropTypes.string,
	className: PropTypes.string,
	dataCy: PropTypes.string,
	onLoad: PropTypes.func,
	src: PropTypes.string
};

export default Image;
