import React from 'react';
import { Col, Button, Row } from 'reactstrap';
import metaTagConfig from './meta-tags';
import { MetaTags } from 'components/molecules';
import { Text, Title } from 'components/atoms';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ReferSharing } from 'components/organisms';
import { hasProp } from 'utils/object';
import { history } from 'utils/router';

export class ReferaCoworker extends React.Component {
	state = {
		copied: false,
		isLoadingReferralLink: true,
		message: this.props.translate('ReferACoWorker.copybutton_copy'),
		hasError: false
	};

	componentDidMount = async () => {
		if (this.state.isLoadingLink === false) {
			this.setState({ isLoadingReferralLink: true });
		}
		const res = await this.props.getProfile();

		if (hasProp(res, 'urls.referral')) {
			this.props.setReferralLink(res.urls.referral);
			this.setState({ isLoadingReferralLink: false });
		} else {
			this.setState({ isLoadingReferralLink: false, hasError: true });
			if (this.props.skipStep) {
				this.props.skipStep();
			}
		}
	};

	componentDidUpdate = () => {
		const { userLoading, hasConnections } = this.props;
		/*
			If the user doesn't have a connected card, they should
			not be able to access this page.
		 */
		if (!userLoading && !hasConnections) {
			history.replace('/mydinova/#/');
		}
	};

	render() {
		const { className, translate, link, showCompleteButton, parent } = this.props;
		const { hasError } = this.state;
		const classProps = classNames('refer-a-coworker', className);
		const subTitle = translate('ReferACoWorker.auth-sub-title');

		return (
			<div className={classProps}>
				<MetaTags tags={metaTagConfig} />

				<Row>
					<Col>
						{subTitle.indexOf('Missing') !== -1 && (
							<Text size="h2" className="refer-body-content" dataCy="refer-sub-titles" align="center">
								{subTitle}
							</Text>
						)}
						<Text size="sm" weight="thin" className="refer-body-content" dataCy="refer-body-content">
							{translate('ReferACoWorker.body')}
						</Text>
					</Col>
				</Row>
				{!hasError ? (
					<ReferSharing link={link} parent={parent} isLoadingLink={this.state.isLoadingReferralLink} />
				) : (
					<Row className="refer-body-error">
						<Col>
							<Title align="center" weight="thin" dataCy="refer-error" className="refer-body-content">
								{translate('ReferACoWorker.error-title')}
							</Title>
							<Text align="center" weight="thin" dataCy="refer-error" className="refer-body-content">
								{translate('ReferACoWorker.error-body')}
							</Text>
						</Col>
					</Row>
				)}

				{showCompleteButton && (
					<Row className="buttons">
						<Col md="12" xs="12" className="d-flex align-items-center justify-content-center">
							<Button color="primary" className="z-depth-0" onClick={this.props.onClick}>
								{this.props.translate('ReferACoWorker.completebutton')}
							</Button>
						</Col>
					</Row>
				)}
			</div>
		);
	}
}

ReferaCoworker.propTypes = {
	className: PropTypes.string,
	hasConnections: PropTypes.bool,
	userLoading: PropTypes.bool,
	translate: PropTypes.func,
	onClick: PropTypes.func,
	link: PropTypes.string,
	parent: PropTypes.string.isRequired,
	showCompleteButton: PropTypes.bool,
	getProfile: PropTypes.func,
	setReferralLink: PropTypes.func,
	skipStep: PropTypes.func.isRequired
};

export default ReferaCoworker;
