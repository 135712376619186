import Catering from './ui-component';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { connect } from 'react-redux';
import { loadRestaurant } from 'actions/details';
import { update, login, getProfile } from 'actions/users';
import { setCateringIntegrationType } from 'actions/catering';

const mapStateToProps = state => ({
	hasGeoLocation: state.geoLocation.hasGeoLocation,
	geoLocationLoading: state.geoLocation.isLoading,
	translate: getTranslate(state.locale),
	currentLanguage: getActiveLanguage(state.locale).code,
	catering: state.catering,
	users: state.users,
	details: state.details,
	config: state.config,
	promotion: {
		isRunning: state.promotions.isRunning,
		banner: state.promotions.banner
	},
	isCateringSearchFlow: state.landingCards.activeCard === '1'
});

const mapDispatchToProps = dispatch => ({
	loadRestaurant: data => dispatch(loadRestaurant(data)),
	update: (user, callback) => dispatch(update(user, callback)),
	login: (user, callback) => dispatch(login(user, callback)),
	getProfile: () => dispatch(getProfile()),
	setCateringIntegrationType: type => dispatch(setCateringIntegrationType(type))
});

export default connect(mapStateToProps, mapDispatchToProps)(Catering);
