import RegisteredConnections from './ui-component';
import { connect } from 'react-redux';
import { hasProp } from 'utils/object';
import { sortUserConnections } from 'utils/user';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => {
	return {
		imageBaseUrl: state.config.imageBaseUrl,
		translate: getTranslate(state.locale),
		userConnections: hasProp(state, 'users.user.connections') ? sortUserConnections(state.users.user) : [],
		supportedConnectionCardTypes: hasProp(state.general, 'company.supportedConnections')
			? state.general.company.supportedConnections.map(c => c.cardType)
			: []
	};
};

export default connect(mapStateToProps /*, mapDispatchToProps*/)(RegisteredConnections);
