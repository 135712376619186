import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import { ReduxFormInput } from 'components/organisms';
import { Field } from 'redux-form';
import { hasProp } from 'utils/object';
import Cookies from 'universal-cookie';
import validateMFAForm from './validate';
import { getPhoneOrFax } from 'utils/regex';
import FirstPage from './FirstPage';
import PhoneNumberPage from './PhoneNumberPage';
import CodePage from './CodePage';

class MFAModalPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			didClick: false,
			phoneField: null,
			codeField: null,
			hasError: false,
			phoneNumber: this.props.phoneNumber ? this.props.phoneNumber : null,
			visibleScreen: props.section
		};

		this.isUnmounting = false;
		this.cookies = new Cookies();
	}

	componentDidUpdate() {
		if (hasProp(this.props.formVals, 'dontAsk') && this.props.formVals.dontAsk) {
			this.cookies.set('dontAsk', true, { maxAge: 2147483647 });
		}
		if (hasProp(this.props.formVals, 'phoneNumberField') && this.props.formVals.phoneNumberField) {
			console.log(this.props.formVals);
		}
	}

	componentDidMount = () => {
		this.initPhoneField();
		this.initCodeField();
		if (this.props.closeOnOutsideClick) {
			document.addEventListener('click', this.handleOutsideClick);
		}
	};

	componentWillUnmount = () => {
		if (this.props.closeOnOutsideClick) {
			document.removeEventListener('click', this.handleOutsideClick);
		}
	};

	handleOutsideClick = e => {
		let wrapper = document.getElementById(this.props.modal.id);
		if (wrapper) {
			let isInside = wrapper.contains(e.target);
			if (!isInside && this.props.isOpen) {
				if (!this.state.didClick) {
					this.setState({ didClick: true });
				} else {
					this.onToggle();
				}
			}
		}
	};

	handleBack = e => {
		e.preventDefault();
		if (this.state.phoneNumber && this.state.phoneNumber.includes('*')) {
			this.setState({
				phoneNumber: null
			});
		}
		this.showScreen('phoneNumber');
	};

	onPrimaryClick = () => {
		this.props.primaryClick && this.props.primaryClick();
		this.showScreen('phoneNumber');
	};

	onSecondaryClick = () => {
		this.props.secondaryClick && this.props.secondaryClick();
		this.onToggle();
	};

	onTertiaryClick = () => {
		this.props.tertiaryClick && this.props.tertiaryClick({ code: this.props.formVals.codeField });
		this.onToggle();
	};

	onStepTwoNextClick = () => {
		this.setState({
			hasError: false
		});
		let vals =
			hasProp(this.props.formVals, 'phoneNumberField') && this.props.formVals.phoneNumberField
				? this.props.formVals.phoneNumberField
				: '';

		let errors = validateMFAForm(getPhoneOrFax(vals));

		if (hasProp(errors, 'phone')) {
			this.setState({
				hasError: true
			});
			document.getElementById('input-phoneNumberField').focus();
		} else {
			let phone = getPhoneOrFax(vals);
			this.setState({
				phoneNumber: phone
			});

			let userUpdate = {
				phone: {
					mobile: phone
				}
			};

			this.props.authorize_mfa(userUpdate, response => {
				switch (response.code) {
					case 200:
						this.props.login(this.props.user, response => {
							if (response) {
								this.showScreen('code');
							}
						});

						break;
					case 411:
						this.showScreen('code');
						break;
					default:
						console.log('Error:', response);
						break;
				}
			});
		}
	};

	onStepTwoBackClick = () => {
		this.props.onStepTwoBackClick && this.props.onStepTwoBackClick();
		this.showScreen('start');
	};

	onToggle = () => {
		const isOpen = !this.props.isOpen;
		if (!isOpen) {
			this.setState({ didClick: false });
		}
		this.props.toggleModal(isOpen);
	};

	initPhoneField = () => {
		const phoneField = (
			<Field
				name="phoneNumberField"
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								dataCy: 'phoneNumberField-field',
								name: 'input_phoneNumberField',
								id: 'input-phoneNumberField',
								error: 'Please enter a valid phone number',
								hasError: this.state.hasError,
								label: 'Mobile Number',
								example: 'ex: (555) 123-1234',
								disabled: false,
								required: true,
								option: {
									id: 'phoneNumber',
									name: 'phoneNumber'
								},
								value:
									hasProp(this.props.formVals, 'phoneNumberField') &&
									this.props.formVals.phoneNumberField
										? this.normalizeInput(this.props.formVals.phoneNumberField)
										: null
							}}
						/>
					);
				}}
			/>
		);

		this.setState({
			phoneField: phoneField
		});
	};

	initCodeField = () => {
		const codeField = (
			<Field
				name="codeField"
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								dataCy: 'codeField-field',
								name: 'input_codeField',
								id: 'input-codeField',
								label: 'Authentication Code',
								disabled: false,
								option: {
									id: 'code',
									name: 'code'
								}
							}}
						/>
					);
				}}
			/>
		);

		this.setState({
			codeField: codeField
		});
	};

	normalizeInput = value => {
		this.setState({
			hasError: false
		});
		if (!value) return value;
		const currentValue = value.replace(/[^\d]/g, '');
		const cvLength = currentValue.length;

		if (cvLength < 4) return currentValue;
		if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
		return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
	};

	showScreen(showScreen) {
		this.setState({ visibleScreen: showScreen });
	}

	screenMap = {
		start: 1,
		phoneNumber: 2,
		code: 3
	};

	render() {
		const {
			hasError,
			modal: { header, body, id, isOpen, secondaryButtonText }
		} = this.props;

		return (
			<Modal isOpen={isOpen} toggle={this.toggle} fade={true} centered id={id} className="mfa-modalpage">
				<div className="Di_MyDinovaLogo mb-5">
					<img
						src={`${this.props.config.cdn_basePath}/logos/myDinova_logo_final.svg`}
						alt="myDinova Rewards logo"
						width="192px"
					/>
				</div>

				<div className={`wrapper screen-${this.screenMap[this.state.visibleScreen]}`}>
					<div id="start" className="screen">
						<FirstPage
							body={body}
							header={header}
							secondaryButtonText={secondaryButtonText}
							onPrimaryClick={this.onPrimaryClick}
							onSecondaryClick={this.onSecondaryClick}
							isRequired={this.props.isRequired}
						/>
					</div>
					<div id="phoneNumber" className="screen">
						<PhoneNumberPage
							phoneField={this.state.phoneField}
							onStepTwoBackClick={this.onStepTwoBackClick}
							onStepTwoNextClick={this.onStepTwoNextClick}
						/>
					</div>
					<div id="code" className="screen">
						<CodePage
							phoneNumber={this.state.phoneNumber}
							codeField={this.state.codeField}
							onTertiaryClick={this.onTertiaryClick}
							handleBack={this.handleBack}
							error={hasError}
						/>
					</div>
				</div>
			</Modal>
		);
	}
}

MFAModalPage.propTypes = {
	closeOnOutsideClick: PropTypes.bool,
	isOpen: PropTypes.bool.isRequired,
	modal: PropTypes.object.isRequired,
	primaryClick: PropTypes.func,
	secondaryClick: PropTypes.func,
	tertiaryClick: PropTypes.func,
	onStepTwoBackClick: PropTypes.func,
	toggleModal: PropTypes.func.isRequired,
	config: PropTypes.object,
	formVals: PropTypes.object,
	authorize_mfa: PropTypes.func,
	login: PropTypes.func,
	user: PropTypes.object,
	section: PropTypes.string,
	phoneNumber: PropTypes.string,
	hasError: PropTypes.oneOf([false, PropTypes.oneOfType[PropTypes.string]]),
	isRequired: PropTypes.bool
};

export default MFAModalPage;
