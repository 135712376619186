import React, { useEffect } from 'react';
import { Text } from 'components/atoms';
import styles from './style.module.scss';
import StepTemplate from '../../StepTemplate';
import PropTypes from 'prop-types';
import { ProfileSetupSteps } from 'reducers/profile_setup';

const TRANSLATE_BASE_KEY = 'ProfileSetup.modals.finish';

const ProfileSetupFinishStep = ({ translate, completeOnboarding, viewedCompletion }) => {
	const points = translate('ProfileSetup.points');
	useEffect(() => {
		viewedCompletion();
	}, []);

	return (
		<StepTemplate
			title={translate(`${TRANSLATE_BASE_KEY}.title`)}
			variant={ProfileSetupSteps.FINISH}
			onSubmit={completeOnboarding}
		>
			<div className={styles.content}>
				<Text className={styles.subTitle} weight="bold">
					{translate(`${TRANSLATE_BASE_KEY}.body`, { points })}
				</Text>
			</div>
		</StepTemplate>
	);
};

ProfileSetupFinishStep.propTypes = {
	translate: PropTypes.func.isRequired,
	completeOnboarding: PropTypes.func,
	viewedCompletion: PropTypes.func
};

export default ProfileSetupFinishStep;
