import ReferACoworker from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { setReferralLink } from 'actions/registration';
import { getProfile } from 'actions/users';
import { hasProp } from 'utils/object';

const mapDispatchToProps = dispatch => ({
	setReferralLink: link => dispatch(setReferralLink(link)),
	getProfile: () => dispatch(getProfile())
});

const mapStateToProps = state => ({
	hasConnections: state?.users?.user?.hasConnections,
	link: hasProp(state, 'users.user.urls.referral') ? state.users.user.urls.referral : null,
	translate: getTranslate(state.locale),
	userLoading: !state?.users?.user
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferACoworker);
