import React from 'react';
import PropTypes from 'prop-types';
import { Container, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from 'reactstrap';
import { Button } from 'reactstrap';

class ModalPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			didClick: false
		};

		this.isUnmounting = false;
	}

	componentDidMount = () => {
		if (this.props.closeOnOutsideClick) {
			document.addEventListener('click', this.handleOutsideClick);
		}
	};

	componentWillUnmount = () => {
		if (this.props.closeOnOutsideClick) {
			document.removeEventListener('click', this.handleOutsideClick);
		}
	};

	handleOutsideClick = e => {
		let wrapper = document.getElementById(this.props.modal.id);
		if (wrapper) {
			let isInside = wrapper.contains(e.target);
			if (!isInside && this.props.isOpen) {
				if (!this.state.didClick) {
					this.setState({ didClick: true });
				} else {
					this.onToggle();
				}
			}
		}
	};

	onPrimaryClick = () => {
		this.props.primaryClick && this.props.primaryClick();
		this.onToggle();
	};

	onSecondaryClick = () => {
		this.props.secondaryClick && this.props.secondaryClick();
		this.onToggle();
	};

	onToggle = () => {
		const isOpen = !this.props.isOpen;
		if (!isOpen) {
			this.setState({ didClick: false });
		}
		this.props.toggleModal(isOpen);
	};

	render() {
		const {
			modal: { body, id, isOpen, header, primaryButtonText, secondaryButtonText, tertiaryButtonText }
		} = this.props;
		let largeColWidth = this.hasSecondButton ? '6' : '12';

		return (
			<Modal isOpen={isOpen} toggle={this.toggle} fade={true} centered id={id}>
				{header && <ModalHeader toggle={this.toggle}>{header}</ModalHeader>}
				<ModalBody>{body}</ModalBody>
				<ModalFooter>
					<Container>
						<Row>
							<Col sm="12" lg={largeColWidth}>
								<Button
									block
									className="z-depth-0"
									onClick={this.onPrimaryClick}
									color="primary"
									id="modal-primary-button"
								>
									{primaryButtonText}
								</Button>
							</Col>
							<Col sm="12" lg={largeColWidth}>
								{secondaryButtonText && (
									<Button
										block
										className="z-depth-0"
										onClick={this.onSecondaryClick}
										id="modal-secondary-button"
									>
										{secondaryButtonText}
									</Button>
								)}
							</Col>
							{tertiaryButtonText && (
								<Col sm="12" lg={largeColWidth}>
									<Button
										block
										className="z-depth-0"
										onClick={this.onTertiaryClick}
										id="modal-tertiary-button"
									>
										{tertiaryButtonText}
									</Button>
								</Col>
							)}
						</Row>
					</Container>
				</ModalFooter>
			</Modal>
		);
	}
}

ModalPage.propTypes = {
	closeOnOutsideClick: PropTypes.bool,
	isOpen: PropTypes.bool.isRequired,
	modal: PropTypes.object.isRequired,
	primaryClick: PropTypes.func,
	secondaryClick: PropTypes.func.isRequired,
	toggleModal: PropTypes.func.isRequired
};

export default ModalPage;
