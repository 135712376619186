import ToGoOptionsList from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { hasProp } from 'utils/object';

const getOptions = details => {
	let options = {};
	if (hasProp(details, 'restaurant.amenities.general')) {
		details.restaurant.amenities.general.forEach(o => {
			let ids = [6, 7, 8];
			if (ids.indexOf(o.id) > -1) {
				options[o.name] = true;
			}
		});
	}

	if (hasProp(details, 'restaurant.safetyMeasures.sanitization')) {
		let hasSealedItems = details.restaurant.safetyMeasures.sanitization.filter(s => s.id === 603)[0];
		options['Sealed Packaging'] = hasSealedItems;
	}

	return options;
};

const mapStateToProps = state => {
	const options = getOptions(state.details);

	return {
		options: [
			{
				title: 'Meal Kits',
				image: `${state.config.cdn_basePath}/icons/meal kits.svg`,
				show: options['Meal Kits']
			},
			{
				title: 'Alcohol',
				image: `${state.config.cdn_basePath}/icons/alcohol.svg`,
				show: options['Alcohol To-Go']
			},
			{
				title: 'Sealed Items',
				image: `${state.config.cdn_basePath}/icons/sealed_items.svg`,
				show: options['Sealed Packaging']
			},
			{
				title: 'Pantry Items',
				image: `${state.config.cdn_basePath}/icons/pantry_items.svg`,
				show: options['Pantry Items']
			}
		].filter(o => o.show),
		translate: getTranslate(state.locale)
	};
};

export default connect(mapStateToProps)(ToGoOptionsList);
