import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Truncate from 'react-truncate';

/**
 * Displays text description of a restaurant.  If text is more than 10 lines, text will be clipped, elippses added, "Read More" button rendered.
 * @extends Component
 * @param {DescriptionProps} props
 */
class Description extends Component {
	/**
	 * Initial state.
	 * @type {Object}
	 * @property {boolean} [isTextExpanded=false]
	 * @property {number} [visibleLines=10]
	 */
	state = {
		isTextExpanded: false,
		visibleLines: 5
	};

	maxLines = 5;

	/**
	 * "Read More" button handler. Shows all description text.
	 */
	expand = () => {
		if (this.state.isTextExpanded) {
			this.setState({ isTextExpanded: false, visibleLines: 5 });
		} else {
			this.setState({ isTextExpanded: true, visibleLines: 99999 });
		}
	};

	render() {
		const { text, translate } = this.props,
			classProps = classNames(
				'restaurant-description column',
				this.state.isTextExpanded ? 'expanded' : 'collapsed'
			),
			buttonText = this.state.isTextExpanded ? translate('Details.readLess') : translate('Details.readMore');

		// preservedText, keep line breaks
		let preservedText = text.split('\n').map((line, i, arr) => {
			line = <span key={i}>{line}</span>;

			if (i === arr.length - 1) {
				return line;
			} else {
				return [line, <br key={i + 'br'} />];
			}
		});

		return preservedText.length > 0 ? (
			<div className={classProps}>
				<Truncate
					lines={this.state.visibleLines}
					ellipsis={
						<React.Fragment>
							...
							<Button onClick={this.expand} id="read-more-button" className="more-button">
								{buttonText}
							</Button>
						</React.Fragment>
					}
				>
					{preservedText}
				</Truncate>
				{this.state.isTextExpanded && (
					<Button onClick={this.expand} id="read-more-button" className="more-button">
						{buttonText}
					</Button>
				)}
			</div>
		) : null;
	}
}

Description.defaultProps = {
	text: 'Description'
};

/**
 * {@link Description} Props
 * @interface Props_Description
 * @property {function} translate
 * @property {string} [text="Description"]
 */
Description.propTypes = {
	// Required
	translate: PropTypes.func.isRequired,

	// Optional
	text: PropTypes.string
};

export default Description;
