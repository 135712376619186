import CompleteSetupBanner from './ui-component';
import { connect } from 'react-redux';
import { toggleModalOpen } from 'actions/profile_setup';
import { selectCompletedSteps } from 'actions/profile_setup/selectors';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => {
	const {
		emailComplete,
		profileComplete,
		favoritesComplete,
		viewCompletion,
		creditCardComplete
	} = selectCompletedSteps(state);
	const showComplete = emailComplete && profileComplete && favoritesComplete && creditCardComplete;
	return {
		dismissedForever: state.profileSetup.dismissedForever,
		translate: getTranslate(state.locale),
		emailComplete,
		profileComplete,
		favoritesComplete,
		viewCompletion,
		showComplete
	};
};

const mapDispatchToProps = dispatch => ({
	toggleModalOpen: () => dispatch(toggleModalOpen())
});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteSetupBanner);
