import React from 'react';
import PropTypes from 'prop-types';

const WelcomePhrase = ({ firstName, isAuthed, points, translate }) => {
	//
	const userName = ` ${firstName || ''} • `,
		_points = (points && Number.parseInt(points).toLocaleString() + ' points') || '',
		useSimpleHeader =
			window.location.hash.indexOf('/checkout/') > -1 || window.location.hash.indexOf('/start/') > -1;

	return isAuthed && !useSimpleHeader ? (
		<span className="welcome-phrase">
			<span className="welcome">{translate('header.welcome')}</span>
			{userName}
			{_points}
		</span>
	) : null;
};

WelcomePhrase.defaultProps = {};

WelcomePhrase.propTypes = {
	firstName: PropTypes.string.isRequired,
	isAuthed: PropTypes.bool.isRequired,
	points: PropTypes.string.isRequired,
	translate: PropTypes.func.isRequired
};

export default WelcomePhrase;
