import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SearchFilter from '../SearchFilter';
import { Button } from 'reactstrap';
import { closeFilterGroups } from 'utils/windowEvents';

class BusinessSearchFilter extends Component {
	onDoneClick = () => {
		closeFilterGroups();
		this.props.toggleShowFilter(false);
	};

	render() {
		const { isSV, translate } = this.props;
		const classProps = classnames('business-search-filter-wrapper', this.props.className, !isSV && 'mt-4');
		return (
			<div className={classProps}>
				<SearchFilter
					className="business-search-filter"
					addFilter={this.props.addFilter}
					removeFilter={this.props.removeFilter}
					filters={this.props.filters}
					hasFilters={this.props.hasFilters}
				/>
				{isSV && (
					<div className="business-search-filter-footer">
						<Button onClick={this.props.clearFilters}>{translate('FilterModal.clear')}</Button>
						<Button active onClick={this.onDoneClick}>
							{translate('common.done')}
						</Button>
					</div>
				)}
			</div>
		);
	}
}

BusinessSearchFilter.defaultProps = {};

BusinessSearchFilter.propTypes = {
	addFilter: PropTypes.func.isRequired,
	className: PropTypes.string,
	clearFilters: PropTypes.func,
	filters: PropTypes.shape({
		amenities: PropTypes.object,
		cuisine: PropTypes.object,
		dietaryPreferences: PropTypes.object
	}),
	hasFilters: PropTypes.bool.isRequired,
	isSV: PropTypes.bool.isRequired,
	removeFilter: PropTypes.func.isRequired,
	toggleShowFilter: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

export default BusinessSearchFilter;
