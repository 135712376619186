import FavoriteRestaurantButton from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { addFavoriteRestaurant, removeFavoriteRestaurant } from 'actions/favorites_page';
import { updateOnboardingStatus } from 'actions/users';
import { completeStep } from 'actions/profile_setup';
import { ProfileSetupSteps } from 'reducers/profile_setup';

const mapStateToProps = state => ({
	isAuthed: Boolean(state?.users?.user),
	translate: getTranslate(state.locale),
	userHasFavorites: state?.users?.user?.onboardingStatus.favoriteRestaurant === true ? true : false
});

const mapDispatchToProps = dispatch => ({
	addFavoriteRestaurant: ({ restaurantId }) => dispatch(addFavoriteRestaurant({ restaurantId })),
	removeFavoriteRestaurant: ({ restaurantId }) => dispatch(removeFavoriteRestaurant({ restaurantId })),
	updateOnboardingStatus: () => {
		dispatch(completeStep(ProfileSetupSteps.FAVORITE));
		dispatch(updateOnboardingStatus({ onboardingStatus: { favoriteRestaurant: true } }));
	},
	completeStep: () => dispatch(completeStep(ProfileSetupSteps.FAVORITE))
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteRestaurantButton);
// export default FavoriteRestaurantButton;
