import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

class SocialShare extends React.Component {
	render() {
		const { className, link, translate } = this.props;
		const classProps = classNames('social-share', className);
		return (
			<div>
				<Row className={'mt-5 mb-5 darker'}>
					<Col className="d-flex justify-content-center">{translate('ReferACoWorker.sharecopy')}</Col>
				</Row>
				<Row className={classProps}>
					<Col />
					<Col className="d-flex justify-content-between social" xs="12" md="8">
						<div>
							<a
								href={`https://twitter.com/home?status=${translate(
									'ReferACoWorker.social.twitter.text'
								)} - ${link}`}
								id="twitter"
								rel="noopener noreferrer"
								target="_blank"
							>
								<span className="hidden">${translate('ReferACoWorker.social.twitter.alt')}</span>
								<i className="fab fa-twitter" />
							</a>
						</div>
						<div>
							<a
								href={`https://www.facebook.com/sharer/sharer.php?u=${link}&p&quote=${translate(
									'ReferACoWorker.social.facebook.text'
								)} - ${link}`}
								id="facebook"
								rel="noopener noreferrer"
								target="_blank"
							>
								<span className="hidden">${translate('ReferACoWorker.social.facebook.alt')}</span>
								<i className="fab fa-facebook-f" />
							</a>
						</div>
						<div>
							<a
								href={`mailto:?&subject=${translate(
									'ReferACoWorker.social.mail.subject'
								)}&body=${translate('ReferACoWorker.social.mail.text')} - ${link}`}
								id="mail"
								rel="noopener noreferrer"
								target="_blank"
							>
								<span className="hidden">${translate('ReferACoWorker.social.mail.alt')}</span>
								<i className="far fa-envelope" />
							</a>
						</div>
					</Col>
					<Col />
				</Row>
			</div>
		);
	}
}

SocialShare.propTypes = {
	className: PropTypes.string,
	link: PropTypes.string,
	translate: PropTypes.func
};

export default SocialShare;
