import React from 'react';
import PropTypes from 'prop-types';
import { OptionsList } from 'components/organisms';

/**
 * List of available dietary preferences.  Used in {@link DetailsPage}.
 * @param {DietaryPreferencesProps} props
 */
const DietaryPreferences = ({ dietaryPrefs }) => {
	return dietaryPrefs.length > 0 ? <OptionsList options={dietaryPrefs} dataCy="dietary-prefs" /> : null;
};

DietaryPreferences.defaultProps = {
	dietaryPrefs: []
};

/**
 * {@link DietaryPreferences} Props
 * @interface Props_DietaryPreferences
 * @property {string[]} [dietaryPrefs=[]]
 */
DietaryPreferences.propTypes = {
	// Optional
	dietaryPrefs: PropTypes.array
};

export default DietaryPreferences;
