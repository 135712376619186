import ModalPage from './ui-component';
import { connect } from 'react-redux';
import { toggleModal } from 'actions/modal';

const mapDispatchToProps = dispatch => ({
	toggleModal: isOpen => dispatch(toggleModal(isOpen))
});

const mapStateToProps = state => ({
	modal: state.modal
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalPage);
