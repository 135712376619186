import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FavoriteRestaurantButton } from 'components/molecules';
import styles from './styles.module.scss';

/**
 * Show the currently selected restaurant name with a dropdown that allows
 * the user to select a near by location of the same restaurant.
 *
 * Currently selected restaurant corresponds to redux state - state.details.
 * @param {LocationsBarProps} props
 */

const LocationsBar = ({ enableFavoriting, isLV, restaurant, title }) => {
	return (
		<div className={classnames('locations-bar', !isLV && 'col', styles.locationsBar)}>
			<h1 data-cy="locations-bar-title">{title}</h1>
			{enableFavoriting && (
				<div className={styles.favoriteIconWrapper}>
					<FavoriteRestaurantButton restaurant={restaurant} />
				</div>
			)}
		</div>
	);
};

LocationsBar.defaultProps = {
	title: 'Restaurant Name'
};

/**
 * {@link LocationsBar} Props
 * @interface Props_LocationsBar
 * @property {string} title The text to be shown as the name of the selected resturant.
 */
LocationsBar.propTypes = {
	enableFavoriting: PropTypes.bool,
	isLV: PropTypes.bool.isRequired,
	restaurant: PropTypes.any,
	title: PropTypes.string
};

export default LocationsBar;
