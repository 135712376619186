import * as t from './types';
import axios from 'axios';
import { basePath, AUTH_REQUEST_HEADER } from 'actions/utils';

export const fetchFavorites = () => async dispatch => {
	dispatch({
		type: t.FETCH_FAVORITES_START
	});

	var URL = `${basePath()}/users/restaurants/favorite`;

	var results = await axios.get(URL, AUTH_REQUEST_HEADER());

	if (results.data.response.code === 200) {
		dispatch({
			type: t.FETCH_FAVORITES_SUCCESS,
			payload: results.data.restaurants
		});

		return results.data.restaurants;
	} else {
		dispatch({
			type: t.FETCH_FAVORITES_ERROR,
			payload: { error: results.data.response.code, message: results.data.response.message }
		});
	}
};

export const addFavoriteRestaurant = ({ restaurantId }) => async dispatch => {
	console.log('%c addFavoriteRestaurant restaurantId', 'color: red', restaurantId);
	dispatch({
		type: t.ADD_FAVORITES_START
	});
	const URL = `${basePath()}/users/restaurants/favorite`;
	const results = await axios.post(URL, JSON.stringify({ restaurantId }), AUTH_REQUEST_HEADER());
	console.log('%c addFavoriteRestaurant results', 'color: red', results);
};

export const removeFavoriteRestaurant = ({ restaurantId }) => async dispatch => {
	console.log('%c removeFavoriteRestaurant restaurantId', 'color: red', restaurantId);
	dispatch({
		type: t.REMOVE_FAVORITES_START
	});

	const results = await axios.delete(
		`${basePath()}/users/restaurants/favorite/${restaurantId}`,
		AUTH_REQUEST_HEADER()
	);
	console.log('%c removeFavoriteRestaurant results', 'color: red', results);

	if (results.data.response.code === 200) {
		dispatch({
			type: t.REMOVE_FAVORITES_SUCCESS,
			payload: restaurantId
		});
	}
};
