import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Title } from 'components/atoms';
import { PaymentNotConnected } from 'components/molecules';
import { ProfileSectionList, ReferSharing } from 'components/organisms';
import { startCase } from 'lodash';

const ViewProfileSection = ({ className, section, list, translate }) => {
	const classProps = classNames(`view-profile-section view-profile-${section} section`, className),
		isPayment = section === 'payment',
		isReferral = section === 'referral',
		isRecommendations = section === 'recommendations',
		showEditBtn = !(isPayment && !list.length);

	return (
		<div className={classProps}>
			<header className="list-header">
				<Title size="h2" align="left">
					{translate(`Profile.${section}.title`)}
				</Title>

				{showEditBtn && !isReferral && (
					<Link className="goto-edit" to={isRecommendations ? `/${section}/#/` : `/profile/${section}/#/`}>
						{startCase(translate(isRecommendations ? 'common.view' : 'common.editBtn'))}
					</Link>
				)}
				{isReferral && (
					<Link className="goto-edit" to={`/refer/details/#/`}>
						{translate('common.learnMoreBtn')}
					</Link>
				)}
			</header>
			{isPayment && !list.length ? (
				<PaymentNotConnected />
			) : isReferral ? (
				<ReferSharing link={list[0].value} parent={'profile'} />
			) : (
				<ProfileSectionList section={section} list={list} />
			)}
		</div>
	);
};

ViewProfileSection.defaultProps = {
	className: ''
};

ViewProfileSection.propTypes = {
	className: PropTypes.string,
	list: PropTypes.array.isRequired,
	section: PropTypes.oneOf(['personal', 'payment', 'preferences', 'security', 'referral', 'recommendations'])
		.isRequired,
	translate: PropTypes.func.isRequired
};

export default ViewProfileSection;
