// card.type options
export const HASH = 'hash';
export const EMP_ID = 'empId';
export const EMAIL = 'email';
// card.cardType options
export const AMEX = 1;
export const VISA = 2;
export const MASTERCARD = 3;

var cardValidator = require('card-validator');

export const getCardType = number => {
	let re = new RegExp('^4');
	if (number.match(re) != null) return 'visa';

	re = new RegExp('^(34|37)');
	if (number.match(re) != null) return 'amex';

	re = new RegExp('^5[1-5]');
	if (number.match(re) != null) return 'mastercard';

	re = new RegExp('^6011');
	if (number.match(re) != null) return 'discover';

	return '';
};

export const getCardId = type => {
	switch (type) {
		case 'amex':
			return 1;
		case 'visa':
			return 2;
		case 'mastercard':
			return 3;
		case 'discover':
			return 4;
		default:
			return 0;
	}
};

export const preferredCCTypeNormalized = preferredCCType => {
	switch (preferredCCType) {
		case 1:
			return 'amex';
		case 2:
			return 'visa';
		case 3:
			return 'mastercard';
		case 4:
			return 'discover';
		default:
			break;
	}
};

export const getCCInputLabel = (card, translate, cardTypeDataAccr = 'cardType') => {
	let baseTranslateKey = 'ConnectToPoints.inputs.creditcard.',
		label = translate(`${baseTranslateKey}label`);

	if (card) {
		if (!card.fullNumberRequired) {
			baseTranslateKey += 'lastFour.';
		}

		if (card[cardTypeDataAccr] === 1 || card[cardTypeDataAccr] === 'amex') {
			// amex
			label = translate(`${baseTranslateKey}amex`);
		} else if (card[cardTypeDataAccr] === 2 || card[cardTypeDataAccr] === 'visa') {
			// visa
			label = translate(`${baseTranslateKey}visa`);
		} else if (
			card[cardTypeDataAccr] === 3 ||
			card[cardTypeDataAccr] === 'mastercard' ||
			card[cardTypeDataAccr] === 'mc'
		) {
			// mastercard
			label = translate(`${baseTranslateKey}mc`);
		} else if (card[cardTypeDataAccr] === 3 || card[cardTypeDataAccr] === 'discover') {
			label = translate(`${baseTranslateKey}discover`);
		}
	}

	return label;
};

export const getCCInputLabelBasic = (translate, cardType, fullNumberRequired = true) => {
	let label = translate('ConnectToPoints.inputs.creditcard.label');

	if (!cardType) {
		return label;
	}

	if (cardType === 'amex' || cardType === 1) {
		label = fullNumberRequired
			? translate('ConnectToPoints.inputs.creditcard.amexLabel')
			: translate('ConnectToPoints.inputs.creditcard.lastFour.amex');
	} else if (cardType === 'visa' || cardType === 2) {
		label = fullNumberRequired
			? translate('ConnectToPoints.inputs.creditcard.visaLabel')
			: translate('ConnectToPoints.inputs.creditcard.lastFour.visa');
	} else if (cardType === 'mastercard' || cardType === 3) {
		label = fullNumberRequired
			? translate('ConnectToPoints.inputs.creditcard.mcLabel')
			: translate('ConnectToPoints.inputs.creditcard.lastFour.mc');
	} else if (cardType === 'discover' || cardType === 4) {
		label = fullNumberRequired
			? translate('ConnectToPoints.inputs.creditcard.discoverLabel')
			: translate('ConnectToPoints.inputs.creditcard.lastFour.discover');
	}

	return label;
};

export const getCCHelp = (translate, card) => {
	var showFullNumber = card.fullNumberRequired === undefined ? true : card.fullNumberRequired;
	console.log('getting help', showFullNumber);
	let help = translate('ConnectToPoints.inputs.creditcard.help');

	if (card && !showFullNumber) {
		help = translate('ConnectToPoints.inputs.creditcard.lastFour.help');
	}

	return help;
};

export const getInternalConnectionType = cardConnectionType => {
	switch (cardConnectionType) {
		case 2: {
			return 'empID';
		}
		case 3: {
			return 'email';
		}
		default: {
			return 'hash';
		}
	}
};

export const preferredCCTypeDisplayText = cardType => {
	if (cardType === undefined) {
		return;
	}
	switch (cardType) {
		case 1:
			return 'American Express';
		case 2:
			return 'Visa';
		case 3:
			return 'Mastercard';
		case 4:
			return 'Discover';
		default:
			break;
	}
};

const transformCardTypeToValidatorType = cardType => {
	switch (cardType) {
		case 1:
			return 'american-express';
		case 2:
			return 'visa';
		case 3:
			return 'mastercard';
		case 4:
			return 'discover';
		default:
			break;
	}
};

export const validateCard = (card, number) => {
	if (number.indexOf('X') > -1) {
		return { isValidCardType: true, isValidCardNumber: false };
	}
	const validState = cardValidator.number(number);
	const isValidCardType =
			transformCardTypeToValidatorType(card.cardType) === (validState.card ? validState.card.type : undefined),
		isValidCardNumber = validState.isValid;
	return { isValidCardType, isValidCardNumber };
};

export const getCardTypeValitationReqs = type => {
	if (type === 1) {
		return HASH;
	} else if (type === 2) {
		return EMP_ID;
	} else if (type === 3) {
		return EMAIL;
	}
};

export const getLangFileCardLabelKey = cardType => {
	let cardKey = '';
	switch (cardType) {
		case 1: {
			cardKey = 'amex';
			break;
		}
		case 2: {
			cardKey = 'visa';
			break;
		}
		case 3: {
			cardKey = 'mc';
			break;
		}
		case 4: {
			cardKey = 'discover';
			break;
		}
		default:
			break;
	}

	// if (fullNumberRequired) {
	return cardKey;
	// } else {
	// return `ConnectToPoints.inputs.creditcard.lastFour.${cardKey}`;
	// }
};
