import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ProfilePreferenceItem, ProfileSectionItem } from 'components/molecules';

const ProfileSectionList = props => {
	const { className, list, section } = props;
	const classProps = classNames('profile-section-list', `profile-${section}-list`, className);
	const isPreferences = section === 'preferences';

	return (
		<div className={classProps}>
			{list &&
				list.map(item =>
					item.key === 'securityPIN' ? (
						<div className={classProps} key={item.key}>
							<ProfileSectionItem item={item} section={section} />
						</div>
					) : isPreferences ? (
						<ProfilePreferenceItem key={item.key} item={item} section={section} />
					) : (
						<ProfileSectionItem key={item.key} item={item} section={section} />
					)
				)}
		</div>
	);
};

ProfileSectionList.defaultProps = {
	className: ''
};

ProfileSectionList.propTypes = {
	className: PropTypes.string,
	list: PropTypes.array.isRequired,
	section: PropTypes.oneOf(['personal', 'payment', 'preferences', 'security', 'recommendations']).isRequired
};

export default ProfileSectionList;
