import EditPaymentFormNew from './ui-component';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reduxForm } from 'redux-form';
import { hasProp } from 'utils/object';
import { getTranslate } from 'react-localize-redux';
import { updatePaymentInfo, getProfile } from 'actions/users';
import { setError } from 'actions/loader';
import { update } from 'actions/users';

const mapStateToProps = state => {
	const { card } = state.editPayment;
	if (!card) {
		// to handle if EditPaymentPage is refreshed - so component can load then redirect user to profile page
		return {
			card: false,
			initialValues: {},
			modal: {},
			translate: getTranslate(state.locale)
		};
	}
	let cardNumber;
	if (card.fullNumberRequired) {
		cardNumber = card.cardType === '1' ? `XXXX-XXXXXX-X${card.last4}` : `XXXX-XXXX-XXXX-${card.last4}`;
	} else {
		cardNumber = card.last4;
	}
	return {
		card,
		companyName: hasProp(state, 'users.user.company.name') ? state.users.user.company.name : '',
		modal: state.modal,
		initialValues: {
			cardNumber,
			employeeId: card.employeeId || '',
			nameOnCard: card.nameOnCard,
			firstName: hasProp(state, 'users.user.name.first') ? state.users.user.name.first : '',
			middleName: hasProp(state, 'users.user.name.middle') ? state.users.user.name.middle : '',
			lastName: hasProp(state, 'users.user.name.last') ? state.users.user.name.last : '',
			email: 'lorem@example.com'
		},
		isDoD: state.general.dodMode,
		translate: getTranslate(state.locale),
		formVals: hasProp(state, 'form.editPayment.values') ? state.form.editPayment.values : {}
	};
};

const mapDispatchToProps = dispatch => ({
	clearErrors: () => dispatch(setError()),
	updatePaymentInfo: (paymentInfo, callback) => dispatch(updatePaymentInfo(paymentInfo, callback)),
	getProfile: (forceProfileFetch = true) => dispatch(getProfile(false, forceProfileFetch)),
	updateUser: (userDetails, callback) => dispatch(update(userDetails, callback))
});

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'editPayment' })(EditPaymentFormNew))
);
