import CompanySelectModal from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { toggleShowCompanySelectModal } from 'actions/company_select_modal';
// import { setCompany, setCompanyName, setCompanyCode } from 'actions/general';
import { startChangeStep, assignCard, setDidRegisterWithIneligible } from 'actions/registration';
import { toggleShowPointsElegibilityModal } from 'actions/points_elegibility_modal';
import { update } from 'actions/users';
import { lookupCompany, selectCompany } from 'actions/company_select';
import {
	setCompany,
	setCompanyName,
	setCompanyCode,
	setPreferredCCType,
	setPreferredConnectionID,
	setPreferredEmployeeIDType
} from 'actions/general';
import { toggleShowDeleteConnectionsWarningModal } from 'actions/delete_connections_warning_modal';
import { hasProp } from 'utils/object';
import { getProfile } from 'actions/users';

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	...state.companySelectModal,
	userCompany: hasProp(state.users, 'user.company') ? state.users.user.company : null,
	hasConnections: hasProp(state.users, 'user.hasConnections') ? state.users.user.hasConnections : false,
	company:
		Object.keys(state.companySelect.selected).length > 0
			? state.companySelect.selected
			: state.companySelect.search || ''
});

const mapDispatchToProps = dispatch => ({
	closeCompanySelectModal: () => dispatch(toggleShowCompanySelectModal(false)),
	setCompany: company => {
		const { name, code } = company,
			showLoading = false;

		dispatch(setCompany(company)); // old Redux store general
		dispatch(setCompanyCode(code, showLoading)); // old Redux store general
		dispatch(setCompanyName(name)); // old Redux store general
		dispatch(selectCompany(company)); // new Redux store comapnySelect

		if (company.supportedConnections.length === 1) {
			dispatch(setPreferredCCType(company.supportedConnections[0].cardType));
			dispatch(setPreferredConnectionID(company.supportedConnections[0].id));
			dispatch(assignCard(company.supportedConnections[0]));
			switch (company.supportedConnections[0].type) {
				case 2: {
					dispatch(setPreferredEmployeeIDType('empID'));
					break;
				}
				case 3: {
					dispatch(setPreferredEmployeeIDType('email'));
					break;
				}
				default: {
					dispatch(setPreferredEmployeeIDType('hash'));
					break;
				}
			}
		}
	},
	setDidRegisterWithIneligible: () => dispatch(setDidRegisterWithIneligible()),
	showCancelModal: () => {
		dispatch(toggleShowCompanySelectModal(false));
		setTimeout(() => {
			dispatch(toggleShowCompanySelectModal(true, 'cancel'));
		}, 400);
	},
	showDeleteConnectionsWarningModal: confirmHandler =>
		dispatch(toggleShowDeleteConnectionsWarningModal(true, confirmHandler)),
	showSkipModal: () => {
		dispatch(toggleShowCompanySelectModal(false));
		setTimeout(() => {
			dispatch(toggleShowCompanySelectModal(true, 'skip'));
		}, 400);
	},
	showSelectModal: () => {
		dispatch(toggleShowCompanySelectModal(false));
		setTimeout(() => {
			dispatch(toggleShowCompanySelectModal(true, 'select'));
		}, 400);
	},
	showMerckModal: () => {
		dispatch(toggleShowCompanySelectModal(false));
		setTimeout(() => {
			dispatch(toggleShowCompanySelectModal(true, 'merck'));
		}, 400);
	},
	showUnconnectedModal: () => {
		dispatch(toggleShowCompanySelectModal(false));
		setTimeout(() => {
			dispatch(toggleShowCompanySelectModal(true, 'unconnected'));
		}, 400);
	},
	startChangeStep: step => dispatch(startChangeStep(step)),
	showPointsElegibilityModal: () => dispatch(toggleShowPointsElegibilityModal(true)),
	showConfirmCompanyChangeToIneligibleModal: () => {
		dispatch(toggleShowCompanySelectModal(false));
		setTimeout(() => {
			dispatch(toggleShowCompanySelectModal(true, 'confirm_ineligible_company_change'));
		}, 400);
	},
	// PLACEHOLDER
	lookupCompany: async name => {
		const company = await lookupCompany(dispatch, name);
		return company;
	},
	updateUser: (userDetails, callback) => dispatch(update(userDetails, callback)),
	refreshUserProfile: () => dispatch(getProfile(false))
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanySelectModal);
