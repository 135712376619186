import * as t from '../actions/general/types';
import * as user from '../actions/users/types';
import * as registration from '../actions/registration/types';
import { hasProp } from 'utils/object';

/**
 * Redux_GeneralState
 * @interface Redux_GeneralState
 * @property {Boolean} [isBusy=true]
 * @property {String}  [message=""]
 * @property {Object}  [error=undefined]
 * @property {Object}  [company=null]
 * @property {string}  [campaignID=undefined]
 * @property {string}  [companyCode=undefined]
 * @property {string}  [companyName=undefined]
 * @property {array}   [preferredCCType=[]]
 * @property {number}  [preferredEmployeeIDType=undefined]
 * @property {number}  [preferredConnectionID=null]
 */

const generalDefaultState = {
	company: null,
	campaignID: undefined,
	companyCode: undefined,
	companyName: undefined,
	dodMode: false,
	doordashMode: false,
	preferredCCType: [],
	preferredEmployeeIDType: undefined,
	preferredConnectionID: null
};

/**
 * Check to determine if company is a DoD company.
 * @param  {[string|object]}  company Can be a string or a user.company object
 * @return {Boolean}         If the company contains "dod-"
 */
const isDodCompany = company => {
	if (typeof company === 'string') {
		return company.toLowerCase().indexOf('dod') > -1;
	} else {
		if (hasProp(company, 'code')) {
			return company.code.toLowerCase().indexOf('dod') > -1 || company.name.toLowerCase().indexOf('dod') > -1;
		} else {
			return company.name && company.name.toLowerCase().indexOf('dod') > -1;
		}
	}
};

export default (state = generalDefaultState, action) => {
	switch (action.type) {
		case t.RESET_STATE:
			return {
				...state,
				campaignID: undefined,
				companyCode: undefined,
				companyName: undefined,
				preferredCCType: [],
				preferredEmployeeIDType: undefined
			};
		case user.CLEAR_USER: {
			return {
				...state,
				company: null,
				companyCode: null,
				companyName: null,
				dodMode: false,
				doordashMode: false,
				preferredCCType: [],
				preferredEmployeeIDType: undefined,
				preferredConnectionID: null
			};
		}
		case t.SET_CAMPAIGN_ID:
			return {
				...state,
				campaignID: action.payload
			};
		case t.SET_COMPANY: {
			// update dodMode
			return {
				...state,
				company: action.payload,
				dodMode: isDodCompany(action.payload)
			};
		}
		case t.SET_COMPANY_CODE: {
			// update dodMode
			return {
				...state,
				companyCode: action.payload,
				dodMode: isDodCompany(action.payload)
			};
		}
		case t.SET_COMPANY_NAME: {
			// update dodMode
			return {
				...state,
				companyName: action.payload,
				dodMode: isDodCompany(action.payload)
			};
		}
		case t.SET_PREFERRED_CC_TYPE:
			return {
				...state,
				preferredCCType: [...state.preferredCCType, action.payload]
			};
		case t.SET_PREFERRED_EMPID_TYPE:
			return {
				...state,
				preferredEmployeeIDType: action.payload
			};
		case t.SET_PREFERRED_CONNECTION_ID:
			return {
				...state,
				preferredConnectionID: action.payload
			};
		case t.CLEAR_CONNECTION_TYPE: {
			return {
				...state,
				company: null,
				companyCode: undefined,
				companyName: undefined,
				preferredCCType: [],
				preferredEmployeeIDType: undefined,
				preferredConnectionID: null
			};
		}
		case registration.RESET: {
			return {
				...state,
				company: null,
				campaignID: undefined,
				companyCode: undefined,
				companyName: undefined,
				preferredCCType: [],
				preferredEmployeeIDType: undefined,
				preferredConnectionID: null
			};
		}
		case t.DOD_MODE: {
			return {
				...state,
				dodMode: action.payload
			};
		}

		case t.DOORDASH_MODE: {
			return {
				...state,
				doordashMode: action.payload
			};
		}

		case user.SET_USER: {
			// update dodMode
			if (action.registration && !action.payload.catering) {
				// flow from registration step 1 where there is no company set.
				// also check that action.payload.catering === false.  If the user registered from the catering registration they will have chosen a company.
				return state;
			} else {
				return {
					...state,
					dodMode: isDodCompany(action.payload.company)
				};
			}
		}
		default:
			return state;
	}
};
