import { getLowerCasedParams } from 'utils/url';
import { hasProp } from 'utils/object';
import { sortUserConnections } from 'utils/user';
import isTrickCleanState from './isTrickCleanState';

export { isTrickCleanState };

export const getName = (whichName, state) => {
	let parsed = getLowerCasedParams();
	return hasProp(parsed, whichName)
		? parsed[whichName]
		: hasProp(state.users, `user.name.${whichName}`)
		? state.users.user.name[whichName]
		: '';
};

export const getSelectedCardType = state => {
	const supportedConnections = hasProp(state.general, 'company.supportedConnections')
		? state.general.company.supportedConnections
		: [];
	let selectedCardType = undefined;
	if (supportedConnections && supportedConnections.length === 1) {
		selectedCardType = supportedConnections[0];
	}

	return selectedCardType;
};

export const getConnectToPointsFormInitialValues = state => {
	let parsed = getLowerCasedParams();
	const userConnections = hasProp(state.users, 'user.connections') ? sortUserConnections(state.users.user) : [],
		isFirstConnection = userConnections.length === 0,
		firstName = getName('first', state),
		lastName = getName('last', state);

	const initialValues = {
		cardNumber: undefined,
		email: isFirstConnection
			? hasProp(state.users, 'user.email.primary')
				? state.users.user.email.primary
				: ''
			: '',
		employeeId: hasProp(state.registration, 'cardDetails.employeeId')
			? state.registration.cardDetails.employeeId
			: hasProp(parsed, 'empid')
			? parsed.empid
			: hasProp(state, 'users.user.company')
			? state.users.user.company.employeeId
			: undefined,
		nameOnCard: isFirstConnection ? `${firstName} ${lastName}`.trim() : '',
		firstName: '',
		middleName: '',
		lastName: '',
		selectedCardType: getSelectedCardType(state)
	};

	return initialValues;
};
