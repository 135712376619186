// awesome-phonenumber DOCS https://github.com/grantila/awesome-phonenumber
import PhoneNumber from 'awesome-phonenumber';
import { onlyNumbers } from 'utils/regex';

const phoneFormatter = (value, countryCode = 'US', numberType = 'personal-number', formatter = 'national') => {
	value = onlyNumbers(value);
	let asYouType = PhoneNumber.getAsYouType(countryCode, numberType, formatter);
	value = asYouType.reset(value);
	return value;
};

export default phoneFormatter;
