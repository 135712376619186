import CompleteProfile from './ui-component';
import { connect } from 'react-redux';
import { completeStep, updateForm } from 'actions/profile_setup';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import { getTranslate } from 'react-localize-redux';
import { update, updateOnboardingStatus } from 'actions/users';

const mapStateToProps = state => {
	return {
		doesOrderCatering: state.profileSetup.form.doesOrderCatering,
		doesSchedulePrivateEvents: state.profileSetup.form.doesSchedulePrivateEvents,
		doNotPlanCateringOrEvents: state.profileSetup.form.doNotPlanCateringOrEvents,
		translate: getTranslate(state.locale),
		user: state.users.user
	};
};

const mapDispatchToProps = dispatch => ({
	completeStep: checkBox => () => {
		const checkedItems = checkBox.reduce((result, checkbox) => {
			if (checkbox.checked) {
				if (checkbox.name === 'doesOrderCatering') {
					result.push({ id: '1' });
				} else if (checkbox.name === 'doesSchedulePrivateEvents') {
					result.push({ id: '2' });
				}
			}
			return result;
		}, []);
		dispatch(update({ diningTypes: checkedItems }));
		dispatch(completeStep(ProfileSetupSteps.PROFILE));
		dispatch(updateOnboardingStatus({ onboardingStatus: { completeProfile: true } }));
	},
	updateForm: ({ field, value }) => dispatch(updateForm({ field, value }))
});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteProfile);
