import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SubmissionError } from 'redux-form';
import { Field } from 'redux-form';
import { MetaTags, ModalPage, PageError, TermsAndConditions } from 'components/molecules';
import { CompanySelect, ReduxFormInput } from 'components/organisms';
import { Button, Row, Col } from 'reactstrap';
import validateRegistrationForm from './validate';
import metaTagConfig from './meta-tags';
import { getLowerCasedParams } from 'utils/url';
import { hasProp } from 'utils/object';
import { companySelectSubmitNoValWindowEvent } from 'utils/windowEvents';
import { phoneFormatter } from 'utils/formatters';

class RegistrationForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			behaviorsField: null,
			behaviorsToggled: false,
			emailOptSelected: false,
			offersOptSelected: false,
			emailField: null,
			emailOptField: null,
			firstNameField: null,
			lastNameField: null,
			offersOptField: null,
			passwordField: null,
			phoneField: null,
			usernameField: null,
			termsAndConditionsField: null
		};
	}

	componentDidMount = () => {
		this.initEmailField();
		this.initEmailOptField();
		this.initFirstNameField();
		this.initLastNameField();
		this.initOffersOptField();
		this.initPasswordField();
		this.initUsernameField();
		this.initTermsAndConditionsField();

		if (this.isCateringRegistration()) {
			this.initCompanyField();
			this.initPhoneField();
		}

		this.initCancelModal();
	};

	isCateringRegistration = () => {
		let params = getLowerCasedParams();

		return hasProp(params, 'redirect') && params.redirect.indexOf('catering') > -1;
	};

	initCancelModal = () => {
		this.props.setModal({
			id: this.props.translate('Registration.modals.cancel.id'),
			header: this.props.translate('Registration.modals.cancel.header'),
			body: this.props.translate('Registration.modals.cancel.body'),
			primaryButtonText: this.props.translate('Registration.modals.cancel.primaryButtonText'),
			secondaryButtonText: this.props.translate('Registration.modals.cancel.secondaryButtonText')
		});
	};

	initCompanyField = () => {
		let companyField = (
			<Field
				name="company"
				component={() => {
					return <CompanySelect onFocus={this.onCompanySelectFocus} onBlur={this.onCompanySelectBlur} />;
				}}
			/>
		);

		this.setState({ companyField });
	};

	initEmailField = () => {
		let emailField = (
			<Field
				name="email"
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								required: true,
								dataCy: 'email-field',
								name: 'input_email',
								id: 'input-email',
								error: this.props.translate('Registration.inputs.email.error'),
								hasError: !field.meta.valid,
								label: this.props.translate('Registration.inputs.email.label'),
								help: this.props.translate('Registration.inputs.email.help')
							}}
						/>
					);
				}}
			/>
		);

		this.setState({ emailField });
	};

	initEmailOptField = () => {
		let emailOptField = (
			<Field
				name="emailOpt"
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								type: 'radio',
								dataCy: 'emailOpt-field',
								name: 'input_emailOpt',
								id: 'input-emailOpt',
								error: this.props.translate('Registration.optin-error'),
								hasError: !field.meta.valid,
								label: this.props.translate('Registration.email-summary-label'),
								inputAlign: this.props.isSV ? 'inline' : null,
								alignOptions: 'horizontal',
								value: field.input.value.value,
								required: false,
								purpose: 'Points Summary Email Prefernce',
								options: [
									{
										value: 'yes',
										displayValue: 'Yes',
										id: 'email-yes',
										name: 'optIn.email'
									},
									{
										value: 'no',
										displayValue: 'No',
										id: 'email-no',
										name: 'optIn.email'
									}
								]
							}}
						/>
					);
				}}
			/>
		);

		this.setState({ emailOptField });
	};

	initFirstNameField = () => {
		const firstNameField = (
			<Field
				name="firstname"
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								required: true,
								dataCy: 'firstname-field',
								name: 'input_firstname',
								id: 'input-firstname',
								error: this.props.translate('Registration.inputs.firstname.error'),
								hasError: !field.meta.valid,
								label: this.props.translate('Registration.inputs.firstname.label'),
								help: this.props.translate('Registration.inputs.firstname.help')
							}}
						/>
					);
				}}
			/>
		);
		this.setState({ firstNameField });
	};

	initLastNameField = () => {
		const lastNameField = (
			<Field
				name="lastname"
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								required: true,
								dataCy: 'lastname-field',
								name: 'input_lastname',
								id: 'input-lastname',
								error: this.props.translate('Registration.inputs.lastname.error'),
								hasError: !field.meta.valid,
								label: this.props.translate('Registration.inputs.lastname.label'),
								help: this.props.translate('Registration.inputs.lastname.help')
							}}
						/>
					);
				}}
			/>
		);
		this.setState({ lastNameField });
	};

	initOffersOptField = () => {
		let offersOptField = (
			<Field
				name="offersOpt"
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								type: 'radio',
								dataCy: 'offersOpt-field',
								name: 'input_offersOpt',
								id: 'input-offersOpt',
								error: this.props.translate('Registration.optin-error'),
								hasError: !field.meta.valid,
								label: this.props.translate('Registration.offers-label'),
								inputAlign: this.props.isSV ? 'inline' : null,
								alignOptions: 'horizontal',
								value: field.input.value.value,
								required: false,
								purpose: 'Offers Email Prefernce',
								options: [
									{
										value: 'yes',
										displayValue: 'Yes',
										id: 'offers-yes',
										name: 'optIn.offers'
									},
									{
										value: 'no',
										displayValue: 'No',
										id: 'offers-no',
										name: 'optIn.offers'
									}
								]
							}}
						/>
					);
				}}
			/>
		);

		this.setState({ offersOptField });
	};

	initPasswordField = () => {
		let passwordField = (
			<Field
				name="password"
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								required: true,
								dataCy: 'password-field',
								name: 'input_password',
								id: 'input-password',
								error: '',
								type: 'password',
								hasError: !field.meta.valid,
								label: this.props.translate('Registration.inputs.password.label')
							}}
						/>
					);
				}}
			/>
		);

		this.setState({ passwordField });
	};

	initPhoneField = () => {
		let phoneField = (
			<Field
				name="phone"
				normalize={value => {
					return phoneFormatter(value);
				}}
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								required: true,
								dataCy: 'phone-field',
								name: 'input_phone',
								id: 'input-phone',
								error: this.props.translate('Registration.inputs.phone.error'),
								hasError: !field.meta.valid,
								label: this.props.translate('Registration.inputs.phone.label'),
								maxLength: 14
							}}
						/>
					);
				}}
			/>
		);

		this.setState({ phoneField });
	};

	initTermsAndConditionsField = () => {
		let termsAndConditionsField = (
			<Field
				name="termsAndConditions"
				type="checkbox"
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								type: 'checkboxSingle',
								required: true,
								option: {
									label: this.props.translate('Registration.terms-label'),
									checked: field.input.checked,
									id: 'tandc',
									name: 'tandc'
								},
								isReduxForm: true,
								dataCy: 'termsAndConditions-field',
								name: 'input_termsAndConditions',
								id: 'input-termsAndConditions',
								error: this.props.translate('Registration.terms-label-error'),
								hasError: !field.meta.valid,
								label: this.props.translate('Registration.terms-label'),
								triggers: [<TermsAndConditions key="trigger-two" />]
							}}
						/>
					);
				}}
			/>
		);

		this.setState({ termsAndConditionsField });
	};

	initUsernameField = () => {
		let usernameField = (
			<Field
				name="username"
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								required: true,
								dataCy: 'username-field',
								name: 'input_username',
								id: 'input-username',
								error: '',
								hasError: !field.meta.valid,
								label: this.props.translate('Registration.inputs.username.label')
							}}
						/>
					);
				}}
			/>
		);

		this.setState({ usernameField });
	};

	submit = async vals => {
		let company = vals.companyCode ? vals.companyCode : ''; // val came from quick register link
		if (hasProp(this.props.general, 'company')) {
			company = this.props.general.company.code;
		}
		if (hasProp(this.props, 'companySelectForm')) {
			// need this if statement to override the quick register link with the CompanySelect input val - in the case user changed company after quick regitser link
			company = this.props.companySelectForm.values.name; // val came from CompanySelect input
		}
		vals.companyCode = company;
		let errors = validateRegistrationForm(vals, this.isCateringRegistration());

		if (errors.companyCode) {
			companySelectSubmitNoValWindowEvent();
		}

		if (Object.keys(errors).length) {
			throw new SubmissionError(errors);
		} else {
			Object.keys(vals).forEach(key => {
				if (typeof vals[key] === 'string') {
					vals[key] = vals[key].trim();
				}
			});
			const res = await this.doSubmit(vals);
			this.completeRegistrationStepOne(res);
		}
	};

	doSubmit = async vals => {
		const userData = {
			name: {
				first: vals.firstname,
				last: vals.lastname
			},
			phone: {
				mobile: vals.phone
			},
			email: {
				primary: vals.email
			},
			company: this.props.isDoD ? 'DOD' : vals.companyCode,
			// should be
			preferences: {
				notification: {
					monthlySummary: vals.emailOpt.value === 'yes' ? 'email' : 'none',
					specialOffers: vals.offersOpt.value === 'yes' ? 'email' : 'none'
				}
			},
			userName: vals.username,
			password: vals.password,
			campaign: {
				id: vals.campaign
			},
			registrationPath: vals.registrationPath,
			token: vals.referralToken,

			acceptedTC: vals.termsAndConditions
		};

		return new Promise(resolve => {
			this.props.register(userData, res => {
				resolve(res);
			});
		});
	};

	completeRegistrationStepOne = res => {
		if (hasProp(res, 'response.code') && res.response.code === 200) {
			let isMyDinovaEligible = true;

			if (this.isCateringRegistration()) {
				if (!res.user.company.code) {
					isMyDinovaEligible = false;
				}
				if (!isMyDinovaEligible) {
					this.props.toggleShowPointsElegibilityModal(true);
				}
			}
			if (this.props.doAfterSubmit) {
				this.props.doAfterSubmit(isMyDinovaEligible);
			}
		} else {
			window.scrollTo(0, 0);
		}
	};

	getCardUsage = behaviors => {
		// business dining behaviors - catering = 1, privateDining = 2
		let usage = [];
		if (!behaviors) {
			return usage;
		}
		if (behaviors.catering) {
			usage.push(1);
		}
		if (behaviors.private_dining) {
			usage.push(2);
		}

		return usage;
	};

	showCancelModal = () => {
		const isOpen = !this.props.modal.isOpen;
		this.props.toggleModal(isOpen);
	};

	onSecondaryClick = () => {
		let redirect = getLowerCasedParams().redirect;
		if (redirect) {
			if (redirect.indexOf('#') === -1) {
				redirect += '/#/';
			}
			this.props.history.replace(redirect);
		} else {
			this.props.history.replace('/');
		}
	};

	render() {
		return (
			<div className="registration-form-wrapper">
				<MetaTags tags={metaTagConfig} />
				<ModalPage
					isOpen={this.props.modal.isOpen}
					primaryClick={() => {}}
					secondaryClick={this.onSecondaryClick}
				/>{' '}
				{(this.state.hasErrors || this.props.registration.error) && (
					<PageError
						message={
							(this.props.registration.error &&
								this.props.translate(`errors.codes.${this.props.registration.error}.message`)) ||
							this.props.translate('errors.page_level_message')
						}
					/>
				)}
				<form
					onSubmit={this.props.handleSubmit(async vals => await this.submit(vals))}
					className="registration-form"
				>
					<Row>
						<Col>
							<div className="Di_RequiredHeader">
								<span>*</span>
								{this.props.translate('required.required')}
							</div>
						</Col>
					</Row>
					<Row>
						<Col xs="12" className="pl-0 pr-0">
							{this.state.usernameField}
						</Col>
						<Col xs="12" className="pl-0 pr-0">
							{this.state.passwordField}
						</Col>
						<Col xs="12" className="pl-0 pr-0">
							{this.state.emailField}
						</Col>
						<Col xs="12" className="pl-0 pr-0">
							{this.state.firstNameField}
						</Col>
						<Col xs="12" className="pl-0 pr-0">
							{this.state.lastNameField}
						</Col>
						<Col xs="12" className="pl-0 pr-0">
							{this.state.phoneField}
						</Col>
						<Col xs="12" className="pl-0 pr-0">
							{this.state.companyField}
						</Col>
						<Col xs="12" className="pl-0 pr-0">
							{this.state.termsAndConditionsField}
						</Col>
						<Col xs="12" className="pl-0 pr-0">
							{this.state.emailOptField}
						</Col>
						<Col xs="12" className="pl-0 pr-0">
							{this.state.offersOptField}
						</Col>
						<Col xs="12" className="pl-0 pr-0">
							{this.state.behaviorsField}
						</Col>
					</Row>
					<Row className="Di_FootMargin Di_ActionBttns">
						<Col xs="12">
							<Button className="z-depth-0" onClick={this.showCancelModal} id="register-cancel">
								{this.props.translate('Registration.secondary_button')}
							</Button>
							<Button type="submit" color="primary" className="z-depth-0" id="register-submit">
								{this.props.translate('Registration.primary_button')}
							</Button>
						</Col>
					</Row>
				</form>
			</div>
		);
	}
}

RegistrationForm.defaultProps = {};

RegistrationForm.propTypes = {
	companySelectForm: PropTypes.object,
	doAfterSubmit: PropTypes.func,
	error: PropTypes.string,
	handleSubmit: PropTypes.func.isRequired, // redux form prop
	history: PropTypes.object.isRequired,
	isDoD: PropTypes.bool.isRequired,
	isSV: PropTypes.bool.isRequired,
	modal: PropTypes.object.isRequired,
	register: PropTypes.func.isRequired,
	setModal: PropTypes.func.isRequired,
	toggleModal: PropTypes.func.isRequired,
	toggleShowPointsElegibilityModal: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired,
	warning: PropTypes.shape({
		username: PropTypes.array
	}),
	registration: PropTypes.object,
	general: PropTypes.object
};

export default RegistrationForm;
