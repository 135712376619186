import ReduxFormCCTypeDropdown from './ui-component';
import { connect } from 'react-redux';
import { hasProp } from 'utils/object';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
	cards: hasProp(state.general, 'company.supportedConnections') ? state.general.company.supportedConnections : [],
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(ReduxFormCCTypeDropdown);
