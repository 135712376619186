import * as t from '../actions/details/types';

/**
 * Redux_RestaurantDetails
 * @interface Redux_RestaurantDetails
 * @property {object}   [restaurant=null]
 * @property {array}    [actions=[]]
 * @property {array}    [dietaryPrefs=[]]
 * @property {array}    [amenities=[]]
 * @property {array}    [contactInfo=[]]
 * @property {string}   [keywords=null]
 * @property {string}   [description=null]
 * @property {string}   [cuisineAndMS=null]
 * @property {string}   [title=null]
 * @property {string}   [bannerImg=null]
 * @property {string}   [logoImg=null]
 * @property {object}   [location=null]
 * @property {string[]} [addresses=[]]
 * @property {string}   [errMsg=null]
 */
const mapDefaultState = {
	restaurant: null,
	actions: [],
	dietaryPrefs: [],
	amenities: [],
	contactInfo: [],
	keywords: null,
	description: null,
	cuisineAndMS: null,
	title: null,
	bannerImg: null,
	logoImg: null,
	location: null,
	addresses: [],
	errMsg: null,
	recommendations: [],
	recOptions: {
		positive: []
	}
};

const findRec = (recommendationGroups, recId) => {
	let rec = false;
	recommendationGroups.forEach(group => {
		group.categories.forEach(cat => {
			if (cat.id === recId) {
				rec = cat;
			}
		});
	});

	return rec;
};

export default (state = mapDefaultState, action) => {
	switch (action.type) {
		case t.SUBMIT_RECOMMENDATION_SUCCESS: {
			if (window.location.pathname.indexOf('restaurants') > -1) {
				let recommendationType = action.payload.recommendations.recommended ? 'positive' : 'negative';
				if (recommendationType === 'positive') {
					let recommendationOptions = state.recOptions.positive;
					let newRecs = action.payload.recommendations.for.reduce((accum, recId) => {
						let newRec = findRec(recommendationOptions, recId);

						return [...accum, newRec];
					}, []);
					let newCats = { ...state.recommendations.categories };

					newRecs.forEach(rec => {
						let found = false;
						Object.keys(newCats).forEach(key => {
							if (parseInt(rec.id) === parseInt(key)) {
								found = true;
								newCats[key] = {
									...newCats[key],
									total: newCats[key].total + 1
								};
							}
						});
						if (!found) {
							const key = rec.id;
							newCats[key] = {
								name: rec.name,
								total: 1
							};
						}
					});

					let newState = {
						...state,
						recommendations: {
							total: state.recommendations.total + 1,
							categories: newCats
						}
					};

					return newState;
				}
			}
			return state;
		}
		case t.LOAD_RESTAURANT: {
			if (action.status === 'success') {
				const restaurant = action.response.restaurant;

				return {
					...state,
					restaurant,
					recommendations: restaurant.recommendations.positive,
					errMsg: null
				};
			} else {
				return {
					...state,
					...mapDefaultState,
					errMsg: action.error
				};
			}
		}
		case t.CLEAR_RESTAURANT: {
			return {
				...state,
				restaurant: null,
				recommendations: null,
				errMsg: null
			};
		}
		default:
			return state;
	}
};
