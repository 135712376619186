import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ShareBox, SocialShare } from 'components/molecules';
import { LoadingBase } from 'components/atoms';

const ReferSharing = props => {
	const { className, isLoadingLink, link, parent, showSocial, showLearnMore } = props;
	const classProps = classNames(`refer-sharing`, className);

	return isLoadingLink ? (
		<LoadingBase message="Loading Referral Link" />
	) : link ? (
		<div className={classProps}>
			<ShareBox link={link} parent={parent} isLoadingLink={isLoadingLink} showLearnMore={showLearnMore} />
			{showSocial && <SocialShare link={link} parent={parent} />}
		</div>
	) : null;
};

ReferSharing.defaultProps = {
	isLoadingLink: false,
	showSocial: true,
	showLearnMore: false
};

ReferSharing.propTypes = {
	className: PropTypes.string,
	link: PropTypes.string,
	parent: PropTypes.string.isRequired,
	isLoadingLink: PropTypes.bool,
	showSocial: PropTypes.bool,
	showLearnMore: PropTypes.bool
};

export default ReferSharing;
