import React from 'react';
import PropTypes from 'prop-types';
// import classnames from 'classnames';

// import './style.scss';

const ReadOnlyInput = ({ className, dataCy, label, labelKey, translate, value }) => {
	const displayLabel = label || translate(labelKey);
	return (
		<div className={className} data-cy={`read-only-wrapper-${dataCy}`}>
			<span className="label" data-cy={`read-only-label-${dataCy}`}>
				{displayLabel}:{' '}
			</span>
			<strong className="value" data-cy={`read-only-value-${dataCy}`}>
				{value}
			</strong>
		</div>
	);
};

ReadOnlyInput.defaultProps = {};

ReadOnlyInput.propTypes = {
	className: PropTypes.string,
	dataCy: PropTypes.string,
	label: PropTypes.string,
	labelKey: PropTypes.string,
	translate: PropTypes.func.isRequired,
	value: PropTypes.string
};

export default ReadOnlyInput;
