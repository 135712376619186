import React, { useCallback, useState } from 'react';
import styles from './style.module.scss';
import { Text } from 'components/atoms';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import StepTemplate from '../../StepTemplate';

const CompleteEmail = ({ completeStep, defaultOptInToEmails, formOptInToEmails, translate, updateForm }) => {
	const [value, setValue] = useState(defaultOptInToEmails ?? false);

	const [didChange, setDidChange] = useState(false);
	const translateBaseKey = 'ProfileSetup.modals.step3';

	const handleChange = useCallback(() => {
		updateForm({ field: 'optInToEmails', value: !value });
		setValue(!value);
		if (!didChange) {
			setDidChange(true);
		}
	}, [defaultOptInToEmails, didChange, formOptInToEmails, updateForm]);

	return (
		<StepTemplate
			title={translate(`${translateBaseKey}.title`)}
			onSubmit={completeStep(formOptInToEmails)}
			submitDisabled={!value}
		>
			<div className={styles.wrapper}>
				<Text weight="300" className={styles.body}>
					{translate(`${translateBaseKey}.body`)}
				</Text>
				<span className={styles.switch}>
					<Switch
						checked={didChange ? formOptInToEmails : defaultOptInToEmails}
						onChange={handleChange}
						name="optInToEmails"
						inputProps={{ 'aria-label': 'secondary checkbox' }}
					/>
				</span>
			</div>
		</StepTemplate>
	);
};

CompleteEmail.propTypes = {
	completeStep: PropTypes.func,
	defaultOptInToEmails: PropTypes.bool,
	formOptInToEmails: PropTypes.bool,
	translate: PropTypes.func.isRequired,
	updateForm: PropTypes.func
};

export default CompleteEmail;
