import { BaseFilterGroup } from 'utils/search_new/classes';

import {
	ameitiesFilterAggregate,
	// covidPracticesFilterAggregate,
	cuisineFilterAggregate,
	deliveryOptsFilterAggregate,
	dietaryPrefsFilterAggregate,
	diningOptsFilterAggregate,
	favoriteFilterAggregate,
	recommendationsFilterAggregate,
	safetyPracticesFilterAggregate
} from 'utils/search_new/filter_aggregates';

// Order of filterConfig will determine order of filter groups on the UI
const filterConfig = {
	favorite: {
		filterAggregateFunc: favoriteFilterAggregate,
		groupTitle: 'Favorite',
		filterType: 'AND'
	},
	diningOptions: {
		filterAggregateFunc: diningOptsFilterAggregate,
		groupTitle: 'Dining Options',
		filterType: 'OR'
	},
	deliveryOptions: {
		filterAggregateFunc: deliveryOptsFilterAggregate,
		groupTitle: 'To-Go Options',
		filterType: 'OR'
	},
	safetyPractices: {
		filterAggregateFunc: safetyPracticesFilterAggregate,
		groupTitle: 'Safety Practices',
		filterType: 'AND'
	},
	// covidPractices: {
	// 	filterAggregateFunc: covidPracticesFilterAggregate,
	// 	groupTitle: 'COVID-19 Practices',
	// 	filterType: 'AND'
	// },
	cuisines: {
		filterAggregateFunc: cuisineFilterAggregate,
		groupTitle: 'Cuisines',
		filterType: 'OR'
	},
	dietaryPreferences: {
		filterAggregateFunc: dietaryPrefsFilterAggregate,
		groupTitle: 'Dietary Preferences',
		filterType: 'AND'
	},
	recommendations: {
		filterAggregateFunc: recommendationsFilterAggregate,
		groupTitle: 'Recommendations',
		filterType: 'OR'
	},
	amenities: {
		filterAggregateFunc: ameitiesFilterAggregate,
		filterAggregateIgnoreKeys: ['general', 'takeout'],
		groupTitle: 'Amenities',
		filterType: 'OR'
	}
};

const buildRestaurantFilters = (restaurants, recGroups) => {
	const filter = {};
	let recOptions = recGroups.reduce((accum, group) => {
		group.categories.forEach(cat => {
			accum[cat.id] = cat.name;
		});
		return accum;
	}, {});
	Object.values(filterConfig).forEach(config => {
		if (!filter[config.groupTitle]) {
			filter[config.groupTitle] = new BaseFilterGroup({ filterType: config.filterType });
		}
		restaurants.forEach(restaurant => {
			if (config.groupTitle === 'Recommendations') {
				config.filterAggregateFunc(filter, restaurant, config, recOptions);
			} else {
				config.filterAggregateFunc(filter, restaurant, config);
			}
		});
	});

	console.log('%c filter', 'color: red', filter);

	return filter;
};

export default buildRestaurantFilters;
