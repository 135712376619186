import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { IconCheckMark, IconWarning, Text } from 'components/atoms';
import { Button } from 'reactstrap';
import { isEqual } from 'lodash';
import { hasProp } from 'utils/object';

const filters = ['Dining Options.Curbside', 'Amenities.catering', 'Dining Options.takeout', 'Dining Options.delivery'];

const getCTAFilters = availableFilters => {
	let ctaFilters = [];
	Object.keys(availableFilters).forEach(filterCatKey => {
		let currentFilterCat = availableFilters[filterCatKey];
		Object.keys(currentFilterCat).forEach(filterKey => {
			if (filterKey !== 'activeCount') {
				let currentFilter = currentFilterCat[filterKey];
				if (filters.indexOf(currentFilter.filterKey) > -1) {
					ctaFilters.push({ ...currentFilter, selected: true });
				}
			} else {
				return;
			}
		});
	});

	return ctaFilters;
};

class COVID19Message extends React.Component {
	constructor(props) {
		super(props);
		this.content = React.createRef();
	}

	componentDidMount = () => {
		window.addEventListener('resize', this.handleResize);
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.handleResize);
	};

	handleResize = () => {
		if (hasProp(this.content, 'style') && this.content.style.height && this.props.show) {
			this.content.style.height = '';
			this.content.style.height = this.content.offsetHeight + 'px';
		}
	};

	componentDidUpdate = prevProps => {
		if (!isEqual(prevProps.config, this.props.config)) {
			this.handleResize();
		}
	};

	render() {
		const {
			applyPickupDeliveryFilter,
			availableFilters,
			config,
			COVID19State,
			show,
			toggleCovidShow,
			translate
		} = this.props;

		if (!config.active) {
			return null;
		} else {
			const { active, activeFilter, color, message } = config;

			return COVID19State && active ? (
				<div className={classnames('message-banner', color, show ? 'show' : 'hide')} data-cy="covid-wrapper">
					<div className="flex justify-between align-center">
						<Text size="lg" dataCy="covid-title">
							{color === 'red' ? <IconWarning fill={'#fff'} /> : <IconCheckMark fill={'#fff'} />}
							{translate('notification.covid-title')}
						</Text>
						<span onClick={toggleCovidShow} className="flex">
							<i className={'fa fa-chevron-up'} />
						</span>
					</div>
					<div
						ref={c => {
							if (c) {
								this.content = c;
								if (!this.content.style.height) {
									this.content.style.height = this.content.offsetHeight + 'px';
								}
							}
						}}
					>
						<Text size="sm" dataCy="covid-message">
							{message}
						</Text>

						{activeFilter && (
							<Text size="sm">
								<Button
									onClick={() => {
										const filters = getCTAFilters(availableFilters);
										applyPickupDeliveryFilter(filters);
									}}
									data-cy="covid-cta"
								>
									{translate('notification.covid-filter-cta-text')}
								</Button>
							</Text>
						)}
					</div>
				</div>
			) : null;
		}
	}
}

COVID19Message.defaultProps = {};

COVID19Message.propTypes = {
	applyPickupDeliveryFilter: PropTypes.func.isRequired,
	availableFilters: PropTypes.object,
	config: PropTypes.shape({
		active: PropTypes.bool.isRequired,
		message: PropTypes.any.isRequired,
		color: PropTypes.string.isRequired,
		activeFilter: PropTypes.bool.isRequired
	}),
	COVID19State: PropTypes.string.isRequired,
	show: PropTypes.bool.isRequired,
	toggleCovidShow: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

export default COVID19Message;
