import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { InvisibleButton } from 'components/atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import classnames from 'classnames';
import isBool from 'utils/isBool';
import toast from 'react-hot-toast';

const FavoriteRestaurantButton = ({
	addFavoriteRestaurant,
	color,
	isAuthed,
	removeFavoriteRestaurant,
	restaurant,
	updateOnboardingStatus,
	additionalLocation,
	translate,
	userHasFavorites
}) => {
	const location = useLocation();

	const [isFavorited, setIsFavorited] = useState(() =>
		location.pathname === '/favorites/' ? true : restaurant?.user?.favorite
	);

	const handleShowToast = (message, success) => {
		success
			? toast.success(message, {
					position: 'top-center',
					duration: parseInt(translate('Favorites.messageDuration'))
			  })
			: toast.error(message, {
					position: 'top-center',
					duration: parseInt(translate('Favorites.messageDuration'))
			  });
	};

	const toggleIsFavorited = useCallback(
		e => {
			e.stopPropagation();
			const restaurantId = restaurant.id;

			if (!isFavorited) {
				addFavoriteRestaurant({ restaurantId });
				updateOnboardingStatus();

				handleShowToast(translate('Favorites.addMessage'), true);
			} else {
				removeFavoriteRestaurant({ restaurantId });
				handleShowToast(translate('Favorites.removeMessage'), false);
			}
			setIsFavorited(!isFavorited);
		},
		[isFavorited, restaurant, userHasFavorites]
	);

	useEffect(() => {
		if (isBool(restaurant?.user?.favorite) && !isBool(isFavorited)) {
			setIsFavorited(restaurant?.user?.favorite);
		}
	}, [restaurant]);

	useEffect(() => {
		setIsFavorited(location.pathname === '/favorites/' ? true : restaurant?.user?.favorite);
	}, [location.pathname, restaurant]);

	console.log('checking userHasFavorite?', userHasFavorites);

	return isAuthed ? (
		<div
			className={classnames(styles.favoriteBtnWrapper, {
				[styles.solid]: Boolean(color),
				[styles.additionalLocation]: additionalLocation
			})}
		>
			<InvisibleButton onClick={toggleIsFavorited}>
				<FontAwesomeIcon
					icon={isFavorited ? 'fas fa-heart' : 'far fa-heart'}
					{...(color ? { style: { color: '#1c871c' } } : {})}
				/>
			</InvisibleButton>
		</div>
	) : null;
};

FavoriteRestaurantButton.propTypes = {
	translate: PropTypes.func,
	additionalLocation: PropTypes.bool,
	addFavoriteRestaurant: PropTypes.func.isRequired,
	updateOnboardingStatus: PropTypes.func.isRequired,
	color: PropTypes.oneOf(['green', 'red']),
	isAuthed: PropTypes.bool,
	userHasFavorites: PropTypes.bool,
	isFavorited: PropTypes.bool,
	removeFavoriteRestaurant: PropTypes.func.isRequired,
	restaurantId: PropTypes.string,
	restaurant: PropTypes.shape({
		id: PropTypes.string.isRequired,
		user: PropTypes.shape({
			favorite: PropTypes.bool.isRequired
		})
	}).isRequired
};

export default FavoriteRestaurantButton;
