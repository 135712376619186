import BusinessSearchFilter from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import withSizes from 'react-sizes';
import { isSV } from 'utils/sizes';
import { toggleShowFilter } from 'actions/search_page';

const mapStateToFilters = state => {
	return {
		filters: state.businessSearchFilter.userFilters,
		filteredResults: state.businessSearchFilter.filteredResults,
		isLoading: state.businessSearchFilter.isLoading,
		hasFilters: Object.keys(state.businessSearchFilter.userFilters).length > 0,
		translate: getTranslate(state.locale)
	};
};

const mapDispatchToProps = dispatch => ({
	toggleShowFilter: show => dispatch(toggleShowFilter(show))
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withSizes(mapSizesToProps)(connect(mapStateToFilters, mapDispatchToProps)(BusinessSearchFilter));
