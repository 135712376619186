import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody } from 'react-accessible-accordion';
import { Caret } from 'components/atoms';
import { hasProp } from 'utils/object';

class DinovaAccordion extends React.Component {
	constructor(props) {
		super(props);

		this.isControlled = hasProp(props, 'expanded');

		this.state = {
			expanded: hasProp(props, 'expanded') ? props.expanded : false
		};
	}

	componentDidUpdate = (prevProps, prevState) => {
		const wasStateExpanded = prevState.expanded,
			isPropsExpanded = this.props.expanded;

		if (this.isControlled && isPropsExpanded && !wasStateExpanded) {
			this.setState({ expanded: this.props.expanded });
		}
	};

	toggleExpanded = () => {
		if (this.props.canCollapse) {
			this.setState({ expanded: !this.state.expanded });
		}
	};

	render() {
		const {
			canCollapse,
			children,
			className,
			dataCy,
			showTitle,
			striped,
			title,
			titleAlign,
			titleClass
		} = this.props;
		const classProps = classNames('accordion', canCollapse && 'collapsible', className, striped && 'striped');
		const headerClassProps = classNames(
			'section-title normal-text',
			titleAlign && titleAlign,
			titleClass && titleClass
		);

		return (
			<Accordion className={classProps} data-cy={`accordion-${dataCy}`}>
				<AccordionItem expanded={this.state.expanded} data-cy={`accordion-content-${dataCy}`}>
					<AccordionItemTitle onClick={this.toggleExpanded} data-cy={`accordion-header-${dataCy}`}>
						{showTitle && (
							<React.Fragment>
								<h2 className={headerClassProps} data-cy={`accordion-title-${dataCy}`}>
									{title}
								</h2>
								<Caret isOpen={this.state.expanded} dataCy={`accordion-${dataCy}`} />
							</React.Fragment>
						)}
					</AccordionItemTitle>

					<AccordionItemBody className="section-body" data-cy={`accordion-body-${dataCy}`}>
						{children && children}
					</AccordionItemBody>
				</AccordionItem>
			</Accordion>
		);
	}
}

DinovaAccordion.defaultProps = {
	canCollapse: true,
	children: null,
	className: '',
	expanded: true,
	showTitle: true,
	striped: false,
	title: 'Accordion Title',
	titleAlign: 'left'
};

/**
 * {@link DinovaAccordion} Props
 * @interface Props_Accordion
 * @property {Boolean} [canCollapse=true] If the accordion can be collapsed.
 * @property {Node} [children=null] Items to render inside of the accordion body.
 * @property {String} [className=""] Additional classNames to add to the accordion wrapper.
 * @property {Boolean} [dataCy=""] A unique id for cypress testing.
 * @property {Boolean} [expanded=true] If the accordion should initialize expanded.
 * @property {Boolean} [showTitle=true] If the accordion show render a title bar.  The title bar also contains the collapse button.  If showTitle=false, canCollapse will be false.
 * @property {Boolean} [stripped=true] If the accordion headers should alternate background colors.
 * @property {String} [title='Accordion Title'] The text to show as the accordion title.
 * @property {String} [titleAlign='center'] How to align the title text.
 */
DinovaAccordion.propTypes = {
	// Optional
	canCollapse: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	className: PropTypes.string,
	dataCy: PropTypes.string,
	expanded: PropTypes.bool,
	showTitle: PropTypes.bool,
	striped: PropTypes.bool,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
	titleAlign: PropTypes.oneOf(['left', 'center', 'right']),
	titleClass: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.string])
};

export default DinovaAccordion;
