import {
	cardIdValidator,
	creditCardNumberValidator,
	lengthValidator,
	nameOnCardValidator
} from 'utils/form/validators';
import { hasProp } from 'utils/object';

const validateConenctToPointsForm = (vals, idType, fullNumberRequired, isDoD = false) => {
	const errors = {};

	// Selected Card Validate
	if (!vals.selectedCardType) {
		errors.selectedCardType = 'ConnectToPoints.inputs.creditcardType.error';
	}

	// Card Name Validate
	if (!isDoD) {
		let nameOnCardError = nameOnCardValidator(vals.nameOnCard);
		if (nameOnCardError) {
			errors.nameOnCard = nameOnCardError;
		}
	} else {
		let firstNamePassesLength = lengthValidator(vals.firstName, 1);
		if (!firstNamePassesLength) {
			errors.firstName = !firstNamePassesLength;
		}

		let lastNamePassesLength = lengthValidator(vals.lastName, 1);
		if (!lastNamePassesLength) {
			errors.lastName = !lastNamePassesLength;
		}
	}

	// Card Number Validate
	const card = hasProp(vals, 'selectedCardType') ? vals.selectedCardType : false;
	card.fullNumberRequired = fullNumberRequired;
	let cardNumebrError = creditCardNumberValidator(card, vals.cardNumber);
	if (cardNumebrError) {
		errors.cardNumber = cardNumebrError;
	}

	// Card ID Validate
	if (idType === 'email') {
		let emailError = cardIdValidator(vals.email, 'email');
		if (emailError) {
			errors.email = emailError;
		}
	} else if (idType === 'empID') {
		let employeeIdError = cardIdValidator(vals.employeeId, 'empID');
		if (employeeIdError) {
			errors.employeeId = employeeIdError;
		}
	}
	console.log('errors', errors);
	return errors;
};

export default validateConenctToPointsForm;
