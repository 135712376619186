import ConnectToPointsForm from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { hasProp } from 'utils/object';
import { change, reduxForm } from 'redux-form';
import { getTranslate } from 'react-localize-redux';
import { toggleShowCompanySelectModal } from 'actions/company_select_modal';
import { connectToPoints, getProfile } from 'actions/users';
import { isSV, isMV, isLV } from 'utils/sizes';
import { getConnectToPointsFormInitialValues } from './utils';
import { withRouter } from 'react-router';
import { setModal, toggleModal } from 'actions/modal';
import { update } from 'actions/users';

const FORM_NAME = 'connectToPoints';

const mapStateToProps = state => {
	return {
		cards: hasProp(state.general, 'company.supportedConnections') ? state.general.company.supportedConnections : [],
		connectionCount: hasProp(state, 'users.user.connections') ? state.users.user.connections.length : 0,
		company: hasProp(state.general, 'company') ? state.general.company : undefined,
		initialValues: getConnectToPointsFormInitialValues(state),
		isDoD: state.general.dodMode,
		isDoorDash: state.general.doordashMode,
		formVals: hasProp(state.form, 'connectToPoints.values') ? state.form.connectToPoints.values : {},
		modal: state.modal,
		translate: getTranslate(state.locale)
	};
};

const mapDispatchToProps = dispatch => ({
	refreshUser: () => dispatch(getProfile(true)),
	submitConnection: (card, cb) => dispatch(connectToPoints(card, cb)),
	setFormValue: (fieldName, value) => dispatch(change(FORM_NAME, fieldName, value)),
	setModal: modal => dispatch(setModal(modal)),
	showShowSkipModal: () => dispatch(toggleShowCompanySelectModal(true, 'skip')),
	toggleModal: toggle => dispatch(toggleModal(toggle)),
	updateUser: (userDetails, callback) => dispatch(update(userDetails, callback))
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes),
	isMV: isMV(sizes),
	isLV: isLV(sizes)
});

export default withRouter(
	withSizes(mapSizesToProps)(
		connect(
			mapStateToProps,
			mapDispatchToProps
		)(
			reduxForm({
				form: FORM_NAME
			})(ConnectToPointsForm)
		)
	)
);
