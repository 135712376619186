import * as t from './types';
import * as fetchTypes from 'actions/requests/types';
import { basePath, AUTH_REQUEST_HEADER } from 'actions/utils';
import { hasProp } from 'utils/object';
import axios from 'axios';
import AirBrake from 'utils/airbrake';
import { shouldDoTimeInMinsDependantRequest, DAY_IN_MINS } from 'utils/requests';

/**
 * Sets if to show catering zone service unavailable message.
 * @param {boolean} payload If to show the modal.*
 */
export const setShowNoServiceNotice = payload => ({ type: t.SET_SHOW_NO_SERVICE, payload });

/**
 * Sets catering service type.
 * @param {string} payload The service type to set.
 */
export const setServiceType = payload => ({ type: t.SET_SERVICE_TYPE, payload });

/**
 * Sets address to send catering to.
 * @param {string} payload Address string. NOTE: Is this a string or a google address object?
 */
export const setAddress = payload => ({ type: t.SET_ADDRESS, payload });

/**
 * Sets the date to deliver the catering order.
 * @param {object} payload Date object.
 */
export const setDate = payload => ({ type: t.SET_DATE, payload });

/**
 * Get catering zones for a particular lat/lng
 * @param  {object}   location A {lat: number, lng: number} object.
 * @param  {function} dispatch Redux dispatch function
 */
export const getZones = location => (dispatch, getState) => {
	let URL = `${basePath()}/catering/zones?location=`;

	if (location) {
		URL += `${location.lat},${location.lng}`;
	}

	if (!shouldDoTimeInMinsDependantRequest(URL, DAY_IN_MINS, getState)) {
		return getState().requests[URL].data;
	} else {
		return axios
			.get(URL, AUTH_REQUEST_HEADER())
			.then(zones => {
				const zonesFound = hasProp(zones, 'data.cateringZones') ? zones.data.cateringZones.length : false;
				if (zonesFound) {
					if (!location) {
						// if no location, this is all results, save them to redux state so we don't have to fetch again.
						dispatch({ type: t.FETCH_ZONE_SUCCESS, payload: zones.data.cateringZones });
					}

					dispatch({
						type: fetchTypes.FETCH_SUCCESS,
						payload: {
							url: URL,
							data: zones.data.cateringZones
						}
					});

					return zones.data.cateringZones;
				} else {
					// no zones found for area.
					return { code: 204, message: 'No zones found for this address' };
				}
			})
			.catch(e => {
				dispatch({
					type: fetchTypes.FETCH_ERROR,
					payload: URL
				});

				if (hasProp(e.response, 'data.response.code')) {
					return new Error({ error: e.response.data.response.code, message: e.response.data.response.code });
				} else {
					AirBrake.notify({
						error: new Error(`Fetch zones unknown error - ${e.message}`),
						params: { info: `fetchZones` },
						environment: AirBrake._dinovaEnv
					});
					return new Error({ error: 500, message: 'API error' });
				}
			});
	}
};

export const setCateringIntegrationType = type => ({
	type: t.SET_CATERING_INTEGRATION_TYPE,
	payload: type
});
