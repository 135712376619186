import { connect } from 'react-redux';
import ProfileSetupGreetingStep from './ui-component';
import { getTranslate } from 'react-localize-redux';
import { dismissForever } from 'actions/profile_setup';

const mapStateToProps = state => {
	return {
		dismissedForever: state.profileSetup.dismissedForever,
		translate: getTranslate(state.locale)
	};
};

const mapDispatchToProps = dispatch => ({
	dismissForever: () => dispatch(dismissForever())
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSetupGreetingStep);
