import * as t from '../types';
import { recommendationSubmitWindowEvent } from 'utils/windowEvents';

export default (dispatch, payload, resolve) => {
	dispatch({
		type: t.SUBMIT_RECOMMENDATION_SUCCESS,
		payload
	});
	// Fire event so Recommendation Component can now when to hide itself. - after submitting a recommendation, remove the "Would you leave a recommendation Yes No" from UI.
	recommendationSubmitWindowEvent(payload);

	resolve();
	return;
};
