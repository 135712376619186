import * as t from './types';
import { basePath, AUTH_REQUEST_HEADER } from 'actions/utils';
import axios from 'axios';
import { isNaN } from 'lodash';
import { setIsLoading, setError } from 'actions/loader';
import { hasProp } from 'utils/object';
import AirBrake from 'utils/airbrake';

/**
 * Sets the campaign id.
 * @param {string} payload The campain id.
 */
export const setCampaignId = payload => ({ type: t.SET_CAMPAIGN_ID, payload });

/**
 * Sets the client code.
 * @param {string} payload The client code.
 */
export const setClientCode = payload => ({ type: t.SET_COMPANY_CODE, payload });

/**
 * Sets the user's company.
 * @param {object} payload The company object returned from the API.
 */
export const setCompany = payload => ({ type: t.SET_COMPANY, payload });

/**
 * Sets the user's company name.
 * @param {string} payload The company name.
 */
export const setCompanyName = payload => ({ type: t.SET_COMPANY_NAME, payload });

/**
 * Sets the user's preferred cretid card type.
 * @param {number} payload The id of the credit card type.
 */
export const setPreferredCCType = payload => {
	if (!isNaN(payload)) {
		switch (payload) {
			case 1:
				payload = 'amex';
				break;
			case 2:
				payload = 'visa';
				break;
			case 3:
				payload = 'mastercard';
				break;
			default:
				break;
		}
	}
	return { type: t.SET_PREFERRED_CC_TYPE, payload };
};

/**
 * Sets the employee id type.  Can be "email" or "empId".
 * @param {sting} payload The employee id type.
 */
export const setPreferredEmployeeIDType = payload => ({ type: t.SET_PREFERRED_EMPID_TYPE, payload });

/**
 * Sets the user's preferred card id.
 * @param {sting} payload The id of the selected credit card.  Id comes from API after user saves a credit card.
 */
export const setPreferredConnectionID = payload => ({ type: t.SET_PREFERRED_CONNECTION_ID, payload });

/**
 * Resets state.general to default state.
 */
export const resetState = () => ({ type: t.RESET_STATE });

/**
 * Set's the user's company code.
 * @param {sring} companyCode The code for the user's company.
 */
export const setCompanyCode = (companyCode, showLoading = true) => async dispatch => {
	dispatch(setClientCode(companyCode));
	companyCode && (await dispatch(getConnectionType(companyCode, showLoading)));
};

/**
 * Fetches company data from API.
 * @param {sring} clientCode The code for the company that is being requested.
 */
export const getCompany = (clientCode, showLoading = true) => async dispatch => {
	let url = `${basePath()}/clients/${clientCode}`;
	if (showLoading) {
		dispatch(setIsLoading(true, 'Fetching client information'));
	}

	return await axios
		.get(url, AUTH_REQUEST_HEADER())
		.then(res => {
			switch (res.data.response.code) {
				case 200: {
					let client = res.data.clients[0];
					if (showLoading) {
						dispatch(setIsLoading(false));
					}
					return client;
				}
				default:
					dispatch(setError(res.data.response.code));
					break;
			}
			if (showLoading) {
				dispatch(setIsLoading(false));
			}
			return res.data.clients[0];
		})
		.catch(e => {
			console.dir(e);
			if (showLoading) {
				dispatch(setIsLoading(false));
			}
			dispatch(setError(e.response.status));
			return e.response.status;
		});
};

/**
 * Fetches the credit card types that are available for a company.  After fetch success, sets state.general.companyName and state.general.clientCode.
 * @param  {string} clientCode The code for the company who's card types you would like to fetch.
 */
export const getConnectionType = (clientCode, showLoading = true) => dispatch => {
	if (showLoading) {
		dispatch(setIsLoading(true, 'Fetching connection information'));
	}
	let url = `${basePath()}/clients/${clientCode}`;

	return axios
		.get(url, AUTH_REQUEST_HEADER())
		.then(response => {
			let client = response.data.clients[0];
			dispatch(setCompanyName(client.name));
			dispatch(setClientCode(client.code));
			if (showLoading) {
				dispatch(setIsLoading(false));
			}
			return client;
		})
		.catch(e => {
			if (hasProp(e.response, 'data.response.code')) {
				dispatch(setError(e.response.data.response.code));
				if (showLoading) {
					dispatch(setIsLoading(false));
				}
				return e.response.data.response.code;
			} else {
				AirBrake.notify({
					error: new Error(`Get connection type unknown error - ${e.message}`),
					params: { info: `getConnectionType` },
					environment: AirBrake._dinovaEnv
				});
				dispatch(setError(500));
				if (showLoading) {
					dispatch(setIsLoading(false));
				}
				return 500;
			}
		});
};

export const clearConnectionState = () => ({ type: t.CLEAR_CONNECTION_TYPE });

export const dodMode = (active = true) => ({ type: t.DOD_MODE, payload: active });
export const doordashMode = (active = true) => ({ type: t.DOORDASH_MODE, payload: active });
