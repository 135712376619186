import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Menu, MenuItem } from '@material-ui/core';
import { hasPermissions } from 'utils/permissions';

const MenuNavItem = ({ permissions, disabled, item, itemClassName, hidden, isActive }) => {
	const history = useHistory();
	const [anchorEl, setAnchorEl] = useState(null);
	if (hidden) {
		return null;
	}

	const handleClick = event => {
		event.preventDefault();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = url => {
		setAnchorEl(null);

		if (typeof url === 'string') {
			history.push(url);
		}
	};

	const wrapperClass = classnames('nav-item menu', disabled && 'disabled'),
		classProps = classnames(
			'nav-link',
			itemClassName && itemClassName,
			isActive && 'active',
			item.upperCase === false && 'normal-text'
		),
		title = item.mobileTitle || item.title;

	return (
		<li className={wrapperClass}>
			<a
				role="button"
				className={classProps}
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				{title}
			</a>
			<Menu
				className={classnames(classProps, 'nav-menu')}
				id="simple-menu"
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				{item.items.map(link =>
					hasPermissions(permissions, link.permissions) ? (
						<MenuItem key={link.url} onClick={handleClose.bind(this, link.url)}>
							{link.title}
						</MenuItem>
					) : null
				)}
			</Menu>
		</li>
	);
};

MenuNavItem.defaultProps = {};
MenuNavItem.propTypes = {
	permissions: PropTypes.arrayOf(PropTypes.string),
	disabled: PropTypes.bool,
	itemClassName: PropTypes.string,
	// icon: PropTypes.string,
	hidden: PropTypes.bool,
	isActive: PropTypes.bool,
	item: PropTypes.shape({
		mobileTitle: PropTypes.string,
		isMenu: PropTypes.bool,
		title: PropTypes.string,
		upperCase: PropTypes.bool,
		mobileLeft: PropTypes.bool,
		mobileRight: PropTypes.bool,
		topNav: PropTypes.bool,
		items: PropTypes.arrayOf(
			PropTypes.shape({
				mobileTitle: PropTypes.string,
				title: PropTypes.string,
				url: PropTypes.string,
				fullUrl: PropTypes.bool,
				auth: PropTypes.bool,
				icon: PropTypes.string,
				permissions: PropTypes.arrayOf(PropTypes.string),
				upperCase: PropTypes.bool
			})
		)
	})
};

export default MenuNavItem;
