import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import MetaTags from './MetaTags';
import SmartBanner from 'react-smartbanner';
import Cookies from 'universal-cookie';
import IdleTimer from 'react-idle-timer';
import queryString from 'query-string';
import ScrollToTop from 'utils/scrollToTop';
import LazyHelpFAQ from 'components/ecosystems/HelpFAQ/lazy-container.js';
import {
	ProfileSetupModal,
	CompanySelectModal,
	DeleteConnectionsWarningModal,
	RecommendationsModal,
	PointsElegibilityModal
} from 'components/organisms';
import { LastLocationProvider } from 'react-router-last-location';
import { Loader } from 'components/atoms';
import classnames from 'classnames';
import { isIE, isFireFox, isEdge, isMobile, isSafari } from 'utils/browser';
import { Completion } from 'components/molecules';
import { PERMISSIONS } from 'utils/permissions';
import { Toaster } from 'react-hot-toast';
import {
	LazyAnonDashboardPage,
	LazyAutoEnrollAdminPage,
	LazyAutoEnrollUserPage,
	LazyCompanyDashboard,
	LazyDashboardPage,
	LazyDetailsPage,
	LazyEditPaymentPage,
	LazyEditPersonalPage,
	LazyEditPreferencesPage,
	LazyEditSecurity,
	LazyExpired,
	LazyFavoritesPage,
	LazyFooter,
	LazyForgottenPage,
	LazyHeader,
	LazyLandingPage,
	LazyLoginPage,
	LazyLogoutPage,
	LazyNoResponsePage,
	LazyNotFoundPage,
	LazyOrderPage,
	LazyOrderReceiptPage,
	LazyPermissionsRoute,
	LazyPrivateRoute,
	LazyProfilePage,
	LazyRecommendationsPage,
	LazyReferPage,
	LazyRegistrationPage,
	LazyResetPage,
	LazyResetPasswordPage,
	LazyResetRoute,
	LazyRewardErrorPage,
	LazyRewardsPage,
	LazyRewardViewPage,
	LazyRoster,
	LazySearchPage,
	LazyTermsPage,
	LazyTransactionsPage,
	LazyUnsubscribePage
} from 'components/ecosystems';
import { history } from 'utils/router';

class Layout extends Component {
	constructor(props) {
		super(props);
		this.idleTimer = null;
		this.state = {
			timedOut: false,
			banner: {
				title: this.props.translate('Banner.title'),
				author: this.props.translate('Banner.author'),
				price: {
					ios: this.props.translate('Banner.price.ios'),
					android: this.props.translate('Banner.price.android'),
					windows: this.props.translate('Banner.price.windows'),
					kindle: this.props.translate('Banner.price.kindle')
				},
				button: this.props.translate('Banner.button'),
				storeText: {
					ios: this.props.translate('Banner.storeText.ios'),
					android: this.props.translate('Banner.storeText.android'),
					windows: this.props.translate('Banner.storeText.windows'),
					kindle: this.props.translate('Banner.storeText.kindle')
				},
				icon: `${props.IMAGE_BASE_URL}/logos/Dinova-favicon.png`
			}
		};
	}

	cookies = new Cookies();

	componentDidMount = async () => {
		queryString.parse(window.location.search).applicationAuth && this.props.setAndroid(true);
		queryString.parse(window.location.search).visa && this.props.setMinimalLayout(true);
		queryString.parse(window.location.search).network === 'visaedge' && this.props.setMinimalLayout(true);
		this.cookies.get('visaedge') && this.props.setMinimalLayout(true);
	};

	componentDidUpdate() {
		this.idleTimer && this.idleTimer.reset();
	}

	onClick = () => {
		if (this.props.mobileNavOpen) {
			this.props.toggle();
		}
	};

	onActive = () => {
		this.setState({
			timedOut: false
		});
	};

	onIdle = () => {
		this.setState({
			timedOut: true
		});
	};

	reset = () => {
		this.idleTimer.reset();
	};

	redirect = (location = '/logout') => {
		this.setState({
			timedOut: false
		});
		return <Redirect to={`${location}/`} />;
	};

	render() {
		const {
			androidVersion,
			minimalLayout,
			isBusy,
			isDoD,
			isDoorDash,
			loaingMessage,
			translate,
			users
		} = this.props;

		const classProps = classnames(
			'app-wrapper',
			isIE() && 'ie',
			isFireFox() && 'ff',
			isEdge() && 'edge',
			isMobile() && 'mobile',
			isSafari() && 'safari'
		);

		return (
			<Router history={history}>
				<LastLocationProvider>
					<ScrollToTop>
						{/* update meta tags */}
						<MetaTags />
						{!androidVersion && !isDoorDash && !isDoD && !minimalLayout && (
							<SmartBanner {...this.state.banner} key={0} />
						)}
						<div onClick={this.onClick} className={classProps}>
							<div className="app-content">
								<Toaster
									toastOptions={{
										success: {
											style: {
												background: 'green',
												color: 'white'
											}
										},
										error: {
											style: {
												background: 'red',
												color: 'white'
											}
										}
									}}
								/>
								<RecommendationsModal />
								<PointsElegibilityModal />
								<DeleteConnectionsWarningModal />
								<CompanySelectModal />

								{this.state.timedOut && users.authenticated && this.redirect()}
								{isBusy && <Loader message={loaingMessage || 'Please wait'} />}
								{!androidVersion && <LazyHeader />}
								<ProfileSetupModal />
								<Switch>
									<Redirect from="/catering" to="/?tab=catering" />
									<Route
										exact
										path="/mydinova"
										component={users.authenticated ? LazyDashboardPage : LazyAnonDashboardPage}
									/>

									<Route
										exact
										path="/search"
										render={({ match, location, history }) => {
											const parsed = queryString.parse(location.search);
											if (parsed.network === 'mceasy') {
												const cookies = new Cookies();
												cookies.set('mceasy', true, { maxAge: 2147483647 });
												let query = new URLSearchParams(location.search);
												query.delete('network');
												history.replace({ search: query.toString() });
											}
											if (parsed.network === 'visaedge') {
												const cookies = new Cookies();
												cookies.set('visaedge', true, { maxAge: 2147483647 });
												let query = new URLSearchParams(location.search);
												query.delete('network');
												history.replace({ search: query.toString() });
											}

											return <LazySearchPage match={match} location={location} />;
										}}
									/>
									<Route
										exact
										path="/restaurants/:id/:restaurantName-:city-:state/"
										render={({ match, location, history }) => {
											return (
												<LazyDetailsPage match={match} location={location} history={history} />
											);
										}}
									/>
									<Route
										exact
										path="/restaurants/:id/:restaurantName-:city-:state/about_us"
										render={({ match, location, history }) => {
											return (
												<LazyDetailsPage match={match} location={location} history={history} />
											);
										}}
									/>
									<Route
										exact
										path="/restaurants/:id/:restaurantName-:city-:state/catering"
										render={({ match, location, history }) => {
											return (
												<LazyDetailsPage match={match} location={location} history={history} />
											);
										}}
									/>
									<Route
										path="/restaurants/:id/:restaurantName-:city-:state/private_dining"
										render={({ match, location, history }) => {
											return (
												<LazyDetailsPage match={match} location={location} history={history} />
											);
										}}
									/>
									<Route exact path="/unable-to-connect" component={LazyNoResponsePage} />
									<Route path="/login" component={LazyLoginPage} exact />
									<Route path="/logout" component={LazyLogoutPage} />
									<Route path="/forgotten" component={LazyForgottenPage} exact />
									<Route path="/users/register" component={LazyRegistrationPage} exact />
									<Route path="/users/connect" component={LazyRegistrationPage} exact />
									<Route path="/reset" component={LazyResetPage} exact />
									<LazyResetRoute path="/change" component={LazyResetPage} />
									<Route path="/helpfaq" exact component={LazyHelpFAQ} />
									<Route path={'/helpfaq/business'} component={LazyHelpFAQ} exact />
									<Route path={'/helpfaq/catering'} component={LazyHelpFAQ} exact />
									<Route path={'/helpfaq/rewards/azn'} component={LazyHelpFAQ} exact />
									<Route path={'/helpfaq/rewards'} component={LazyHelpFAQ} exact />
									<Route path={'/helpfaq/dod'} component={LazyHelpFAQ} exact />
									<Route path={'/helpfaq/doordash'} component={LazyHelpFAQ} exact />
									<Route path="/terms" component={LazyTermsPage} exact />
									<Route path="/resetpassword" component={LazyResetPasswordPage} exact />
									<Route path="/expired" component={LazyExpired} exact />
									<LazyPermissionsRoute
										permissions={[PERMISSIONS.ADMIN]}
										path="/admin/auto-enroll"
										component={LazyAutoEnrollAdminPage}
										exact
									/>
									<Route
										path="/users/register/auto-enroll"
										component={LazyAutoEnrollUserPage}
										exact
									/>
									<LazyPermissionsRoute
										permissions={[PERMISSIONS.ADMIN]}
										path="/admin/dashboard"
										component={LazyCompanyDashboard}
									/>
									<Route path="/users/register/complete" component={Completion} exact />
									<Route path="/refer" component={LazyReferPage} exact />

									<LazyPrivateRoute path="/favorites" component={LazyFavoritesPage} exact />

									<Route path="/unsubscribe" component={LazyUnsubscribePage} exact />
									<LazyPrivateRoute path="/refer/details" component={LazyReferPage} exact />
									<LazyPrivateRoute
										path="/recommendations"
										component={LazyRecommendationsPage}
										exact
									/>
									<Route path="/roster" component={LazyRoster} exact />
									<LazyPrivateRoute path="/rewards" component={LazyRewardsPage} exact />
									<LazyPrivateRoute path="/rewards/error" component={LazyRewardErrorPage} />
									<LazyPrivateRoute path="/rewards/order/:id" component={LazyOrderPage} />
									<LazyPrivateRoute path="/rewards/receipt" component={LazyOrderReceiptPage} />
									<LazyPrivateRoute path="/rewards/view/:id" component={LazyRewardViewPage} />
									<LazyPrivateRoute path="/history" component={LazyTransactionsPage} exact />
									<LazyPrivateRoute path="/profile" component={LazyProfilePage} exact />
									<LazyPrivateRoute path="/profile/personal" component={LazyEditPersonalPage} />
									<LazyPrivateRoute path="/profile/payment" component={LazyEditPaymentPage} />
									<LazyPrivateRoute path="/profile/preferences" component={LazyEditPreferencesPage} />
									<LazyPrivateRoute path="/profile/security" component={LazyEditSecurity} />
									<Route
										exact
										path="/"
										render={({ location }) => {
											// redirect to search page
											const parsed = queryString.parse(location.search);
											if (parsed.network === 'mceasy') {
												const cookies = new Cookies();
												cookies.set('mceasy', true, { maxAge: 2147483647 });
											}
											if (parsed.network === 'visaedge') {
												const cookies = new Cookies();
												cookies.set('visaedge', true, { maxAge: 2147483647 });
											}
											if (parsed.location || parsed.find || parsed.near || parsed.keywords) {
												return <Redirect to={`/search${location.search}`} />;
											}

											return <LazyLandingPage />;
										}}
									/>
									<Route component={LazyNotFoundPage} />
								</Switch>
							</div>
							{!androidVersion && <LazyFooter />}

							{this.props.users.user && (
								<IdleTimer
									key={'idle-timer'}
									ref={ref => {
										this.idleTimer = ref;
									}}
									element={document}
									onActive={this.onActive}
									onIdle={this.onIdle}
									timeout={1000 * 60 * Number.parseInt(translate('common.timeoutInMinutes'))}
									startOnLoad
									reset={this.reset}
								/>
							)}
						</div>
					</ScrollToTop>
				</LastLocationProvider>
			</Router>
		);
	}
}

Layout.propTypes = {
	androidVersion: PropTypes.bool,
	minimalLayout: PropTypes.bool,
	isBusy: PropTypes.bool.isRequired,
	isDoD: PropTypes.bool,
	isDoorDash: PropTypes.bool.isRequired,
	loaingMessage: PropTypes.string,
	mobileNavOpen: PropTypes.bool.isRequired,
	users: PropTypes.object,
	toggle: PropTypes.func,
	setAndroid: PropTypes.func,
	setMinimalLayout: PropTypes.func,
	translate: PropTypes.func,
	config: PropTypes.object,
	location: PropTypes.object,
	IMAGE_BASE_URL: PropTypes.string,
	hasGeoLocation: PropTypes.bool.isRequired,
	geoLocationLoading: PropTypes.bool.isRequired,
	geoLocatoinServicesDenied: PropTypes.bool.isRequired
};

export default Layout;
