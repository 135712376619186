import CompanySelect from './ui-component';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { getTranslate } from 'react-localize-redux';
import debounce from 'utils/debounce';
import { fetchCompanies, selectCompany /*setCompanySearch*/ } from 'actions/company_select';
import {
	setCompany,
	setCompanyName,
	setCompanyCode,
	setPreferredCCType,
	setPreferredConnectionID,
	setPreferredEmployeeIDType
} from 'actions/general';
import { assignCard } from 'actions/registration';
import { hasProp } from 'utils/object';
import { getProfile } from 'actions/users';

const mapStateToProps = state => {
	return {
		initialValues: {
			name: hasProp(state.general, 'companyName')
				? state.general.companyName
				: hasProp(state.users, 'user.company.name')
				? state.users.user.company.name
				: '',
			code: ''
		},
		usersCompany: hasProp(state, 'users.user.company') ? state.users.user.company : false,
		companyName: state.companySelect.search,
		suggestions: state.companySelect.suggestions || [],
		isLoading: state.companySelect.isLoading,
		hasError: hasProp(state, 'form.companySelect.values.name')
			? state.form.companySelect.values.name.length === 0
			: true,
		hasSelection: Object.keys(state.companySelect.selected).length > 0,
		hasValue: hasProp(state, 'companySelect.search') ? state.companySelect.search.length > 0 : false,
		translate: getTranslate(state.locale)
	};
};

const mapDispatchToProps = dispatch => ({
	selectCompany: company => dispatch(selectCompany(company)), // new Redux store comapnySelect,
	setCompany: company => {
		const { name, code } = company,
			showLoading = false;

		dispatch(setCompany(company)); // old Redux store general
		dispatch(setCompanyCode(code, showLoading)); // old Redux store general
		dispatch(setCompanyName(name)); // old Redux store general
		dispatch(selectCompany(company)); // new Redux store comapnySelect
		if (company.supportedConnections && company.supportedConnections.length === 1) {
			dispatch(setPreferredCCType(company.supportedConnections[0].cardType));
			dispatch(setPreferredConnectionID(company.supportedConnections[0].id));
			dispatch(assignCard(company.supportedConnections[0]));
			switch (company.supportedConnections[0].type) {
				case 2: {
					dispatch(setPreferredEmployeeIDType('empID'));
					break;
				}
				case 3: {
					dispatch(setPreferredEmployeeIDType('email'));
					break;
				}
				default: {
					dispatch(setPreferredEmployeeIDType('hash'));
					break;
				}
			}
		}
	},
	fetchCompanies: companyName => debounce(fetchCompanies(dispatch, companyName), 300),
	asyncFetchCompanies: async companyName => await fetchCompanies(dispatch, companyName),
	getProfile: () => dispatch(getProfile())
});

const validate = values => {
	const errors = {};
	if (!values.name) {
		errors.name = 'Company name is required';
	}
	return errors;
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	reduxForm({
		form: 'companySelect',
		onSubmit: (/*values*/) => {},
		validate
	})(CompanySelect)
);
