console.originalError = console.error;
console.error = function(...params) {
	const message = params.shift();
	const consoleError = message.startsWith('Warning: ') ? console.warn : console.originalError;
	consoleError(message, ...params);
};

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import Cookies from 'universal-cookie';
import { Layout } from 'components/ecosystems';
import { STORE } from 'components/environment/App/redux_store.js';
import { AirbrakeErrorBoundary, Loader } from 'components/atoms';
import isNodeEnv from 'utils/isNodeEnv';
import { loadConfig } from 'actions/config';
import { initialize } from 'react-localize-redux';
import { startFetchStrings } from 'actions/strings';
import { dodMode, doordashMode } from 'actions/general';
import queryString from 'query-string';

class App extends Component {
	cookies = new Cookies();
	constructor(props) {
		super(props);
		this.languages = ['en'];
		this.cookies = new Cookies();
		this.state = {
			isInitialized: false
		};

		if (isNodeEnv('development')) {
			if (window.Cypress) {
				window['store'] = STORE;
			}
		}
	}

	componentDidMount = () => {
		const me = this;

		// init redux store with config vars and langauges
		STORE.dispatch([loadConfig(), initialize(this.languages)]);
		// fetch langauge translations
		STORE.dispatch(startFetchStrings()).then(() => {
			me.setState({ isInitialized: true });
		});

		// Trigger setDoDMode
		if (this.isDoD()) {
			this.setSpecialMode('dod');
		}

		if (this.isDoorDash() || this.isDoorDashCookie()) {
			this.setSpecialMode('doordash');
		}
	};

	isDoD = () =>
		queryString.parse(window.location.search).dod &&
		queryString.parse(window.location.search).dod.startsWith('true');

	isDoorDash = () =>
		queryString.parse(window.location.search).doordash &&
		queryString.parse(window.location.search).doordash.startsWith('true');

	isDoorDashCookie = () => this.cookies.get('doordash');

	setSpecialMode = mode => {
		let { origin, pathname, search, hash } = window.location;
		if (pathname === '/') {
			pathname = '';
		}
		search = queryString.parseUrl(search);
		if (mode === 'dod') {
			// Dispatch if dodMode based on presence of dod=true in the URL
			STORE.dispatch(dodMode());
			// Clean up the dod=true from the URL
			delete search.query.dod;
		}
		if (mode === 'doordash') {
			this.cookies.set('doordash', true, { maxAge: 2147483647 });
			STORE.dispatch(doordashMode());
			delete search.query.doordash;
		}

		let URL = queryString.stringifyUrl({
			url: `${origin}${pathname}`,
			query: search.query,
			fragmentationIdentifier: hash
		});
		// Set the cleaned up URL without refreshing the page - we don't want to lose our dodMode state.
		window.history.replaceState({}, document.title, URL);
	};

	render() {
		// console.log('Initialized?', this.state.isInitialized);
		return (
			<AirbrakeErrorBoundary>
				{/* eslint-disable-next-line no-extra-boolean-cast */}
				<Provider store={STORE}>{this.state.isInitialized ? <Layout /> : <Loader />}</Provider>
			</AirbrakeErrorBoundary>
		);
	}
}

App.defaultProps = {};

App.propTypes = {};

export default App;
