import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';
import Moment from 'react-moment';
import CurrencyFormat from 'react-currency-format';
import { Recommendations } from 'components/organisms';
import { LoadingBase } from 'components/atoms';

const TransactionsList = props => {
	const { className, isLoading, transactions, translate } = props;
	const classProps = classNames('transactions_list', 'table-responsive', className);

	const isLessThanYearOld = transaction => {
		const now = new Date(),
			transactionDate = new Date(transaction.date),
			timeSince = now.getTime() - transactionDate.getTime(), // milliseconds
			daysSince = timeSince / (1000 * 3600 * 24);

		return daysSince < 366;
	};

	return (
		<div className={classProps}>
			<Row className="border-bottom">
				<Col md="2" sm="3">
					{translate('Transactions.table.date')}
				</Col>
				<Col md="2" className="d-sm-none d-md-block">
					{translate('Transactions.table.type')}
				</Col>
				<Col md="4" sm="6" className="d-sm-none d-md-block">
					{translate('Transactions.table.desc')}
				</Col>
				<Col sm="6" className="d-sm-block d-md-none d-lg-none">
					{translate('Transactions.table.details')}
				</Col>
				<Col md="2" className="d-sm-none d-md-block">
					{translate('Transactions.table.spend')}
				</Col>
				<Col md="2" sm="3">
					{translate('Transactions.table.points')}
				</Col>
			</Row>
			{isLoading && <LoadingBase className="pt-5" message={translate('Transactions.loadingMsg')} />}
			{!isLoading && transactions.length === 0 && (
				<Row>
					<Col style={{ minHeight: '87px' }} className="pt-5 darker bigger">
						{translate('Transactions.no-transactions')}
					</Col>
				</Row>
			)}
			{!isLoading &&
				transactions.map((transaction, index) => {
					const isRecommendableTransaction =
						(transaction.type.id === 1 || transaction.type.id === 11) && transaction.venue.id !== undefined;

					return (
						<Row key={index} className={'border-bottom ' + (index % 2 !== 0 ? 'alt' : '')}>
							<Col md="2" sm="3" className="centerContainer">
								<div className="Di_DateBox">
									<span className="Di_WeekDay">
										<Moment parse="YYYY-M-DD" format="dddd">
											{transaction.date}
										</Moment>
									</span>
									<span className="Di_MonthDay">
										<Moment parse="YYYY-M-DD" format="DD">
											{transaction.date}
										</Moment>
									</span>
								</div>
							</Col>
							<Col md="2" className="d-sm-none d-md-flex darker centerContainer centerText">
								{translate(`Transactions.types.${transaction.type.id}`)}
							</Col>
							<Col md="4" sm="6" className="d-sm-none d-md-flex centerContainer centerText">
								{transaction.venue.name}
							</Col>
							<Col sm="6" className="d-sm-flex d-md-none d-lg-none centerContainer centerText">
								<div className="d-sm-block">
									<div className="details_1">
										{translate(`Transactions.types.${transaction.type.id}`)}
									</div>
									<div className="details_2">
										{transaction.venue.name}
										{transaction.spend.amount && <span>&nbsp;|&nbsp;</span>}
										{transaction.spend.amount && (
											<CurrencyFormat
												value={transaction.spend.amount.replace(/\$/g, '')}
												displayType={'text'}
												thousandSeparator={true}
												prefix={'$'}
												isNumericString={true}
											/>
										)}
									</div>
								</div>
							</Col>
							<Col md="2" className="d-sm-none d-md-flex darker centerContainer centerText">
								{transaction.spend.amount && (
									<CurrencyFormat
										value={transaction.spend.amount.replace(/\$/g, '')}
										displayType={'text'}
										thousandSeparator={true}
										prefix={'$'}
										isNumericString={true}
									/>
								)}
							</Col>
							<Col md="2" sm="3" className="centerContainer centerText">
								{transaction.points === '0' && <strong>{'--'}</strong>}
								{transaction.points > 0 && <strong>{transaction.points}</strong>}
								{transaction.points < 0 && <strong>{`(${transaction.points})`}</strong>}
							</Col>
							<Col sm="12">
								{isLessThanYearOld(transaction) &&
									!transaction.venue.recommended &&
									isRecommendableTransaction && (
										<Recommendations
											incentiveKey={'Recommendations.historyIncentive'}
											fetchOnButtonClick
											restaurantId={transaction.venue.id}
											className="transactions"
										/>
									)}
							</Col>
						</Row>
					);
				})}
		</div>
	);
};

TransactionsList.propTypes = {
	className: PropTypes.string,
	transactions: PropTypes.array,
	translate: PropTypes.func,
	isLoading: PropTypes.bool.isRequired
};

export default TransactionsList;
