/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import PropTypes from 'prop-types';
import { FilterGroupItem } from 'components/molecules';

// import "./style.scss";

/**
 * [SearchPageQuickFilters description]
 * @extends Component
 */
const SearchPageQuickFilters = ({ addFilter, filters, removeFilter }) => {
	const toggleFilter = async filter => {
		if (filter.forEach) {
			filter.forEach(async f => {
				if (f) {
					await toggleFilter(f);
				}
			});
		} else {
			if (filter.selected === true) {
				await removeFilter(filter);
			} else if (filter.selected === false) {
				await addFilter(filter);
			}
		}
	};

	return (
		<div className="quick-filters">
			<ul className="flex mb-0">
				{Object.entries(filters).reduce((accum, [key, value]) => {
					if (
						key === 'activeCount' ||
						key === 'filterType' ||
						key === 'filterFunc' ||
						key === 'resultsCount'
					) {
						return accum;
					} else {
						let render;
						if (value.forEach) {
							render = value.filter(v => v === false).length === 0;
						} else {
							render = value ? true : false;
						}

						return render
							? [
									...accum,
									<FilterGroupItem key={key} onClick={toggleFilter} filter={value} filterName={key} />
							  ]
							: accum;
					}
				}, [])}
			</ul>
		</div>
	);
};

SearchPageQuickFilters.defaultProps = {};

/**
 * [propTypes description]
 * @interface SearchPageQuickFiltersProps
 *
 * @example {}
 */
SearchPageQuickFilters.propTypes = {
	addFilter: PropTypes.func.isRequired,
	filters: PropTypes.object,
	removeFilter: PropTypes.func.isRequired
};

export default SearchPageQuickFilters;
