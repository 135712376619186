import Layout from './ui-component';
import { connect } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { toggleMobileNav, setAndroid, setMinimalLayout } from 'actions/viewport';

const mapStateToProps = state => ({
	androidVersion: state.viewport.androidVersion,
	minimalLayout: state.viewport.minimalLayout,
	config: state.config,
	currentLanguage: getActiveLanguage(state.locale).code,
	geoLocationLoading: state.geoLocation.isLoading,
	geoLocatoinServicesDenied: state.geoLocation.wasDenied,
	hasGeoLocation: state.geoLocation.hasGeoLocation,
	IMAGE_BASE_URL: state.config.imageBaseUrl,
	isBusy: state.loader.isBusy,
	isDoD: state.general.dodMode,
	isDoorDash: state.general.doordashMode,
	loaingMessage: state.loader.message,
	mobileNavOpen: state.viewport.mobileNavOpen,
	translate: getTranslate(state.locale),
	users: state.users
});

const mapDispatchToProps = dispatch => ({
	toggle: () => dispatch(toggleMobileNav()),
	setAndroid: () => dispatch(setAndroid()),
	setMinimalLayout: () => dispatch(setMinimalLayout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
