/* eslint-disable no-case-declarations */
import * as t from '../actions/search_page/types';
import { isUndefined } from 'lodash';

const getInitialShowListState = () =>
	isUndefined(window.localStorage.getItem('search-page-show-list'))
		? true
		: window.localStorage.getItem('search-page-show-list') === 'false'
		? false
		: true;

/**
 * Redux_RewardState
 * @interface Redux_RewardState
 * @property {array}  [availableRewards=[]]
 * @property {object} [selectedReward={}]
 * @property {object} [redeemedReward=undefined]
 */
const searchPageDefaultState = {
	showFilter: window.innerWidth > 768,
	showList: getInitialShowListState(),
	activePin: null,
	mapCardDisplayed: false,
	searchErrType: '',
	rehydrateFromSaveState: false,
	hydrateState: null,
	lastSearch: '/',
	isLoading: false
};

export default (state = searchPageDefaultState, action) => {
	switch (action.type) {
		case t.SEARCH_RESTAURANTS_START: {
			return {
				...state,
				isLoading: true
			};
		}
		case t.SEARCH_RESTAURANTS_SUCCESS: {
			return {
				...state,
				isLoading: false
			};
		}
		case t.SEARCH_RESTAURANTS_ERROR: {
			return {
				...state,
				isLoading: false
			};
		}
		case t.TOGGLE_SHOW_FILTER: {
			let showFilter = action.payload;
			if (isUndefined(showFilter)) {
				showFilter = !state.showFilter;
			}
			return {
				...state,
				showFilter
			};
		}

		case t.TOGGLE_SHOW_LIST: {
			let showList = action.payload;
			if (isUndefined(showList)) {
				showList = !state.showList;
			}
			return {
				...state,
				showList
			};
		}

		case t.SET_ACTIVE_PIN: {
			return {
				...state,
				activePin: action.payload
			};
		}

		case t.SET_MAP_CARD_DISPLAYED: {
			return {
				...state,
				mapCardDisplayed: action.payload
			};
		}

		case t.SET_SEARCH_PAGE_ERROR_TYPE: {
			return {
				...state,
				searchErrType: action.payload
			};
		}

		case t.SAVE_SEARCH_PAGE_STATE: {
			return {
				...state,
				rehydrateFromSaveState: true,
				hydrateState: {
					...state
				}
			};
		}

		case t.HYDRATE_FROM_SEARCH_PAGE_SAVE_STATE: {
			return {
				...state.hydrateState,
				rehydrateFromSaveState: false,
				hydrateState: null
			};
		}

		case t.SET_LAST_BUSINESS_SEARCH: {
			return {
				...state,
				lastSearch: action.payload
			};
		}

		default:
			return state;
	}
};
