import * as t from './types';
import axios from 'axios';
import { clearUserRequest } from 'actions/requests/actions';
import { getProfile, login } from 'actions/users';
import { basePath } from 'actions/utils';

/* eslint-disable no-unused-vars */

const checkAutoEnrollTokenStart = {
	type: t.CHECK_AUTO_ENROLL_TOKEN_START
};

const checkAutoEnrollTokenSuccess = payload => ({
	type: t.CHECK_AUTO_ENROLL_TOKEN_SUCCESS,
	payload
});

const enrollStart = {
	type: t.CREATE_AUTO_ENROLL_USER_START
};

const enrollSuccess = {
	type: t.CREATE_AUTO_ENROLL_USER_SUCCESS
};

const generateTokenStart = {
	type: t.GENERATE_NEW_AUTO_ENROLL_TOKEN_START
};

const generateTokenSuccess = {
	type: t.GENERATE_NEW_AUTO_ENROLL_TOKEN_SUCCESS
};

const generateTokenError = payload => ({
	type: t.GENERATE_NEW_AUTO_ENROLL_TOKEN_ERROR,
	payload
});

export const createAutoEnrollUser = ({ password, emailsOpt, offersOpt, token, terms }) => async dispatch => {
	let user = {
		password: password,
		autoEnrollToken: decodeURIComponent(token),
		termsConditionsAccepted: terms,
		preferences: {
			notification: {
				monthlySummary: emailsOpt && emailsOpt.value === 'yes' ? 'email' : 'none',
				specialOffers: offersOpt && offersOpt.value === 'yes' ? 'email' : 'none'
			}
		}
	};

	dispatch(enrollStart);

	dispatch(
		login(user, response => {
			dispatch(getProfile(false));
			dispatch(enrollSuccess);
			dispatch(clearUserRequest);
		})
	);
};

export const generateNewAutoEnrollToken = token => dispatch => {
	dispatch(generateTokenStart);

	return axios
		.get(`${basePath()}/tokens?token=${decodeURIComponent(token)}&regenerate=true`)
		.then(res => {
			dispatch(generateTokenSuccess);
		})
		.catch(err => {
			console.dir(err);
			switch (err.response.status) {
				case 404: {
					// used
					dispatch(generateTokenError(err.response.data.response.msg));
					return;
				}
				case 400: {
					// could not regen
					dispatch(generateTokenError(err.response.data.response.msg));
					return;
				}
				case 408: {
					// regenerated
					dispatch(generateTokenSuccess);
					return;
				}
				default: {
					dispatch(generateTokenError(err.response.data.response.msg));
					return;
				}
			}
		});
};

export const checkAutoEnrollTokenStatus = token => dispatch => {
	dispatch(checkAutoEnrollTokenStart);

	const statusMap = {
		200: 'valid',
		400: 'invalid',
		404: 'used',
		408: 'expired'
	};
	return axios
		.get(`${basePath()}/tokens?token=${decodeURIComponent(token)}`)
		.then(res => {
			let status = { token, tokenStatus: statusMap[res.status] || 'invalid' };
			dispatch(checkAutoEnrollTokenSuccess(status));
		})
		.catch(err => {
			console.dir(err);
			let status = { token, tokenStatus: statusMap[err.response.status] || 'invalid' };
			dispatch(checkAutoEnrollTokenSuccess(status));
		});
};
