import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Text } from 'components/atoms';

class MapCard extends Component {
	compileSpecialties = () => {
		const { data } = this.props;
		if (!data.menuSpecialties || data.menuSpecialties.length === 0) {
			return '';
		}
		let specialties = [];
		let i = 0;

		while (i < 5 && data.menuSpecialties[i]) {
			specialties.push(data.menuSpecialties[i].name);
			i++;
		}

		specialties = specialties.join(', ');

		return specialties;
	};

	onClick = e => {
		e.preventDefault();
		this.props.onTransition({
			id: this.props.data.id,
			name: this.props.data.name,
			city: this.props.data.city,
			state: this.props.data.state,
			distance: this.props.data.distance
		});
	};

	render() {
		const { className, data, isActive, translate } = this.props;
		const classProps = classNames('map-card', className);

		let cardWidth = window.innerWidth - 30;
		if (cardWidth > 364) {
			cardWidth = 364;
		}

		let imgWidth = cardWidth - 16,
			cardStyle = { width: cardWidth },
			imgStyle = { width: imgWidth };

		const specialties = this.compileSpecialties();

		return (
			<a
				className={classProps}
				style={cardStyle}
				onClick={this.onClick}
				ref={this.props.refSetter}
				data-cy={isActive ? 'map-card-active' : 'map-card'}
			>
				<div className="img-wrapper">
					<img className="img" style={imgStyle} src={data.imgBanner} alt={`${data.name} food`} />
					{data.bannerText && <Text size="sm">{data.bannerText}</Text>}
				</div>
				<div className="info">
					<div className="restaurant-info">
						<span className="name" data-cy={isActive ? 'map-card-title-active' : 'map-card-title'}>
							{data.name}
						</span>
						<span className="cuisine">
							{data.cuisine}
							{specialties ? `, ${specialties}` : null}
						</span>
					</div>
					<span className="distance">
						{parseFloat(data.distance.span).toFixed(1) + ' '}
						{translate(`Units.${data.distance.units}`)}
					</span>
				</div>
			</a>
		);
	}
}

MapCard.defaultProps = {
	className: ''
};

MapCard.propTypes = {
	className: PropTypes.string,
	isActive: PropTypes.bool,
	data: PropTypes.object.isRequired,
	onTransition: PropTypes.func.isRequired,
	refSetter: PropTypes.func,
	translate: PropTypes.func.isRequired
};

export default MapCard;
