import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Body, DashboardHeader, PageHeader } from 'components/molecules';
import { PageHeaderLinks } from 'components/atoms';
import { history } from 'utils/router';
import { WithHelp } from 'components/molecules';

// import './style.scss';

const DashboardPageWrapper = ({
	bannerImg,
	bannerUrl,
	bodyMaxWidth,
	children,
	help,
	isLV,
	pageTitleKey,
	translate,
	cobranded,
	useCrop,
	width,
	height
}) => {
	const onClick_GoBack = e => {
		e.preventDefault();
		history.goBack();
	};

	const headerLinks = (
		<PageHeaderLinks position="left">
			{!isLV && (
				<Link to="/" className="link-back" onClick={onClick_GoBack}>
					{translate('common.backBtn')}
				</Link>
			)}
		</PageHeaderLinks>
	);

	const helpComponent = help ? (
		<WithHelp id={`page-header-help`} help={help} showHelpText={false} className="floating" />
	) : null;

	return (
		<React.Fragment>
			<DashboardHeader />
			<PageHeader
				bannerImg={bannerImg}
				bannerUrl={bannerUrl}
				cobranded={cobranded}
				isLV={isLV}
				useCrop={useCrop}
				width={width}
				height={height}
			/>
			<Body
				maxWidth={bodyMaxWidth}
				leftPageHeaderLinks={headerLinks}
				title={translate(pageTitleKey)}
				help={helpComponent}
				bodyContent={children}
			/>
		</React.Fragment>
	);
};

DashboardPageWrapper.defaultProps = {
	cobranded: false,
	useCrop: true
};

DashboardPageWrapper.propTypes = {
	bannerImg: PropTypes.string,
	bannerUrl: PropTypes.string,
	bodyMaxWidth: PropTypes.number,
	children: PropTypes.node,
	help: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	isLV: PropTypes.bool.isRequired,
	pageTitleKey: PropTypes.string.isRequired,
	translate: PropTypes.func.isRequired,
	cobranded: PropTypes.bool,
	useCrop: PropTypes.bool,
	width: PropTypes.string,
	height: PropTypes.string
};

export default DashboardPageWrapper;
