import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Title = ({ align, bold, children, className, dataCy, inline, size, transform }) => {
	// prepare classes
	const inlineClass = inline && 'd-inline';
	const alignClass = align && `text-${align}`;
	const transformClass = transform !== 'none' ? `text-${transform}` : 'text-normal';
	const boldType = bold === 'false' ? false : true;
	const classProps = classNames(
		'title',
		inlineClass,
		alignClass,
		transformClass,
		className,
		!boldType && 'normal-weight'
	);

	const Heading = size;

	return (
		<Heading data-cy={dataCy} className={classProps}>
			{children}
		</Heading>
	);
};

Title.defaultProps = {
	bold: true,
	children: '',
	className: '',
	inline: false,
	size: 'h1',
	transform: 'uppercase'
};

Title.propTypes = {
	align: PropTypes.oneOf(['left', 'center', 'right']),
	bold: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
	className: PropTypes.string,
	dataCy: PropTypes.string,
	inline: PropTypes.bool,
	size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
	transform: PropTypes.oneOf(['none', 'capitalize', 'lowercase', 'uppercase'])
};

export default Title;
