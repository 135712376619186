import * as t from 'actions/dine_assist_form_page/types';

/**
 * Redux_PointsElegibilityModalModalState
 * @interface Redux_PointsElegibilityModalModalState
 * @property {boolean} [show=false]
 */
const dineAssistFormPageInitialState = {
	visited: false
};

export default (state = dineAssistFormPageInitialState, action) => {
	switch (action.type) {
		case t.FORM_VISITED: {
			return { visited: true };
		}

		default:
			return state;
	}
};
