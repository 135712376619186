import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import AddressAndDirections from './ui-component.js';
import { hasProp } from 'utils/object';

const mapStateToProps = state => {
	let addresses = hasProp(state, 'details.restaurant.address') ? state.details.restaurant.address : false;
	if (addresses) {
		addresses = [
			...addresses.street,
			`${addresses.city}, ${hasProp(addresses, 'state.abbreviation') ? addresses.state.abbreviation : ''} ${
				addresses.postalCode
			}`
		];
	} else {
		addresses = [];
	}

	return {
		addresses,
		API_KEY: state.config.apiKey,
		pinLocation: hasProp(state, 'details.restaurant.location')
			? state.details.restaurant.location
			: { lat: 0, lon: 0 },
		title: hasProp(state, 'details.restaurant.name') ? state.details.restaurant.name : '',
		translate: getTranslate(state.locale)
	};
};

export default withRouter(connect(mapStateToProps)(AddressAndDirections));
