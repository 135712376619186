import RegistrationForm from './ui-component';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { getTranslate } from 'react-localize-redux';
import withSizes from 'react-sizes';
import { isSV } from 'utils/sizes';
import { setModal, toggleModal } from 'actions/modal';
import { hasProp } from 'utils/object';
import { register } from 'actions/users';
import { withRouter } from 'react-router-dom';
import { toggleShowPointsElegibilityModal } from 'actions/points_elegibility_modal';
import { default as initialValues } from './initialFormState';
import { default as warn } from './formWarnings';

const mapStateToProps = state => {
	return {
		companySelectForm: hasProp(state.form, 'companySelect') ? state.form.companySelect : null,
		error: state.loader.error,
		initialValues: initialValues(),
		isDoD: state.general.dodMode,
		modal: state.modal,
		warn,
		translate: getTranslate(state.locale),
		general: state.general
	};
};

const mapDispatchToProps = dispatch => ({
	register: (user, callback) => dispatch(register(user, callback)),
	setModal: modal => dispatch(setModal(modal)),
	toggleModal: toggle => dispatch(toggleModal(toggle)),
	toggleShowPointsElegibilityModal: show => dispatch(toggleShowPointsElegibilityModal(show))
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withSizes(mapSizesToProps)(
	withRouter(connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'register' })(RegistrationForm)))
);
