import PointsElegibilityModal from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { toggleShowPointsElegibilityModal } from 'actions/points_elegibility_modal';

const mapStateToProps = state => ({
	...state.pointsElegibilityModal,
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	onClose: () => dispatch(toggleShowPointsElegibilityModal(false))
});

export default connect(mapStateToProps, mapDispatchToProps)(PointsElegibilityModal);
