import CalendarDropdown from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import withSizes from 'react-sizes';
import { isLV, isSV, isMV } from 'utils/sizes';
import { setDate } from 'actions/catering';

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	cateringDate: state.catering.date
});

const mapDispatchToProps = dispatch => ({
	setDate: date => dispatch(setDate(date))
});

const mapSizesToProps = sizes => ({
	isLV: isLV(sizes),
	isSV: isSV(sizes),
	isMV: isMV(sizes)
});

export default withSizes(mapSizesToProps)(connect(mapStateToProps, mapDispatchToProps)(CalendarDropdown));
