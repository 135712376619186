import React from 'react';
import PropTypes from 'prop-types';

// import './style.scss';

const TableHeader = ({ headers }) => (
	<tr>
		{headers.map((h, i) => (
			<th data-cy={`header-${h.title}`} key={`${h.title}-${i}`}>
				{h.title}
			</th>
		))}
	</tr>
);
TableHeader.propTypes = {
	headers: PropTypes.array
};

const TableRows = ({ rows }) =>
	rows.map((r, i) => (
		<tr key={`row-${i}`}>
			{r.values.map((v, ib) => {
				return (
					<td data-cy={`data-${i}-${ib}`} key={`${v}-${i}-${ib}`}>
						{v}
					</td>
				);
			})}
		</tr>
	));
TableRows.propTypes = {
	rows: PropTypes.array
};

const Table = ({ dataCy, headers, rows }) => {
	return (
		<table data-cy={`${dataCy ? `${dataCy}-` : ''}table`}>
			<thead>
				<TableHeader headers={headers} />
			</thead>

			<tbody>
				<TableRows rows={rows} />
			</tbody>
		</table>
	);
};

Table.defaultProps = {};

Table.propTypes = {
	dataCy: PropTypes.string,
	headers: PropTypes.array,
	rows: PropTypes.array
};

export default Table;
