export const REQUEST_NAV = 'REQUEST_NAV'; // Note: is this used?
export const RESET_STATE = 'RESET_STATE';
export const SET_CAMPAIGN_ID = 'SET_CAMPAIGN_ID';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_COMPANY_CODE = 'SET_COMPANY_CODE';
export const SET_COMPANY_NAME = 'SET_COMPANY_NAME';
export const SET_PREFERRED_CC_TYPE = 'SET_PREFERRED_CC_TYPE';
export const SET_PREFERRED_CONNECTION_ID = 'SET_PREFERRED_CONNECTION_ID';
export const SET_PREFERRED_EMPID_TYPE = 'SET_PREFERRED_EMPID_TYPE';
export const CLEAR_CONNECTION_TYPE = 'CLEAR_CONNECTION_TYPE';
export const DOD_MODE = 'DOD_MODE';
export const DOORDASH_MODE = 'DOORDASH_MODE';
export const SHOW_TOAST = 'SHOW_TOAST';
