import RecommendationCountMessage from './ui-component';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { hasProp } from 'utils/object';

const mapStateToProps = state => {
	let didRecommendFromState = false;
	if (
		hasProp(state, 'userRecommendations.restaurantIdsWithRecs') &&
		state.userRecommendations.restaurantIdsWithRecs.length > 0
	) {
		let restaurantId = state.details.restaurant.id;
		if (state.userRecommendations.restaurantIdsWithRecs.indexOf(restaurantId) > -1) {
			didRecommendFromState = true;
		}
	}

	let didRecommendFromBackendRestaurant = false;
	if (
		hasProp(state, 'details.restaurant.user.recommendation') &&
		state.details.restaurant.user.recommendation.length > 0
	) {
		didRecommendFromBackendRestaurant = true;
	}

	return {
		didRecommendFromState,
		didRecommendFromBackendRestaurant,
		didRecommend: false,
		translate: getTranslate(state.locale)
	};
};

export default connect(mapStateToProps)(RecommendationCountMessage);
