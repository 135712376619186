import HeaderLogo from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => {
	return {
		config: state.config,
		translate: getTranslate(state.locale)
	};
};

export default connect(mapStateToProps)(HeaderLogo);
