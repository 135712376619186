import React from 'react';
import PropTypes from 'prop-types';
import { Container, ModalBody, ModalFooter, Row, Col, ModalHeader } from 'reactstrap';
import { Button } from 'reactstrap';
import { Text } from 'components/atoms';

const CodePage = ({ error, phoneNumber, codeField, onTertiaryClick, handleBack }) => {
	return (
		<div>
			<ModalHeader>Enter your authentication code</ModalHeader>
			<ModalBody>
				<div>To make sure your myDinova is secure, we need to verify your idenity.</div>
				<div>
					Enter the verification code we texted to (***)***-
					{phoneNumber ? phoneNumber.substr(phoneNumber.length - 4, 4) : `0000`}
				</div>
				<Row>{codeField}</Row>
				{error && <Text className="error">{error}</Text>}
			</ModalBody>
			<ModalFooter>
				<Container>
					<Row>
						<Col sm="12" lg="12">
							<Button
								block
								className="z-depth-0"
								onClick={onTertiaryClick}
								color="primary"
								id="modal-primary-button"
							>
								<div className="d-inline-block">Verify</div>
							</Button>
						</Col>
					</Row>
					{phoneNumber && !phoneNumber.includes('*') && (
						<Row>
							<Col sm="12" lg="12">
								Didn&apos;t receive a code? Click{' '}
								<a href="#" onClick={handleBack}>
									here
								</a>{' '}
								to try again.
							</Col>
						</Row>
					)}
				</Container>
			</ModalFooter>
		</div>
	);
};

CodePage.defaultProps = {};
CodePage.propTypes = {
	phoneNumber: PropTypes.string,
	codeField: PropTypes.object,
	onTertiaryClick: PropTypes.func,
	handleBack: PropTypes.func,
	error: PropTypes.oneOf([false, PropTypes.oneOfType[PropTypes.string]])
};

export default CodePage;
