import React from 'react';
import styles from './style.module.scss';
import PropTypes from 'prop-types';

const ProfileSetupProgressBar = ({ completedStepsCount, totalSteps }) => {
	const completedPercent = (completedStepsCount / totalSteps) * 100;

	return (
		<div className={styles.progressBar}>
			<div className={styles.completedPercent} style={{ width: `${completedPercent}%` }} />
		</div>
	);
};

ProfileSetupProgressBar.propTypes = {
	completedStepsCount: PropTypes.number,
	totalSteps: PropTypes.number
};

export default ProfileSetupProgressBar;
