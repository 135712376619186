import StepTemplate from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import { goToStep } from 'actions/profile_setup';

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	goToGreeting: () => dispatch(goToStep(ProfileSetupSteps.GREETING))
});

export default connect(mapStateToProps, mapDispatchToProps)(StepTemplate);
