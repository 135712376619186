import RestaurantHours from './data-wrapper';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import withSizes from 'react-sizes';
import { isLV } from 'utils/sizes';
import { hasProp } from 'utils/object';

const mapStateToProps = state => ({
	googlePlaceId: hasProp(state.details, 'restaurant.googlePlacesId') ? state.details.restaurant.googlePlacesId : null,
	translate: getTranslate(state.locale)
});

const mapSizesToProps = sizes => ({
	isLV: isLV(sizes)
});

export default connect(mapStateToProps)(withSizes(mapSizesToProps)(RestaurantHours));
