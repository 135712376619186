import COVIDRestaurantFeedback from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { fetchRecommendationsOptions, toggleShowModal } from 'actions/recommendations_modal';
import { hasProp } from 'utils/object';

const mapStateToProps = state => ({
	isAuthed: state.users.authenticated.length > 0,
	fetchOnButtonClick: !hasProp(state, 'recOptions.positive') || !hasProp(state, 'recOptions.negative'),
	restaurantId: hasProp(state.details, 'restaurant.id') ? state.details.restaurant.id : '',
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	fetchRecommendationsOptions: async restaurantId => fetchRecommendationsOptions(dispatch, restaurantId),
	showModal: (show, type, restaurantId) => dispatch(toggleShowModal(show, type, restaurantId, true))
});

export default connect(mapStateToProps, mapDispatchToProps)(COVIDRestaurantFeedback);
