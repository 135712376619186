import SearchPageFilterPanel from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { isSV } from 'utils/sizes';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
	activeFilters: state.businessSearchFilter.selectedFilters,
	isLoading: state.businessSearchFilter.isLoading || !Object.keys(state.businessSearchFilter.userFilters).length,
	showFilter: state.searchPage.showFilter,
	hasActiveFilters: state.businessSearchFilter.selectedFilters.length > 0,
	translate: getTranslate(state.locale)
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default connect(mapStateToProps)(withSizes(mapSizesToProps)(SearchPageFilterPanel));
