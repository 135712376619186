import { hasProp } from 'utils/object';
import { getCardTypeValitationReqs, EMP_ID, EMAIL } from 'utils/card';

const requiredFieldsForCardTypePresentValidator = (formVals, isDoD = false) => {
	if (!formVals.selectedCardType) {
		return false;
	}
	const { type } = formVals.selectedCardType,
		uniqueIdReq = getCardTypeValitationReqs(type),
		hasSelectedCard = hasProp(formVals, 'selectedCardType.id'),
		hasCardholderName = hasProp(formVals, 'nameOnCard'),
		hasCardNumber = hasProp(formVals, 'cardNumber'),
		hasFirstName = hasProp(formVals, 'firstName'),
		hasLastName = hasProp(formVals, 'lastName'),
		passesPreliminaryCheck = isDoD
			? hasSelectedCard && hasFirstName && hasLastName && hasCardNumber
			: hasSelectedCard && hasCardholderName && hasCardNumber;

	if (uniqueIdReq === EMP_ID) {
		// employee id required
		const hasEmpId = hasProp(formVals, 'employeeId');

		return passesPreliminaryCheck && hasEmpId;
	} else if (uniqueIdReq === EMAIL) {
		// email required
		const hasEmail = hasProp(formVals, 'email');

		return passesPreliminaryCheck && hasEmail;
	} else {
		return passesPreliminaryCheck;
	}
};

export default requiredFieldsForCardTypePresentValidator;
