import CateringZones from './ui-component';
import { connect } from 'react-redux';
import { generateLinksFromState } from 'utils/catering';
// import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
	links: generateLinksFromState(state).links
	// translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(CateringZones);
