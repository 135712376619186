import DiningOptionsList from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { isLV } from 'utils/sizes';
import { hasProp } from 'utils/object';

const getHasDineIn = details => {
	return hasProp(details, 'restaurant.amenities.general')
		? details.restaurant.amenities.general.filter(g => g.id === 1).length > 0
		: false;
};

const getHasCurbsidePickup = details => {
	return hasProp(details, 'restaurant.amenities.general')
		? details.restaurant.amenities.general.filter(g => g.id === 2).length > 0
		: false;
};

const getHasDelivery = details => {
	return hasProp(details, 'restaurant.amenities.delivery.types')
		? details.restaurant.amenities.delivery.types.length > 0
		: false;
};

const getHasTakeout = details => {
	return hasProp(details, 'restaurant.amenities.taekout.url')
		? details.restaurant.amenities.takeout.url.length > 0
		: false;
};

const getHasDriveThru = details => {
	return hasProp(details, 'restaurant.amenities.general')
		? details.restaurant.amenities.general.filter(g => g.id === 5).length > 0
		: false;
};

const getDeliveryTitle = details => {
	if (hasProp(details, 'restaurant.amenities.delivery')) {
		let types = details.restaurant.amenities.delivery.types;
		if (types && types.length) {
			if (types[0].name === 'Self') {
				return `Delivery - by owner`;
			}
			return `Delivery - ${types[0].name}`;
		} else {
			return 'Delivery';
		}
	} else {
		return 'Delivery';
	}
};

const mapStateToProps = state => {
	const hasCurbsidePickup = getHasCurbsidePickup(state.details),
		hasDriveThru = getHasDriveThru(state.details),
		hasDelivery = getHasDelivery(state.details),
		hasDineIn = getHasDineIn(state.details),
		hasTakeout = getHasTakeout(state.details);

	return {
		options: [
			{
				title: 'Dine-In',
				checked: hasDineIn,
				show: hasDineIn
			},
			{
				title: 'Drive Thru',
				checked: hasDriveThru,
				show: hasDriveThru
			},
			{
				title: 'Takeout',
				checked: hasTakeout,
				show: !hasCurbsidePickup && !hasDriveThru && hasTakeout
			},
			{
				title: 'Curbside Pickup',
				checked: hasCurbsidePickup,
				show: hasCurbsidePickup
			},
			{
				title: getDeliveryTitle(state.details),
				checked: hasDelivery,
				show: hasDelivery
			}
		].filter(o => o.show)
	};
};

const mapSizesToProps = sizes => ({
	isLV: isLV(sizes)
});

export default connect(mapStateToProps)(withSizes(mapSizesToProps)(DiningOptionsList));
