import DeleteConnectionsWarningModal from './ui-component';
import { connect } from 'react-redux';
import { toggleShowDeleteConnectionsWarningModal } from 'actions/delete_connections_warning_modal';
import { getTranslate } from 'react-localize-redux';
import { deletePaymentInfo, getProfile } from 'actions/users';
import { setIsLoading } from 'actions/loader';
import { hasProp } from 'utils/object';
import { change } from 'redux-form';

const mapStateToProps = state => ({
	...state.deleteConnectionsWarningModal,
	connections: hasProp(state.users, 'user.connections') ? state.users.user.connections : [],
	translate: getTranslate(state.locale),
	usersCompanyName: hasProp(state.users, 'user.company.name') ? state.users.user.company.name : ''
});

const mapDispatchToProps = dispatch => ({
	close: () => dispatch(toggleShowDeleteConnectionsWarningModal(false, null)),
	deleteConnections: async (cardId, cb) => await dispatch(deletePaymentInfo(cardId, cb)),
	setIsLoading: (show, persist) => dispatch(setIsLoading(show, 'removing payment information', persist)),
	refreshUser: () => dispatch(getProfile(true)),
	setCompanySearch: text => dispatch(change('companySelect', 'name', text))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteConnectionsWarningModal);
