import COVID19Message from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import withSizes from 'react-sizes';
import { isLV } from 'utils/sizes';
import { toggleCovidShow } from 'actions/COVID-19';

const mapStateToProps = state => ({
	availableFilters: state.businessSearchFilter.staticFilters,
	config: state.COVID19.config,
	COVID19State: state.COVID19.state.toUpperCase(),
	show: state.COVID19.show,
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	applyPickupDeliveryFilter: filters => {
		filters.forEach(f => ownProps.addFilter(f));
	},
	toggleCovidShow: () => dispatch(toggleCovidShow())
});

const mapSizesToProps = sizes => ({
	isLV: isLV(sizes)
});

export default connect(mapStateToProps, mapDispatchToProps)(withSizes(mapSizesToProps)(COVID19Message));
