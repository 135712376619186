import React, { useCallback } from 'react';
import StepListItem from './StepListItem';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import styles from './style.module.scss';
import PropTypes from 'prop-types';
import { SETUP_FLOW_URL_PARAM, SETUP_COMPLETE_URL_PARAM } from 'components/organisms/ProfileSetupModal/constants';

const ProfileSetupStepList = ({
	closeProfileSetupModal,
	creditCardComplete,
	emailComplete,
	favoritesComplete,
	history,
	profileComplete,
	translate
}) => {
	const translateBaseKey = 'ProfileSetup.modals';
	const navigateToSearch = useCallback(() => {
		closeProfileSetupModal();
		const url = new URL(window.location.href);
		const path = url.pathname;
		const queryString = url.search;
		const resetSearch = path === '/search' && queryString.includes('setupComplete=true');
		console.log('ProfileSetupStepList url1', document.URL);
		const newUrl = resetSearch
			? `/search?${SETUP_FLOW_URL_PARAM}&${SETUP_COMPLETE_URL_PARAM}`
			: `/search?${SETUP_FLOW_URL_PARAM}`;
		history.push(newUrl);
		console.log('ProfileSetupStepList url2', document.URL);

		// if (resetSearch) history.go(0);
	}, []);

	return (
		<li className={styles.list}>
			<StepListItem
				complete={profileComplete}
				title={translate(`${translateBaseKey}.step1.title`)}
				step={ProfileSetupSteps.PROFILE}
			/>
			<StepListItem
				complete={creditCardComplete}
				title={translate(`${translateBaseKey}.step2.title`)}
				step={ProfileSetupSteps.ADD_CREDIT_CARD}
			/>
			<StepListItem
				complete={emailComplete}
				title={translate(`${translateBaseKey}.step3.title`)}
				step={ProfileSetupSteps.EMAILS}
			/>
			<StepListItem
				complete={favoritesComplete}
				title={translate(`${translateBaseKey}.step4.title`)}
				step={ProfileSetupSteps.FAVORITE}
				onClick={navigateToSearch}
			/>
		</li>
	);
};

ProfileSetupStepList.propTypes = {
	closeProfileSetupModal: PropTypes.func,
	creditCardComplete: PropTypes.bool,
	emailComplete: PropTypes.bool,
	favoritesComplete: PropTypes.bool,
	history: PropTypes.object,
	profileComplete: PropTypes.bool,
	translate: PropTypes.func.isRequired
};

export default ProfileSetupStepList;
