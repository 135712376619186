import * as t from '../actions/profile_setup/types';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const ProfileSetupSteps = {
	ADD_CREDIT_CARD: 'ADD_CREDIT_CARD',
	GREETING: 'GREETING',
	PROFILE: 'PROFILE',
	EMAILS: 'EMAILS',
	EMAILS_NOT_READY: 'EMAILS_NOT_READY',
	FAVORITE: 'FAVORITE',
	FINISH: 'FINISH'
};

const profileSetupDefaultState = {
	dismissedForever: false,
	open: true,
	currentStep: ProfileSetupSteps.GREETING,
	completeSteps: new Set(),
	form: {
		optInToEmails: false,
		doNotPlanCateringOrEvents: false,
		doesOrderCatering: false,
		doesSchedulePrivateEvents: false
	},
	totalSteps: 4,
	completedSteps: 0,
	onboardingFinished: false
};

const profileSetupReducer = (state = profileSetupDefaultState, action) => {
	const dismissedForever = cookies.get('profile_setup_dismissed_forever', false);

	switch (action.type) {
		case t.PROCEED: {
			return state;
		}

		case t.TOGGLE_MODAL_OPEN: {
			if (dismissedForever === true || state.dismissedForever === true) {
				console.log(
					'TOGGLE_MODAL_OPEN - nope - because cookie is',
					dismissedForever,
					'or state is ',
					state.dismissedForever
				);
				return {
					...state,
					open: false
				};
			}
			console.log('TOGGLE_MODAL_OPEN - sure', state);
			return { ...state, open: !state.open, currentStep: ProfileSetupSteps.GREETING };
		}

		case t.CLOSE_MODAL: {
			return { ...state, open: false, currentStep: ProfileSetupSteps.GREETING };
		}

		case t.GO_TO_STEP: {
			return {
				...state,
				currentStep: action.payload || ProfileSetupSteps.GREETING
			};
		}

		case t.UPDATE_FORM: {
			const { field, value } = action.payload;
			const newForm = { ...state.form };

			if (field === 'doNotPlanCateringOrEvents') {
				newForm['doNotPlanCateringOrEvents'] = value;
				newForm['doesOrderCatering'] = value ? false : state.form.doesOrderCatering;
				newForm['doesSchedulePrivateEvents'] = value ? false : state.form.doesSchedulePrivateEvents;
			} else if (field === 'doesOrderCatering' || field === 'doesSchedulePrivateEvents') {
				newForm['doNotPlanCateringOrEvents'] = value ? false : state.form.doNotPlanCateringOrEvents;
				newForm[field] = value;
			} else {
				newForm[field] = value;
			}

			return {
				...state,
				form: newForm
			};
		}

		case t.COMPLETE_STEP: {
			const newCompleteSteps = new Set(state.completeSteps);
			newCompleteSteps.add(action.payload);
			var completedSteps = state.completedSteps;
			if (action.payload !== 'EMAILS_NOT_READY') {
				completedSteps += 1;
			} else {
				completedSteps > 0 ? (completedSteps -= 1) : completedSteps;
			}
			console.log('state from profile_setup_reducer', state);
			return {
				...state,
				currentStep: ProfileSetupSteps.GREETING,
				completeSteps: newCompleteSteps,
				completedSteps: completedSteps
			};
		}

		case t.DISMISS_FOREVER: {
			cookies.set('profile_setup_dismissed_forever', true);

			return {
				...state,
				open: false,
				dismissedForever: true
			};
		}

		case t.DEFAULT_ONBOARDING: {
			return profileSetupDefaultState;
		}

		case t.FINISH_ONBOARDING: {
			return {
				...state,
				onboardingFinished: true // You can include this in your state
			};
		}

		case t.SET_COMPLETED_STEPS: {
			console.log('SET_COMPLETED_STEPS', action);
			return {
				...state,
				completedSteps: action.payload
			};
		}

		default: {
			if (dismissedForever === true) {
				return {
					...state,
					dismissedForever: true,
					open: false
				};
			}
			return state;
		}
	}
};

export default profileSetupReducer;
