import React from 'react';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import PropTypes from 'prop-types';
import { hasProp } from 'utils/object';

/**
 * Info icon that, when clicked, triggers to show/hide a text box.
 * @param {ToggleButtonProps} props
 * @param {string} props.id The id for the toggle button.
 * @param {object} props.config Dinova Redux state.config.
 * @param {function} [props.onToggle] On toggle icon click handler.
 *
 */
class ToggleButton extends React.Component {
	onToggle = e => {
		if (this.props.onToggle) {
			this.props.onToggle(e);
		} else {
			this.toggleVisibility();
		}
	};

	render() {
		const { dataCy, id, config, translate } = this.props;
		const isOpen = hasProp(this.props, 'isOpen') ? this.props.isOpen : this.state.isOpen;
		const icon = isOpen ? 'icon-show' : 'icon-hide';

		return (
			<React.Fragment>
				<div
					data-cy={`toggle-btn-${dataCy}`}
					data-toggle="collapse"
					type="button"
					className="toggle-btn btn-flat"
					id={id}
					onClick={this.onToggle}
					ref={c => (this.dropdown_trigger = c)}
				>
					<img
						className="Di_SideShowIcon"
						src={`${config.cdn_basePath}/icons/${icon}.svg`}
						alt={translate('common.toggleBtn')}
					/>{' '}
				</div>
			</React.Fragment>
		);
	}
}

ToggleButton.defaultProps = {};

/**
 * ToggleButton Props
 * @interface Props_ToggleButton
 * @property {string} id The id for the toggle button
 * @property {object} config Dinova Redux state.config.
 * @property {function} translate Dinova Redux getTranslate(state.locale).
 * @property {function} [onToggle] On toggle icon click handler.
 */
ToggleButton.propTypes = {
	// Required
	id: PropTypes.string.isRequired,
	config: PropTypes.object.isRequired,
	translate: PropTypes.func.isRequired,
	// Optional
	dataCy: PropTypes.string,
	onToggle: PropTypes.func,
	isOpen: PropTypes.bool
};

const mapStateToProps = state => ({
	config: state.config,
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(ToggleButton);
