import Completion from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { logout } from 'actions/users';
import { updateHeader } from 'actions/viewport';
import { isSV } from 'utils/sizes';
import withSizes from 'react-sizes';

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

const mapStateToProps = state => ({
	isDoD: state.general.dodMode,
	isDoorDash: state.general.doordashMode,
	companyCode: state.users.user.company.code,
	registeredAsIneligible: state.registration.registeredAsIneligible,
	translate: getTranslate(state.locale),
	users: state.users
});

const mapDispatchToProps = dispatch => ({
	logout: async () => await logout(dispatch),
	updateHeader: useMinimalHeaderFooter => dispatch(updateHeader(useMinimalHeaderFooter))
});

export default withSizes(mapSizesToProps)(connect(mapStateToProps, mapDispatchToProps)(Completion));
