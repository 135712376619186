import React from 'react';
import PropTypes from 'prop-types';
import { CompanySelect } from 'components/organisms';
import { Button } from 'reactstrap';
import { history } from 'utils/router';
import { Container, Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from 'reactstrap';
import { hasProp } from 'utils/object';
import { companySelectSubmitNoValWindowEvent /*, deleteAllConnectionsWindowEvent*/ } from 'utils/windowEvents';

class CompanySelectModal extends React.Component {
	constructor(props) {
		super(props);

		this.confirm = this.confirm.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.confirmCompanyChangeToIneligible = this.confirmCompanyChangeToIneligible.bind(this);

		this.state = {
			showHelp: false,
			isCompanySelectFocused: false,
			initialCompany:
				props.userCompany && props.userCompany.name && props.userCompany.name !== undefined
					? props.userCompany
					: false,
			initialCompanySet:
				props.userCompany && props.userCompany.name && props.userCompany.name !== undefined ? true : false
		};
	}

	keyPressRoutes = ['/users/connect', '/users/register', '/users/connect/', '/users/register/'];

	isRegistrationFlow = () => window.location.pathname.indexOf('/users/register') > -1;
	isConnectToPointsFlow = () => window.location.pathname.indexOf('/users/connect') > -1;

	componentDidMount() {
		window.addEventListener('keypress', this.handleKeyPress);
	}

	componentDidUpdate = () => {
		if (!this.state.initialCompany && this.props.userCompany && this.props.show && !this.state.initialCompanySet) {
			this.setState({ initialCompany: this.props.userCompany, initialCompanySet: true });
		}
		if (!this.props.show && this.state.initialCompany) {
			this.setState({ initialCompanySet: false, initialCompany: false });
		}
	};

	componentWillUnmount = () => {
		window.removeEventListener('keypress', this.handleKeyPress);
	};

	handleKeyPress = e => {
		if (e.key === 'Enter') {
			if (
				// blur input if on catering registration step 1
				this.keyPressRoutes.indexOf(window.location.pathname) > -1 &&
				window.location.search.indexOf('redirect=/catering') > -1
			) {
				companySelectSubmitNoValWindowEvent();
			} else if (
				// trigger modal flow if on company select modal route
				this.keyPressRoutes.indexOf(window.location.pathname) > -1
			) {
				if (!this.props.company.length) {
					companySelectSubmitNoValWindowEvent();
				} else if (!this.state.isCompanySelectFocused && !e.defaultPrevented) {
					const { type } = this.props;
					if (type === 'select') {
						this.confirm();
					} else if (type === 'skip') {
						this.doSkip();
					} else if (type === 'cancel') {
						this.confirmCancel();
					} else if (type === 'confirm_ineligible_company_change') {
						this.confirmCompanyChangeToIneligible();
					}
				}
			}
		}
	};

	onCompanySelectFocus = () => {
		this.setState({ isCompanySelectFocused: true });
	};

	onCompanySelectBlur = () => {
		this.setState({ isCompanySelectFocused: false });
	};

	toggleShowHelp = showHelp => {
		if (showHelp === undefined || showHelp.target) {
			showHelp = !this.state.showHelp;
		}
		this.setState({ showHelp });
	};

	doShowSkipModal = () => {
		this.toggleShowHelp(false);
		this.props.showSkipModal();
	};

	confirm = async (e, deleteConfirmed = false) => {
		if (this.isRegistrationFlow()) {
			await this.handleNotLegacyUser(deleteConfirmed);
		} else {
			if (hasProp(this.state.initialCompany, 'code')) {
				await this.handleNotLegacyUser(deleteConfirmed);
			} else {
				// for updating a user's company when they don't have a company set but already have connections - we don't want to delete the connections
				await this.handleLegacyUser(deleteConfirmed);
			}
		}
	};

	// for updating a user's company when they don't have a company set but already have connections - we don't want to delete the connections
	handleLegacyUser = async (deleteConfirmed = false) => {
		const selectedCompanyItem = hasProp(this.props, 'company.name');
		let company, isEligible;

		if (selectedCompanyItem) {
			company = this.props.company;
			isEligible = !(!this.props.company.myDinova.eligible || this.props.company.myDinova.participant !== 2);
		} else {
			const companyFound = await this.props.lookupCompany(this.props.company);
			company = companyFound;
			if (companyFound) {
				if (company.code) {
					isEligible = companyFound.myDinova.eligible && companyFound.myDinova.participant === 2;
				} else {
					isEligible = false;
				}
			} else {
				isEligible = false;
			}
		}

		if (deleteConfirmed) {
			await this.updateUser(() => this.setCompany(company), company.name);
		} else if (isEligible) {
			await this.updateUser(() => this.setCompany(company), company.name);
		} else {
			this.props.showConfirmCompanyChangeToIneligibleModal();
		}
	};

	handleNotLegacyUser = async (deleteConfirmed = false) => {
		if (deleteConfirmed || !this.props.hasConnections) {
			if (hasProp(this.props, 'company.name')) {
				await this.updateUser(() => this.setCompany(this.props.company), this.props.company.name);
				if (this.props.company.name === 'Merck') {
					this.props.showMerckModal();
					return;
				}
				if (!this.props.company.myDinova.eligible || this.props.company.myDinova.participant !== 2) {
					this.props.showConfirmCompanyChangeToIneligibleModal();
				}
			} else {
				const companyFound = await this.props.lookupCompany(this.props.company);

				if (companyFound.name === 'Merck') {
					this.props.showMerckModal();
					await this.updateUser(() => this.setCompany(companyFound), companyFound.name);
					return;
				}

				let isMyDinovaEligible = false;
				if (companyFound) {
					isMyDinovaEligible = companyFound.myDinova.eligible && companyFound.myDinova.participant === 2;
				}

				if (isMyDinovaEligible) {
					await this.updateUser(() => this.setCompany(companyFound), companyFound.name);
				} else {
					this.props.showConfirmCompanyChangeToIneligibleModal();
				}
			}
		} else if (!deleteConfirmed && this.props.hasConnections) {
			this.props.showDeleteConnectionsWarningModal(this.confirm.bind(this, null, true));
			return;
		}
	};

	updateUser = async (cb, companyName) => {
		const name = companyName || this.props.company.name || this.props.company;
		const currentCompany = this.props.userCompany;
		let noChange = name === currentCompany.name;

		if (noChange) {
			cb();
		} else {
			const doUpdate = () =>
				new Promise(resolve => {
					const callBack = () => {
						cb();
						resolve();
					};
					this.props.updateUser(
						{
							company: {
								name
							}
						},
						callBack
					);
				});

			await doUpdate();
			await this.props.refreshUserProfile();
		}
	};

	confirmCompanyChangeToIneligible = () => {
		const me = this;
		const doAfterUpdate = () => {
			me.props.closeCompanySelectModal();
			if (!me.isRegistrationFlow()) {
				me.props.showPointsElegibilityModal();
				history.push('/profile');
			} else {
				me.props.setDidRegisterWithIneligible();
				me.props.startChangeStep(4);
			}
		};
		this.updateUser(doAfterUpdate);
	};

	cancel = () => {
		this.toggleShowHelp(false);
		this.props.showCancelModal();
	};

	confirmCancel = () => {
		this.goToProfile();
	};

	denyCancel = () => {
		const { showSelectModal, userCompany } = this.props;
		if (userCompany.name || this.props.company.code) {
			this.doClose();
		} else {
			showSelectModal();
		}
	};

	onClose = () => {
		if (this.props.userCompany.name || this.props.company.code) {
			this.doClose();
		} else {
			this.cancel();
		}
	};

	setCompany = (company, doClose = true) => {
		this.props.setCompany(company);
		if (doClose) {
			this.doClose();
		}
	};

	doClose = () => {
		this.toggleShowHelp(false);
		this.props.closeCompanySelectModal();
	};

	goToProfile = () => {
		this.props.closeCompanySelectModal();
		history.push('/profile');
	};

	selectModal = () => {
		const { show, translate } = this.props,
			{ showHelp } = this.state,
			inRegistation = window.location.pathname.indexOf('/users/register') > -1;

		return (
			<Modal isOpen={show} className="company_select_modal" maxWidth="550px" centered>
				<ModalHeader toggle={this.onClose}>{translate(`select-company.header`)}</ModalHeader>
				<ModalBody>
					{translate(`select-company.body`)}
					<CompanySelect
						showHelp={showHelp}
						onHelpClick={show => this.toggleShowHelp(show)}
						onFocus={this.onCompanySelectFocus}
						onBlur={this.onCompanySelectBlur}
					/>
				</ModalBody>

				<ModalFooter>
					<Container>
						<Row>
							<Col sm="12">
								{/* Next Button */}
								<Button block color="primary" onClick={this.confirm} data-cy="select-company-confirm">
									{translate(`select-company.primaryButtonText`)}
								</Button>
							</Col>
							<Col sm="12">
								{/* Can't Find Company / Help Button */}
								<Button
									block
									color="secondary"
									onClick={() => this.toggleShowHelp(!showHelp)}
									data-cy="company-select-help"
									id="company-select-help"
								>
									{translate(`select-company.secondaryButtonText`)}
								</Button>
							</Col>
							<Col sm="12">
								{/* If on Registration Page, Skip this Step Button */}
								{inRegistation && (
									<Button
										block
										className={'z-depth-0'}
										color="tertiary"
										onClick={this.doShowSkipModal}
										data-cy="company-select-skip"
										id="company-select-skip"
									>
										{translate(`select-company.tertiaryButtonText`)}
									</Button>
								)}
							</Col>
						</Row>
					</Container>
				</ModalFooter>
			</Modal>
		);
	};

	cancelModal = () => {
		const { show, translate } = this.props;

		return (
			<Modal isOpen={show} className="company_select_modal" maxWidth="550px" centered>
				<ModalHeader>{translate(`ConnectToPoints.modals.cancel.header`)}</ModalHeader>
				<ModalBody>{translate(`ConnectToPoints.modals.cancel.body`)}</ModalBody>
				<ModalFooter>
					<Container>
						<Row>
							<Col sm="12">
								{/* Go Back To Select Company Button */}
								<Button block color="primary" onClick={this.denyCancel}>
									{translate(`ConnectToPoints.modals.cancel.primaryButtonText`)}
								</Button>
							</Col>
							<Col sm="12">
								{/* Cancel Select Company Button */}
								<Button block color="secondary" onClick={this.confirmCancel}>
									{translate(`ConnectToPoints.modals.cancel.secondaryButtonText`)}
								</Button>
							</Col>
						</Row>
					</Container>
				</ModalFooter>
			</Modal>
		);
	};

	informInitialSelectOfIneligibleCompanyModal = () => {
		const { show, showSelectModal, translate } = this.props;

		return (
			<Modal isOpen={show} className="company_select_modal" maxWidth="550px" centered>
				<ModalHeader>{translate(`ConnectToPoints.modals.initialIneligibleCompanySelect.header`)}</ModalHeader>
				<ModalBody>{translate(`ConnectToPoints.modals.initialIneligibleCompanySelect.body`)}</ModalBody>
				<ModalFooter>
					<Container>
						<Row>
							<Col sm="12">
								{/* Confirm Switch to Ineligible Company Button */}
								<Button block color="primary" onClick={this.confirmCompanyChangeToIneligible}>
									{translate(
										`ConnectToPoints.modals.initialIneligibleCompanySelect.primaryButtonText`
									)}
								</Button>
							</Col>
							<Col sm="12">
								{/* Cancel Switch to Ineligible Company Button */}
								<Button block color="secondary" onClick={showSelectModal}>
									{translate(
										`ConnectToPoints.modals.initialIneligibleCompanySelect.secondaryButtonText`
									)}
								</Button>
							</Col>
						</Row>
					</Container>
				</ModalFooter>
			</Modal>
		);
	};

	confirmCompanyChangeToIneligibleModal = () => {
		const { show, showSelectModal, translate } = this.props;

		return (
			<Modal isOpen={show} className="company_select_modal" maxWidth="550px" centered>
				<ModalHeader>{translate(`ConnectToPoints.modals.confirmIneligibleSwitch.header`)}</ModalHeader>
				<ModalBody>{translate(`ConnectToPoints.modals.confirmIneligibleSwitch.body`)}</ModalBody>
				<ModalFooter>
					<Container>
						<Row>
							<Col sm="12">
								{/* Confirm Switch to Ineligible Company Button */}
								<Button block color="primary" onClick={this.confirmCompanyChangeToIneligible}>
									{translate(`ConnectToPoints.modals.confirmIneligibleSwitch.primaryButtonText`)}
								</Button>
							</Col>
							<Col sm="12">
								{/* Cancel Switch to Ineligible Company Button */}
								<Button block color="secondary" onClick={showSelectModal}>
									{translate(`ConnectToPoints.modals.confirmIneligibleSwitch.secondaryButtonText`)}
								</Button>
							</Col>
						</Row>
					</Container>
				</ModalFooter>
			</Modal>
		);
	};

	doSkip = () => {
		const { startChangeStep } = this.props;
		this.props.closeCompanySelectModal();
		startChangeStep(4);
	};

	skipModal = () => {
		const { show, showSelectModal, translate } = this.props;
		const me = this;
		const doCancelSkip = () => {
			if (me.props.company.code) {
				me.doClose();
			} else {
				showSelectModal();
			}
		};

		return (
			<Modal isOpen={show} toggle={this.onClose} className="company_select_modal" maxWidth="550px" centered>
				<ModalHeader toggle={this.toggle}>{translate(`ConnectToPoints.modals.skip.header`)}</ModalHeader>
				<ModalBody>{translate(`ConnectToPoints.modals.skip.body`)}</ModalBody>
				<ModalFooter>
					<Container>
						<Row>
							<Col sm="12">
								{/* "CONTINUE" button - Go Back To Select Company Button */}
								<Button block color="primary" onClick={doCancelSkip} data-cy="cancel-skip">
									{translate(`ConnectToPoints.modals.skip.primaryButtonText`)}
								</Button>
							</Col>
							<Col sm="12">
								{/* "YES, SKIP" button - Cancel Select Company Button */}
								<Button block color="secondary" onClick={this.doSkip} data-cy="confirm-skip">
									{translate(`ConnectToPoints.modals.skip.secondaryButtonText`)}
								</Button>
							</Col>
						</Row>
					</Container>
				</ModalFooter>
			</Modal>
		);
	};

	merkModal = () => {
		const { show, translate } = this.props;
		return (
			<Modal isOpen={show} toggle={this.onClose} className="company_select_modal" maxWidth="550px" centered>
				<ModalHeader toggle={this.toggle}>{translate(`blocked-company.header`)}</ModalHeader>
				<ModalBody>{translate(`blocked-company.message`)}</ModalBody>
				<ModalFooter>
					<Container>
						<Row>
							<Col sm="12">
								{/* Cloase Modal and Go To Profile Button */}
								<Button block color="primary" onClick={this.goToProfile}>
									{translate(`blocked-company.primaryButtonText`)}
								</Button>
							</Col>
						</Row>
					</Container>
				</ModalFooter>
			</Modal>
		);
	};

	unconnectedModal = () => {
		const { show, translate } = this.props;
		return (
			<Modal isOpen={show} toggle={this.onClose} className="company_select_modal" maxWidth="550px" centered>
				<ModalHeader toggle={this.toggle}>{translate(`unconnected-company.header`)}</ModalHeader>
				<ModalBody>{translate(`unconnected-company.message`)}</ModalBody>
				<ModalFooter>
					<Container>
						<Row>
							<Col sm="12">
								{/* Cloase Modal and Go To Profile Button */}
								<Button block color="primary" onClick={this.doClose}>
									{translate(`unconnected-company.primaryButtonText`)}
								</Button>
							</Col>
						</Row>
					</Container>
				</ModalFooter>
			</Modal>
		);
	};

	render() {
		const { type } = this.props;

		return type === 'merck'
			? this.merkModal()
			: type === 'unconnected'
			? this.unconnectedModal()
			: type === 'select'
			? this.selectModal()
			: type === 'skip'
			? this.skipModal()
			: type === 'cancel'
			? this.cancelModal()
			: type === 'confirm_ineligible_company_change' || type === 'initial_ineligible_company_select'
			? this.isRegistrationFlow()
				? this.informInitialSelectOfIneligibleCompanyModal()
				: this.confirmCompanyChangeToIneligibleModal()
			: null;
	}
}

CompanySelectModal.defaultProps = {
	show: false
};

CompanySelectModal.propTypes = {
	closeCompanySelectModal: PropTypes.func.isRequired,
	company: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	hasConnections: PropTypes.bool.isRequired,
	lookupCompany: PropTypes.func.isRequired,
	refreshUserProfile: PropTypes.func.isRequired,
	setCompany: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
	showCancelModal: PropTypes.func.isRequired,
	showDeleteConnectionsWarningModal: PropTypes.func.isRequired,
	showConfirmCompanyChangeToIneligibleModal: PropTypes.func.isRequired,
	showMerckModal: PropTypes.func.isRequired,
	// showUnconnectedModal: PropTypes.func.isRequired,
	showSelectModal: PropTypes.func.isRequired,
	showSkipModal: PropTypes.func.isRequired,
	startChangeStep: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
	updateUser: PropTypes.func.isRequired,
	userCompany: PropTypes.object
};

export default CompanySelectModal;
