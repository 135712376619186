import React from 'react';
import PropTypes from 'prop-types';
import { Container, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { Button } from 'reactstrap';
import { ReduxFormInput } from 'components/organisms';
import { Field } from 'redux-form';

const FirstPage = ({ body, header, onPrimaryClick, onSecondaryClick, secondaryButtonText, isRequired }) => {
	return (
		<div>
			<ModalBody>
				<div className="mb-4">{header}</div>
				<div>{body}</div>
			</ModalBody>
			<ModalFooter>
				<Container>
					<Row>
						<Col sm="12">
							<Button
								block
								className="z-depth-0"
								onClick={onPrimaryClick}
								color="primary"
								id="modal-primary-button"
							>
								<i className="fa fa-mobile-alt fa-2x" aria-hidden="true"></i>
								<div className="d-inline-block">&nbsp;&nbsp;&nbsp;SMS Authentication</div>
							</Button>
						</Col>
						<Col sm="12" lg="12">
							{secondaryButtonText && (
								<Button
									block
									className="z-depth-0"
									onClick={onSecondaryClick}
									id="modal-secondary-button"
								>
									{secondaryButtonText}
								</Button>
							)}
						</Col>
						{!isRequired && (
							<Col sm="12">
								<Field
									name="dontAsk"
									type="checkbox"
									component={field => {
										return (
											<ReduxFormInput
												field={field}
												inputProps={{
													type: 'checkboxSingle',
													required: false,
													option: {
														label: "Don't ask again",
														checked: field.input.checked,
														id: 'dontAsk',
														name: 'dontAsk'
													},
													isReduxForm: true,
													dataCy: 'dontAsk-field',
													name: 'input_dontAsk',
													id: 'input-dontAsk',
													label: "Don't ask again",
													triggers: []
												}}
											/>
										);
									}}
								/>
							</Col>
						)}
					</Row>
				</Container>
			</ModalFooter>
		</div>
	);
};

FirstPage.defaultProps = {};
FirstPage.propTypes = {
	body: PropTypes.string,
	header: PropTypes.string,
	onPrimaryClick: PropTypes.func,
	onSecondaryClick: PropTypes.func,
	secondaryButtonText: PropTypes.string,
	isRequired: PropTypes.bool
};

export default FirstPage;
