import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text } from 'components/atoms';
import {
	RecommendationEndorsement,
	RecommendationEndorsementButtons,
	RecommendationsIncentive
} from 'components/molecules';
import { Col } from 'reactstrap';
import { RECOMMENDATION_SUBMIT } from 'utils/windowEvents';

class Recommendations extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			didSubmit: false
		};

		this.isUnmounting = false;

		this.isDetailsPage = window.location.pathname.indexOf(`restaurants`) > -1;
		this.handleAfterSubmit = this.handleAfterSubmit.bind(this);
	}

	componentDidMount = () => {
		// Event is fired from submitRecommendation action handler success.
		window.addEventListener(RECOMMENDATION_SUBMIT, this.handleAfterSubmit);
	};

	componentWillUnmount = () => {
		// Event is fired from submitRecommendation action handler success.
		window.removeEventListener(RECOMMENDATION_SUBMIT, this.handleAfterSubmit);
	};

	handleAfterSubmit = event => {
		if (event.detail.restaurantId === this.props.restaurantId || this.isDetailsPage) {
			if (!this.isUnmounting) {
				this.setState({ didSubmit: true });
			}
		}
	};

	componentWillUnmount = () => {
		this.isUnmounting = true;
		window.removeEventListener(RECOMMENDATION_SUBMIT, this.handleAfterSubmit);
	};

	render() {
		// history page did submit replace component
		if (this.props.didRecommend && !this.isDetailsPage) {
			// history transaction page after submit recommendation
			return (
				<div
					data-cy="recommendations"
					id="recommendations-section"
					className="recommendations transaction-didSubmit"
				>
					<Col xs="8">
						<div className="flex justify-center">
							<Text size="xs" align="center" dataCy="transactions-list-ty">
								{this.props.translate('Recommendations.transactions-will-receive-points')}
							</Text>
						</div>
					</Col>
				</div>
			);
		}

		const {
			className,
			didRecommend,
			fetchOnButtonClick,
			incentiveKey,
			onCountClick,
			restaurantId,
			isSV
		} = this.props;
		const classProps = classnames('recommendations', onCountClick && 'has-count', className && className);

		if (this.state.didSubmit && this.isDetailsPage) {
			return null;
		}

		const sv = (
			<div data-cy="recommendations" id="recommendations-section" className={classProps}>
				{!didRecommend && (
					<div className="flex align-center">
						<div className="mr-4">
							<RecommendationEndorsement />
							<RecommendationsIncentive incentiveKey={incentiveKey} />
						</div>
						<RecommendationEndorsementButtons
							fetchOnButtonClick={fetchOnButtonClick}
							restaurantId={restaurantId}
						/>
					</div>
				)}
			</div>
		);

		const mv = (
			<div data-cy="recommendations" id="recommendations-section" className={classProps}>
				{!didRecommend && (
					<div className="flex justify-center align-center">
						<RecommendationEndorsement />
						<RecommendationEndorsementButtons
							fetchOnButtonClick={fetchOnButtonClick}
							restaurantId={restaurantId}
						/>
						<RecommendationsIncentive incentiveKey={incentiveKey} />
					</div>
				)}
			</div>
		);

		if (className === 'transactions') {
			return sv;
		} else {
			return didRecommend ? false : isSV ? sv : mv;
		}
	}
}

Recommendations.defaultProps = {};

Recommendations.propTypes = {
	className: PropTypes.string,
	incentiveKey: PropTypes.string,
	fetchOnButtonClick: PropTypes.bool,
	onCountClick: PropTypes.func,
	restaurantId: PropTypes.string,
	isSV: PropTypes.bool.isRequired,
	didRecommend: PropTypes.bool.isRequired,
	translate: PropTypes.func.isRequired
};

export default Recommendations;
