import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';

class RecommendationEndorsement extends React.Component {
	sv = () => {
		const { translate } = this.props;
		return (
			<Text dataCy="recommend-question" size="xs">
				{translate('Recommendations.recommendQuestion')}
			</Text>
		);
	};

	mv = () => {
		const { translate } = this.props;
		return (
			<div className="flex justify-center align-center">
				<Text dataCy="recommend-question" size="xs">
					{translate('Recommendations.recommendQuestion')}
				</Text>
			</div>
		);
	};

	render() {
		const { isSV } = this.props;

		return (
			<div className="endorse">
				{isSV && this.sv()}
				{!isSV && this.mv()}
			</div>
		);
	}
}

RecommendationEndorsement.propTypes = {
	translate: PropTypes.func.isRequired,
	isSV: PropTypes.bool.isRequired
};

export default RecommendationEndorsement;
