/* eslint-disable no-case-declarations */
import * as t from '../actions/company_roster/types';

/**
 * Redux_CompanyState
 * @interface Redux_CompanyState
 * @property {object}  [selected={}]
 * @property {array} [suggestions=[]]
 * @property {boolean} [isLoading=false]
 * @property {boolean} [hasError=false]
 * @property {string} [errorText='']
 */
const companyRosterDefaultState = {
	errorText: '',
	hasError: false,
	isLoading: false
};

export default (state = companyRosterDefaultState, action) => {
	switch (action.type) {
		case t.FETCH_ROSTER_START:
			return {
				...companyRosterDefaultState,
				isLoading: true,
				clients: []
			};
		case t.FETCH_ROSTER_SUCCESS:
			return {
				...state,
				clients: action.payload,
				isLoading: false,
				hasError: false,
				errorText: ''
			};
		case t.FETCH_ROSTER_FAILURE:
			return {
				...companyRosterDefaultState,
				isLoading: false,
				hasError: true,
				errorText: action.payload
			};
		default:
			return state;
	}
};
