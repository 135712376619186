import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text, Title } from 'components/atoms';
import {
	COVIDRestaurantFeedback,
	COVIDRestaurantPrecautions,
	COVIDRestaurantUniquePrecautions
} from 'components/molecules';

/**
 * [COVIDSafetyMeasures description]
 * @extends Component
 */
class COVIDSafetyMeasures extends Component {
	render() {
		const { isLV, translate } = this.props,
			align = isLV ? 'left' : 'center';

		const subTitle = translate('Details.covid.subTitle');

		return (
			<div className={'COVIDSafetyMeasures'}>
				<Title align={align} transform="none">
					{translate('Details.covid.title')}
				</Title>
				<Text align={align} className="mb-6">
					{subTitle}
				</Text>
				<COVIDRestaurantPrecautions />
				<COVIDRestaurantUniquePrecautions />
				<COVIDRestaurantFeedback />
			</div>
		);
	}
}

COVIDSafetyMeasures.defaultProps = {};

/**
 * [propTypes description]
 * @interface COVIDSafetyMeasuresProps
 *
 * @example {}
 */
COVIDSafetyMeasures.propTypes = {
	isLV: PropTypes.bool.isRequired,
	translate: PropTypes.func.isRequired
};

export default COVIDSafetyMeasures;
