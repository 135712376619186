import React, { useEffect, useState } from 'react';
import RestaurantHours from './ui-component';
import PropTypes from 'prop-types';
/* interface RestaurantHoursDataWrapperProps {
}*/
const RestaurantHoursDataWrapper = (props /*: RestaurantHoursDataWrapperProps*/) => {
	const [open, setOpen] = useState(true);
	const [hours, setHours] = useState([]);
	useEffect(() => {
		const { googlePlaceId } = props;
		const google = window.google;
		const googlePlaces = google.maps.places;
		if (googlePlaces && googlePlaceId) {
			const service = new googlePlaces.PlacesService(document.createElement('div'));
			var request = {
				placeId: googlePlaceId,
				fields: ['opening_hours']
			};
			service.getDetails(request, (place, status) => {
				if (status == google.maps.places.PlacesServiceStatus.OK) {
					if (place.opening_hours?.weekday_text) {
						setHours(place.opening_hours?.weekday_text);
						setOpen(place.opening_hours.open_now);
					}
				} else {
					// error
				}
			});
		}
	}, [props.googlePlaceId]);
	return <RestaurantHours {...props} hours={hours} hasHours={hours.length > 0} open={open} />;
};
RestaurantHoursDataWrapper.propTypes = {
	googlePlaceId: PropTypes.string.isRequired
};
export default RestaurantHoursDataWrapper;
