import RecommendationItem from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { showRecommendationsModalPrefilled } from 'actions/recommendations_modal';
import { getTranslate } from 'react-localize-redux';

import { isSV } from 'utils/sizes';

const mapStateToProps = state => ({
	translate: getTranslate(state.locale),
	options: [
		...state.recommendationsModal.negative.reduce((accum, group) => [...accum, ...group.categories], []),
		...state.recommendationsModal.positive.reduce((accum, group) => [...accum, ...group.categories], [])
	]
});

const mapDispatchToProps = dispatch => ({
	showRecommendationsModalPrefilled: async (type, recommendation) =>
		await showRecommendationsModalPrefilled(dispatch, type, recommendation)
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withSizes(mapSizesToProps)(connect(mapStateToProps, mapDispatchToProps)(RecommendationItem));
