import SearchPageQuickFilters from './ui-component';
import { connect } from 'react-redux';
import { addBusinessFilter, removeBusinessFilter } from 'actions/new_business_search_filter';
import { hasProp } from 'utils/object';

const mapStateToProps = state => {
	// takeout, delivery, curbside, drive-through
	const baseKey = 'businessSearchFilter.userFilters',
		userFilters = state.businessSearchFilter.userFilters;

	let deliveryByOwner = hasProp(state, `${baseKey}.Dining Options.delivery`)
			? userFilters['Dining Options'].delivery
			: false,
		takeout = hasProp(state, `${baseKey}.Dining Options.takeout`) ? userFilters['Dining Options'].takeout : false,
		curbside = hasProp(state, `${baseKey}.Dining Options.Curbside`)
			? userFilters['Dining Options'].Curbside
			: false,
		driveThru = hasProp(state, `${baseKey}.Dining Options.Drive-Thru`)
			? userFilters['Dining Options']['Drive-Thru']
			: false;

	if (deliveryByOwner && deliveryByOwner.resultsCount === 0) {
		deliveryByOwner = false;
	}

	if (takeout && takeout.resultsCount === 0) {
		takeout = false;
	}

	if (curbside && curbside.resultsCount === 0) {
		curbside = false;
	}

	if (driveThru && driveThru.resultsCount === 0) {
		driveThru = false;
	}

	const filters = {
		'Take Out': takeout,
		Delivery: deliveryByOwner,
		'Curbside Pickup': curbside,
		'Drive Thru': driveThru
	};

	return { filters };
};

const mapDispatchToProps = dispatch => ({
	addFilter: async filter => await addBusinessFilter(dispatch, filter),
	removeFilter: async filter => await removeBusinessFilter(dispatch, filter)
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchPageQuickFilters);
