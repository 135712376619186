import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import classnames from 'classnames';
import { CTANav } from 'components/molecules';
import { Text } from 'components/atoms';

class CateringZones extends Component {
	constructor(props) {
		super(props);

		this.state = {};
	}

	render() {
		const { links } = this.props;

		return (
			<div className="catering-zones">
				<Text size="xs">Now serving these metro areas: </Text>
				<CTANav links={links} />
			</div>
		);
	}
}

CateringZones.defaultProps = {};

CateringZones.propTypes = {
	links: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			action: PropTypes.func
		})
	)
};

export default CateringZones;
