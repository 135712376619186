/* eslint-disable no-case-declarations */
import * as t from '../actions/COVID-19/types';
import * as d from '../actions/details/types';
import { hasProp } from 'utils/object';

/**
 * Redux_COVID19State
 * @interface Redux_COVID19State
 * @property {string}  [state]
 */
const covid19DefaultState = {
	state: '',
	isOpen: true,
	show: true,
	hideSafeyMeasures: false,
	config: {
		active: false,
		message: '',
		color: '',
		activeFilter: false
	}
};

export default (state = covid19DefaultState, action) => {
	switch (action.type) {
		case t.COVID_SET_STATE:
			return {
				...state,
				...action.payload
			};
		case t.TOGGLE_COVID_SHOW: {
			if (action.payload === true || action.payload === false) {
				return {
					...state,
					show: action.payload
				};
			} else {
				return {
					...state,
					show: !state.show
				};
			}
		}
		case d.LOAD_RESTAURANT: {
			if (action.status === 'success') {
				const restaurant = action.response.restaurant;

				if (Object.keys(restaurant.safetyMeasures).length === 0) {
					return {
						...state,
						hideSafeyMeasures: true
					};
				}

				let hasPractices = [];
				if (hasProp(restaurant, 'safetyMeasures.socialDistancing')) {
					hasPractices.push(restaurant.safetyMeasures.socialDistancing.length > 0);
				}

				if (hasProp(restaurant, 'safetyMeasures.dinerScreening')) {
					hasPractices.push(restaurant.safetyMeasures.dinerScreening.length > 0);
				}

				if (hasProp(restaurant, 'safetyMeasures.sanitization')) {
					hasPractices.push(restaurant.safetyMeasures.sanitization.length > 0);
				}

				if (hasPractices.filter(v => v === false).length === 0) {
					return {
						...state,
						hideSafeyMeasures: false
					};
				} else {
					return {
						...state,
						hideSafeyMeasures: true
					};
				}
			}

			return state;
		}
		default:
			return state;
	}
};
