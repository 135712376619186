import * as t from './types';
import axios from 'axios';
import { basePath, AUTH_REQUEST_HEADER } from 'actions/utils';

export const fetchCompanies = async (dispatch, companyName) => {
	dispatch({ type: t.FETCH_COMPANIES_START, payload: companyName });

	if (companyName === '') {
		if (dispatch) {
			dispatch({
				type: t.FETCH_COMPANIES_SUCCESS,
				payload: []
			});
		}
		return;
	}

	let url = `${basePath()}/clients/autocomplete?input=${companyName}`;
	try {
		const res = await axios.get(url, AUTH_REQUEST_HEADER());
		if (dispatch) {
			dispatch({
				type: t.FETCH_COMPANIES_SUCCESS,
				payload: res.data.suggestions
			});
		}
		return res.data.suggestions;
	} catch (err) {
		if (dispatch) {
			dispatch({
				type: t.FETCH_COMPANIES_FAILURE,
				payload: err.msg
			});
		}
	}
};

export const lookupCompany = async (dispatch, companyName) => {
	let url = `${basePath()}/clients?search=${companyName}`;

	const res = await axios.get(url).catch(() => {
		return false;
	});

	// res will be client or false
	return res ? res.data.clients[0] : res;
};

export const selectCompany = company => ({
	type: t.SELECT_COMPANY,
	payload: company
});

export const setCompanySearch = text => ({
	type: t.SET_SEARCH_COMPANY_VALUE,
	payload: text
});
