export const selectCreditCardComplete = state => state.users?.user?.connections?.length > 0;

export const selectCompletedSteps = state => ({
	creditCardComplete: state.users?.user?.onboardingStatus?.connectedCard === true,
	emailComplete: state.users?.user?.onboardingStatus?.optInNotification === true,
	profileComplete: state.users?.user?.onboardingStatus?.completeProfile === true,
	favoritesComplete: state.users?.user?.onboardingStatus?.favoriteRestaurant === true
});

export const selectCompletedStepsCount = state => {
	const completedSteps = selectCompletedSteps(state);
	return Object.values(completedSteps).filter(v => v).length;
};
