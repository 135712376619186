import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { handleNavLinkClick } from 'utils/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';

const IconNavItem = ({ className, useFaIcon, hidden, icon, item, isActive, key }) => {
	const classProps = classNames('icon-nav-item', isActive && 'active', className);

	const Icon = useMemo(() => {
		if (useFaIcon) {
			return (
				<FontAwesomeIcon
					icon={icon}
					alt={item.iconTitle ? item.iconTitle : item.title || ''}
					className={styles.faIcon}
				/>
			);
		} else {
			return <img src={icon} alt={item.iconTitle ? item.iconTitle : item.title} />;
		}
	}, [useFaIcon, icon, item]);

	if (hidden) {
		return null;
	}

	return item.fullUrl === true ? (
		<a href={item.url} className={classProps} key={key}>
			{/* <img src={icon} alt="" /> */}
			{Icon}
			<span>{item.iconTitle ? item.iconTitle : item.title}</span>
		</a>
	) : (
		<Link to={item.url} className={classProps} key={key} onClick={handleNavLinkClick.bind(this, item.url)}>
			{/* <img src={icon} alt={item.iconTitle ? item.iconTitle : item.title} /> */}
			{Icon}
			<span>{item.iconTitle ? item.iconTitle : item.title}</span>
		</Link>
	);
};

IconNavItem.defaultProps = {
	hidden: false,
	icon: '',
	isActive: false
};

IconNavItem.propTypes = {
	// Required
	item: PropTypes.object.isRequired,

	// Optional
	className: PropTypes.string,
	useFaIcon: PropTypes.bool,
	hidden: PropTypes.bool,
	icon: PropTypes.string,
	isActive: PropTypes.bool,
	key: PropTypes.string
};

export default withRouter(IconNavItem);
