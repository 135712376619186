import LastLoginMessage from './ui-component';
import { connect } from 'react-redux';
import { hasProp } from 'utils/object';
import { withLastLocation } from 'react-router-last-location';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
	lastLogin: hasProp(state, 'users.lastLogin') ? state.users.lastLogin : {},
	translate: getTranslate(state.locale)
});

export default connect(mapStateToProps)(withLastLocation(LastLoginMessage));
