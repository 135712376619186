// Dashboard MetaTags config
// @type MetaConfig[]
export default [
	{
		type: 'title',
		translateKey: 'Home.meta-title'
	},
	{
		type: 'meta',
		translateKey: 'Home.meta-description',
		name: 'description'
	},
	{
		type: 'meta',
		translateKey: 'Home.meta-keywords',
		name: 'keywords'
	},
	{
		type: 'meta',
		translateKey: 'Home.og-image',
		name: 'og:image'
	}
];
