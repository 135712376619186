import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { EditProfileActions, RequiredFieldNote } from 'components/molecules';
import { CompanySelect, InputField } from 'components/organisms';
import FormWithCompanySelect from 'hoc/FormWithCompanySelect';
import { phoneFormatter } from 'utils/formatters';
import { isEqual } from 'lodash';
import { history } from 'utils/router';
import { connect } from 'react-redux';
import { hasProp } from 'utils/object';
import isBool from 'utils/isBool';

class EditPersonalForm extends React.Component {
	componentDidMount() {
		console.clear();
	}

	componentDidUpdate = prevProps => {
		if (!isEqual(prevProps.initialValues, this.props.initialValues)) {
			this.setState({ initialValues: this.getFormVals(this.props.initialValues) });
		}
	};

	isCleanState = () => {
		const currentVals = this.getFormVals(this.props.data),
			{ companySelectVals, initialValues } = this.props,
			initVals = this.getFormVals(initialValues);

		const isCleanCompany =
			isEqual(initVals.company, companySelectVals.name) || isEqual(initVals.company, companySelectVals.code);

		delete initVals.company;
		delete currentVals.company;

		return isEqual(initVals, currentVals) && isCleanCompany;
	};

	getFormVals = data => {
		return data.reduce((accum, d) => {
			return {
				...accum,
				[d.key]: d.value
			};
		}, {});
	};

	onUpdate = e => {
		if (this.isCleanState()) {
			history.replace('/profile');
		} else {
			this.props.onUpdate(e);
		}
	};

	onCancel = e => {
		if (this.isCleanState()) {
			history.replace('/profile');
		} else {
			this.props.onCancel(e);
		}
	};

	render = () => {
		const { className, data, onChange, translate, onCompanySelectFocus, onCompanySelectBlur } = this.props;

		const classProps = classNames('edit-personal-form', className);
		// TODO fix asterisk, add validation, change handler and error text
		return (
			<div className={classProps}>
				<RequiredFieldNote />

				{data.map(item => {
					const isPhone = item.key === 'phone';

					if (item.key === 'company') {
						return (
							<CompanySelect
								key={item.key}
								onFocus={onCompanySelectFocus}
								onBlur={onCompanySelectBlur}
								hasError={!item.isValid}
								className={classNames('Di_NoSideIcon field', !item.isValid && 'Di_HasError')}
							/>
						);
					}

					console.log('item:', item, 'this', this);
					console.log(
						'%c isPhone ? phoneFormatter(item.value) : item.value',
						'color: limegreen',
						isPhone ? phoneFormatter(item.value) : isBool(item.value) ? `${item.value}` : item.value
					);

					return (
						<InputField
							key={item.key}
							dataCy={item.key}
							label={
								item.key !== 'catering' && item.key !== 'private_dining'
									? translate(`Profile.personal.labels.${item.key}`) +
									  `${item.mark === '✝︎' ? ' ✝︎' : ''}`
									: ''
							}
							name={`${item.key}Field`}
							id={`${item.key}-field`}
							hasError={!item.isValid}
							error={translate(`EditPersonal.errors.${item.key}`)}
							className={classNames('Di_NoSideIcon field', !item.isValid && 'Di_HasError')}
							value={
								isPhone ? phoneFormatter(item.value) : isBool(item.value) ? `${item.value}` : item.value
							}
							onChange={onChange.bind(this, item.key)}
							required={item.mark === 'asterisk'}
							example={isPhone ? translate('EditPersonal.numberExample') : ''}
							type={item.key === 'catering' || item.key === 'private_dining' ? 'checkbox' : 'text'}
							options={
								item.key === 'catering' || item.key === 'private_dining'
									? [{ checked: item.value, label: translate(`Profile.personal.labels.${item.key}`) }]
									: []
							}
						/>
					);
				})}

				<EditProfileActions onUpdate={this.onUpdate.bind(this)} onCancel={this.onCancel.bind(this)} />
				<p className="note form-note">
					<span className="cross-mark">&#10013;&#xFE0E;</span>
					{translate('EditPersonal.note')}
				</p>
			</div>
		);
	};
}

EditPersonalForm.defaultProps = {
	className: '',
	data: {}
};

EditPersonalForm.propTypes = {
	className: PropTypes.string,
	companySelectVals: PropTypes.object.isRequired,
	onChange: PropTypes.func,
	translate: PropTypes.func,
	data: PropTypes.array,
	initialValues: PropTypes.array,
	onUpdate: PropTypes.func,
	onCancel: PropTypes.func,
	// onCompanySelect funcs come from FormWithCompanySelect HOC
	onCompanySelectFocus: PropTypes.func,
	onCompanySelectBlur: PropTypes.func
};

const mapStateToProps = state => ({
	companySelectVals: hasProp(state, 'form.companySelect.values') ? state.form.companySelect.values : {}
});

export default FormWithCompanySelect(connect(mapStateToProps)(EditPersonalForm), { onEnterPress: 'onUpdate' });
