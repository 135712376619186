import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Title } from 'components/atoms';
import { OptionsList } from 'components/organisms';

class RestaurantRecommendations extends React.Component {
	componentDidMount = async () => {
		if (!this.hasRecommendationOptions() && this.props.restaurantId) {
			this.props.fetchRecommendationsOptions(this.props.restaurantId);
		}
	};

	hasRecommendations = () => this.props.recommendations.total > 0;

	hasRecommendationOptions = () => this.props.recommendationOptions.length > 0;

	compileGroups = () => {
		const { recommendations, recommendationOptions } = this.props;

		let groups = Object.values(recommendationOptions).reduce((accum, { name, categories }) => {
			categories.forEach(cat => {
				if (recommendations.categories[cat.id]) {
					accum[name] = {
						...(accum[name] || {}),
						[cat.name]: recommendations.categories[cat.id].total
					};
				}
			});
			return accum;
		}, {});

		return groups;
	};

	generateLists = groups => {
		const classProps = classnames('details-recommendations', !this.props.isLV && 'single-col');

		let lists = Object.entries(groups).map(([groupName, groupValues], i) => {
			let options = Object.entries(groupValues).map(([catName, catTotal]) => {
				return `${catName} (${catTotal})`;
			});
			return (
				<div key={groupName} className="half-width">
					<Title align="left" transform="none" className={i > 1 ? 'mt-4' : ''}>
						{groupName}
					</Title>
					<OptionsList className={classProps} options={options} />
				</div>
			);
		});

		return lists;
	};

	render() {
		if (!this.hasRecommendationOptions() || !this.hasRecommendations()) {
			return null;
		}

		let lists = this.generateLists(this.compileGroups());

		return lists.length ? <div className="flex row-wrap justify-between">{lists}</div> : null;
	}
}

RestaurantRecommendations.defaultProps = {
	recommendations: []
};

/**
 * {@link RestaurantRecommendations} Props
 * @interface Props_RestaurantRecommendations
 * @property {string[]} [recommendations=[]]
 */
RestaurantRecommendations.propTypes = {
	// Optional
	recommendations: PropTypes.object,
	recommendationOptions: PropTypes.array,
	fetchRecommendationsOptions: PropTypes.func.isRequired,
	restaurantId: PropTypes.string,
	// Required
	isLV: PropTypes.bool.isRequired
};

export default RestaurantRecommendations;
