import {
	cardIdValidator,
	creditCardNumberValidator,
	lengthValidator,
	nameOnCardValidator
} from 'utils/form/validators';

const validateEditPaymentForm = (vals, idType, card, isDoD) => {
	const errors = {};

	// Name on Card Validate
	if (!isDoD) {
		let nameOnCardError = nameOnCardValidator(vals.nameOnCard);
		if (nameOnCardError) {
			errors.nameOnCard = nameOnCardError;
		}
	} else {
		let firstNamePassesLength = lengthValidator(vals.firstName, 1);
		if (!firstNamePassesLength) {
			errors.firstName = !firstNamePassesLength;
		}

		let lastNamePassesLength = lengthValidator(vals.lastName, 1);
		if (!lastNamePassesLength) {
			errors.lastName = !lastNamePassesLength;
		}
	}

	// Card Number Validate
	if (vals.cardNumber.indexOf('X') === -1) {
		// only if user changed number
		let cardNumebrError = creditCardNumberValidator(card, vals.cardNumber);
		if (cardNumebrError) {
			errors.cardNumber = cardNumebrError;
		}
	}

	// Card ID Validate
	if (idType === 'email') {
		let emailError = cardIdValidator(vals.email, 'email');
		if (emailError) {
			errors.email = emailError;
		}
	} else if (idType === 'empID') {
		let employeeIdError = cardIdValidator(vals.email, 'empID');
		if (employeeIdError) {
			errors.employeeId = employeeIdError;
		}
	}

	return errors;
};

export default validateEditPaymentForm;
