import * as t from './types';
import { getLowerCasedParams } from 'utils/url';

export const addBusinessFilter = (dispatch, filter, recOptions) => {
	dispatch({ type: t.FILTER_WORKING, payload: true });
	dispatch({ type: t.ADD_BUSINESS_FILTER, payload: { filter, recOptions } });
	dispatch({ type: t.FILTER_WORKING, payload: false });
};

export const removeBusinessFilter = (dispatch, filter, recOptions) => {
	dispatch({ type: t.FILTER_WORKING, payload: true });
	dispatch({ type: t.REMOVE_BUSINESS_FILTER, payload: { filter, recOptions } });
	dispatch({ type: t.FILTER_WORKING, payload: false });
};

export const clearFilters = () => dispatch => dispatch({ type: t.CLEAR_FILTERS });

export const resetSearch = dispatch => {
	return new Promise(resolve => {
		dispatch({
			type: t.RESET_SEARCH
		});
		resolve();
	});
};

export const applyURLParamFilters = () => dispatch => {
	console.log('%c ****BusinessSearchFilter - applyURLParamFilters****', 'color:red');
	const urlParams = getLowerCasedParams();
	delete urlParams.center;
	delete urlParams.location;
	delete urlParams.near;
	delete urlParams.zoom;
	delete urlParams.keywords;

	dispatch({
		type: t.APPLY_URL_SEARCH_FILTERS,
		payload: urlParams
	});
};
