import DetailsAboutCTAMenu from './ui-component';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { has } from 'lodash';
import { getPhoneOrFax, formatPhoneNumber } from 'utils/regex';

const generateActions = (restaurant, translate, IMAGE_BASE_URL) => {
	let actions = [],
		isTesting = false;

	if (has(restaurant, 'contacts.general.url') || isTesting) {
		let url = isTesting ? '#' : restaurant.contacts.general.url;
		let titleDesktop = 'Website';
		/*url.replace('http://', '');
		titleDesktop = titleDesktop.replace('https://', '');
		if (titleDesktop[titleDesktop.length - 1] === '/') {
			titleDesktop = titleDesktop.substring(0, titleDesktop.length - 1);
		}*/
		actions.push({
			icon: `${IMAGE_BASE_URL}/icons/icon-website.svg`,
			mobileIcon: `${IMAGE_BASE_URL}/icons/icon-website_green.svg`,
			url,
			title: translate('Details.actions.website'),
			titleDesktop,
			titleMobile: translate('Details.actionsShort.website')
			// titleShort: translate('Details.actionsShort.website')
		});
	}
	// main phone
	if (has(restaurant, 'contacts.general.numbers.main') || isTesting) {
		const phoneTitle = restaurant.contacts.general.numbers.main;
		let phoneNumber = getPhoneOrFax(phoneTitle),
			url = isTesting ? '#' : `tel:${formatPhoneNumber(phoneNumber)}`,
			title = formatPhoneNumber(phoneNumber);

		actions.push({
			icon: `${IMAGE_BASE_URL}/icons/icon-call.svg`,
			mobileIcon: `${IMAGE_BASE_URL}/icons/icon-call_green.svg`,
			url,
			title: translate('Details.actions.call') + ` (${phoneTitle})`,
			titleDesktop: title,
			titleMobile: translate('Details.actions.call')
		});
	}

	// reservations
	if (has(restaurant, 'amenities.reservations.url') || isTesting) {
		let url = isTesting ? '#' : restaurant.amenities.reservations.url;
		actions.push({
			icon: `${IMAGE_BASE_URL}/icons/icon-detail-reservations.svg`,
			mobileIcon: `${IMAGE_BASE_URL}/icons/icon-detail-reservations_green.svg`,
			url,
			title: translate('Details.actions.reservations'),
			titleDesktop: translate('Details.actionsShort.reservations'),
			titleMobile: translate('Details.actionsShort.reservations')
		});
	}

	// catering
	if (has(restaurant, 'amenities.catering.url') || isTesting) {
		let url = isTesting ? '#' : restaurant.amenities.catering.url;
		actions.push({
			icon: `${IMAGE_BASE_URL}/icons/icon-catering.svg`,
			mobileIcon: `${IMAGE_BASE_URL}/icons/icon-catering.svg`,
			url,
			title: translate('Details.actions.catering'),
			titleDesktop: translate('Details.actionsShort.catering'),
			titleMobile: translate('Details.actionsShort.catering')
		});
	}

	// order
	if (has(restaurant, 'amenities.onlineOrdering.url') || isTesting) {
		let url = isTesting ? '#' : restaurant.amenities.onlineOrdering.url;
		actions.push({
			icon: `${IMAGE_BASE_URL}/icons/icon-detail-online-ordering.svg`,
			mobileIcon: `${IMAGE_BASE_URL}/icons/icon-detail-online-ordering_green.svg`,
			url,
			title: translate('Details.actions.ordering'),
			titleDesktop: translate('Details.actionsShort.ordering'),
			titleMobile: translate('Details.actionsShort.ordering')
		});
	}
	// -->
	// directions
	if (has(restaurant, 'location') || isTesting) {
		let url = isTesting
			? '#'
			: `https://www.google.com/maps?saddr=Current+Location&daddr=${restaurant.location.lat},${restaurant.location.lon}`;
		actions.push({
			icon: `${IMAGE_BASE_URL}/icons/icon-map.svg`,
			mobileIcon: `${IMAGE_BASE_URL}/icons/icon-map-green.svg`,
			url,
			title: translate('Details.actions.directions'),
			titleDesktop: translate('Details.actionsShort.directions'),
			titleMobile: translate('Details.actionsShort.directions')
		});
	}

	return actions;
};

const mapStateToProps = state => ({
	actions: generateActions(state.details.restaurant, getTranslate(state.locale), state.config.imageBaseUrl)
});

export default connect(mapStateToProps)(DetailsAboutCTAMenu);
