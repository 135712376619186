import AutoEnrollUserForm from './ui-component';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { getTranslate } from 'react-localize-redux';
import { createAutoEnrollUser } from 'actions/auto_enroll_user_form_page';
import { login } from 'actions/users';
// import { getLowerCasedParams } from 'utils/url';
const FORM_NAME = 'autoEnrollUser';
import { default as warn } from './formWarnings';

/**
 * This is our hook into the redux state.  We can use this to apply parts or redux state to our component.  The values will be passes as component props.
 * @param  {object} state The current redux state.
 * @return {object}       An object to be passed as component props.
 */
const mapStateToProps = state => {
	return {
		complete: state.autoEnrollUserFormPage.complete,
		error: state.autoEnrollUserFormPage.error,
		initialValues: {
			password: '',
			passwordConfirm: '',
			subscription: false,
			terms: false,
			offersOpt: null,
			emailsOpt: null
		},
		isLoading: state.autoEnrollUserFormPage.isLoading,
		warn,
		translate: getTranslate(state.locale),
		user: state.users.user
	};
};

/**
 * This is our hook to the redux action dispatch.  We can use this to pass action crators to our component as props.
 * @param  {function} dispatch The redux dispatch function
 * @return {object}            An object that will be provided as component props.
 */
const mapDispatchToProps = dispatch => ({
	createAutoEnrollUser: user => dispatch(createAutoEnrollUser(user)),
	login: (user, callback) => dispatch(login(user, callback))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	/* reduxForm hoc - this is how we connect our components form and inputs to redux form */
	reduxForm({
		/*
			The only required param for reduxForm is "form" which is expected to be a string and is applied as the form's name as will as the object key in our redux store for this form
			We called this form "expressConnect" so it will be found in the redux state as state.form.expressConnect
		*/
		form: FORM_NAME
	})(AutoEnrollUserForm)
);
