import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { RING_TYPES } from 'components/ecosystems/DashboardPage/ui-component';

class PointsRing extends React.Component {
	pointsRing = () => {
		const { translate } = this.props;
		const pointsText = [];
		const pointsTextCount = parseInt(translate('Rewards.pointsRing.getPoints.count'));
		for (let i = 0; i < pointsTextCount; i++) {
			pointsText.push(translate(`Rewards.pointsRing.getPoints.${i + ''}`));
		}

		return (
			<text x="50%" y="30%" textAnchor="middle" dy="0.3em">
				<tspan x="50%" dy="1.5em" className="font-weight-bold">
					{pointsText[0]}
				</tspan>
				<tspan x="50%" dy="1.2em" className="font-weight-bold">
					{pointsText[1]}
				</tspan>
				<tspan x="50%" dy="1.3em" className="font-small">
					{pointsText[2]}
				</tspan>
				<tspan x="50%" dy="1.2em" className="font-small">
					{pointsText[3]}
				</tspan>
			</text>
		);
	};

	connectionsRing = () => {
		const { translate } = this.props;
		const connectionsText = [];
		const connectionsTextCount = parseInt(translate('Rewards.pointsRing.connections.count'));
		for (let i = 0; i < connectionsTextCount; i++) {
			connectionsText.push(translate(`Rewards.pointsRing.connections.${i + ''}`));
		}

		return (
			<text x="50%" y="30%" textAnchor="middle" dy="0.3em">
				<tspan x="50%" dy=".6em" className="font-weight-bold">
					{connectionsText[0]}
				</tspan>
				<tspan x="50%" dy="1.2em" className="font-weight-bold">
					{connectionsText[1]}
				</tspan>
				<tspan x="50%" dy="1.3em" className="font-weight-bold">
					{connectionsText[2]}
				</tspan>
				<tspan x="50%" dy="1.2em" className="font-small">
					{connectionsText[3]}
				</tspan>
				<tspan x="50%" dy="1.2em" className="font-small">
					{connectionsText[4]}
				</tspan>
			</text>
		);
	};

	pointsGetConnectedRing = () => {
		const { points, translate } = this.props;
		const pointsGetConnectedText = [];
		const pointsGetConnectedTextCount = parseInt(translate('Rewards.pointsRing.points-getConnected.count'));
		for (let i = 0; i < pointsGetConnectedTextCount; i++) {
			pointsGetConnectedText.push(translate(`Rewards.pointsRing.points-getConnected.${i + ''}`));
		}

		return (
			<text x="50%" y="30%" textAnchor="middle" dy="0.3em">
				<tspan x="50%" dy="1.5em" className="font-xsmall font-weight-bold">
					{pointsGetConnectedText[0]}
				</tspan>
				<tspan x="50%" dy=".9em" className="font-weight-bold font-large point">
					{pointsGetConnectedText[1].replace('{points}', Number.parseInt(points).toLocaleString())}
				</tspan>
				<tspan x="50%" dy="1.5em" className="font-small font-weight-bold">
					{pointsGetConnectedText[2].replace('{s}', points === 1 ? '' : 'S')}
				</tspan>
			</text>
		);
	};

	render() {
		const { className, ringType } = this.props;
		const classProps = classNames('points-ring', className);
		const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

		return (
			<div className={classProps + ' outer'}>
				<svg
					viewBox="0 0 140 140"
					preserveAspectRatio="xMaxYMin meet"
					width={isIE11 ? '380' : null}
					height={isIE11 ? '380' : null}
				>
					<g>
						<circle
							r="48%"
							cx="50%"
							cy="50%"
							className={
								ringType() !== RING_TYPES.HAS_POINTS &&
								ringType() !== RING_TYPES.NO_POINTS_NO_CONNECTIONS
									? 'partial'
									: ' circle-back'
							}
							transform={isIE11 ? 'rotate(-61, 70, 70)' : null}
						/>
						{ringType() === RING_TYPES.NO_POINTS && this.pointsRing()}
						{ringType() === RING_TYPES.NO_CONNECTIONS && this.connectionsRing()}
						{(ringType() === RING_TYPES.HAS_POINTS || ringType() === RING_TYPES.NO_POINTS_NO_CONNECTIONS) &&
							this.pointsGetConnectedRing()}
					</g>
				</svg>
			</div>
		);
	}
}

PointsRing.propTypes = {
	className: PropTypes.string,
	points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	ringType: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

export default PointsRing;
