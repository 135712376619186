import AutoEnrollAdminForm from './ui-component';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';
import FormWithCompanySelect from 'hoc/FormWithCompanySelect';
import { hasProp } from 'utils/object';
import { getTranslate } from 'react-localize-redux';
import { getCompany, setCompany } from 'actions/general';
import { setIsLoading } from 'actions/loader';
const FORM_NAME = 'expressConnect';

/**
 * Helper for when we determing the form's initial value for selectedCardType.
 * @param  {object} state      The current redux state.
 * @return {object|undfined}   If there is a single supported connection, we return that as the initial value for selectedCardType, otherwise we will return undefined to allow the user to select the connection type they desire.
 */
export const getSelectedCardType = state => {
	const supportedConnections = hasProp(state.general, 'company.supportedConnections')
		? state.general.company.supportedConnections
		: [];
	let selectedCardType = undefined;
	if (supportedConnections && supportedConnections.length === 1) {
		selectedCardType = supportedConnections[0];
	}

	return selectedCardType;
};

/**
 * This is our hook into the redux state.  We can use this to apply parts or redux state to our component.  The values will be passes as component props.
 * @param  {object} state The current redux state.
 * @return {object}       An object to be passed as component props.
 */
const mapStateToProps = state => ({
	company: hasProp(state.general, 'company') ? state.general.company : {},
	formValues: hasProp(state.form, FORM_NAME) ? state.form[FORM_NAME].values : {},
	idToken: state.users.idToken,
	initialValues: {
		includeCC: true,
		file: null,
		selectedCardType: getSelectedCardType(state)
	},
	translate: getTranslate(state.locale),
	usersCompany: hasProp(state, 'users.user.company') ? state.users.user.company : {},
	username: hasProp(state.users, 'user.userName') ? state.users.user.userName : ''
});

/**
 * This is our hook to the redux action dispatch.  We can use this to pass action crators to our component as props.
 * @param  {function} dispatch The redux dispatch function
 * @return {object}            An object that will be provided as component props.
 */
const mapDispatchToProps = dispatch => ({
	getCompany: (clientCode, showLoading = false) => dispatch(getCompany(clientCode, showLoading)),

	setIsLoading: show => dispatch(setIsLoading(show, 'Uploading CSV')),
	setCompany: company => dispatch(setCompany(company)),
	setFormValue: (fieldName, value) => dispatch(change(FORM_NAME, fieldName, value))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(
	/* reduxForm hoc - this is how we connect our components form and inputs to redux form */
	reduxForm({
		/*
			The only required param for reduxForm is "form" which is expected to be a string and is applied as the form's name as will as the object key in our redux store for this form
			We called this form "expressConnect" so it will be found in the redux state as state.form.expressConnect
		*/
		form: FORM_NAME
	})(
		/* FormWithCompanySelect is a custom HOC that handles click events for the company select*/
		FormWithCompanySelect(AutoEnrollAdminForm /*, { allowFreeForm: false } */)
	)
);
