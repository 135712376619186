/**
 * hasProp is an Object utility that will determine if an object key:value pair exsists.
 * @global
 * @module ObjectUtils_hasProp
 * @param  {Object}  obj               The object to check for key:value existance in.
 * @param  {string}  prop              The data accesor to use to find the desired key:value pair.  Ex: 'user.profile.username'
 * @param  {boolean} [returnVal=false] If hasProp should return the value found in the key:value pair.
 * @return {Boolean|any}               hasProp defaults to return a boolean dictating if the desired key:value pair was found.  If hasProp is run with returnVal=true, hasProp will return the value of the key:value pair if found, null if not found.
 * @example const obj = {user: {profile: {username: 'person'}}};
const prop = "user.profile.username";
hasProp(obj, prop)
// -> true

hasProp(obj, prop, true)
// -> "person"
 */
export const hasProp = (obj, prop, returnVal) => {
	if (!obj) {
		return false;
	}
	let propFound = false;
	let accrs = prop.split('.');
	let i = 0;
	while (i < accrs.length) {
		if (!obj) {
			break;
		}
		obj = obj[accrs[i]];
		i++;
	}

	if (obj || obj === false) {
		propFound = true;
	}

	return returnVal ? obj : propFound;
};

/**
 * Retrieves the value from an object.  Deffers to {@link hasProp} to determine if prop exists.
 * @global
 * @module ObjectUtils_getVal
 * @param  {Object}  obj    The object to check for key:value existance in.
 * @param  {string}  prop   The data accesor to use to find the desired key:value pair.  Ex: 'user.profile.username'
 * @return {any}            Returns the value if found, null if not found.
 *
 * @example const obj={user: {profile: {username: 'person'}}};
const prop = "user.profile";

getVal(obj, prop);
// -> {username: 'person'};
 */
export const getVal = (obj, prop) => {
	const returnVal = true;
	const val = hasProp(obj, prop, returnVal);

	return val ? val : null;
};

export const buildObjWithKeyValue = (keys, value) => {
	let obj = {};

	keys.split('.')
		.reverse()
		.forEach((key, i) => {
			if (i === 0) {
				obj = { [key]: { ...obj, ...value } };
			} else {
				obj = { [key]: { ...obj } };
			}
		});

	return obj;
};

export const transformKeyNames = (object, transformer) => {
	return Object.entries(object).reduce((accum, [key, value]) => {
		accum[transformer(key)] = value;
		return accum;
	}, {});
};
