import CateringUnavailableModal from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { isSV } from 'utils/sizes';
import { generateLinksFromState } from 'utils/catering';
import { setShowNoServiceNotice } from 'actions/catering';
import { getZones } from 'actions/catering';
import { getTranslate } from 'react-localize-redux';
import { hasProp } from 'utils/object';

const mapStateToProps = state => ({
	links: generateLinksFromState(state).links,
	show: state.catering.showCateringZoneUnavailable,
	translate: getTranslate(state.locale),
	formLocation: hasProp(state.form, 'cateringSearch.values.location') ? state.form.cateringSearch.values.location : ''
});

const mapDispatchToProps = dispatch => ({
	onClose: () => dispatch(setShowNoServiceNotice(false)),
	getZones: async () => await dispatch(getZones(null)), // null to get all zones
	setShowNoServiceNotice: shouldShow => dispatch(setShowNoServiceNotice(shouldShow))
});

const mapSizesToProps = sizes => ({
	isSV: isSV(sizes)
});

export default withSizes(mapSizesToProps)(connect(mapStateToProps, mapDispatchToProps)(CateringUnavailableModal));
