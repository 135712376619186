import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import { startCase } from 'lodash';

// import "./style.scss";

/**
 * [DiningOptionsContactInfo description]
 * @extends Component
 */
class DiningOptionsContactInfo extends Component {
	render() {
		const { phone, translate, url } = this.props;

		let title = url
			? translate('Details.togo-options.or-call')
			: startCase(translate('Details.togo-options.or-call').replace('or ', ''));

		return (
			<div className={'dining-options-contact-info'}>
				{url && (
					<a className="btn btn-primary" href={url} target="_blank" rel="noopener noreferrer">
						{translate('Details.togo-options.order-online')}
					</a>
				)}
				{phone && (
					<div className="flex justify-center align-center">
						<Text className="mr-2 mb-0">{title}</Text>
						<a href={`tel:${phone}`}>{phone}</a>
					</div>
				)}
			</div>
		);
	}
}

DiningOptionsContactInfo.defaultProps = {};

/**
 * [propTypes description]
 * @interface DiningOptionsContactInfoProps
 *
 * @example {}
 */
DiningOptionsContactInfo.propTypes = {
	phone: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	translate: PropTypes.func.isRequired,
	url: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

export default DiningOptionsContactInfo;
