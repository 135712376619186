import Promise from 'bluebird';
import * as t from './types';
import { setIsLoading } from 'actions/loader';

const geoLocationErrors = {
	// user said no
	1: 'Please allow browser geolocation services or enter a search location.',
	// device can't get data
	2: 'There was an error trying to fetch your location.',
	// timeout
	3: 'Please allow browser geolocation services or enter a search location.'
};

export const geoLocationSuccess = (pos, dispatch) => {
	dispatch({
		type: t.FETCH_GEO_LOCATION,
		payload: pos
	});
	console.log('%c ****geoLocationSuccess - Stopping the loader****', 'color:blue');
	dispatch(setIsLoading(false));
	return pos;
};

export const geoLocationError = (err, dispatch) => {
	let errorMessage = geoLocationErrors[err.code];
	dispatch({
		type: t.FETCH_GEO_LOCATION_ERROR,
		payload: {
			message: errorMessage,
			code: err.code
		}
	});
	console.log('%c ****geoLocationError - Stopping the loader****', 'color:blue');
	dispatch(setIsLoading(false));
	return new Error({ error: err, message: errorMessage });
};

const browserLocationServicesUnavailable = {
	type: t.FETCH_GEO_LOCATION_ERROR,
	payload: { message: 'Browser does not allow geolocation', code: 1 }
};

const geoLocationFetchOptions = {
	timeout: 30000,
	enableHighAccuracy: false,
	maximumAge: 60000 * 60 * 24 // 24 hrs
};

/**
 * Async method to fetch user's navigator.geolocation
 * @param  {function} dispatch Redux dispatch func
 * @return {Promise}           Returns a promise that resolves to either a google.Position object or a google.PositionError obejct.
 * @modifies state.geoLocation - lat, lng, wasDenied, setIsLoading, hasGeoLocation
 */
export const getLocation = dispatch => {
	dispatch({ type: t.FETCH_GEO_LOCATION_START });
	if (navigator.geolocation) {
		return new Promise((resolve, reject) => {
			const geolocation = navigator.geolocation;
			console.log('%c ****getGeoLocation - Starting the loader****', 'color:blue');
			dispatch(setIsLoading(true, 'Getting Geolocation'));
			// init location services
			geolocation.getCurrentPosition(
				pos => resolve(geoLocationSuccess(pos, dispatch)),
				err => {
					reject(geoLocationError(err, dispatch));
				},
				geoLocationFetchOptions
			);
		});
	} else {
		dispatch(browserLocationServicesUnavailable);
	}
};
