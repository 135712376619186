import React from 'react';
import { BasicModal } from 'components/molecules';
import { Text, Title } from 'components/atoms';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

// import './style.scss';

class PointsElegibilityModal extends React.Component {
	constructor(props) {
		super(props);

		this.handleKeyPress = this.handleKeyPress.bind(this);
	}

	componentDidMount = () => {
		window.addEventListener('keypress', this.handleKeyPress);
	};

	componentWillUnmount = () => {
		window.removeEventListener('keypress', this.handleKeyPress);
	};

	handleKeyPress = e => {
		if (this.props.show && e.key === 'Enter') {
			this.props.onClose();
		}
	};

	render = () => {
		const { onClose, show, translate } = this.props;

		return (
			<BasicModal show={show} onClose={onClose} className="points-elegibility-modal" center>
				<div className="points-elegibility-content-wrapper">
					<Title size="h3">{translate('points-eligibility-modal.header')}</Title>
					<Text weight="bold" size="sm" align="center">
						{translate('points-eligibility-modal.sub-header')}
					</Text>
					<Text size="sm">{translate('points-eligibility-modal.content')}</Text>
					<Button color="primary" onClick={onClose}>
						OKAY
					</Button>
				</div>
			</BasicModal>
		);
	};
}

PointsElegibilityModal.defaultProps = {};

PointsElegibilityModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
	translate: PropTypes.func.isRequired
};

export default PointsElegibilityModal;
