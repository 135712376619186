import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import { Button } from 'reactstrap';
import { history } from 'utils/router';

// import "./style.scss";

/**
 * [COVIDRestaurantFeedback description]
 * @extends Component
 */
class COVIDRestaurantFeedback extends Component {
	leaveFeedback = () => {
		const { fetchOnButtonClick, fetchRecommendationsOptions, restaurantId } = this.props;
		if (fetchOnButtonClick) {
			fetchRecommendationsOptions(restaurantId);
		}
		this.toggleModal('positive');
	};

	toggleModal = type => {
		const { isAuthed, showModal, restaurantId } = this.props;
		if (isAuthed) {
			showModal(true, type, restaurantId);
		} else {
			history.push(`/login?redirect=${window.location.pathname + window.location.hash}`);
			showModal(true, type, restaurantId);
		}
	};

	render() {
		const { translate } = this.props;
		return (
			<div className="COVIDRestaurantFeedback flex mt-3">
				<Text className="mb-0">
					<Button className="more-button bold mr-2" onClick={this.leaveFeedback}>
						{translate('Details.covid.leave-feedback')}
					</Button>
					{translate('Details.covid.leave-feedback-desc')}
				</Text>
			</div>
		);
	}
}

COVIDRestaurantFeedback.defaultProps = {};

/**
 * [propTypes description]
 * @interface COVIDRestaurantFeedbackProps
 *
 * @example {}
 */
COVIDRestaurantFeedback.propTypes = {
	isAuthed: PropTypes.bool.isRequired,
	fetchOnButtonClick: PropTypes.bool.isRequired,
	fetchRecommendationsOptions: PropTypes.func.isRequired,
	restaurantId: PropTypes.string,
	showModal: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

export default COVIDRestaurantFeedback;
