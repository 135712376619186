import { combineReducers } from 'redux';
import { hasProp } from 'utils/object';
import { isEqual } from 'lodash';
// import reducers
import { reducer as form } from 'redux-form';
import { localeReducer } from 'react-localize-redux';
import cateringReducer from './catering';
import companyDashboard from './company_dashboard';
import configReducer from './config';
import detailsReducer from './details';
import editPaymentReducer from './edit_payment';
import favoritesPageReducer from './favorites_page';
import generalReducer from './general';
import mapReducer from './map';
import modalReducer from './modal';
import registrationReducer from './registration';
import rewardReducer from './reward';
import usersReducer from './users';
import locationAutocompleteReducer from './location_autocomplete';
import geoLocationReducer from './geo_location';
import businessSearchFilter from './new_business_search_filter';
import favoritesSearchFilter from './favorites_search_filter';
import searchPage from './search_page';
import businessSearchMap from './business_search_map';
import recommendationsModal from './recommendations_modal';
import easterEggs from './easter_eggs';
import userRecommendations from './user_recommendations';
import companySelect from './company_select';
import companyRoster from './company_roster';
import pointsElegibilityModal from './points_elegibility_modal';
import deleteConnectionsWarningModal from './delete_connections_warning_modal';
import companySelectModal from './company_select_modal';
import promotions from './promotions';
import landingPageCards from './landing_page_cards';
import dineAsssitFormPage from './dine_assist_form_page';
import landingPage from './landing_page';
import autoEnrollUserFormPage from './auto_enroll_user_form_page';
import profileSetup, { ProfileSetupSteps } from './profile_setup';

import COVID19 from './COVID-19';
import loader from './loader';
import viewport from './viewport';
import requests from './requests';

// combine to single object
export const reducers = {
	catering: cateringReducer,
	companyDashboard,
	companySelectModal,
	config: configReducer,
	deleteConnectionsWarningModal,
	details: detailsReducer,
	editPayment: editPaymentReducer,
	form,
	general: generalReducer,
	locale: localeReducer,
	map: mapReducer,
	modal: modalReducer,
	registration: registrationReducer,
	reward: rewardReducer,
	users: usersReducer,
	landingCards: landingPageCards,
	locationAutocomplete: locationAutocompleteReducer,
	favoritesPage: favoritesPageReducer,
	geoLocation: geoLocationReducer,
	businessSearchFilter,
	favoritesSearchFilter,
	searchPage,
	businessSearchMap,
	recommendationsModal,
	easterEggs,
	userRecommendations,
	companySelect,
	companyRoster,
	pointsElegibilityModal,
	promotions,
	dineAsssitFormPage,
	COVID19,
	loader,
	viewport,
	requests,
	landingPage,
	autoEnrollUserFormPage,
	profileSetup
};

const forceShowPointsEligiblity = (state, action) => {
	/*
		Because update user does not return the udpated user data, we don't know if the update includes a change from
		a myDinova eligible company to a non myDinova eligible company.
		To make sure we show the points ineligible modal after the personal information form is submitted,
		compare current state.users.user to the new user on action.payload to see if action.payload includes
		a new company and if that company is eligible.
		If it is not eligible, we force the pointsElegibilityModal to show.
	*/
	if (hasProp(state.users, 'user.company') && action.type === 'SET_USER') {
		const companyDidChange = !isEqual(state.users.user.company, action.payload.company),
			hadCompany = hasProp(state, 'users.user.company.name') ? state.users.user.company.name.length > 0 : false,
			notEligible =
				!hasProp(action.payload.company, 'myDinova') ||
				(hasProp(action.payload.company, 'myDinova') && !action.payload.company.myDinova.eligible),
			notParticipant =
				hasProp(action.payload.company, 'myDinova') && action.payload.company.myDinova.participant !== 2;

		if (hadCompany && companyDidChange && (notEligible || notParticipant)) {
			return true;
		}
	}
	return false;
};

// add factory reset for store
const makeRootReducer = reducers => {
	return (state, action) => {
		let storeState = state;
		storeState = action.type === 'FACTORY_RESET' ? undefined : state;

		if (state) {
			// TODO: Remove during state cleanup.  Put here to make sure rec options have passed through rec option reducer mapping func
			state.businessSearchFilter.recOptions = state.recommendationsModal.positive;
			state.details.recOptions = {
				positive: state.recommendationsModal.positive
			};

			if (forceShowPointsEligiblity(state, action)) {
				state.pointsElegibilityModal.show = true;
			}

			// Make sure profile setup modal considers card step complete if user has already added a credit card.
			if (
				storeState?.users?.user?.connections?.length > 0 &&
				!storeState?.profileSetup?.completeSteps?.has(ProfileSetupSteps.ADD_CREDIT_CARD)
			) {
				const completed = new Set(storeState.profileSetup.completeSteps);
				completed.add(ProfileSetupSteps.ADD_CREDIT_CARD);
				storeState.profileSetup.completeSteps = completed;
			}
		}

		return combineReducers(reducers)(storeState, action);
	};
};

// define root reducer
const rootReducer = makeRootReducer(reducers);
export default rootReducer;
