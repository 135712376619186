import React from 'react';
import PropTypes from 'prop-types';
import { CTAItem } from 'components/atoms';
import { history } from 'utils/router';
import classnames from 'classnames';

const POSITIVE = 'positive',
	NEGATIVE = 'negative';

class RecommendationEndorsementButtons extends React.Component {
	toggleModal = type => {
		const { isAuthed, showModal, restaurantId } = this.props;
		if (isAuthed) {
			showModal(true, type, restaurantId);
		} else {
			history.replace(`/login?redirect=${window.location.pathname + window.location.hash}`);
			showModal(true, type, restaurantId);
		}
	};

	onClick = type => {
		const { fetchOnButtonClick, fetchRecommendationsOptions, restaurantId } = this.props;
		if (fetchOnButtonClick) {
			fetchRecommendationsOptions(restaurantId);
		}
		this.toggleModal(type);
	};

	sv = () => {
		const { isSV, translate } = this.props;
		const className = classnames('flex justify-center', !isSV && 'rec-btns align-center');
		return (
			<div className={className}>
				<CTAItem
					dataCy="recommend-pos"
					className="secondary"
					config={{ text: 'Yes', action: this.onClick.bind(this, POSITIVE) }}
				>
					{translate('common.yes')}
				</CTAItem>
				<CTAItem
					dataCy="recommend-neg"
					className="secondary"
					config={{ text: 'No', action: this.onClick.bind(this, NEGATIVE) }}
				>
					{translate('common.no')}
				</CTAItem>
			</div>
		);
	};

	render() {
		return <div className="endorse">{this.sv()}</div>;
	}
}

RecommendationEndorsementButtons.defaultProps = {
	fetchOnButtonClick: false
};

RecommendationEndorsementButtons.propTypes = {
	fetchOnButtonClick: PropTypes.bool.isRequired,
	fetchRecommendationsOptions: PropTypes.func, // required if fetchOnButtonClick
	restaurantId: PropTypes.string,
	isAuthed: PropTypes.bool.isRequired,
	showModal: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired,
	isSV: PropTypes.bool.isRequired
};

export default RecommendationEndorsementButtons;
