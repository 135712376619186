import React, { useCallback, useEffect } from 'react';
import styles from './style.module.scss';
import { Text } from 'components/atoms';
import PropTypes from 'prop-types';
import StepTemplate from '../../StepTemplate';
import { CheckboxInput } from 'components/molecules';

const MODAL_TRANSLATE_KEY = 'ProfileSetup.modals.step1';
const FORM_TRANSLATE_KEY = `${MODAL_TRANSLATE_KEY}.form.fields`;

const CompleteProfile = ({
	completeStep,
	doNotPlanCateringOrEvents,
	doesOrderCatering,
	doesSchedulePrivateEvents,
	translate,
	updateForm,
	user
}) => {
	const handleChange = useCallback(
		({ name, checked }) => {
			updateForm({ field: name, value: !checked });
		},
		[updateForm]
	);

	const CHECKBOXES = [
		{
			checked: doesOrderCatering,
			label: translate(`${FORM_TRANSLATE_KEY}.doesOrderCatering.label`),
			name: 'doesOrderCatering'
		},
		{
			checked: doesSchedulePrivateEvents,
			label: translate(`${FORM_TRANSLATE_KEY}.doesSchedulePrivateEvents.label`),
			name: 'doesSchedulePrivateEvents'
		},
		{
			checked: doNotPlanCateringOrEvents,
			label: translate(`${FORM_TRANSLATE_KEY}.doNotPlanCateringOrEvents.label`),
			name: 'doNotPlanCateringOrEvents'
		}
	];

	const formIsValid = doesOrderCatering || doesSchedulePrivateEvents || doNotPlanCateringOrEvents;

	useEffect(() => {
		if (user.diningTypes.some(x => x.id === 1)) {
			updateForm({ field: 'doesOrderCatering', value: true });
		}
		if (user.diningTypes.some(x => x.id === 2)) {
			updateForm({ field: 'doesSchedulePrivateEvents', value: true });
		}
		if (user.diningTypes.count === 0) {
			updateForm({ field: 'doNotPlanCateringOrEvents', value: true });
		}
	}, []);

	return (
		<StepTemplate
			title={translate(`${MODAL_TRANSLATE_KEY}.title`)}
			onSubmit={completeStep(CHECKBOXES)}
			submitDisabled={!formIsValid}
		>
			<div className={styles.content}>
				<Text weight="300" className={styles.body}>
					{translate(`${MODAL_TRANSLATE_KEY}.body`)}
				</Text>
				<div>
					{CHECKBOXES.map(checkbox => (
						<CheckboxInput
							key={checkbox.label}
							className={styles.checkbox}
							checked={checkbox.checked}
							onChange={handleChange}
							option={{
								label: checkbox.label,
								checked: checkbox.checked,
								name: checkbox.name
							}}
						/>
					))}
				</div>
			</div>
		</StepTemplate>
	);
};

CompleteProfile.propTypes = {
	user: PropTypes.object,
	completeStep: PropTypes.func,
	doNotPlanCateringOrEvents: PropTypes.bool,
	doesOrderCatering: PropTypes.bool,
	doesSchedulePrivateEvents: PropTypes.bool,
	translate: PropTypes.func.isRequired,
	updateForm: PropTypes.func
};

export default CompleteProfile;
