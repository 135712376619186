import React from 'react';
import PropTypes from 'prop-types';
import { Text, Title } from 'components/atoms';
import { Button } from 'reactstrap';
import { BusinessSearchForm, CateringSearchForm } from 'components/organisms';
import { CardPromoBanner } from 'components/molecules';

const CardFold = ({ cardId, close, isLV, isActive, setShowAbout, showAbout, translate, IMAGE_BASE_URL }) => {
	const disableSearchAreaButtons = (isActive && showAbout) || !isActive,
		disableAboutAreaButtons = (isActive && !showAbout) || !isActive,
		imgSrc = `${IMAGE_BASE_URL}/icons/icon-close.svg`;
	const translateKey = `landingPage.cards.cards.${cardId}`;

	return (
		<div className="fold" aria-hidden={!isActive}>
			{isLV && <img className="close" onClick={close} src={imgSrc} alt="close card button" />}
			<div className="left">
				{!isLV && (
					<React.Fragment>
						<Title size="h3" transform="none" align="left">
							{translate(`${translateKey}.fold.search.header`)}
						</Title>
						<Text size="sm">{translate(`${translateKey}.fold.search.catchPhrase`)}</Text>
					</React.Fragment>
				)}

				{isLV && (
					<Title size="h3" transform="none" align="left">
						{translate(`${translateKey}.fold.search.catchPhrase`)}
					</Title>
				)}

				{!isLV && (
					<Button
						onClick={setShowAbout.bind(this, true)}
						className="bright link-button"
						disabled={disableSearchAreaButtons}
					>
						{translate(`${translateKey}.aboutLink`)}
					</Button>
				)}
				{translate(`${translateKey}.cardKey`) === 'business' && isActive ? (
					<React.Fragment>
						<BusinessSearchForm
							submitButtonText={translate('landingPage.cards.cards.0.searchLinkButton')}
						/>
						<div className="footer">
							{!isLV && <img className="close" onClick={close} src={imgSrc} alt="close card button" />}
						</div>
					</React.Fragment>
				) : translate(`${translateKey}.cardKey`) === 'catering' && isActive ? (
					<React.Fragment>
						<CateringSearchForm
							submitButtonText={translate('landingPage.cards.cards.0.searchLinkButton')}
						/>
						<div className="footer">
							{!isLV && <img className="close" onClick={close} src={imgSrc} alt="close card button" />}
						</div>
					</React.Fragment>
				) : null}
			</div>
			<div className="right">
				<div>
					<div>
						{!isLV && (
							<Button
								className="bright link-button"
								onClick={setShowAbout.bind(this, false)}
								disabled={disableAboutAreaButtons}
							>
								{translate('common.backBtn')}
							</Button>
						)}
						<Title size="h3" transform="none" align="left">
							{translate(`${translateKey}.fold.about.header`)}
						</Title>
						<Text size="sm">{translate(`${translateKey}.fold.about.description`)}</Text>
						{/* {isLV && translate(`${translateKey}.cardKey`) !== 'dine-assist' ? (
							<CardPromoBanner translateKey={translateKey} isFoldPromo={false} />
						) : null}
						 */}
						{isLV &&
							translate(`${translateKey}.cardKey`) !== 'dine-assist' &&
							(translate(`${translateKey}.cardKey`) === 'catering2' ? (
								<CardPromoBanner translateKey={translateKey} isFoldPromo={true} />
							) : null)}
						{(translate(`${translateKey}.cardKey`) === 'business' ||
							translate(`${translateKey}.cardKey`) === 'catering') && (
							<CardPromoBanner translateKey={translateKey} />
						)}
					</div>
					{!isLV && (
						<Button
							color="primary"
							className="card-button"
							onClick={setShowAbout.bind(this, false)}
							disabled={disableAboutAreaButtons}
						>
							{translate(`${translateKey}.searchLinkButton`)}
						</Button>
					)}
				</div>
				{!isLV && (
					<div className="footer">
						<img className="close" onClick={close} src={imgSrc} alt="close card button" />
					</div>
				)}
			</div>
		</div>
	);
};

CardFold.defaultProps = {};

CardFold.propTypes = {
	// component props
	cardId: PropTypes.string.isRequired,

	// redux props
	close: PropTypes.func.isRequired,
	isActive: PropTypes.bool.isRequired,
	setShowAbout: PropTypes.func.isRequired,
	showAbout: PropTypes.bool.isRequired,
	translate: PropTypes.func.isRequired,
	IMAGE_BASE_URL: PropTypes.string.isRequired,
	// with sizes props
	isLV: PropTypes.bool.isRequired
};

export default CardFold;
