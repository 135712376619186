import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/atoms';

const RecommendationCountMessage = ({
	count,
	didRecommendFromState,
	didRecommendFromBackendRestaurant,
	onClick,
	translate
}) => {
	const translateKey = count === 0 ? 'notRecommendedYet' : 'recommendedCount';
	let msg = translate(`Recommendations.${translateKey}`).replace('{count}', count);

	if (didRecommendFromState && !didRecommendFromBackendRestaurant) {
		msg = translate('Recommendations.submitThankYou');
	}

	if (didRecommendFromBackendRestaurant) {
		if (count === 0) {
			count = 1;
		}
		msg = translate('Recommendations.recommendedCount').replace('{count}', count);
	}

	return (
		<Link
			className="recommendation-count text sm normal text-none"
			onClick={count > 0 ? onClick : undefined}
			dataCy="recommendations-count"
		>
			{msg}
		</Link>
	);
};

RecommendationCountMessage.defaultProps = {
	count: 0
};

RecommendationCountMessage.propTypes = {
	count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	didRecommendFromState: PropTypes.bool.isRequired,
	didRecommendFromBackendRestaurant: PropTypes.bool.isRequired,
	onClick: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

export default RecommendationCountMessage;
