import React from 'react';
import styles from './style.module.scss';
import { Button, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import classnames from 'classnames';
import { ProfileSetupSteps } from 'reducers/profile_setup';

const StepTemplate = ({ children, goToGreeting, onSubmit, submitDisabled, title, translate, variant }) => {
	return (
		<div className={classnames(styles.wrapper, styles[variant])}>
			<Text size="lg" weight="bold" transform="uppercase" className={styles.title}>
				{title}
			</Text>
			<div className={styles.content}>{children}</div>
			{variant !== ProfileSetupSteps.GREETING &&
				variant !== ProfileSetupSteps.ADD_CREDIT_CARD &&
				variant !== ProfileSetupSteps.FINISH && (
					<Row className={styles.footer}>
						<div className={styles.btnWrapper}>
							<Button color="primary" onClick={onSubmit} disabled={submitDisabled}>
								{translate('common.submit')}
							</Button>
							<Button onClick={goToGreeting}>{translate('common.cancelBtn')}</Button>
						</div>
					</Row>
				)}
			{variant === ProfileSetupSteps.FINISH && (
				<Row className={styles.footer}>
					<div className={styles.btnWrapper}>
						<Button color="primary" onClick={onSubmit} disabled={false}>
							{translate('common.finish')}
						</Button>
					</div>
				</Row>
			)}
		</div>
	);
};

StepTemplate.defaultProps = {
	variant: 'default'
};

StepTemplate.propTypes = {
	children: PropTypes.node,
	goToGreeting: PropTypes.func,
	onSubmit: PropTypes.func,
	submitDisabled: PropTypes.bool,
	title: PropTypes.string,
	translate: PropTypes.func.isRequired,
	variant: PropTypes.oneOf(['greeting', 'default', 'GREETING', 'FINISH', 'finish'])
};

export default StepTemplate;
