import { getLowerCasedParams } from 'utils/url';
import { hasProp } from 'utils/object';

const registrationFormInitialState = () => {
	let parsed = getLowerCasedParams();

	let companyCode = hasProp(parsed, 'companycode') ? parsed.companycode : '',
		firstname = hasProp(parsed, 'first') ? parsed.first : '',
		lastname = hasProp(parsed, 'last') ? parsed.last : '',
		email = hasProp(parsed, 'wemail') ? parsed.wemail : '',
		campaign = hasProp(parsed, 'utm_campaign') ? parsed.utm_campaign : '',
		registrationPath =
			hasProp(parsed, 'redirect') && parsed.redirect.indexOf('catering') > -1 ? 'catering' : 'mydinova',
		referralToken = hasProp(parsed, 'token') ? parsed.token : '';

	return {
		behaviors: {
			catering: false,
			private_dining: false
		},
		campaign,
		companyCode,
		firstname,
		lastname,
		email,
		emailOpt: { value: null },
		offersOpt: { value: null },
		password: '',
		phone: '',
		registrationPath,
		referralToken,
		termsAndConditions: false,
		username: email
	};
};

export default registrationFormInitialState;
