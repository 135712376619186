import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

const EditProfileActions = props => {
	const {
		cancelButtonText,
		className,
		onUpdate,
		onCancel,
		reverse,
		translate,
		updateButtonText,
		updateButtonDisabled
	} = props;
	const classProps = classNames('edit-profile-actions', className);
	const submitButtonProps = {
		type: onUpdate ? undefined : 'submit',
		onClick: onUpdate ? onUpdate : undefined,
		disabled: updateButtonDisabled
	};

	return reverse ? (
		<div className={classProps}>
			<Button onClick={onCancel}>{cancelButtonText ? cancelButtonText : translate('common.cancelBtn')}</Button>
			<Button {...submitButtonProps} color="primary">
				{updateButtonText ? updateButtonText : translate('common.updateBtn')}
			</Button>
		</div>
	) : (
		<div className={classProps}>
			<Button {...submitButtonProps} color="primary">
				{updateButtonText ? updateButtonText : translate('common.updateBtn')}
			</Button>
			<Button onClick={onCancel}>{cancelButtonText ? cancelButtonText : translate('common.cancelBtn')}</Button>
		</div>
	);
};

EditProfileActions.defaultProps = {
	className: '',
	updateButtonDisabled: false
};

EditProfileActions.propTypes = {
	cancelButtonText: PropTypes.string,
	className: PropTypes.string,
	onUpdate: PropTypes.func,
	onCancel: PropTypes.func,
	reverse: PropTypes.bool,
	translate: PropTypes.func.isRequired,
	updateButtonText: PropTypes.string,
	updateButtonDisabled: PropTypes.bool
};

export default EditProfileActions;
