import React from 'react';
import PropTypes from 'prop-types';
import { Field, SubmissionError } from 'redux-form';
import { LoadingBase, Text } from 'components/atoms';
import { EditProfileActions, ReadOnlyInput } from 'components/molecules';
import { CompanySelect, ReduxFormCCTypeDropdown, ReduxFormInput, Table } from 'components/organisms';
import { hasProp } from 'utils/object';
import { isEqual, startCase } from 'lodash';
import { preferredCCTypeDisplayText } from 'utils/card';
import AWSUpload from 'utils/AWSUpload';
import { history } from 'utils/router';
import Papa from 'papaparse'; // https://www.papaparse.com/docs#config
import { doPapaParse, processCSVFromCardType } from 'utils/auto_enroll/csv-processor';

class AutoEnrollAdminForm extends React.Component {
	state = {
		cardTypeField: null,
		companyField: null,
		didSubmit: false,
		fileField: null,
		creditCardToggleField: null,
		formError: false,
		hasErrors: false,
		processed: false,
		progress: 0,
		tableData: {
			headers: [],
			rows: []
		},
		uploadedCount: 0,
		uploadError: false
	};

	componentDidMount = () => {
		this.initCompanyField();
		this.initFileField();
		this.initCreditCardToggleField();
	};

	componentDidUpdate = async prevProps => {
		if (!this.props.company.supportedConnections && this.props.usersCompany.name) {
			const company = await this.props.getCompany(this.props.usersCompany.code);
			this.props.setCompany(company);
		}
		if (hasProp(this.props.formValues, 'includeCC') && this.props.formValues.includeCC) {
			console.log('click', this.props.formValues.includeCC);
		}

		/* because the users's company may not be set on didMount, we will set the cardType field on didUpdate. */
		/* the card type field is reliant upon the user's company */
		this.initCardTypeField(prevProps);
	};

	initCardTypeField = prevProps => {
		const hasCompany =
			(this.props.initialValues.selectedCardType && prevProps.initialValues.selectedCardType === undefined) ||
			!isEqual(this.props.company, prevProps.company) ||
			(!this.state.cardTypeField && this.props.company)
				? true
				: false;

		if (hasCompany) {
			const cardTypeField = (
				<Field
					name="selectedCardType"
					component={field => {
						if (!field.input.value && this.props.initialValues.selectedCardType) {
							field.input.onChange(this.props.initialValues.selectedCardType);
						}

						return hasProp(this.props.company, 'supportedConnections') &&
							this.props.company.supportedConnections.length === 1 ? (
							<ReadOnlyInput
								dataCy="cc-type"
								labelKey="ConnectToPoints.inputs.creditcardType.singleCardLabel"
								value={`${
									this.props.company.supportedConnections[0].issuer.name
								} (${preferredCCTypeDisplayText(this.props.company.supportedConnections[0].cardType)})`}
							/>
						) : (
							<ReduxFormCCTypeDropdown field={field} />
						);
					}}
				/>
			);

			this.setState({ cardTypeField });
		}
	};

	/**
	 * Init the company field as a redux field that renders our company select component.
	 */
	initCompanyField = () => {
		let companyField = (
			<Field
				name="company"
				component={() => {
					return <CompanySelect readOnly={true} className="mt-2 mb-2" />;
				}}
			/>
		);

		this.setState({ companyField });
	};

	/**
	 * Init the company field as a redux field that renders a file input.
	 */
	initFileField = () => {
		const fileField = (
			<Field
				name="file"
				type="file"
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								dataCy: 'csv',
								fileType: 'text/csv',
								hasError: !field.meta.valid,
								error: field.meta.error ? this.props.translate(field.meta.error) : '',
								id: 'file',
								label: this.props.translate('AdminDashboard.AutoEnrollAdminPage.uploadBtn'),
								placeholder: false,
								name: 'file',
								onChange: this.onFileChange.bind(this, field),
								type: 'file'
							}}
						/>
					);
				}}
			/>
		);

		this.setState({ fileField });
	};

	initCreditCardToggleField = () => {
		let creditCardToggleField = (
			<Field
				name="includeCC"
				type="checkbox"
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								dataCy: 'includeCC-field',
								type: 'checkboxSingle',
								required: false,
								option: {
									label: 'File includes credit card info',
									id: 'includeCC',
									name: 'includeCC',
									checked: field.input.checked
								},
								isReduxForm: true,
								id: 'input-includeCC',
								name: 'input_includeCC',
								label: 'File includes credit card info',
								onChange: this.onIncludeCCChange.bind(this, field),
								triggers: []
							}}
						/>
					);
				}}
			/>
		);

		this.setState({ creditCardToggleField });
	};

	onFileChange = (field, e) => {
		field.input.onChange(e.target.files[0]);
		this.setState({ formError: false, hasErrors: false, didSubmit: false, processed: false });
	};

	onIncludeCCChange = (field, e) => {
		field.input.onChange(e.target.checked);
	};

	/**
	 * Our submit funciton for when the user submits the form.
	 * @param  {object} vals This will be the redux-form vals object.
	 */
	submit = async vals => {
		this.setState({ didSubmit: false, formError: false, hasErrors: false });
		const errors = this.validate(vals);
		if (Object.keys(errors).length) {
			throw new SubmissionError(errors);
		} else {
			this.props.setIsLoading(true);
			try {
				const processed = await this.processCSVFile(vals.file);
				await this.handleProcessedResults(vals.file, processed);
			} catch (err) {
				console.log('error caught while processing csv file', err);
			} finally {
				this.props.setIsLoading(false);
			}
		}
	};

	processCSVFile = async file => {
		const connectionType = this.props.formValues.selectedCardType.type,
			cardType = this.props.formValues.selectedCardType.cardType,
			fullNumberRequired = this.props.formValues.selectedCardType.fullNumberRequired,
			cardRequired = this.props.formValues.includeCC,
			processed = await doPapaParse(file, {
				complete: processCSVFromCardType.bind(
					this,
					connectionType,
					cardType,
					cardRequired,
					fullNumberRequired,
					this.props.translate
				)
			});
		return processed;
	};

	handleProcessedResults = async (file, processed) => {
		if (processed.results.errors.length > 0) {
			this.generateTableData(processed.results.errors, processed.results.error);
			this.setState({ hasErrors: true, processed });
		} else {
			await this.uploadToAWS(file, processed);
		}
	};

	uploadToAWS = async (file, processed) => {
		this.props.setIsLoading(true);

		const didUpload = await AWSUpload({
				idToken: this.props.idToken,
				username: this.props.username,
				file,
				processedData: Papa.unparse([...processed.results.valids, ...processed.results.errors]),
				supportedConnectionId: this.props.formValues.selectedCardType.id
			}),
			uploadCount = processed.results.errors.length + processed.results.valids.length;

		if (didUpload === true) {
			this.setState({ didSubmit: true, uploadCount, hasErrors: false, formError: false, uploadError: false });
		} else {
			if (didUpload.code === 'NotAuthorizedException') {
				this.uploadToAWS(file, processed);
			} else {
				this.setState({ uploadError: didUpload.message, didSubmit: true, hasErrors: false });
			}
		}

		this.props.setIsLoading(false);
	};

	validate = vals => {
		let errors = {};

		if (!vals.file) {
			errors.file = 'required.required';
		}

		if (vals.file && vals.file.type !== 'text/csv' && vals.file.name.indexOf('.csv') === -1) {
			errors.file = 'AdminDashboard.AutoEnrollAdminPage.inputs.file.errors.invalidFileType';
		}

		if (!vals.selectedCardType) {
			errors.selectedCardType = 'required.required';
		}

		return errors;
	};

	generateTableData = (errors, formError) => {
		const tableData = errors.reduce(
			(accum, error, i) => {
				let rowData = [];
				Object.keys(error).forEach(key => {
					if (key === 'row' || key === 'errors') {
						if (i === 0) {
							// create table headers from first error object, all error objects will have the same keys.
							if (key === 'row') {
								// make row the first header
								accum.headers.splice(0, 0, { title: startCase(key) });
							} else {
								// make errors the second header
								accum.headers.splice(1, 0, { title: startCase(key) });
							}
						}

						if (key === 'row') {
							// make row the column value
							rowData.splice(0, 0, error[key]);
						} else {
							// make errors the second column value
							rowData.splice(1, 0, error[key].join(', '));
						}
					}
				});
				accum.rows.push({ values: rowData });
				return accum;
			},
			{ headers: [], rows: [] }
		);

		return this.setState({ formError, tableData });
	};

	prepareForNewUpload = () => {
		this.props.setFormValue('file', null);
		this.setState({ formError: false, hasErrors: false, uploadError: false, didSubmit: false, fileField: null });
		this.initFileField();
	};

	leavePage = () => {
		history.push('/');
	};

	/**
	 * Render a form with the redux-form provided handleSubmit prop.  The handleSubmit prop expects to be passed a submit function.
	 * If no submit funciton is provided, the form will subumit by the default html form submit funcitonality.
	 * @return {[type]} [description]
	 */
	render = () => {
		const { translate } = this.props,
			successfullySubmitted =
				!this.state.formError && !this.state.hasErrors && !this.state.uploadError && this.state.didSubmit;

		const hasConnections = this.props.company.supportedConnections;

		return (
			<React.Fragment>
				{successfullySubmitted && (
					<div>
						{this._successMessage(this.state.uploadCount)}
						<EditProfileActions
							onUpdate={this.prepareForNewUpload}
							updateButtonText={translate('AdminDashboard.AutoEnrollAdminPage.uploadAnotherBtn')}
							cancelButtonText={translate('common.done')}
							onCancel={this.leavePage}
						/>
					</div>
				)}
				{!successfullySubmitted && (
					<React.Fragment>
						<Text paragraph size="sm" className="mt-6">
							{translate('AdminDashboard.AutoEnrollAdminPage.formHelp')}
						</Text>
						<form onSubmit={this.props.handleSubmit(this.submit)} className="auto-enroll-form">
							{!hasConnections && <LoadingBase />}
							{hasConnections && (
								<React.Fragment>
									{this.state.companyField}
									{this.state.cardTypeField}
									{this.state.fileField}
									{this.state.creditCardToggleField}
								</React.Fragment>
							)}
							{this._errorMessage()}
							{this._uploadError()}
							{hasConnections && (
								<EditProfileActions
									updateButtonDisabled={this.state.hasErrors}
									updateButtonText={translate('common.submit')}
									onCancel={this.props.onCancel}
								/>
							)}
						</form>
					</React.Fragment>
				)}

				<Text paragraph align="center" className="mt-10 mb-0">
					{translate('AdminDashboard.AutoEnrollAdminPage.help')}
				</Text>
			</React.Fragment>
		);
	};

	_successMessage = uploadedCount => {
		return (
			<div className="sucess-wrapper" data-cy="success-wrapper">
				<Text dataCy="success-header" align="center">
					{this.props.translate('common.success')}
				</Text>
				<Text dataCy="success-message" align="center">
					{this.props
						.translate('AdminDashboard.AutoEnrollAdminPage.successMessage')
						.replace('${x}', uploadedCount)}
				</Text>
			</div>
		);
	};

	_errorMessage = () => {
		return this.state.hasErrors ? (
			<div className="table-wrapper mt-8" data-cy="error-wrapper">
				<Text align="center" size="sm" dataCy="generic-error" className="generic error pt-3">
					{this.props.translate('AdminDashboard.AutoEnrollAdminPage.errorsMessage')}
				</Text>
				<Table dataCy="auto-enroll-errors" {...this.state.tableData} />
			</div>
		) : null;
	};

	_uploadError = () => {
		return this.state.uploadError ? (
			<Text align="center" className="error" dataCy="upload-error">
				{this.props.translate('AdminDashboard.AutoEnrollAdminPage.permissionsError')}
			</Text>
		) : null;
	};
}

AutoEnrollAdminForm.defaultProps = {};

AutoEnrollAdminForm.propTypes = {
	company: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.number.isRequired]), // comes from general store, includes supported connections
	formValues: PropTypes.object.isRequired,
	getCompany: PropTypes.func.isRequired,
	idToken: PropTypes.string,
	initialValues: PropTypes.shape({
		company: PropTypes.object,
		file: PropTypes.object,
		selectedCardType: PropTypes.object,
		creditCardToggleField: PropTypes.object
	}).isRequired,
	setIsLoading: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired, // redux-form provided prop
	onCancel: PropTypes.func.isRequired,
	setCompany: PropTypes.func.isRequired,
	setFormValue: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired,
	username: PropTypes.string.isRequired,
	usersCompany: PropTypes.object.isRequired // comes from users store, does not include supported connections
};

export default AutoEnrollAdminForm;
