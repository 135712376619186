import CardFace from './ui-component';
import withSizes from 'react-sizes';
import { isLV } from 'utils/sizes';
import { connect } from 'react-redux';
import { setActiveCard, setShowAbout } from 'actions/landing_page_cards';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = (state, ownProps) => ({
	isActive: state.landingCards.activeCard === ownProps.cardId && state.landingCards.activeCard !== undefined,
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	open: cardKey => dispatch(setActiveCard(ownProps.cardId, cardKey)),
	setShowAbout: show => dispatch(setShowAbout(show, ownProps.cardId))
});

const mapSizesToProps = sizes => ({
	isLV: isLV(sizes)
});

export default connect(mapStateToProps, mapDispatchToProps)(withSizes(mapSizesToProps)(CardFace));
