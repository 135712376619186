import Cookies from 'universal-cookie';
import { STORE } from 'components/environment/App/redux_store.js';

export const basePath = () => {
	let config = STORE.getState().config;
	let basePath = config[`api_basePath_myDinova`];
	return basePath;
};

export const basePath_restaurant = () => {
	let config = STORE.getState().config;
	let basePath = config[`api_basePath_restaurant`];
	return basePath;
};

export const basePath_dashboard = () => {
	let config = STORE.getState().config;
	let basePath = config[`api_basePath_dashboard`];
	return basePath;
};

export const token = () => {
	let cookies = new Cookies();
	let users = STORE.getState().users;
	if (cookies.get('token')) {
		return cookies.get('token');
	} else if (users.authenticated) {
		return users.authenticated;
	}
	return null;
};

export const id = () => {
	let cookies = new Cookies();
	let users = STORE.getState().users;
	if (cookies.get('idToken')) {
		return cookies.get('idToken');
	} else if (users.idToken) {
		return users.idToken;
	}
	return null;
};

export const AUTH_REQUEST_HEADER = () => ({
	headers: {
		Authorization: token(),
		'Content-type': 'application/json'
	}
});

export const AUTH_REQUEST_HEADER_ID = () => ({
	headers: {
		Authorization: id(),
		'Content-type': 'application/json'
	}
});
