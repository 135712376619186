import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Text } from 'components/atoms';
import { FilterGroup, FilterGroupItem } from 'components/molecules';
import { startCase } from 'lodash';

class SearchFilter extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tempFilters: props.filters
		};
	}

	toggleFilter = async filter => {
		if (this.props.useTempFilters) {
			// this.toggleTempFilter(filter);
		} else {
			filter = { ...filter, selected: !filter.selected };

			if (filter.selected) {
				await this.props.addFilter(filter);
			} else {
				await this.props.removeFilter(filter);
			}
		}
	};

	desktopHeader = () => {
		const { isSV, translate } = this.props;

		let filters = Object.entries(this.props.filters).reduce((accum, [key, value]) => {
			let filterOptions = Object.entries(value).reduce((accum, [vKey, vValue]) => {
				if (
					vKey === 'activeCount' ||
					vKey === 'filterType' ||
					vKey === 'filterFunc' ||
					vKey === 'resultsCount'
				) {
					return accum;
				} else {
					if (vValue.resultsCount === 0) {
						return accum;
					} else {
						return [
							...accum,
							<FilterGroupItem
								key={vKey}
								onClick={this.toggleFilter}
								filter={vValue}
								filterName={vValue.filterOptionDisplayValue || startCase(vKey)}
								useCheck
								showResultsCount
							/>
						];
					}
				}
			}, []);
			return {
				...accum,
				[key]: filterOptions
			};
		}, {});

		return Object.entries(filters).map(([filterName, value], i) => {
			let className = classnames('pb-2', i !== 0 && 'pt-2', isSV && 'full-width');

			return (
				<FilterGroup
					key={filterName}
					filterName={filterName}
					options={value}
					className={className}
					concat={true}
					expandText={{
						closed: `${translate('common.show')} ${translate('common.more')}`,
						open: `${translate('common.show')} ${translate('common.less')}`
					}}
				/>
			);
		});
	};

	mobileHeader = () => {
		return <div className="filters flex-col">{this.desktopHeader()}</div>;
	};

	render() {
		const { isSV, translate } = this.props;
		const classProps = classnames('filter-wrapper', this.props.className);

		if (this.props.hasFilters) {
			if (isSV) {
				return (
					<>
						<div className="filter-header">
							<Text size="md" weight="bold" align="center">
								{translate('FilterModal.title')}
							</Text>
						</div>
						{this.mobileHeader()}
					</>
				);
			} else {
				return <div className={classProps}>{this.desktopHeader()}</div>;
			}
		} else {
			return null;
		}
	}
}

SearchFilter.defaultProps = {};

SearchFilter.propTypes = {
	addFilter: PropTypes.func.isRequired,
	useTempFilters: PropTypes.bool,
	className: PropTypes.string,
	filters: PropTypes.shape({
		amenities: PropTypes.object,
		cuisine: PropTypes.object,
		dietaryPreferences: PropTypes.object
	}),
	hasFilters: PropTypes.bool.isRequired,
	isSV: PropTypes.bool.isRequired,
	removeFilter: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

export default SearchFilter;
