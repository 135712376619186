import { containsNoSpaceValidator, lengthValidator } from 'utils/form/validators';

const usernameWarning = username => {
	const usernameWarning = {
		requirements: [
			{
				status: lengthValidator(username, 3, false) ? 'valid' : 'invalid',
				textKey: 'Registration.requirement-boxes.username.1'
			},
			{
				status: containsNoSpaceValidator(username) ? 'valid' : 'invalid',
				textKey: 'Registration.requirement-boxes.username.2'
			}
		],
		titleKey: 'Registration.requirement-boxes.username.title'
	};

	return usernameWarning;
};

export default usernameWarning;
