import React from 'react';
import PropTypes from 'prop-types';
import OptionsList from 'components/organisms/OptionsList';
import { Title } from 'components/atoms';

// import ./style.scss

/**
 * ToGoOptionsList
 * @param {ToGoOptionsListProps} props
 */
class ToGoOptionsList extends React.Component {
	getImage = option => option.image;

	getOptionText = option => option.title;

	render() {
		const { options, translate } = this.props;

		return options.length > 0 ? (
			<div className={'togo-options-list'}>
				<Title transform="none" size="h4" className="mb-4" align="left">
					{translate('Details.togo-options.title')}
				</Title>
				<OptionsList
					options={options}
					getOptionText={this.getOptionText}
					useImage={true}
					getImage={this.getImage}
					className="togo-options"
					dataCy="togo-options"
				/>
			</div>
		) : null;
	}
}

ToGoOptionsList.defaultProps = {};

/**
 * ToGoOptionsListProps
 * @typedef {Object} ToGoOptionsListProps
 */
ToGoOptionsList.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			checked: PropTypes.bool.isRequired
		})
	).isRequired,
	translate: PropTypes.func.isRequired
};

export default ToGoOptionsList;
