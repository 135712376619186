import React from 'react';
import PropTypes from 'prop-types';
import { Text, Title } from 'components/atoms';

/*
	Header for FAQ page.
 */
const HelpHeader = ({ translate }) => {
	return (
		<div className="help-header">
			<Title>{translate('HelpFAQ.header')}</Title>
			<Text align="center">{translate('HelpFAQ.main')}</Text>
		</div>
	);
};

HelpHeader.defaultProps = {};

HelpHeader.propTypes = {
	translate: PropTypes.func.isRequired
};

export default HelpHeader;
