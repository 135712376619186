import ProfileSetupModal from './ui-component';
import { connect } from 'react-redux';
import { goToNextStep, toggleModalOpen, closeModal, finishOnboarding } from 'actions/profile_setup';
import { withRouter } from 'react-router-dom';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => {
	const isAuthenticated = Boolean(state.users.authenticated);
	const isUserLoaded = Boolean(state.users.user);
	const mfaModalOpen = state.modal.id === 'mfa' && state.modal.isOpen;

	let isSetupComplete = false;
	let registrationDate = null;
	let isFinishedViewed = false;
	let showComplete = false;

	if (isUserLoaded) {
		const { onboardingStatus } = state.users.user;
		const {
			completeProfile,
			optInNotification,
			favoriteRestaurant,
			connectedCard,
			viewCompletion
		} = onboardingStatus;
		showComplete =
			onboardingStatus.optInNotification == true &&
			onboardingStatus.completeProfile == true &&
			onboardingStatus.favoriteRestaurant == true &&
			onboardingStatus.connectedCard == true;
		registrationDate = new Date(state.users.user.registered.date);
		isSetupComplete = completeProfile && optInNotification && favoriteRestaurant && connectedCard && viewCompletion;
		isFinishedViewed = viewCompletion;
		console.log(
			'status:',
			onboardingStatus.optInNotification,
			onboardingStatus.completeProfile,
			onboardingStatus.favoriteRestaurant,
			onboardingStatus.connectedCard
		);
	}

	const setupModalOpen =
		isUserLoaded && !mfaModalOpen && state.profileSetup.open && isAuthenticated && !isSetupComplete;

	const trueCount = state.profileSetup.completedSteps;

	return {
		registrationDate,
		isAuthenticated,
		currentStep: state.profileSetup.currentStep,
		isSetupComplete: isSetupComplete,
		isLoading: !isUserLoaded,
		mfaModalOpen,
		open: state.profileSetup.open,
		modalOpen: setupModalOpen,
		translate: getTranslate(state.locale),
		completedSteps: trueCount,
		totalSteps: 4,
		isFinishedViewed,
		showComplete
	};
};

const mapDispatchToProps = dispatch => ({
	goToNextStep: () => dispatch(goToNextStep()),
	toggleModalOpen: () => dispatch(toggleModalOpen()),
	closeModal: () => dispatch(closeModal()),
	finishOnboarding: () => dispatch(finishOnboarding())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileSetupModal));
