import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text } from 'components/atoms';

const SearchPageViewToggles = ({
	activeFilterCount,
	IMAGE_BASE_URL,
	isSV,
	showFilter,
	showList,
	toggleShowFilter,
	toggleShowList,
	translate
}) => {
	const renderLV = () => {
		const toggleFilterLabel = showFilter ? 'Search.hideFilters' : 'Search.showFilters';

		return (
			<div className="search-page-view-toggles">
				<div
					className={classnames(showFilter && 'open', 'toggle-list-btn')}
					onClick={toggleShowFilter.bind(this, !showFilter)}
				>
					<i className="fa fa-chevron-right mr-2" />
					<Text size="sm" weight="bold" className="label">
						{translate(toggleFilterLabel)}
					</Text>
				</div>
			</div>
		);
	};

	const renderSV = () => {
		const toogleListImgName = showList && isSV ? 'map' : 'list',
			toggleListLabel = (showList ? 'Search.hideList' : 'Search.showList') + (isSV ? 'Short' : ''),
			toggleListImg = `${IMAGE_BASE_URL}/icons/icon-${toogleListImgName}-green.svg`,
			toggleListClassProps = classnames(toogleListImgName + '-icon', 'icon');

		return (
			<div className="search-page-view-toggles">
				<div className="title" onClick={toggleShowFilter.bind(this, !showFilter)}>
					<span className="flex">
						<i className={'fa fa-chevron-down'} />
					</span>
					<Text dataCy="mobile-show-filters-toggle" size="sm" weight="bold">
						{translate('FilterModal.title')}
					</Text>

					{activeFilterCount > 0 && <div className="filter-count ml-1">({activeFilterCount})</div>}
				</div>

				<div className="toggle-list-btn" onClick={toggleShowList.bind(this, !showList)}>
					<img
						className={toggleListClassProps}
						src={toggleListImg}
						alt={showList ? 'hide results list' : 'show results list'}
					/>
					<Text size="sm" weight="bold" className="label">
						{translate(toggleListLabel)}
					</Text>
				</div>
			</div>
		);
	};

	if (!isSV) {
		return renderLV();
	} else {
		return renderSV();
	}
};

SearchPageViewToggles.defaultProps = {
	selectedFilters: []
};

SearchPageViewToggles.propTypes = {
	activeFilterCount: PropTypes.number.isRequired,

	isSV: PropTypes.bool.isRequired,
	selectedFilters: PropTypes.array.isRequired,
	showFilter: PropTypes.bool.isRequired,

	toggleShowFilter: PropTypes.func.isRequired,
	toggleShowList: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

export default SearchPageViewToggles;
