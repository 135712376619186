import React from 'react';
import PropTypes from 'prop-types';
import OptionsList from 'components/organisms/OptionsList';

/**
 * DiningOptionsList
 * @param {DiningOptionsListProps} props
 */
class DiningOptionsList extends React.Component {
	getIcon = option => {
		return option.checked ? 'fa fa-check' : 'fa fa-times';
	};

	getOptionText = option => option.title;

	render() {
		const { isLV, options /*, translate*/ } = this.props;

		return (
			<div className={'dining-options-list'}>
				<OptionsList
					options={options}
					getOptionText={this.getOptionText}
					useIcon={true}
					getIcon={this.getIcon}
					className="dining-options"
					dataCy="dining-options"
					getOptionClass={() => (isLV ? 'full-width' : 'half-width')}
				/>
			</div>
		);
	}
}

DiningOptionsList.defaultProps = {};

/**
 * DiningOptionsListProps
 * @typedef {Object} DiningOptionsListProps
 */
DiningOptionsList.propTypes = {
	isLV: PropTypes.bool.isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			checked: PropTypes.bool.isRequired
		})
	).isRequired
	// translate: PropTypes.func.isRequired
};

export default DiningOptionsList;
