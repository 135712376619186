import React from 'react';
import PropTypes from 'prop-types';
import { BasicModal } from 'components/molecules';
import { Link, Text, Title } from 'components/atoms';
import { Button } from 'reactstrap';

class RecommendationsThankYouModal extends React.Component {
	componentWillUnmount = () => {
		const { toggleThankYou } = this.props;

		toggleThankYou(false);
	};

	render() {
		const { show, toggleThankYou, translate } = this.props;

		const pointVal = translate('Recommendations.pointVal'),
			isFromTransactionRecommendation = window.location.pathname.indexOf('history') > -1,
			thankYouMsg = translate(
				`Recommendations.thankYouMessage-${isFromTransactionRecommendation ? 'Transactions' : 'Details'}`
			);

		return (
			<BasicModal className="recommendations_ty_modal" show={show} showClose={false} maxWidth={'550px'}>
				<Title size="h2" align="center">
					{translate('common.thankYou')}
				</Title>
				<Text size="sm">{thankYouMsg}</Text>
				{isFromTransactionRecommendation && (
					<Link>
						<Text size="sm">
							{translate('Recommendations.thankYouPointsReceived').replace('{points}', pointVal)}
						</Text>
					</Link>
				)}
				<Button className={'submit'} color="primary" onClick={toggleThankYou.bind(this, false)}>
					{translate('common.continueBtn')}
				</Button>
			</BasicModal>
		);
	}
}

RecommendationsThankYouModal.defaultProps = {};

RecommendationsThankYouModal.propTypes = {
	show: PropTypes.bool.isRequired,
	toggleThankYou: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired
};

export default RecommendationsThankYouModal;
