import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Text } from 'components/atoms';
import { startCase } from 'lodash';
import { getSpecialFilterLabel } from 'utils/search_new';

const ActiveFiltersBar = ({ activeFilters }) => {
	const classProps = classnames('active-filter-bar', activeFilters && activeFilters.length > 0 && 'active');

	return activeFilters && activeFilters.length > 0 ? (
		<div className={classProps}>
			<div className="active-filter-list-wrapper">
				<Text className="flex mb-0">
					{activeFilters
						.map(f => {
							let name = f.selectedFilterDisplayValue || startCase(f.value);
							let spcialLabel = getSpecialFilterLabel(f.dataAccr);

							return spcialLabel || name;
						})
						.join(', ')}
				</Text>
			</div>
		</div>
	) : null;
};

ActiveFiltersBar.defaultProps = {};

ActiveFiltersBar.propTypes = {
	activeFilters: PropTypes.arrayOf(
		PropTypes.shape({
			dataAccr: PropTypes.string.isRequired
		})
	)
};

export default ActiveFiltersBar;
