import FAQReferACoworker from './ui-component';
import { connect } from 'react-redux';
import { getProfile } from 'actions/users';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	getProfile: showLoading => dispatch(getProfile(showLoading))
});

export default connect(mapStateToProps, mapDispatchToProps)(FAQReferACoworker);
