import LocationsBar from './ui-component';
import { connect } from 'react-redux';
import withSizes from 'react-sizes';
import { isLV } from 'utils/sizes';
import { hasProp } from 'utils/object';

const mapStateToProps = state => ({
	title: hasProp(state, 'details.restaurant.name') ? state.details.restaurant.name : '',
	restaurant: state?.details?.restaurant,
	restaurantId: state?.details?.restaurant?.id
});

const mapSizesToProps = sizes => ({
	isLV: isLV(sizes)
});

export default connect(mapStateToProps)(withSizes(mapSizesToProps)(LocationsBar));
