import {
	emailValidator,
	lengthValidator,
	passwordValidator,
	phoneValidator,
	usernameValidator
} from 'utils/form/validators';

const validateRegistrationForm = (vals, isCateringRegistration) => {
	const errors = {};

	const usernameError = usernameValidator(vals.username);
	if (usernameError) {
		errors.username = usernameError;
	}

	const passwordError = passwordValidator(vals.password);
	if (passwordError) {
		errors.password = passwordError;
	}

	const firstnameValid = lengthValidator(vals.firstname, 1);
	if (!firstnameValid) {
		errors.firstname = 'Lorem.. Please enter a valid email.';
	}

	const lastnameValid = lengthValidator(vals.lastname, 1);
	if (!lastnameValid) {
		errors.lastname = 'Lorem.. Please enter a valid email.';
	}

	const emailError = emailValidator(vals.email, true);
	if (emailError) {
		errors.email = emailError;
	}

	if (!vals.termsAndConditions) {
		errors.termsAndConditions = 'Lorem.. You must accept the ts and cs.';
	}

	if (!vals.emailOpt) {
		errors.emailOpt = 'Lorem.. Please select an option.';
	}

	if (!vals.offersOpt) {
		errors.offersOpt = 'Lorem.. Please select an option.';
	}

	if (isCateringRegistration) {
		const phoneValid = phoneValidator(vals.phone);
		if (!phoneValid) {
			errors.phone = 'Lorem.. Please enter a valid phone number.';
		}

		if (!vals.companyCode) {
			errors.companyCode = 'Lorem.. you must provide your company name.';
		}
	}

	return errors;
};

export default validateRegistrationForm;
