import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link, Title } from 'components/atoms';
import { PaymentNotConnected, ProfilePaymentIneligibilityMessage } from 'components/molecules';
import { history } from 'utils/router';
import { getLangFileCardLabelKey } from 'utils/card';
import { WithHelp } from 'components/molecules';

class ProfilePaymentInfo extends Component {
	state = {
		connectionsCount: undefined,
		supportedConnectionCardTypes: [],
		ineligibleConnectionCardTypes: [],
		hasEligible: true,
		helpOpen: false
	};

	componentDidMount = async () => {
		const me = this;

		if (!this.state.connectionsCount && this.props.company.code) {
			let hasEligible = false;
			const res = await this.props.getCompany(this.props.company.code);
			let supportedConnectionCardTypes = [];
			let ineligibleConnectionCardTypes = new Set();
			(res.supportedConnections || []).forEach(c => {
				supportedConnectionCardTypes.push(c.cardType);
			});
			this.props.connections.forEach(c => {
				if (supportedConnectionCardTypes.indexOf(c.cardType) === -1) {
					ineligibleConnectionCardTypes.add(c.cardType);
				} else {
					hasEligible = true;
				}
			});
			me.setState({
				connectionsCount: (res.supportedConnections || []).length,
				supportedConnectionCardTypes,
				ineligibleConnectionCardTypes,
				hasEligible
			});
		}
	};

	onEdit = connection => {
		this.props.setCardToEdit(connection);
		history.push('/profile/payment/#/');
	};

	onDelete = connection => {
		this.props.deletePaymentInfo(connection.id, () => {});
	};

	setHelpOpen = index => {
		if (this.state.helpOpen === index) {
			this.setState({ helpOpen: false });
		} else {
			this.setState({ helpOpen: index });
		}
	};

	render() {
		const { connections, isDoD, isMerck, translate } = this.props,
			{ supportedConnectionCardTypes, ineligibleConnectionCardTypes } = this.state;

		// isMerck refers to if the user's company is Merck. Merkc is a 'blocked' company and cannot add connections or earn points.
		return isMerck ? null : (
			<div className="view-profile-section view-profile-payment section">
				<header className="list-header">
					<Title size="h2" align="left">
						{translate(`Profile.payment.title_new`)}
					</Title>
				</header>
				<div className="profile-section-list profile-payment-list">
					{connections.map((connection, index) => {
						let cardName = '';
						if (this.props.isDoD) {
							cardName = `${this.props.name.first} ${this.props.name.middle} ${this.props.name.last}`;
						} else {
							cardName = connection.nameOnCard;
						}
						const isEligible =
								supportedConnectionCardTypes.length > 0 &&
								supportedConnectionCardTypes.indexOf(connection.cardType) > -1,
							wrapperClassProps = classnames(
								!isEligible && 'ineligible has-help',
								this.state.helpOpen === index && 'help-open'
							),
							classProps = classnames('profile-section-item');

						return (
							<div key={connection.id} className={wrapperClassProps}>
								{!isEligible && (
									<WithHelp
										id={`profile-connection-item-${index}-help`}
										help={translate('RegisteredConnections.ineligibleConnectionHelp')}
										onWrapperClick={this.setHelpOpen.bind(this, index)}
									/>
								)}
								<div className="item" data-cy={`profile-connection-item-${index}`}>
									<div>
										<Link
											className={`profile-connection-item-edit-${index} goto-edit`}
											onClick={
												isEligible
													? this.onEdit.bind(this, connection)
													: this.onDelete.bind(this, connection)
											}
											id={`connection-edit-${index}`}
										>
											{isEligible ? translate('common.editBtn') : translate('common.delete')}
										</Link>
									</div>
									<div>
										<div className={classProps}>
											<Title inline size="h3" align="left">
												{connection.cardType
													? translate(
															`ConnectToPoints.inputs.creditcard.${getLangFileCardLabelKey(
																connection.cardType
															)}`
															/* eslint-disable no-mixed-spaces-and-tabs */
													  )
													: translate('ConnectToPoints.inputs.creditcard.label')}
											</Title>
											<span id={'company-field'} className="item-value">
												{`XXXX-XXXX-XXXX-${connection.last4}`}
											</span>
										</div>
										<div className={classProps}>
											<Title inline size="h3" align="left">
												{translate('Profile.payment.labels.cardholderName')}
											</Title>
											<span id={'company-field'} className="item-value">
												{cardName}
											</span>
										</div>
										{connection.employeeId && (
											<div className={classProps}>
												<Title inline size="h3" align="left">
													{translate('Profile.payment.labels.employeeId')}
												</Title>
												<span id={'employeeId-field'} className="item-value">
													{connection.employeeId}
												</span>
											</div>
										)}
									</div>
								</div>
							</div>
						);
					})}
				</div>

				<ProfilePaymentIneligibilityMessage
					ineligibleConnectionCardTypes={ineligibleConnectionCardTypes}
					supportedConnectionCardTypes={supportedConnectionCardTypes}
					hasEligible={this.state.hasEligible}
				/>

				{((isDoD && connections.length < 1) || (!isDoD && connections.length < 10)) && (
					<div className="center">
						<PaymentNotConnected showNotConnectedMessage={connections.length === 0} />
					</div>
				)}
			</div>
		);
	}
}

ProfilePaymentInfo.defaultProps = {};

ProfilePaymentInfo.propTypes = {
	company: PropTypes.object,
	connections: PropTypes.array.isRequired,
	deletePaymentInfo: PropTypes.func.isRequired,
	getCompany: PropTypes.func.isRequired,
	name: PropTypes.shape({
		first: PropTypes.string,
		middle: PropTypes.string,
		last: PropTypes.string
	}),
	isDoD: PropTypes.bool.isRequired,
	isMerck: PropTypes.bool.isRequired,
	translate: PropTypes.func.isRequired,
	setCardToEdit: PropTypes.func.isRequired
};

export default ProfilePaymentInfo;
