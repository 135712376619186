import { AMEX, VISA, MASTERCARD } from 'utils/card';

const isTrickDoDCleanState = formVals => {
	let hasValue = [];
	let formKeys = ['firstName', 'middleName', 'lastName', 'cardNumber'];
	formKeys.forEach(key => {
		hasValue.push(formVals[key] ? formVals[key].length > 0 : false);
	});
	if (hasValue.indexOf(true) === -1) {
		// empty form
		return true;
	}
	if (hasValue.indexOf(true) > -1 && hasValue.indexOf(false) > -1) {
		// in complete form
		return false;
	}
};

const isTrickCleanState = (formVals, isDoD) => {
	if (isDoD) {
		return isTrickDoDCleanState(formVals);
	}
	const isTrickCleanStateForCardTypeOne = !formVals.cardNumber && !formVals.nameOnCard;
	const isTrickCleanStateForCardTypeTwo = !formVals.cardNumber && !formVals.employeeId && !formVals.nameOnCard;
	const isTrickCleanStateForCardTypeThree = !formVals.cardNumber && !formVals.email && !formVals.nameOnCard;

	if (!formVals.selectedCardType) {
		return isTrickCleanStateForCardTypeTwo || isTrickCleanStateForCardTypeThree || isTrickCleanStateForCardTypeOne;
	} else {
		if (formVals.selectedCardType.type === AMEX) {
			return isTrickCleanStateForCardTypeOne;
		} else if (formVals.selectedCardType.type === VISA) {
			return isTrickCleanStateForCardTypeTwo;
		} else if (formVals.selectedCardType.type === MASTERCARD) {
			return isTrickCleanStateForCardTypeThree;
		} else {
			return (
				isTrickCleanStateForCardTypeTwo || isTrickCleanStateForCardTypeThree || isTrickCleanStateForCardTypeOne
			);
		}
	}
};

export default isTrickCleanState;
