import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { BasicModal } from 'components/molecules';
import { Text, Title } from 'components/atoms';
import { CTANav } from 'components/molecules';
import { history } from 'utils/router';

class CateringUnavailableModal extends React.Component {
	componentDidMount = async () => {
		if (!this.props.links.length) {
			await this.props.getZones();
		}
	};

	searchBusinessDining = () => {
		const { formLocation, setShowNoServiceNotice } = this.props;

		if (formLocation) {
			setShowNoServiceNotice(false);
			history.push(`/search?near=${formLocation}&amenities=catering`);
		}
	};

	render() {
		const { links, onClose, isSV, show, translate } = this.props;

		return (
			<BasicModal show={show} onClose={onClose} className="no-catering-service-modal">
				<div className="no-catering-service">
					{!isSV && <div className="splash" />}
					<div className="no-catering-service-content-wrapper">
						<div>
							<Title size="h3">{translate('Catering.zoneUnavailability.title')}</Title>
							<Text size="md" weight="bold" paragraph align="center">
								{translate('Catering.zoneUnavailability.notice')}
							</Text>
							<Text size="sm" paragraph align="center">
								{translate('Catering.zoneUnavailability.description')}
							</Text>
							<Button color="primary" onClick={this.searchBusinessDining}>
								{translate('common.searchCptl')}
							</Button>
						</div>
						<div className="basic-modal-footer">
							<Title transform="none" size="h5">
								{translate('Catering.zoneUnavailability.availabilityNotice')}
							</Title>
							<CTANav links={links} />
						</div>
					</div>
				</div>
			</BasicModal>
		);
	}
}

CateringUnavailableModal.defaultProps = {
	getZones: () => {
		console.log('default prop: getZones()');
	},
	onClose: () => {
		console.log('default prop: onClose()');
	},
	links: [],
	show: false
};

CateringUnavailableModal.propTypes = {
	getZones: PropTypes.func.isRequired,
	isSV: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	links: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			action: PropTypes.func
		})
	),
	formLocation: PropTypes.string,
	setShowNoServiceNotice: PropTypes.func.isRequired,
	show: PropTypes.bool,
	translate: PropTypes.func.isRequired
};

export default CateringUnavailableModal;
