import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Text, Title } from 'components/atoms';
import { WithHelp } from 'components/molecules';
import { phoneFormatter } from 'utils/formatters';

class ProfileSectionItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false
		};
	}

	toggleShow = () => {
		this.setState({ show: !this.state.show });
	};

	render() {
		const { className, item, section, translate } = this.props;
		const classProps = classNames(
			'profile-section-item',
			!item.value && !item.isDesc && 'disabled',
			className,
			item.className && item.className,
			item.help && 'has-help'
		);

		if (item.isDesc) {
			return (
				<div className={classProps}>
					<Text size="xs">{translate(`Profile.${section}.description`)}</Text>
				</div>
			);
		}

		let value;
		if (item.key === 'phone') {
			value = phoneFormatter(item.value);
		} else if (item.private) {
			value = this.state.show ? item.value : 'X'.repeat(item.value.length);
		} else {
			value = item.value;
		}

		return (
			<div className={classProps}>
				{item.help && <WithHelp id={`profile-${section}-help`} help={item.help} />}
				<Title inline size="h3" align="left">
					{translate(`Profile.${section}.labels.${item.key}`)}
					{item.required && <span className="required">*</span>}:
				</Title>
				<span id={`${item.key}-field`} className="item-value">
					{value}
				</span>
				{item.private && (
					<span onClick={this.toggleShow} className="ml-1">
						<span className={classNames(this.state.show ? '' : 'hidden')}>
							<i className={'fa fa-eye-slash'} aria-hidden="true" />
						</span>
						<span className={classNames(this.state.show ? 'hidden' : '')}>
							<i className={'fa fa-eye'} aria-hidden="true" />
						</span>
					</span>
				)}
			</div>
		);
	}
}

ProfileSectionItem.defaultProps = {
	className: ''
};

ProfileSectionItem.propTypes = {
	className: PropTypes.string,
	item: PropTypes.object.isRequired,
	section: PropTypes.oneOf(['personal', 'payment', 'preferences', 'security', 'recommendations']).isRequired,
	translate: PropTypes.func.isRequired
};

export default ProfileSectionItem;
