import ProfileSetupProgressBar from './ui-component';
import { connect } from 'react-redux';
import { selectCompletedStepsCount } from 'actions/profile_setup/selectors';

const mapStateToProps = state => {
	return {
		completedStepsCount: selectCompletedStepsCount(state),
		totalSteps: state.profileSetup.totalSteps
	};
};

export default connect(mapStateToProps)(ProfileSetupProgressBar);
