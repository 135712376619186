import CompleteProfile from './ui-component';
import { connect } from 'react-redux';
import { completeStep, updateForm } from 'actions/profile_setup';
import { update, updateOnboardingStatus } from 'actions/users';
import { ProfileSetupSteps } from 'reducers/profile_setup';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => {
	const specialOffers = state.users.user.preferences.notification.specialOffers;
	let status = false;
	if (
		specialOffers === 'email' ||
		(typeof specialOffers === 'object' && specialOffers.value.toLowerCase() === 'email')
	) {
		status = true;
	}

	return {
		defaultOptInToEmails: status,
		formOptInToEmails: state.profileSetup.form.optInToEmails,
		translate: getTranslate(state.locale)
	};
};

const mapDispatchToProps = dispatch => ({
	completeStep: doEmail => () => {
		dispatch(update({ preferences: { notification: { specialOffers: doEmail ? 'email' : 'none' } } }));
		dispatch(completeStep(doEmail ? ProfileSetupSteps.EMAILS : ProfileSetupSteps.EMAILS_NOT_READY));
		const onboardingStatus = { optInNotification: doEmail };
		dispatch(updateOnboardingStatus({ onboardingStatus }));
	},
	updateForm: ({ field, value }) => dispatch(updateForm({ field, value }))
});

export default connect(mapStateToProps, mapDispatchToProps)(CompleteProfile);
