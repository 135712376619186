import MFAModalPage from './ui-component';
import { connect } from 'react-redux';
import { toggleModal } from 'actions/modal';
import { reduxForm } from 'redux-form';
import { hasProp } from 'utils/object';
import { login, authorize_mfa, refresh } from 'actions/users';

const mapDispatchToProps = dispatch => ({
	toggleModal: isOpen => dispatch(toggleModal(isOpen)),
	login: (user, callback) => dispatch(login(user, callback)),
	authorize_mfa: (user, callback) => dispatch(authorize_mfa(user, callback)),
	refresh: () => dispatch(refresh())
});

const mapStateToProps = state => ({
	modal: state.modal,
	config: state.config,
	user: state.users.user,
	formVals: hasProp(state, 'form.mfaForm.values') ? state.form.mfaForm.values : {}
});

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: 'mfaForm' })(MFAModalPage));
