import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { EditProfileActions, RequiredFieldNote } from 'components/molecules';
import { ProfileSectionList, ReduxFormCCTextBox, ReduxFormInput } from 'components/organisms';
import { SubmissionError } from 'redux-form';
import validateEditPaymentForm from './validate';
import { getInternalConnectionType } from 'utils/card';
import { isEqual } from 'lodash';

class EditPaymentFormNew extends Component {
	constructor(props) {
		super(props);

		this.state = {
			employeeIdField: null,
			cardNumberField: null,
			nameOnCardField: null,
			firstNameField: null,
			middleNameField: null,
			lastNameField: null,
			emailField: null
		};

		if (!props.card) {
			this.props.history.replace('/profile/#/');
		}
	}

	componentDidMount = () => {
		this.initEmployeeIdField();
		this.initCardNumberField();
		this.initNameOnCardField();
		this.initEmailField();
		this.initFirstNameField();
		if (this.props.isDoD) {
			this.initMiddleNameField();
		}
		this.initLastNameField();
	};

	componentWillUnmount = () => {
		this.props.clearErrors();
	};

	initEmployeeIdField = () => {
		const { card, translate } = this.props,
			employeeIdField = (
				<Field
					name="employeeId"
					component={field => (
						<ReduxFormInput
							field={field}
							inputProps={{
								dataCy: 'employeeId-field',
								name: 'input_employeeId',
								id: 'input-employeeId',
								error: translate('ConnectToPoints.inputs.employeeId.errorText'),
								hasError: !field.meta.valid,
								label: translate('ConnectToPoints.inputs.employeeId.label'),
								help: translate('ConnectToPoints.inputs.employeeId.help'),
								example:
									card && card.instructions
										? card.instructions.connection === 'null'
											? ''
											: card.instructions.connection
										: null
							}}
						/>
					)}
				/>
			);

		this.setState({ employeeIdField });
	};

	initCardNumberField = () => {
		const { card, isDoD } = this.props,
			cardNumberField = (
				<Field
					name="cardNumber"
					component={field => {
						return isDoD ? (
							<ReduxFormCCTextBox
								field={field}
								card={card}
								disabled={this.state.connectionCount === 10}
								required={true}
								label={'Full GTCC Number'}
							/>
						) : (
							<ReduxFormCCTextBox field={field} card={card} />
						);
					}}
				/>
			);

		this.setState({ cardNumberField });
	};

	initNameOnCardField = () => {
		const { translate } = this.props,
			nameOnCardField = (
				<Field
					name="nameOnCard"
					component={field => {
						return (
							<ReduxFormInput
								field={field}
								inputProps={{
									dataCy: 'nameOnCard-field',
									name: 'input_nameOnCard',
									id: 'input-nameOnCard',
									error: translate('ConnectToPoints.inputs.cardholderName.errorText'),
									hasError: !field.meta.valid,
									label: translate('ConnectToPoints.inputs.cardholderName.label'),
									help: translate('ConnectToPoints.inputs.cardholderName.help'),
									example: translate('ConnectToPoints.inputs.cardholderName.example')
								}}
							/>
						);
					}}
				/>
			);

		this.setState({ nameOnCardField });
	};

	initFirstNameField = () => {
		const { translate } = this.props,
			firstNameField = (
				<Field
					name="firstName"
					component={field => {
						return (
							<ReduxFormInput
								field={field}
								inputProps={{
									// className: classnames(this.props.isLV && 'mt-0', !this.props.isLV && 'mt-8'),
									dataCy: 'firstName-field',
									name: 'input_firstName',
									id: 'input-firstName',
									error: translate('ConnectToPoints.inputs.firstName.errorText'),
									hasError: !field.meta.valid,
									label: translate('ConnectToPoints.inputs.dod-firstName.label'),
									required: true
								}}
							/>
						);
					}}
				/>
			);
		this.setState({ firstNameField });
	};

	initMiddleNameField = () => {
		const { translate } = this.props,
			middleNameField = (
				<Field
					name="middleName"
					component={field => {
						return (
							<ReduxFormInput
								field={field}
								inputProps={{
									// className: classnames(this.props.isLV && 'mt-0', !this.props.isLV && 'mt-8'),
									dataCy: 'middleName-field',
									name: 'input_middleName',
									id: 'input-middleName',
									error: translate('ConnectToPoints.inputs.middleName.errorText'),
									hasError: !field.meta.valid,
									label: translate('ConnectToPoints.inputs.dod-middleName.label'),
									required: true
								}}
							/>
						);
					}}
				/>
			);
		this.setState({ middleNameField });
	};

	initLastNameField = () => {
		const { translate } = this.props,
			lastNameField = (
				<Field
					name="lastName"
					component={field => {
						return (
							<ReduxFormInput
								field={field}
								inputProps={{
									// className: classnames(this.props.isLV && 'mt-0', !this.props.isLV && 'mt-8'),
									dataCy: 'lastName-field',
									name: 'input_lastName',
									id: 'input-lastName',
									error: translate('ConnectToPoints.inputs.lastName.errorText'),
									hasError: !field.meta.valid,
									label: translate('ConnectToPoints.inputs.dod-lastName.label'),
									required: true
								}}
							/>
						);
					}}
				/>
			);
		this.setState({ lastNameField });
	};

	initEmailField = () => {
		let emailField = (
			<Field
				name="email"
				component={field => {
					return (
						<ReduxFormInput
							field={field}
							inputProps={{
								dataCy: 'email-field',
								name: 'input_email',
								id: 'input-email',
								error: this.props.translate('ConnectToPoints.inputs.employeeEmail.errorText'),
								hasError: !field.meta.valid,
								label: this.props.translate('ConnectToPoints.inputs.employeeEmail.label'),
								help: this.props.translate('ConnectToPoints.inputs.employeeEmail.help'),
								example: this.props.translate('ConnectToPoints.inputs.employeeEmail.example')
							}}
						/>
					);
				}}
			/>
		);

		this.setState({ emailField });
	};

	isCleanState = vals => {
		if (!this.props.anyTouched) {
			// 	return true;
		}

		if (!vals) {
			vals = this.props.formVals;
		}

		return isEqual(vals, this.props.initialValues);
	};

	submit = async vals => {
		this.props.clearErrors();

		if (this.isCleanState(vals)) {
			this.props.history.replace('/profile');
			return;
		} else {
			const { id, type } = this.props.card;
			const idType = getInternalConnectionType(type);
			const errors = validateEditPaymentForm(vals, idType, this.props.card, this.props.isDoD);

			if (Object.keys(errors).length) {
				throw new SubmissionError(errors);
			} else {
				this.updateConnection(vals, id, idType);
			}
		}
	};

	updateConnection = (vals, cardId, cardIdType) => {
		const cardInfo = {
			nameOnCard: vals.nameOnCard,
			firstName: vals.firstName,
			middleName: vals.middleName,
			lastName: vals.lastName
		};

		if (vals.cardNumber.indexOf('X') === -1) {
			// only add this if they changed the card number
			cardInfo.number = vals.cardNumber;
		}

		if (cardIdType === 'email') {
			cardInfo.email = vals.email;
		} else if (cardIdType === 'empID') {
			cardInfo.employeeId = vals.employeeId;
		}

		if (this.props.isDoD) {
			cardInfo.nameOnCard = `${cardInfo.firstName} ${cardInfo.middleName} ${cardInfo.lastName}`;
			this.props.updatePaymentInfo({ connectionID: cardId, payment: { ...cardInfo } }, response => {
				if (response.code === 200) {
					this.props.updateUser(
						{
							name: {
								first: cardInfo.firstName,
								middle: cardInfo.middleName,
								last: cardInfo.lastName
							}
						},
						() => {
							this.props.getProfile(false);
							this.props.history.goBack();
						}
					);
				}
			});
		} else {
			this.props.updatePaymentInfo({ connectionID: cardId, payment: { ...cardInfo } }, response => {
				if (response.code === 200) {
					this.props.getProfile();
					this.props.history.goBack();
				}
			});
		}
	};

	onCancel = e => {
		if (this.isCleanState(this.props.formVals)) {
			this.props.history.replace('/profile');
		} else {
			this.props.onCancel(e);
		}
	};

	render() {
		const {
			card: { type },
			isDoD,
			translate
		} = this.props;
		const idType = getInternalConnectionType(type);

		return (
			<form name="editPayment" onSubmit={this.props.handleSubmit(this.submit)}>
				<RequiredFieldNote />
				<ProfileSectionList
					section="payment"
					list={[
						{
							key: 'company',
							value: this.props.companyName
						}
					]}
					className="mb-4"
				/>
				{this.state.cardNumberField}
				{!isDoD && this.state.nameOnCardField}
				{isDoD && this.state.firstNameField}
				{isDoD && this.state.middleNameField}
				{isDoD && this.state.lastNameField}
				{idType === 'empID' && this.state.employeeIdField}
				{idType === 'email' && this.state.emailField}
				<EditProfileActions onCancel={this.onCancel.bind(this)} />
				<div className="delete-payment-wrapper center">
					<a className="delete-payment-btn" onClick={this.props.onDelete} data-cy="delete-connection-button">
						{translate('Profile.payment.delete')}
					</a>
				</div>
			</form>
		);
	}
}

EditPaymentFormNew.defaultProps = {};

EditPaymentFormNew.propTypes = {
	anyTouched: PropTypes.bool.isRequired,
	card: PropTypes.object.isRequired,
	clearErrors: PropTypes.func.isRequired,
	companyName: PropTypes.string.isRequired,
	formVals: PropTypes.object.isRequired,
	getProfile: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	initialValues: PropTypes.object.isRequired,
	isDoD: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	translate: PropTypes.func.isRequired,
	updatePaymentInfo: PropTypes.func.isRequired,
	updateUser: PropTypes.func.isRequired
};

export default EditPaymentFormNew;
