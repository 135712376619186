import AutoEnrollUserExpiredToken from './ui-component';
import { connect } from 'react-redux';
import { generateNewAutoEnrollToken } from 'actions/auto_enroll_user_form_page';
import { getTranslate } from 'react-localize-redux';

const mapStateToProps = state => ({
	error: state.autoEnrollUserFormPage.error,
	isLoading: state.autoEnrollUserFormPage.isLoading,
	linkSent: state.autoEnrollUserFormPage.linkSent,
	translate: getTranslate(state.locale)
});

const mapDispatchToProps = dispatch => ({
	generateNewAutoEnrollToken: userData => dispatch(generateNewAutoEnrollToken(userData))
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoEnrollUserExpiredToken);
