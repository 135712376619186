import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import { loadRecentSearches } from 'utils/search';
import classnames from 'classnames';

import { chain, isFunction } from 'lodash';

class RecentSearches extends Component {
	state = {
		activeItem: undefined,
		recentSearches: []
	};

	componentDidMount() {
		const { hasGeolocation, onListLoad, recent, type } = this.props;
		const recentSearches = loadRecentSearches(hasGeolocation, recent, type);
		onListLoad && onListLoad(recentSearches.length > 0);

		this.setState(currState => ({
			...currState,
			recentSearches
		}));
	}

	onMouseEnter = (index, e) => {
		this.setActiveItem(index);
		this.props.onMouseEnter && this.props.onMouseEnter(e);
	};

	onMouseLeave = (index, e) => {
		this.setActiveItem(index);
		this.props.onMouseLeave && this.props.onMouseLeave(e);
	};

	setActiveItem = index => {
		this.setState({ activeItem: index });
	};

	render() {
		const { className, translate } = this.props,
			classProps = classNames('recent-searches', className),
			list = this.generateList();

		return (
			list.length > 0 && (
				<div className={classProps}>
					<span className="label">{translate('Home.searchDropdown.recentLabel')}</span>
					<div>{list}</div>
				</div>
			)
		);
	}

	generateList = () => {
		const { recent } = this.props;

		return chain(this.state.recentSearches)
			.map((item, key) => {
				let keywords = '',
					near = '',
					msg = '';
				switch (recent) {
					case 'keywords':
						msg = keywords = item['keywords'];
						break;
					case 'near':
						msg = near = item['near'];
						break;
					case 'all':
					default:
						keywords = item['keywords'];
						near = item['near'];
						msg = item['message'];
						break;
				}

				const classProps = classnames('item', key === this.state.activeItem && 'suggestion-active');

				return (
					<Button
						key={key}
						className={classProps}
						color="link"
						data-find={keywords}
						data-near={unescape(near)}
						data-cy={`recent-search-${key}`}
						onClick={this.handleRecentSearches}
						onMouseEnter={this.onMouseEnter.bind(this, key)}
						onMouseLeave={this.onMouseLeave.bind(this, undefined)}
					>
						{unescape(msg)}
					</Button>
				);
			})
			.value();
	};

	handleRecentSearches = event => {
		event.stopPropagation();
		let data = {};

		if (event.target.dataset.near) {
			data.near = event.target.dataset.near;
		}

		if (event.target.dataset.find) {
			data.find = event.target.dataset.find;
		}

		// this.props.setSearchSuggestion(data.suggestions);

		if (isFunction(this.props.recentCallback)) {
			this.props.recentCallback(data);
		}
	};
}

RecentSearches.defaultProps = {
	className: '',
	recent: 'all'
};

RecentSearches.propTypes = {
	className: PropTypes.string,
	// setSearchSuggestion: PropTypes.func,
	hasGeolocation: PropTypes.bool,
	onMouseEnter: PropTypes.func,
	onMouseLeave: PropTypes.func,
	onListLoad: PropTypes.func,
	recent: PropTypes.oneOf(['keywords', 'near', 'all']),
	recentCallback: PropTypes.func,
	translate: PropTypes.func.isRequired,
	type: PropTypes.oneOf(['catering', 'business'])
};

export default RecentSearches;
