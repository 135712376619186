import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'reactstrap';
import metaTagConfig from './meta-tags';
import { MetaTags } from 'components/molecules';
import { hasProp } from 'utils/object';

export class Completion extends Component {
	constructor(props) {
		super(props);
	}

	componentDidMount = async () => {
		this.props.updateHeader(true);
	};

	componentWillUnmount() {
		this.props.updateHeader(false);
	}

	render() {
		const {
			translate,
			location,
			connectedViaReferral,
			className,
			registeredAsIneligible,
			isDoD,
			isDoorDash,
			companyCode
		} = this.props;
		const classProps = classNames(
			'completion',
			className,
			isDoD && 'dod',
			isDoorDash && !this.props.isSV && 'doordashComplete_lv doordash'
		);

		const isConnectPage = location.pathname === '/users/connect';
		const hasConnection =
			hasProp(this.props.users, 'user.connections') && this.props.users.user.connections.length > 0;

		let titleId;
		if (isDoD) {
			titleId = 'Complete.title_no_cc'; // Let's Start Earning!
		} else if (isDoorDash) {
			titleId = 'Complete.title_doordash';
		} else if (registeredAsIneligible) {
			titleId = 'Complete.title_registered_ineligible'; //Thanks for your interest in myDinova Rewards
		} else {
			if (hasConnection) {
				titleId = 'Complete.title'; //CONGRATULATIONS!
			} else {
				titleId = 'Complete.title_no_cc'; // Let's Start Earning!
			}
		}

		let descId,
			refferal = false;

		if (isDoD) {
			descId = 'Complete.body_header_dod';
		} else if (isDoorDash) {
			descId = 'Complete.body_header_doordash';
		} else if (
			!translate(`cobranding.companies.${companyCode}.page_copy.Complete.body_header`).includes('Missing')
		) {
			descId = 'cobranding.companies.' + companyCode + '.page_copy.Complete.body_header';
		} else if (isConnectPage) {
			descId = 'Complete.body_header_registered';
		} else if (connectedViaReferral) {
			if (hasConnection) {
				descId = 'Complete.body_header_referred';
				refferal = true;
			} else {
				descId = 'Complete.body_header_referred_no_cc';
			}
		} else {
			if (registeredAsIneligible) {
				descId = 'Complete.body_header_registered_ineligible';
			} else {
				descId = 'Complete.body_header';
			}
		}

		return (
			<div className={classProps}>
				<MetaTags tags={metaTagConfig} />

				<div className="inner-content">
					<h3 className="title" data-cy="completion-title">
						{translate(titleId)}
					</h3>
					<p className={classNames('desc', !refferal && 'text-left')}>{translate(descId)}</p>
					<Button color="primary" className="z-depth-0 continue-btn" onClick={this.props.onClick}>
						{translate('Complete.button_registered')}
					</Button>

					{location.pathname === '/users/register' && [
						<span className="download-text" key={0}>
							{translate('Complete.body_footer')}
						</span>,
						<div className="stores" key={1}>
							<Button // block
								color="transparent"
								className="z-depth-0 appleBadge"
								onClick={this.gotoAppStore}
							/>
							<Button // block
								color="transparent"
								className="z-depth-0 playBadge"
								onClick={this.gotoGooglePlay}
							/>
						</div>
					]}
				</div>
			</div>
		);
	}

	gotoAppStore = () => {
		window.open('https://itunes.apple.com/us/app/dinova-restaurant-marketplace/id829424959?mt=8&at=10l6Xd');
	};

	gotoGooglePlay = () => {
		window.open(
			'https://play.google.com/store/apps/details?id=com.dinova.locator&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
		);
	};
}

Completion.propTypes = {
	className: PropTypes.string,
	connectedViaReferral: PropTypes.bool.isRequired,
	isDoD: PropTypes.bool,
	isDoorDash: PropTypes.bool,
	location: PropTypes.object.isRequired, // react router
	// logout: PropTypes.func.isRequired,
	onClick: PropTypes.func.isRequired,
	registeredAsIneligible: PropTypes.bool,
	translate: PropTypes.func.isRequired,
	updateHeader: PropTypes.func.isRequired,
	users: PropTypes.object,
	companyCode: PropTypes.string,
	isSV: PropTypes.bool
};

export default Completion;
