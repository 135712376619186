import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { history } from 'utils/router';

/**
 * Header Level navigate button.  Goes back one step in history.
 * @extends Component
 *
 * @param {BackButtonProps} props
 */
class BackButton extends Component {
	constructor(props) {
		super(props);

		this.back = this.back.bind(this);
	}

	/**
	 * Go back one step in react-router history stack.
	 * If history stack length is less than one, go back to this.props.baseRoute.
	 * @param  {Object} e Back button click event.
	 */
	back = e => {
		e.preventDefault();

		const { baseRoute } = this.props;

		if (
			history.length > 1 &&
			this.props.lastLocation &&
			this.props.lastLocation.pathname + this.props.lastLocation.hash !==
				window.location.pathname + window.location.hash
		) {
			if (this.props.lastLocation.pathname === '/catering/' && window.location.href.indexOf('about_us') > -1) {
				// prevent toggle between about us and catering tabs.
				history.push(baseRoute);
			} else {
				history.goBack();
			}
		} else {
			history.push(baseRoute);
		}
	};

	zupplerAboutBack = e => {
		e.preventDefault();

		if (!this.props.isCateringSearchFlow) {
			history.replace(this.props.lastSearch);
		} else {
			if (window.location.pathname + window.location.hash === '/catering/#/') {
				history.replace('/');
			} else {
				window.zupplerBridge && window.zupplerBridge.setPage('/#');
				history.replace('/catering/#/');
			}
		}
	};

	render() {
		if (this.props.useZupplerBack && window.location.pathname + window.location.hash !== '/catering/#/') {
			return (
				<React.Fragment>
					<div
						id="zuppler-navigation-back"
						className="zuppler-dinova-link"
						onClick={
							(this.props.cateringIntegrationType === 'order' ||
								this.props.cateringIntegrationType === 'user:login') &&
							this.props.isCateringSearchFlow
								? undefined
								: this.zupplerAboutBack
						}
						hidden={window.location.href.indexOf('about_us') > -1}
					/>
					{window.location.href.indexOf('about_us') > -1 && (
						<a
							className="back-link"
							onClick={
								this.props.cateringIntegrationType === 'order' ||
								this.props.cateringIntegrationType === 'user:login'
									? undefined
									: this.zupplerAboutBack
							}
						>
							<i className="fa fa-chevron-left" />
							{this.props.translate('Details.back')}
						</a>
					)}
				</React.Fragment>
			);
		}

		return (
			<a className="back-link" onClick={this.props.onClick || this.back}>
				<i className="fa fa-chevron-left" />
				{this.props.translate('Details.back')}
			</a>
		);
	}
}

BackButton.defaultProps = {
	baseRoute: '/',
	useZupplerBack: false
};

/**
 * {@link BackButton} Props
 * @interface Props_BackButton
 * @property {Object} history React Router history object.
 * @property {String} [baseRoute] The "home" route. Base route will be used as back button goTo route if history.length < 1.
 */
BackButton.propTypes = {
	// Required
	translate: PropTypes.func.isRequired,
	lastLocation: PropTypes.object, // <- is required but if no history this will come in null
	isCateringSearchFlow: PropTypes.bool.isRequired,
	// Optional
	baseRoute: PropTypes.string,
	onClick: PropTypes.func,
	useZupplerBack: PropTypes.bool,
	lastSearch: PropTypes.string,
	cateringIntegrationType: PropTypes.string
};

export default BackButton;
